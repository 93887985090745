import React, { useEffect } from "react";
import NewStoreApproveTable from "./NewStoreApproveTable";
import ArticleListView from "./ArticleList/ArticleListView"
import { HEADER } from "../NewStoreApproveConstants"
import { connect } from "react-redux";
import * as Notify from "../../../components/Notification/Notifications";
import PageLoader from "../../../components/Loader/PageLoader";
import { getNewStoreApproveList, getNewStoreArticleDetails, resetNSApprove } from "../actions/NewStoreApprove/NewApproveAction"
import { useState } from "react";

const NewStoreApproveView = (props) => {
    const { data, dataLoading, dataError, getNewStoreApproveList, getNewStoreArticleDetails, resetNSApprove, type, store } = props
    const [showStoreDetails, setShowStoreDetails] = useState(false)
    const [selectedStore, setSelectedStore] = useState()

    useEffect(() => {
        getNewStoreList()
        getArticleDetails()
    }, [])

    const getArticleDetails = () => {
        setShowStoreDetails(false)
        // setSelectedStore(store)
        setTimeout(() => {
            setShowStoreDetails(true)
        }, 100)
        getNewStoreArticleDetails({ isRelease: type === "new_store_release", store_code: store?.store_code })
    }

    const getNewStoreList = () => {
        getNewStoreApproveList(type === "new_store_release" ? { "status": "Released" } : {})
    }

    useEffect(() => {
        return () => {
            resetNSApprove()
        }
    }, [])

    return (
        <div className="container__wrapper">
            <div className="container__header m-0">
                <h3 className="fnt-bold">New Store {type === "new_store_release" ? "Release" : "Approval"}</h3>
            </div>
            <PageLoader loader={dataLoading} gridLoader={true}>
                {dataError ?
                    <div className="error">{dataError}</div> :
                    <NewStoreApproveTable data={data.filter(item => item.store_code == store?.store_code)} getArticleDetails={getArticleDetails} />}            </PageLoader>
            {
                showStoreDetails &&
                <section className="section mt-4">
                    <ArticleListView selectedStore={store} type={type} getNewStoreApproveList={getNewStoreList} />
                </section>
            }
        </div>
    )
}

const mapStateToProps = ({ nsApproveReducer }) => ({
    data: nsApproveReducer.data,
    dataLoading: nsApproveReducer.dataLoading,
    dataError: nsApproveReducer.dataError
});

const mapDispatchToProps = (dispatch) => ({
    getNewStoreApproveList: (payload) => dispatch(getNewStoreApproveList(payload)),
    getNewStoreArticleDetails: (payload) => dispatch(getNewStoreArticleDetails(payload)),
    resetNSApprove: () => dispatch(resetNSApprove())
});

export default connect(mapStateToProps, mapDispatchToProps)(NewStoreApproveView);