import React from "react";

const EmbededPaginationMarkup = ({
  flatRows,
  totalCount,
}) => {
  
  return (
    <div className="pagination d-flex justify-content-between mt-3">
          <span>
            Total Records {" "}
            {totalCount || flatRows?.length}
          </span>
    </div>
  );
};

export default EmbededPaginationMarkup;
