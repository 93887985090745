import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getExcessGraphData } from "./Actions/ExcessAction";
import LineChartStack from "../../../components/LineChart/LineChartStack";
import { isEmpty } from "lodash";

/**
 * Function component with Lost Sales Graph View
 * @param {*} props
 * @returns
 */

const ExcessReportGraph = (props) => {
  useEffect(() => {
    if (!isEmpty(props.filters) && props.call) {
      props.getExcessGraphData(props.filters);
    }
  }, [props.filters]);

  return (
    <>
      {props.graphDataSuccess && !isEmpty(props.graphData) ? (
        <div className="excess-graph__container">
          <h4 className="line-chart__heading mb-4">Excess Inventory</h4>
          <LineChartStack
            height={window.innerHeight / 1.5}
            xAxisLabel={"Week"}
            yAxisLabel={"Units"}
            data={props.graphData}
            stacked={false}
          />
        </div>
      ) : props.graphDataError ? (
        <div className="error">{props.graphDataError}</div>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ excessReport }) => {
  return {
    // graphData: cloneDeep(excessReport.excessGraphData),
    graphData: excessReport.excessGraphData,
    graphDataLoading: excessReport.graphDataLoading,
    graphDataError: excessReport.graphDataError,
    graphDataSuccess: excessReport.graphDataSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getExcessGraphData: (payload) => dispatch(getExcessGraphData(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ExcessReportGraph));
