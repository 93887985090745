import * as actionTypes from "../actions/ActionTypes"
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { storeCapacityState } from './InitialState';

export default {
    storeCapacity: handleActions(
        {
            [actionTypes.GET_STORE_CAPACITY]: (state, { payload }) => {
                return immutable(state, {
                    storeCapacityLoading: { $set: true },
                    storeCapacityError: { $set: null },
                    storeCapacityData: { $set: {} },
                    saveSuccess: { $set: false },
                    saveError: { $set: null }
                })
            },
            [actionTypes.GET_STORE_CAPACITY_SUCCESS]: (state, payload ) => {
                return immutable(state, {
                    storeCapacityLoading: { $set: false },
                    storeCapacityError: { $set: null },
                    storeCapacityData: { $set: payload?.data || {} },
                    // allocationStatus: { $set: true }
                })
            },
            [actionTypes.GET_STORE_CAPACITY_ERROR]: (state, payload) => {
                return immutable(state, {
                    storeCapacityLoading: { $set: false },
                    storeCapacityError: { $set: (payload && payload.error) || null },
                    storeCapacityData: { $set: {} }
                })
            },
            [actionTypes.SAVE_STORE_CAPACITY]: (state, { payload }) => {
                return immutable(state, {
                    storeCapacityLoading: { $set: true },
                    saveSuccess: { $set: false },
                    saveError: { $set: null }
                })
            },
            [actionTypes.SAVE_STORE_CAPACITY_SUCCESS]: (state, payload ) => {
                return immutable(state, {
                    storeCapacityLoading: { $set: false },
                    saveSuccess: { $set: (payload && payload.data) || false },
                    saveError: { $set: null }
                })
            },
            [actionTypes.SAVE_STORE_CAPACITY_ERROR]: (state, payload) => {
                return immutable(state, {
                    storeCapacityLoading: { $set: false },
                    saveError: { $set: (payload && payload.error) || null },
                    saveSuccess: { $set: false }
                })
            },
            [actionTypes.RESET]: (state) => {
                return immutable(state, {
                    storeCapacityLoading: { $set: false },
                    saveSuccess: { $set: false },
                    saveError: { $set: null }
                })
            },
        },
        storeCapacityState
    ),
};