import React from "react";
import LoadingOverlay from "react-loading-overlay";
import PulseLoader from "react-spinners/PulseLoader";

const PageLoader = ({ top, loaderMessage, loader, popUp, children, gridLoader, style }) => {
  return (
    <LoadingOverlay
      active={loader}
      spinner={<PulseLoader size={10} color={"#44677b"} />}
      text={loaderMessage || "Loading..."} 
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.96)",
          //borderRadius: "40px",
          // height:'30px'
        }),
        spinner: (base) => ({
          ...base,
          width: "50px",
        }),
        content: (base) => ({
          ...base,
          color: "#44677b",
          top: top ? top : (popUp ? "69%" : "50%"),
          transform: "translateY(-50%)",
          width: "100%",
          position: gridLoader ? "absolute" : "fixed",
          marginTop: gridLoader ? "auto" : "20px",
          left: 0,
        }),
        wrapper: (base) => ({
          ...base,
          ...style,
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  );
};

export default PageLoader;
