import React, { useState, useEffect } from "react";
import Modal from "react-awesome-modal";
import CreateStoreGroupModalRT from "./CreateStoreGroupModalRT";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CreateStoreGroupFilter from "./CreateStoreGroupFilter";
import {
  createStoreGroupAction,
  createStoreGroupFiltersAction,
  storeGroupAction,
  editStoreGroupAction,
  setstoreDataInFirstOrSecondTable,
} from "../../containers/StoreGroup/StoreGroupAction"

import * as Notify from "../Notification/Notifications";
import Notification from "../Notification/Notifications";

export const GetInstanceContext = React.createContext();

function CreateStoreGroupModal(props) {
  const [storeGroupName, setstoreGroupName] = useState("");
  const [RTinstance2, setRTinstance2] = useState(null);
  const [RTinstance1, setRTinstance1] = useState(null);
  const [errorClassName, setErrorClass] = useState(null);
  const [errorState, setErrorState] = useState(false);
  const [callBackData, setCallBackData] = useState({});
  const [createStoreGroupError,setCreateStoreGroupError] = useState(null);
  const [shouldResetTableData, setResetTableData] = useState(false)

  const onSave = async () => {
    const rows =
      RTinstance2 &&
      RTinstance2.data.map((item, i) => {
        return item.store_code;
      });
    if (storeGroupName.length && rows.length >= 3) {
      var regex = /^[^a-zA-Z0-9]+$/;
      if (storeGroupName.match(regex) === null) {
        if (props.existingStoreCode?.storeGroupCode) {
          let l_l1Name = callBackData.data.l1_name ? callBackData.data.l1_name[0] : props.existingStoreCode?.description
          let editStoreResult = await props.editStoreGroup({
            store_group_name: storeGroupName,
            stores_codes: rows,
            store_group_code: props.existingStoreCode?.storeGroupCode.toString(),
            "l1_name": l_l1Name
          });
        } else {
          let response = await props.createStoreGroup({
            store_group_data: [
              {
                store_group_name: storeGroupName,
                stores_codes: rows,
                "l1_name": callBackData.data?.l1_name[0]
              },
            ],
          });
        }
      } else {
        Notify.error("Please enter valid name!");
      }
    } else if (rows.length < 3) {
      Notify.error("Please add min 3 stores to create store group!");
    } else if (!storeGroupName.length) {
      Notify.error("Store group name cannot be empty!");
    } else {
      Notify.error("Selected stores cannot be empty!");
    }
  };

  async function checkClassNameUniq(value) {
    props.checkUniqClassNameApi({
      Store_Group_Name: [value],
    });
  }

  
  useEffect(() => {
    if (props.createStoreError == "Error") {
      // setErrorState(true);
      Notify.error("Store name '" + storeGroupName + "' is already defined!");
      setErrorClass("Store name '" + storeGroupName + "' is already defined!");
    } else {
      // setErrorState(false);
      setErrorClass(null);
    }
  }, [props.createStoreError]);

  useEffect(() => {
    if (props.editStoreError == "Error") {
      Notify.error("Store name '" + storeGroupName + "' is already defined!");
      setErrorClass("Store name '" + storeGroupName + "' is already defined!");
    } else {
      setErrorClass(null);
    }
  }, [props.editStoreError])
  
  

  useEffect(() => {
    if (props.existingStoreCode?.storeGroupName) {
      setstoreGroupName(props.existingStoreCode.storeGroupName);
    }
    props.getStoreFiltersApi({});
  }, [props.existingStoreCode.storeGroupName]);

  useEffect(() => {
    if(props.createStoreSuccess){
      Notify.success("Store Eligibility Group Created Successfully!!")
      props.storeGroupAction({});
      props.closeAlert();
    }
  }, [props.createStoreSuccess])

  useEffect(() => {
    if(props.editStoreSuccess){
      Notify.success("Store Eligibility Group Edited Successfully!!")
      props.storeGroupAction({});
      props.closeAlert();
    }
  }, [props.editStoreSuccess])


  useEffect(() => {
    if(props.createStoreGroupError){
        setCreateStoreGroupError(props.createStoreGroupError)
    }
  }, [props.createStoreGroupError])

  const getCallback = (data, tableData) => {

    setCallBackData({ data, tableData });
  };

  const onChangeStoreName = (value) => {
    setstoreGroupName(value);
    // checkClassNameUniq(value);
  };

  const resetTable = (p_boolean) => {
    setResetTableData(p_boolean)
  }

  return (
    <div className="modal_opacity">
    <Modal
      visible={props.showAlert}
      width="85%"
      height="95%"
      effect="fadeInDown"
      onClickAway={props.closeAlert}
    >
      {/* <Notification /> */}
    
      <div className="modal-container">
          <div class="modal-header"> 
                <h5 className="page-heading text-center m-0"> 
                    {props.existingStoreCode?.storeGroupCode ? "Edit Store Eligibility Group" : "Create Store Eligibility Group"}
                </h5> 
                <button type="button" class="close" data-dismiss="modal"> 
                    <span style={{ cursor: "pointer" }}>
                        <i
                        className="fa fa-times text-right m-2 "
                        aria-hidden="true"
                        onClick={props.closeAlert}
                        />
                    </span>
                </button> 
            </div> 
        {!createStoreGroupError ? (<div className="modal-middle-container">
          <div className="required row mx-2 p-2">
            <label className="input-label mt-2">
              Store Eligibility Group Name
            </label>

            <input
              className="form-input form-control w-auto ml-2"
              type={"text"}
              value={storeGroupName}
              placeholder = 'Store Eligibility Group Name'
              onChange={(e) => {
                onChangeStoreName(e.target.value);
              }}
            />
            {errorClassName && (
              <div className="errorClass">{errorClassName}</div>
            )}
          </div>

          <hr className="hr-dotted mt-top" />
          <div className="mx-2 p-2">
            <h5 className="page-heading text-left m-2 mb-4">
              Select Store filters
            </h5>
            <CreateStoreGroupFilter
              existingStoreCode={props.existingStoreCode}
              getCallback={getCallback}
              resetTableData={resetTable}
            ></CreateStoreGroupFilter>
          </div>

          <hr className="hr-dotted mt-top" />
          <GetInstanceContext.Provider
            value={{ setRTinstance1, setRTinstance2 }}
          >
            <CreateStoreGroupModalRT
              shouldResetTableData = {shouldResetTableData}
              storeGroupName={props.existingStoreCode?.storeGroupName}
              storeGroupDesc = {props.existingStoreCode?.description}
              callBackData={callBackData}
            ></CreateStoreGroupModalRT>
          </GetInstanceContext.Provider>
        </div>): (<div className="error modal-middle-container">{props.createStoreGroupError}</div>)}
        <div className="modal-footer" style={{ padding: "10px" }}>
          <div className="center">
            <button
              onClick={onSave}
              className="btn btn-primary"
              disabled={
                props.existingStoreCode?.storeGroupName === "All Stores"
                  ? true
                  : createStoreGroupError
              }
            >
              Save Selected
            </button>
          </div>
        </div>
      </div>
    </Modal>
    </div>
  );
}

const mapStateToProps = (store) => {
  return {
    createStoreSuccess: store.storeGroup?.createStoreSuccess,
    createStoreError: store.storeGroup?.createStoreError,
    editStoreError: store.storeGroup?.editStoreError,
    createStoreGroupError: store.storeGroup?.createStoreGroupError,
    editStoreSuccess: store.storeGroup?.editStoreSuccess,
  };
};


const mapDispatchToProps = (dispatch) => ({
  createStoreGroup: (bodyObj) => dispatch(createStoreGroupAction(bodyObj)),
  editStoreGroup: (bodyObj) => dispatch(editStoreGroupAction(bodyObj)),
  getStoreFiltersApi: (payload) => dispatch(createStoreGroupFiltersAction(payload)),
  storeGroupAction: (payload) => dispatch(storeGroupAction(payload)),
  setstoreDataInFirstOrSecondTable: (p) =>
  dispatch(setstoreDataInFirstOrSecondTable(p)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateStoreGroupModal));
