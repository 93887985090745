import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ForecastTableView from './ForecastTableView';
import { debounce, isEmpty } from 'lodash';
import * as adaVisualActions from './actions/AdaVisualActions';
import { FORECAST_ROW_HEADERS, ADA_VISUAL_KEYS, NETWORK_ERROR_MSG } from './AdaVisualConstants';
import "./AdaVisual.css";

/**
 * Foercast-Adjustment Toggled View compoenent
 * @param {*} props 
 * @returns 
 */
const ForecastAdjustmentView = (props) => {
    const { adaVisualActions, adaVisualData, currentFiscalYearWeek, timeConstraint, isAdaReqFailed ,isSaveSuccess} = props;
    const [updateParams, setUpdateParams] = useState({});
    const debouncedHandleChange = useCallback(debounce((data, fieldName) => {
        setUpdateParams({
            updatedValue: Number(data.value.replaceAll(',', '')),
            date: Number(data.getAttribute('name')),
            fieldName: fieldName,
        })
    }, 200), []);

    function handleAdjustedOnChange(e) {
        if (e) {
            debouncedHandleChange(e.originalEvent.target, "adjusted_forecast");
        }
    }

    function handleForecastOnChange(e) {
        if (e) {
            debouncedHandleChange(e.originalEvent.target, "forecast_multiplier");
        }
    }

    useEffect(() => {
        if (!isEmpty(updateParams)) {
            adaVisualActions.updateForecastData(updateParams);
        }
        else {
            //No action here
        }
    }, [updateParams]);

    return isAdaReqFailed ? (
      <div className="error">{NETWORK_ERROR_MSG}</div>
    ) : (
      <ForecastTableView
        adaData={adaVisualData}
        keys={ADA_VISUAL_KEYS}
        currentWeek={currentFiscalYearWeek}
        headers={FORECAST_ROW_HEADERS}
        timeConstraint={timeConstraint}
        handleAdjustedOnChange={handleAdjustedOnChange}
        handleForecastOnChange={handleForecastOnChange}
        saveSuccess={isSaveSuccess}
      />
    );
}

const mapStateToProps = ({ adaVisual }) => ({
    adaVisualData: adaVisual.adaVisualData,
    currentFiscalYearWeek: adaVisual.currentFiscalYearWeek,
    timeConstraint: adaVisual.timeConstraint,
    isAdaReqFailed: adaVisual.isAdaReqFailed,
    isSaveSuccess: adaVisual.isSaveSuccess,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForecastAdjustmentView);