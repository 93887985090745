import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { DEMO_ACTION } from "./actions";

export const demoState = {
  name: "",
};

const reducer = {
  demo: handleActions(
    {
      [DEMO_ACTION]: (state) => {
        return immutable(state, {
          name: { $set: "Allocation" },
        });
      },
    },
    demoState
  ),
};

export default reducer;
