// Edit cell renderer, which can return yellow and green BG

import React from "react";

const actionCell = {
  display: "flex",
  justifyContent: "space-evenly",
  width: "100px",
  cursor: "pointer"
}

const yellowBG = {
  fontSize: "18px",
  color: "white",
  backgroundColor: "rgb(226, 166, 0)",
  padding: "9px",
  borderRadius: "50%",
};

const greenBG = {
  fontSize: "18px",
  color: "white",
  backgroundColor: "rgb(104, 156, 0)",
  padding: "9px",
  borderRadius: "50%",
  cursor: "pointer",
};

const handleEditCell = (instance, props) => {
  if (instance && instance.row.original) {
    if (
      instance.row.original.plan_step === "2.1" ||
      instance.row.original.plan_step === "2.2" ||
      instance.row.original.plan_step === "2.3" ||
      instance.row.original.plan_step === "2.4"
    ) {
      props.history.push(`/plan?planCode=${instance.row.original.plan_code}`, {
        planStep: instance.row.original.plan_step,
      });
      window.location.reload();
    } else {
      props.history.push(
        `/plan/cluster?planCode=${instance.row.original.plan_code}`
      );
    }
  }
};

const EditIconCell = ({ instance, ...rest }) => {
  const l_instanceData = instance.row?.original;
  return (
    <div style={actionCell}>
     {
      rest.handleEdit ? (
      <div
        className="cursor-pointer"
        onClick={() => rest.handleEdit(l_instanceData)}
      >
        <i className="fa fa-edit" title="Edit" ></i>
      </div>) : null
     }
      {
        rest.handleDelete ? (
          <div
            className="cursor-pointer"
            onClick={() => rest.handleDelete(l_instanceData)}
          >
            <i className="fa  fa-trash-o" title="Delete" ></i>
          </div>) : null
      }
    </div>
  );
};

export default EditIconCell;
