import React, { useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import PageLoader from "../Loader/PageLoader";

const SaveOptionModal = (props) => {
    const { closeModal, savePreference, showModal, loading } = props
    const [saveOption, setSaveOption] = useState(1)

    return (
        <div className="modal__div">
            <Modal
                visible={showModal}
                width="fit-content"
                // height="0"
                effect="fadeInDown"
                onClickAway={closeModal}
            >
                <PageLoader loader={loading}>
                    <div className="modal-container">

                        <div className="modal-header" style={{position: "unset"}}>
                            <h5 className="page-heading text-center m-0">
                                Select Save Option
                            </h5>
                            <button type="button" className="close" data-dismiss="modal">
                                <span style={{ cursor: "pointer" }}>
                                    <i
                                        className="fa fa-times text-right m-2 "
                                        aria-hidden="true"
                                        onClick={closeModal}
                                    />
                                </span>
                            </button>
                        </div>
                        <div className="modal-middle-container modal-body mt-0 align-items-center">
                            <div className="row mx-2 p-2 save_col_options">
                                <input type="radio" id="gloabl" value={1} onChange={(e)=>setSaveOption(e.target.value)} checked={Number(saveOption) === 1} />
                                <label for="html">Global Preference</label>
                                <input type="radio" id="specific" value={2} onChange={(e)=>setSaveOption(e.target.value)} checked={Number(saveOption) === 2} />
                                <label for="css">Local Preference</label>
                            </div>
                        </div>
                        <div className="text-center" style={{ padding: "1rem" }}>
                            <div className="center">
                                <button
                                    style={{ margin: '1rem' }}
                                    onClick={closeModal}
                                    className="btn btn-secondary"
                                >
                                    Close
                                </button>
                                <button
                                    style={{ margin: '1rem' }}
                                    onClick={() => {savePreference(saveOption)}}
                                    className="btn btn-primary"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </PageLoader>
            </Modal>
        </div>
    )
}

export default SaveOptionModal