import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "./CellRenderer.scss";
import "../../Filters/Filters.css";
import { drop, intersectionWith, isEqual } from "lodash";

const MultiSelect = React.memo(({ ...instance }) => {
  const { defaultOptions, value, dropdownOptions, updateMyData, onDropDownChange, onBlurEvent, isDisabled } = instance;
  const [dropdownValue, setDropdownValue] = useState(null);
  const [isChanged, setIsChanged] = useState(false)

  useEffect(() => {
    if (Array.isArray(value)) {
      setDropdownValue(() => value);
    } else if(value) {
      setDropdownValue({ value: value, label: value });
    }
  }, [value]);

  // useEffect(() => {
  //   if(defaultOptions){
  //     setDropdownValue(() => defaultOptions[0]);
  //   }
  //   else
  //     setDropdownValue(() => value);
  // }, [value]);


  const onDropDownChangeHandler = (val) => {
    let value = intersectionWith(val, dropdownOptions, isEqual);
    setIsChanged(true)
    setDropdownValue(() => value);
    updateMyData(instance.row.index, instance.column.id, value);

    if (onDropDownChange) {
      onDropDownChange(
        instance.row.index,
        instance.column.id,
        value,
        instance.row.values.article
      );
    }
  };

  const onBlurChangeHandler = () => {
    if (onBlurEvent && isChanged) {
    onBlurEvent(        
      instance.row.index,
      instance.column.id,
      value,
      instance.row.values.article
      );
    }
    setIsChanged(false)
  }

  return (
    <>
      <ReactMultiSelectCheckboxes
        // placeholderButtonLabel={props.placeHolder}
        options={(dropdownOptions && dropdownOptions) || []}
        onChange={onDropDownChangeHandler}
        onBlur={onBlurChangeHandler}
        // onFocus={props.onFocus}
        // onKeyDown={props.onKeyDown}
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        value={dropdownValue}
      />
    </>
  );
});

export default MultiSelect;
