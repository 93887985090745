import { takeLatest, put, all, call } from "redux-saga/effects";
import { getDrafts } from "../../routes/apis";
import {
    GET_DRAFTS,
    getDraftsSuccess, getDraftsrror
} from "./DraftsAction"
import moment from "moment";
import { cloneDeep } from "lodash";
import { dateFormat } from "../../utils/commonUtilities";

function* getDraftsWorker(action) {
    const response = yield call(getDrafts, action.payload)
    let data = response.data.data
    data.forEach((item) => {
        if(item.creation_time) {
            item.creation_time = item.creation_time.split('.')[0]
        }
    })
    try {
        if (response.data.status) {
            let data = cloneDeep(response.data.data)
            data.forEach(draftRow => {
                draftRow.created_at = dateFormat(draftRow.created_at)
                draftRow.updated_at = dateFormat(draftRow.updated_at)
            })
            yield put(getDraftsSuccess({ 'data': data }))
        }
        else {
            yield put(getDraftsrror({ 'error': response.message }));
        }
    }
    catch (error) {
        yield put(getDraftsrror(error));
    }
}

function* getDraftsWatcher() {
    yield takeLatest(
        GET_DRAFTS,
        getDraftsWorker
    );
}

export function* draftsSaga() {
    yield all([
        getDraftsWatcher(),
    ]);
}