import React from "react";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import ReactTable from "../../components/Table/ReactTable";
import WithDynamicPlugins from "../../components/Table/WithPlugins";
import PageLoader from "../../components/Loader/PageLoader";
import { updateArticleName } from "../../utils/filterLevelMapping";
import { ERROR_MSG } from "../../constants/globalConstant";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

export const ReportingModal = ({
  openPopup,
  closeAlert,
  popupTitle,
  articleId,
  storeId,
  data,
  column,
  popupLoading,
  popupError,
}) => {

  return (
    <ModalComponent
      showAlert={openPopup}
      closeAlert={closeAlert}
      width="67%"
    >
      <div className="modal-container">
        <div className="modal-header">
          <div
            className="d-flex justify-content-between"
            style={{ width: "100%" }}
          >
            <div>
              <h5 className="page-heading m-0">{popupTitle}</h5>
            </div>
            <div style={{ fontSize: "1.6rem" }}>
              <span> {updateArticleName(false)} ID: </span>
              <span style={{fontWeight:"bold"}}>{articleId}</span>
            </div>
            <div style={{ fontSize: "1.6rem" }}>
              <span>Store Number: </span>
              {storeId}
            </div>
            <div>
              <button type="button" className="close" data-dismiss="modal">
                <i
                  className="fa fa-times text-right m-2 "
                  aria-hidden="true"
                  onClick={closeAlert}
                  style={{ cursor: "pointer" }}
                />
              </button>
            </div>
          </div>
        </div>
        <div
          className="modal-middle-container modal-body"
          style={{ padding: "auto" }}
        >
          <PageLoader loader={popupLoading}>
          { popupError ? (<div className="error">{ERROR_MSG}</div>) :
              <ReactTableWithPlugins
                hideColumnsFilter
                columns={column}
                data={data}
                renderMarkup="TableMarkup"
                style={{maxWidth:'fit-content', margin:'auto'}}
                tableId="reporting_modal"
              />
          }
          </PageLoader>
        </div>
      </div>
    </ModalComponent>
  );
};
