import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const SetAllTable = (props) => {

  const [modal, setModal] = useState(true);

  const toggle = () => {
    setModal(!modal);
  }

  const handleCancel = () => {
    if(props.handleCancel)
    props.handleCancel()
    setModal(!modal)
  }

  const handleOk = () => {
    if(props.handleOk)
    props.handleOk()
    // if(!props.ignoreCloseOnSave)
    setModal(!modal)
  }

  const handleClose = () => {
      if(props.handleClose)
      props.handleClose()
  }

    return (
      <div>
        <Modal
          onClosed={handleClose}
          contentClassName="modalContent"
          scrollable={true}
          isOpen={modal}
          modalTransition={{ timeout: 600 }}
          backdropTransition={{ timeout: 700 }}
          toggle={toggle}
        >
          <ModalHeader toggle={toggle} className="modalHeader">
            {props.heading || "Set All"}
          </ModalHeader>
          <ModalBody style={props.modalBodyStyle}>
           {props.children}
          </ModalBody>
          <ModalFooter className="modalFooter">
            <Button color="primary" onClick={handleOk}>
              {props.buttonLabel || "Apply"}
            </Button>{" "}
            <Button color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
}

export default SetAllTable
