import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-awesome-modal";
import moment from "moment";
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from "primereact/calendar";
import * as adaVisualActions from './actions/AdaVisualActions';
import * as AdaConst from './AdaVisualConstants';
import "./AdaVisual.css";
import { isEmpty } from 'lodash';

const ForecastMultiplierModalView = (props) => {
    const [datesValue, setDatesValue] = useState(null);
    const [errorClassName, setErrorClass] = useState(null);
    const [forecastValue, setForecastValue] = useState(null);
    const [disableDaysList, setDisableDaysList] = useState([0, 2, 3, 4, 5, 6]);
    const { setShowForecastDialog, adaVisualActions, currentFiscalYearWeek, startDateStamp, endDateStamp, applyChartChange } = props;

    function handleDateChange(e) {
        setDatesValue(e.value);
        if (!e.value[1]) {
            let tempList = [1, 2, 3, 4, 5, 6];
            setDisableDaysList(tempList);
        } else if (e.value[1]) {
            let tempList = [0, 2, 3, 4, 5, 6];
            setDisableDaysList(tempList);
        } else {
            //No code here
        }
    }

    function handleApply() {
        if (forecastValue==null)
        {
          setErrorClass("Please enter value!!");
        }
        else{
        // if (datesValue && datesValue[0] && datesValue[1]) {
            adaVisualActions.updateForecastMul({
                // strt_dt: moment(datesValue[0]).format("YYYYWW"),
                // end_dt: moment(datesValue[1]).format("YYYYWW"),
                current_fw: currentFiscalYearWeek,
                forecastValue: Number(forecastValue),
            });
            setTimeout(() => {
                applyChartChange(forecastValue);
            }, 100)
        }
        // }
    };

    return (
        <div className="forecast-multiplier-opacity">
            <Modal
                visible={props.showAlert}
                width="35%"
                height="45%"
                effect="fadeInDown"
                onClickAway={() => setShowForecastDialog(false)}
            >
                <div className="modal-container">
                    <div class="modal-header">
                        <h5 className="page-heading text-center m-0">
                            {AdaConst.FORECAST_MULTIPLIER}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal">
                            <span style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-times text-right m-2 "
                                    aria-hidden="true"
                                    onClick={() => setShowForecastDialog(false)}
                                />
                            </span>
                        </button>
                    </div>

                    <div className="modal-middle-container" style={{ backgroundColor: "initial", overflow: "unset" }}>
                        {/* <span style={{ display: "flex" }} className="mx-2 p-2">
                            <label className="input-label mt-2" style={{ width: "13rem" }}>
                                {AdaConst.SELECT_DATE}
                            </label>
                            <span style={{ width: "20rem" }}>
                                <Calendar
                                    id="FORECAST_MUL_RANGE_PICKER"
                                    value={datesValue}
                                    onChange={(e) => handleDateChange(e)}
                                    selectionMode="range"
                                    style={{ height: "5vh", margin: "0% 2% 0% 1%" }}
                                    minDate={new Date(startDateStamp)}
                                    maxDate={new Date(endDateStamp)}
                                    disabledDays={disableDaysList}
                                    readOnlyInput
                                    showIcon
                                />
                            </span>
                        </span> */}
                        {/* <br /> */}

                        <span style={{ display: "flex" }} className="mx-4 p-4 multiplier_input">
                            <label className="input-label mt-2" style={{ width: "13rem" }}>
                                {AdaConst.FORECAST_MULTIPLIER}
                            </label>
                            <InputNumber
                                mode='decimal'
                                min={0}
                                minFractionDigits={1}
                                maxFractionDigits={2}
                                value={forecastValue}
                                onChange={(e) => {
                                    setForecastValue(e.value);
                                }}
                                className="form-input form-control ml-2"
                                style={{ width: "35%" }}
                            />
                               
                        </span>
                        {errorClassName && (
                                <div className="errorClass" style={{marginLeft:"32%"}}>{errorClassName}</div>
                                )}
                        <div className="modal-footer" style={{ padding: "10px" }}>
                            <div className="center">
                                <button
                                    onClick={handleApply}
                                    className="btn btn-primary"
                                >
                                    {AdaConst.APPLY}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
        </div>
    )
};

const mapStateToProps = ({ adaVisual }) => ({
    currentFiscalYearWeek: adaVisual.currentFiscalYearWeek,
    startDateStamp: adaVisual.startDateStamp,
    endDateStamp: adaVisual.endDateStamp,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForecastMultiplierModalView);