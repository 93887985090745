export const FETCH_STRATEGY_TABLE_DATA = "@@Strategy/FETCH_STRATEGY_TABLE_DATA";
export const FETCH_STRATEGY_TABLE_DATA_SUCCESS =
  "@@Strategy/FETCH_STRATEGY_TABLE_DATA_SUCCESS";
export const FETCH_STRATEGY_TABLE_DATA_ERROR =
  "@@Strategy/FETCH_STRATEGY_TABLE_DATA_ERROR";

export const FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL = "@@Strategy/FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL";
export const FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL_SUCCESS =
  "@@Strategy/FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL_SUCCESS";
export const FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL_ERROR =
  "@@Strategy/FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL_ERROR";
  

export const FETCH_FILTER_DATA = "@@Strategy/FETCH_FILTER_DATA";
export const FETCH_FILTER_SUCCESS = "@@Strategy/FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "@@Strategy/FETCH_FILTER_ERROR";

export const FETCH_STRATEGY_TABLE_DROPDOWN =
  "@@Strategy/FETCH_STRATEGY_TABLE_DROPDOWN";
export const FETCH_STRATEGY_TABLE_DROPDOWN_SUCCESS =
  "@@Strategy/FETCH_STRATEGY_TABLE_DROPDOWN_SUCCESS";
export const FETCH_STRATEGY_TABLE_DROPDOWN_ERROR =
  "@@Strategy/FETCH_STRATEGY_TABLE_DROPDOWN_ERROR";

export const FETCH_SG_MIN_STOCK = "@@Strategy/FETCH_SG_MIN_STOCK";
export const FETCH_SG_MIN_STOCK_SUCCESS =
  "@@Strategy/FETCH_SG_MIN_STOCK_SUCCESS";
export const FETCH_SG_MIN_STOCK_ERROR = "@@Strategy/FETCH_SG_MIN_STOCK_ERROR";

export const SET_TABLE_INSTANCE = "@@Strategy/SET_TABLE_INSTANCE";

export const FETCH_STORE_TABLE_DATA = "@@Strategy/FETCH_STORE_TABLE_DATA";
export const FETCH_STORE_TABLE_DATA_SUCCESS =
  "@@Strategy/FETCH_STORE_TABLE_DATA_SUCCESS";
export const FETCH_STORE_TABLE_DATA_ERROR =
  "@@Strategy/FETCH_STORE_TABLE_DATA_ERROR";

export const EDIT_DATA_ADA = "@@Strategy/EDIT_DATA_ADA";
export const EDIT_DATA_ADA_SUCCESS = "@@Strategy/EDIT_DATA_ADA_SUCCESS";
export const EDIT_DATA_ADA_FAILED = "@@Strategy/EDIT_DATA_ADA_FAILED";

export const FETCH_DC_DATA = "@@Strategy/FETCH_DC_DATA";
export const FETCH_DC_DATA_SUCCESS = "@@Strategy/FETCH_DC_DATA_SUCCESS";
export const FETCH_DC_DATA_ERROR = "@@Strategy/FETCH_DC_DATA_ERROR";

export const REVIEW_ADA = "@@Strategy/REVIEW_ADA";
export const REVIEW_ADA_SUCCESS = "@@Strategy/REVIEW_ADA_SUCCESS";
export const REVIEW_ADA_ERROR = "@@Strategy/REVIEW_ADA_ERROR";

export const CREATE_ALLOCATION = "@@Strategy/CREATE_ALLOCATION";
export const CREATE_ALLOCATION_SUCCESS = "@@Strategy/CREATE_ALLOCATION_SUCCESS";
export const CREATE_ALLOCATION_ERROR = "@@Strategy/CREATE_ALLOCATION_ERROR";

export const UPDATE_RESERVED_QTY = "@@Strategy/UPDATE_RESERVED_QTY";
export const UPDATE_RESERVED_QTY_SUCCESS = "@@Strategy/UPDATE_RESERVED_QTY_SUCCESS";
export const UPDATE_RESERVED_QTY_ERROR = "@@Strategy/UPDATE_RESERVED_QTY_ERROR";

export const SAVE = "@@Strategy/SAVE";
export const SAVE_SUCCESS = "@@Strategy/SAVE_SUCCESS";
export const SAVE_ERROR = "@@Strategy/SAVE_ERROR";



export const GETSTOREGROUPMINSTOCK = "@@Strategy/GETSTOREGROUPMINSTOCK";
export const GETSTOREGROUPMINSTOCK_SUCCESS = "@@Strategy/GETSTOREGROUPMINSTOCK_SUCCESS";
export const GETSTOREGROUPMINSTOCK_ERROR = "@@Strategy/GETSTOREGROUPMINSTOCK_ERROR";



export const GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD =
  "@@Strategy/GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD";
export const GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD_SUCCESS =
  "@@Strategy/GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD_SUCCESS";
export const GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD_ERROR =
  "@@Strategy/GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD_ERROR";

export const ENABLE_FILTERS = "@@Strategy/ENABLE_FILTERS";

export const GET_DRAFTS = "@@Strategy/GET_DRAFTS"
export const GET_DRAFTS_SUCCESS = "@@Strategy/GET_DRAFTS_SUCCESS"
export const GET_DRAFTS_ERROR = "@@Strategy/GET_DRAFTS_ERROR"

export const SAVE_DRAFT = "@@Strategy/SAVE_DRAFT"
export const SAVE_DRAFT_SUCCESS = "@@Strategy/SAVE_DRAFT_SUCCESS"
export const SAVE_DRAFT_ERROR = "@@Strategy/SAVE_DRAFT_ERROR"

export const DELETE_DRAFT = "@@Strategy/DELETE_DRAFT"
export const DELETE_DRAFT_SUCCESS = "@@Strategy/DELETE_DRAFT_SUCCESS"
export const DELETE_DRAFT_ERROR = "@@Strategy/DELETE_DRAFT_ERROR"

export const VALIDATE_PLAN_NAME = "@@Strategy/VALIDATE_PLAN_NAME"
export const VALIDATE_PLAN_NAME_SUCCESS = "@@Strategy/VALIDATE_PLAN_NAME_SUCCESS"
export const VALIDATE_PLAN_NAME_ERROR = "@@Strategy/VALIDATE_PLAN_NAME_ERROR"

export const SET_LOADER = "@@Strategy/SET_LOADER"

export const RESET_SAVE_DRAFT_DATA = "@@Strategy/RESET_SAVE_DRAFT_DATA"

export const RESET_FILTERS_DATA = "@@Strategy/RESET_FILTERS_DATA";
export const RESET_ALL = "@@Strategy/RESET_ALL";
export const RESET_STYLE_INDEX = "@@CONSTRAINTS/RESET_STYLE_INDEX";
export const RESET_STYLE_INDEX_FOR_CHECKALL = "@@CONSTRAINTS/RESET_STYLE_INDEX_FOR_CHECKALL";


export const resetAll = () => ({
  type: RESET_ALL,
});

export const resetStyleIndex = () => ({
  type: RESET_STYLE_INDEX,
})

export const resetStyleIndexForCA = () => ({
  type:  RESET_STYLE_INDEX_FOR_CHECKALL,
})

export const resetFiltersOptions = (payload) => ({
  type: RESET_FILTERS_DATA,
  payload,
})

export const fetchStrategyTableData = (payload) => ({
  type: FETCH_STRATEGY_TABLE_DATA,
  payload,
});

export const fetchStrategyTableDataSuccess = (payload) => ({
  type: FETCH_STRATEGY_TABLE_DATA_SUCCESS,
  payload,
});

export const fetchStrategyTableDataError = (payload) => ({
  type: FETCH_STRATEGY_TABLE_DATA_ERROR,
  payload,
});

export const fetchStrategyTableDataForCheckAll = (payload) => ({
  type: FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL,
  payload,
});

export const fetchStrategyTableDataForCheckAllSuccess = (payload) => ({
  type: FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL_SUCCESS,
  payload,
});

export const fetchStrategyTableDataForCheckAllError = (payload) => ({
  type: FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL_ERROR,
  payload,
});

export const fetchFilterData = (payload) => ({
  type: FETCH_FILTER_DATA,
  payload,
});

export const fetchFilterDataSuccesss = (payload) => ({
  type: FETCH_FILTER_SUCCESS,
  payload,
});

export const fetchFilterDataError = (payload) => ({
  type: FETCH_FILTER_ERROR,
  payload,
});

export const fetchStrategyTableDropdown = () => ({
  type: FETCH_STRATEGY_TABLE_DROPDOWN,
});

export const fetchStrategyTableDropdownSuccess = (payload) => ({
  type: FETCH_STRATEGY_TABLE_DROPDOWN_SUCCESS,
  payload,
});

export const fetchStrategyTableDropdownError = (payload) => ({
  type: FETCH_STRATEGY_TABLE_DROPDOWN_ERROR,
  payload,
});

export const fetchStoregroupMinStock = (payload) => ({
  type: FETCH_SG_MIN_STOCK,
  payload,
});

export const fetchStoregroupMinStockSuccess = (payload) => ({
  type: FETCH_SG_MIN_STOCK_SUCCESS,
  payload,
});

export const fetchStoregroupMinStockError = (payload) => ({
  type: FETCH_SG_MIN_STOCK_ERROR,
  payload,
});

export const setTableInstance = (payload) => ({
  type: SET_TABLE_INSTANCE,
  payload,
});

export const fetchStoreTableData = (payload) => ({
  type: FETCH_STORE_TABLE_DATA,
  payload,
});

export const fetchStoreTableSuccess = (payload) => ({
  type: FETCH_STORE_TABLE_DATA_SUCCESS,
  payload,
});

export const fetchStoreTableError = (payload) => ({
  type: FETCH_STORE_TABLE_DATA_ERROR,
  payload,
});

export const editDataAda= (payload) => ({
  type: EDIT_DATA_ADA,
  payload,
});

export const editDataAdaSuccess= (payload) => ({
  type: EDIT_DATA_ADA_SUCCESS,
  payload,
});

export const editDataAdaFailed= (payload) => ({
  type: EDIT_DATA_ADA_FAILED,
  payload,
});

export const reviewAda = (payload) => ({
  type: REVIEW_ADA,
  payload,
});

export const reviewAdaSuccess= (payload) => ({
  type: REVIEW_ADA_SUCCESS,
  payload,
});

export const reviewAdaError= (payload) => ({
  type: REVIEW_ADA_ERROR,
  payload,
});

export const fetchDCData = (payload) => {
  return {
    type: FETCH_DC_DATA,
    payload,
  };
};

export const fetchDCSuccess = (payload) => ({
  type: FETCH_DC_DATA_SUCCESS,
  payload,
});

export const fetchDCError = (payload) => ({
  type: FETCH_DC_DATA_ERROR,
  payload,
});

export const getStrategyTableDataFromDashboard = (payload) => ({
  type: GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD,
  payload,
});

export const getStrategyTableDataFromDashboardSuccess = (payload) => ({
  type: GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD_SUCCESS,
  payload,
});

export const getStrategyTableDataFromDashboardError = (payload) => ({
  type: GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD_ERROR,
  payload,
});

export const createAllocation = (payload,isSSE) => ({
  type: CREATE_ALLOCATION,
  payload,
  isSSE
});

export const createAllocationSuccess = (payload) => ({
  type: CREATE_ALLOCATION_SUCCESS,
  payload,
});

export const createAllocationError = (payload) => ({
  type: CREATE_ALLOCATION_ERROR,
  payload,
});

export const updateResQty = (payload) => ({
  type: UPDATE_RESERVED_QTY,
  payload,
});

export const updateResQtySuccess = (payload) => ({
  type: UPDATE_RESERVED_QTY_SUCCESS,
  payload,
});

export const updateResQtyError = (payload) => ({
  type: UPDATE_RESERVED_QTY_ERROR,
  payload,
});

export const save = (payload) => ({
  type: SAVE,
  payload,
});

export const saveSuccess = (payload) => ({
  type: SAVE_SUCCESS,
  payload,
});

export const saveError = (payload) => ({
  type: SAVE_ERROR,
  payload,
});

export const enableFilters = () => ({
  type: ENABLE_FILTERS,
});

export const getstoregroupminstock = (payload) => ({
  type: GETSTOREGROUPMINSTOCK,
  payload,
});

export const getstoregroupminstockSuccess = (payload) => ({
  type: GETSTOREGROUPMINSTOCK_SUCCESS,
  payload,
});

export const getstoregroupminstockError = (payload) => ({
  type: GETSTOREGROUPMINSTOCK_ERROR,
  payload,
});

export const getDrafts = (payload) => ({
  type: GET_DRAFTS,
  payload,
});

export const getDraftsSuccess = (payload) => ({
  type: GET_DRAFTS_SUCCESS,
  payload,
});

export const getDraftsError = (payload) => ({
  type: GET_DRAFTS_ERROR,
  payload,
});

export const saveDraft = (payload) => ({
  type: SAVE_DRAFT,
  payload,
});

export const saveDraftSuccess = (payload) => ({
  type: SAVE_DRAFT_SUCCESS,
  payload,
});

export const saveDraftError = (payload) => ({
  type: SAVE_DRAFT_ERROR,
  payload,
});

export const deleteDraft = (payload) => ({
  type: DELETE_DRAFT,
  payload,
});

export const deleteDraftSuccess = (payload) => ({
  type: DELETE_DRAFT_SUCCESS,
  payload,
});

export const deleteDraftError = (payload) => ({
  type: DELETE_DRAFT_ERROR,
  payload,
});

export const resetSaveDraftData = () => ({
  type: RESET_SAVE_DRAFT_DATA
})

export const validatePlanName = (payload) => ({
  type: VALIDATE_PLAN_NAME,
  payload
})

export const validatePlanNameSuccess = (payload) => ({
  type: VALIDATE_PLAN_NAME_SUCCESS,
  payload
})

export const validatePlanNameError = (payload) => ({
  type: VALIDATE_PLAN_NAME_ERROR,
  payload
})

export const setLoaderToFalse = (payload) => ({
  type: SET_LOADER,
  payload
})