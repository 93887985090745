import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { intersectionWith, isEqual } from 'lodash';
import { handleSelectAll } from "../../../utils/SelectAllHandler/SelectAllHander";
import MultiSelect from "../../../components/Filters/MultiSelect";
import Select from "../../../components/Filters/SingleSelect";
import * as strategyConfigActions from '../actions/StrategyConfigActions';
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import {
    STRATEGY_CONFIG_FILTER_DEPARTMENT, STRATEGY_CONFIG_FILTER_GENDER, STRATEGY_CONFIG_FILTER_SUBCATEGORY, STRATEGY_CONFIG_FILTER_DCS,
    STRATEGY_CONFIG_FILTER_STYLE, STRATEGY_CONFIG_FILTER_COLOR, STRATEGY_CONFIG_FILTER_SEASON, 
} from '../StrategyConfigConstants';
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping";
import { getUserPreferenceFilter,shouldHideForCurrentClient, setUserPreferenceFilter ,getLevelFiltersForUserPreference ,getDefaultFiltersCondition,isMandatory } from "../../../utils/commonUtilities";
import AsyncSingle from '../../../components/Filters/AsyncSingle';

/**
 * View Component with Ada Stand Alone Filters
 */
const StrategyConfigFilters = React.memo((props) => {
    const [department, setDepartment] = useState(null);
    const [gender, setGender] = useState(null);
    const [subCat, setSubCat] = useState(null);
    const [dcs, setDCS] = useState(null);
    const [level5, setLevel5] = useState(null);
    const [level6, setLevel6] = useState(null);
    const [level7, setLevel7] = useState(null); 
    const [style, setStyle] = useState(null);
    const [color, setColor] = useState(null);
    const [size, setSize] = useState(null);
    const [request, setRequest] = useState(null)
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [genderOptions, setGenderOptions] = useState(null);
    const [subCatOptions, setSubCatOptions] = useState(null);
    const [dcsOptions, setDCSOptions] = useState(null);
    const [level5Options, setLevel5Options] = useState(null);
    const [level6Options, setLevel6Options] = useState(null);
    const [level7Options, setLevel7Options] = useState(null);
    const [styleOptions, setStyleOptions] = useState(null);
    const [colorOptions, setColorOptions] = useState(null);
    const [sizeOptions, setsizeOptions] = useState(null);

    const { strategyConfigActionsProp, handleFilterRequest, activeTab, handleResetLostSales ,filterData } = props;

    useEffect(() => {
        if (activeTab === "strategyConfig") {
            getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, {onl1Change:onDepartmentChange,onl2Change:onGenderChange})
            // getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
            strategyConfigActionsProp.getStrategyConfigFiltersData({}, "department");
        } else {
            resetFilters();
            getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, {onl1Change:onDepartmentChange,onl2Change:onGenderChange})
            // getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
        }
    }, [activeTab]);

    useEffect(() => {
        strategyConfigActionsProp.getStrategyConfigFiltersData({}, "department");
    }, []);

    useEffect(() => {
        setDepartmentOptions(() => props.departmentOptions);
        setGenderOptions(() => props.genderOptions);
        setSubCatOptions(() => props.subCatOptions);
        setDCSOptions(() => props.dcsOptions);
        setLevel5Options(() => props.level5Options)
        setLevel6Options(() => props.level6Options)
        setLevel7Options(() => props.level7Options)
        setStyleOptions(() => props.styleOptions);
        setColorOptions(() => props.colorOptions);
        setsizeOptions(() => props.sizeOptions);
    }, [props.departmentOptions, props.genderOptions, props.subCatOptions, props.dcsOptions,props.level5Options,props.level6Options,props.level7Options,
    props.styleOptions, props.colorOptions, props.sizeOptions]);

    const resetRestFilters = (p_index) => {
        style && setStyle(null);
        styleOptions && setStyleOptions(null);
        color && setColor(null);
        colorOptions && setColorOptions(null);
        size && setSize(null);
        sizeOptions && setsizeOptions(null);
        if (p_index === 0) {
            gender && setGender(null);
            genderOptions && setGenderOptions(null);
        }
        if (p_index === 0 || p_index === 1) {
            subCat && setSubCat(null);
            subCatOptions && setSubCatOptions(null);
        }
        if (p_index === 0 || p_index === 1 || p_index === 2) {
            dcs && setDCS(null);
            dcsOptions && setDCSOptions(null);
        }

        if (p_index === 0 || p_index === 1 || p_index === 2 || p_index === 3) {
            level5 && setLevel5(null);
            level5Options && setLevel5Options(null)
            // style && setStyle(null);
            // styleOptions && setStyleOptions(null);
        }

        if (p_index === 0 || p_index === 1 || p_index === 2 || p_index === 3 || p_index === 4) {
            level6 && setLevel6(null);
            level6Options && setLevel6Options(null)
            // color && setColor(null);
            // colorOptions && setColorOptions(null);
        }

        if (p_index === 0 || p_index === 1 || p_index === 2 || p_index === 3 || p_index === 4 || p_index === 5) {
            level7 && setLevel7(null);
            level7Options && setLevel7Options(null)
            // size && size(null);
            // sizeOptions && setsizeOptions(null);
        }
    };

    const onDepartmentChange = (val,p_userPreference) => {
        setDepartment(val);
        if(p_userPreference?.user_preference) { 
            strategyConfigActionsProp.getStrategyConfigFiltersData({ l1_name: [val] },'l10');
          }
          else{
           strategyConfigActionsProp.getStrategyConfigFiltersData({ l1_name: [val] }, "gender");
          }
        resetRestFilters(0);
    };

    const onGenderChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], genderOptions, isEqual);
            setGender(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'l2_name': valuesArray[0],
                }
            });

            strategyConfigActionsProp.getStrategyConfigFiltersData({ l1_name: [department], l2_name: req_val }, "sub_category");
        }
        else {
            setGender(val);
            if(event?.user_preference) { 
                strategyConfigActionsProp.getStrategyConfigFiltersData({ l1_name: [event?.l1_name], l2_name: [val] },"l11");
              }
              else{
                strategyConfigActionsProp.getStrategyConfigFiltersData({ l1_name: [department], l2_name: [val] }, "sub_category");
              }
        }
        resetRestFilters(1);
    };

    const onSubCatChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], subCatOptions, isEqual);
            setSubCat(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'l3_name': valuesArray[0],
                }
            });
            strategyConfigActionsProp.getStrategyConfigFiltersData({ l1_name: [department], l2_name: gender, l3_name: req_val }, "dcs");
        }
        else {
            setSubCat(val);
            if(event?.user_preference) { 
                strategyConfigActionsProp.getStrategyConfigFiltersData({ l1_name: [event?.l1_name], l2_name: [event?.l2_name], l3_name:[val] }, "dcs");
              }
              else{
            strategyConfigActionsProp.getStrategyConfigFiltersData({ l1_name: [department], l2_name: gender?.value && [gender], l3_name:[val] }, "dcs");
              }
        }
        resetRestFilters(2);
    };

    const onDCSChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], dcsOptions, isEqual);
            setDCS(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'l4_name': valuesArray[0],
                }
            });

            strategyConfigActionsProp.getStrategyConfigFiltersData({
                l1_name: [department], l2_name: gender, l3_name: subCat,
                l4_name: req_val,
            }, "level5");
        }
        else {
            setDCS(val);
            strategyConfigActionsProp.getStrategyConfigFiltersData(
                { l1_name: [department], l2_name: gender?.value && [gender], l3_name:  subCat?.value && [subCat], l4_name: [val] },
                "level5"
            );
        }
        resetRestFilters(3);
    };

    const onLevel5Change = (val ,event, that)=>{
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], level5Options, isEqual);
            setLevel5(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'l5_name': valuesArray[0],
                }
            });

            strategyConfigActionsProp.getStrategyConfigFiltersData({
                l1_name: [department], l2_name: gender, l3_name: subCat,
                l4_name: dcs ,l5_name:req_val,
            }, "level6");
        }
        else {
            setLevel5(val);
            strategyConfigActionsProp.getStrategyConfigFiltersData(
                { l1_name: [department], l2_name: gender?.value && [gender], l3_name:  subCat?.value && [subCat], l4_name:dcs?.value && [dcs] , l5_name:[val] },
                "level6"
            );
        }
        resetRestFilters(4);
    }

    const onLevel6Change = (val ,event, that)=>{
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], level6Options, isEqual);
            setLevel6(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'l6_name': valuesArray[0],
                }
            });

            strategyConfigActionsProp.getStrategyConfigFiltersData({
                l1_name: [department], l2_name: gender, l3_name: subCat,
                l4_name: dcs ,l5_name: level5, l6_name:req_val,
            }, "level7");
        }
        else {
            setLevel6(val);
            strategyConfigActionsProp.getStrategyConfigFiltersData(
                { l1_name: [department], l2_name: gender?.value && [gender], l3_name:  subCat?.value && [subCat], l4_name:dcs?.value && [dcs] , l5_name:level5?.value && [level5] , l6_name:[val] },
                "level7"
            );
        }
        resetRestFilters(5);
    }

    const onLevel7Change = (val ,event, that)=>{
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], level7Options, isEqual);
            setLevel7(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'l7_name': valuesArray[0],
                }
            });

            // strategyConfigActionsProp.getStrategyConfigFiltersData({
            //     l1_name: [department], l2_name: gender, l3_name: subCat,
            //     l4_name: dcs ,l5_name: level5, l6_name: level6, l7_name:req_val,
            // }, "l8");
        }
        else {
            setLevel7(val);
            // strategyConfigActionsProp.getStrategyConfigFiltersData(
            //     { l1_name: [department], l2_name: gender?.value && [gender], l3_name:  subCat?.value && [subCat], l4_name:dcs?.value && [dcs] , l5_name:level5?.value && [level5] , l6_name:[val] },
            //     "l8"
            // );
        }
    }

    const onStyleChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], styleOptions, isEqual);
            setStyle(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'style': valuesArray[0],
                }
            });
            strategyConfigActionsProp.getStrategyConfigFiltersData({
                l1_name: [department], l2_name: gender, l3_name: subCat,
                l4_name: dcs, style: req_val,
            }, "color");
        }
        else {
            setStyle(val);
            strategyConfigActionsProp.getStrategyConfigFiltersData(
                { l1_name: [department], l2_name: gender?.value && [gender], l3_name: subCat?.value && [subCat], l4_name: dcs?.value && [dcs], style: [val] },
                "color"
            );
        }
        // resetRestFilters(4);
    };

    const onColorChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], colorOptions, isEqual);
            setColor(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'color': valuesArray[0],
                }
            });

            strategyConfigActionsProp.getStrategyConfigFiltersData({
                l1_name: [department], l2_name: gender, l3_name: subCat,
                l4_name: dcs, style: style, color: req_val,
            }, "size");
        }
        else {
            setColor(val);
            strategyConfigActionsProp.getStrategyConfigFiltersData(
                {
                    l1_name: [department], l2_name: gender?.value && [gender], l3_name: subCat?.value && [subCat], l4_name: dcs?.value && [dcs], 
                    style: style?.value && [style], color: [val],
                },
                "season"
            );
        }
        // resetRestFilters(5);
    };

    const onSizeChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], sizeOptions, isEqual);
            setSize(valuesArray[1]);
            strategyConfigActionsProp.getStrategyConfigFiltersData({
                l1_name: [department], l2_name: gender, l3_name: subCat,
                l4_name: dcs, style: style, color: color, size: req_val,
            });
            setRequest(old => {
                return {
                    ...old,
                    'size': valuesArray[0],
                }
            });
        }
        else {
            setSize(val);
            strategyConfigActionsProp.getStrategyConfigFiltersData(
                {
                    l1_name: [department], l2_name: gender?.value && [gender], l3_name: subCat?.value && [subCat], l4_name: dcs?.value && [dcs], 
                    style: style?.value && [style],
                    color: color?.value && [color], season: [val],
                },
            );
        }
        // resetRestFilters(7);
    };

    const applyFilters = () => {
        if (getDefaultFiltersCondition({l1_name: department, l2_name: gender, l3_name: subCat})) {
            handleFilterRequest({
                "l1_name": [department.value],
                "l2_name":  gender?.value && [gender.value],
                "l3_name": subCat?.value && [subCat.value],
                "l4_name": dcs?.value && [dcs.value],
                "l5_name":level5?.value && [level5.value],
                "l6_name":level6?.value && [level6.value],
                "l7_name":level7?.value && [level7.value],
                "style_name": style?.value && [style.value],
                "color": color?.value && [color.value],
                "season": size?.value && [size.value]
            });
            filterData({
                "l1_name": [department.value],
                "l2_name":  gender?.value && [gender.value],
                "l3_name": subCat?.value && [subCat.value],
                "l4_name": dcs?.value && [dcs.value],
                "l5_name":level5?.value && [level5.value],
                "l6_name":level6?.value && [level6.value],
                "l7_name":level7?.value && [level7.value],
                "style_name": style?.value && [style.value],
                "color": color?.value && [color.value],
                "season": size?.value && [size.value]
            });
            // setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id"), "department": department });
            setUserPreferenceFilter({"user_id":localStorage.getItem("user_id")},getLevelFiltersForUserPreference({l1_name:department,l2_name:gender}))
        } else {
            Notify.error("Please Select All Mandatory Options!!");
        }
    };

    const resetFilters = () => {
        setRequest(null);
        setDepartment(null);
        setGender(null);
        setSubCat(null);
        setLevel5(null);
        setLevel6(null);
        setLevel7(null);
        setDCS(null);
        setStyle(null);
        setColor(null);
        setSize(null);

        // handleResetLostSales();
        strategyConfigActionsProp.getStrategyConfigFiltersData({}, "department");
        strategyConfigActionsProp.resetStrategyConfigScreen();
    };

    useEffect(() => {
        getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, {onl1Change:onDepartmentChange,onl2Change:onGenderChange})
        // getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
    }, []);

    const getDDLabel = (label) => label ? label : "";
    return (
        <>
            {/* <Notification /> */}
            <section className="row section" style={{ flexDirection: "column" }}>
                <div className="row" style={{ flexDirection: "column", marginTop: "0" }}>
                    <div className="row">
                        <div className="col-md-3 col-md-3-dd required">
                            <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1}</label>
                            <Select
                                name="department"
                                value={department}
                                onChange={onDepartmentChange}
                                placeHolder={`Select ${levelFiltersLabelFormatter("level1")}`}
                                options={departmentOptions?.length ? departmentOptions : []}
                            />
                        </div>
                        <div className={`col-md-3 col-md-3-dd ${isMandatory("l2_name")}`}>
                            <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level2}</label>
                            <Select
                                name="gender"
                                value={gender}
                                onChange={onGenderChange}
                                placeHolder={`Select ${getDDLabel(props.filterLabels?.level2)}`}
                                options={genderOptions?.length ? genderOptions : []}
                            />
                        </div>
                        <div className={`col-md-3 col-md-3-dd ${isMandatory("l3_name")}`}>
                            <label className="fnt-md fnt-bold  fnt-bold">{props.filterLabels?.level3}</label>
                            <Select
                                name="subCat"
                                value={subCat}
                                onChange={onSubCatChange}
                                placeHolder={`Select ${getDDLabel(props.filterLabels?.level3)}`}
                                options={subCatOptions?.length ? subCatOptions : []}
                            />
                        </div>
                        {/* //levelFiltersLabelFormatter("level4") */}
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{props.filterLabels?.level4}</label>
                            <Select
                                name="dcs"
                                value={dcs}
                                onChange={onDCSChange}
                                placeHolder={`Select ${getDDLabel(props.filterLabels?.level4)}`}
                                options={dcsOptions?.length ? dcsOptions : []}
                            />
                        </div>
                        {
                            !shouldHideForCurrentClient("l5_name") &&
                            <div className="col-md-3 col-md-3-dd mb-3">
                                <label className="fnt-md fnt-bold">{props.filterLabels?.level5}</label>
                                <Select
                                    isDisabled={props.isDisabled}
                                    name="level5"
                                    value={level5}
                                    onChange={onLevel5Change}
                                    placeHolder={`Select ${props.filterLabels?.level5 ? props.filterLabels?.level5 : levelFiltersLabelFormatter("level5")}`}
                                    options={level5Options?.length ? level5Options : []}
                                />
                            </div>
                        }
                         {
                            !shouldHideForCurrentClient("l6_name") &&
                            <div className="col-md-3 col-md-3-dd mb-3">
                                <label className="fnt-md fnt-bold">{props.filterLabels?.level6}</label>
                                <Select
                                    isDisabled={props.isDisabled}
                                    name="level6"
                                    value={level6}
                                    onChange={onLevel6Change}
                                    placeHolder={`Select ${props.filterLabels?.level6 ? props.filterLabels?.level6 : levelFiltersLabelFormatter("level6")}`}
                                    options={level6Options?.length ? level6Options : []}
                                />
                            </div>
                        }
                         {
                            !shouldHideForCurrentClient("l7_name") &&
                            <div className="col-md-3 col-md-3-dd mb-3">
                                <label className="fnt-md fnt-bold">{props.filterLabels?.level7}</label>
                                <Select
                                    isDisabled={props.isDisabled}
                                    name="level7"
                                    value={level7}
                                    onChange={onLevel7Change}
                                    placeHolder={`Select ${props.filterLabels?.level7 ? props.filterLabels?.level7 : levelFiltersLabelFormatter("level7")}`}
                                    options={level7Options?.length ? level7Options : []}
                                />
                            </div>
                        }
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{STRATEGY_CONFIG_FILTER_STYLE}</label>
                            <AsyncSingle
                                name="Style Name"
                                dropdownValue={style}
                                onDropDownChange={onStyleChange}
                                placeHolder={'Select Style'}
                                optionsArray={styleOptions?.length ? styleOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd ">
                            <label className="fnt-md fnt-bold">{STRATEGY_CONFIG_FILTER_COLOR}</label>
                            <Select
                                name="color"
                                value={color}
                                onChange={onColorChange}
                                placeHolder={'Select Color'}
                                options={colorOptions?.length ? colorOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd ">
                            <label className="fnt-md fnt-bold">{STRATEGY_CONFIG_FILTER_SEASON}</label>
                            <Select
                                name="season"
                                value={size}
                                onChange={onSizeChange}
                                placeHolder={"Select season"}
                                options={sizeOptions?.length ? sizeOptions : []}
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <button
                                onClick={applyFilters}
                                className="btn btn-primary filter-constraints-mode"
                                title="Apply filters"
                            >
                                <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
                            </button>
                            <button
                                onClick={() => resetFilters()}
                                className="btn undo-constraints-mode"
                                title="Reset filters"
                            >
                                <i className="fa fa-undo mr-2"></i>Reset
                            </button>
                        </div>
                        <div className="col-md-3 required fnt-md pt-5" style={{ color: "red" }}>
                            <label></label>Fields are mandatory
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
);

const mapStateToProps = ({ strategyConfiguration }) => ({
    departmentOptions: strategyConfiguration.level1Options,
    genderOptions: strategyConfiguration.level2Options,
    subCatOptions: strategyConfiguration.level3Options,
    dcsOptions: strategyConfiguration.level4Options,
    level5Options: strategyConfiguration.level5Options,
    level6Options: strategyConfiguration.level6Options,
    level7Options: strategyConfiguration.level7Options,
    filterLabels: strategyConfiguration.filterlabels,
    styleOptions: strategyConfiguration.styleOptions,
    colorOptions: strategyConfiguration.colorOptions,
    sizeOptions: strategyConfiguration.seasonOptions,
});

const mapDispatchToProps = (dispatch) => ({
    strategyConfigActionsProp: bindActionCreators(strategyConfigActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StrategyConfigFilters);