export const productStatusInitialState = {
    dataLoading: false,
    data: [],
    dataError: null,
    articleData: [],
    articleDataLoading: false,
    articleDataError: null,
    storeGroupData: {},
    storeGroupDataLoading: false,
    storeGroupDataError: null,
    departmentOptions: [],
    genderOptions: [],
    rbuOptions: [],
    dcsOptions: [],
    level5Options: [],
    level6Options: [],
    level7Options: [],
    styleOptions: [],
    colorOptions: [],
    sizeOptions: [],
    productTypeOptions: [],
    productProfilesData: {},
    productProfilesDataLoading: false,
    productProfilesDataError: null,
    storeSizeContributionData: {},
    storeSizeContributionDataLoading: false,
    storeSizeContributionDataError: null,
    dcData: {},
    dcDataLoading: false,
    dcDataError: null,
    createConfigData: {},
    createConfigDataLoading: false,
    createConfigDataError: null,
    createConfigDataSuccess: false,
    deleteConfigData: {},
    deleteConfigDataLoading: false,
    deleteConfigDataError: null,
    selectedFilters: null,
    selectedStoreData: {},
    noArticleError: null,
    noConfigData: [],
    noConfigError: null,
    noConfigLoading: false,
    mappingStyles: [],
    mappingStylesError: null,
    mappingStylesLoading: false,
    productConfigTotalCount: 0,
    productConfigNextIndex: 0,
    articleConfigCheckAllData: [],
    articleConfiCheckAllNextIndex: 0,
    articleConfiCheckAllTotalCount: 0,
    downloadExcelData: [],
    downloadNextIndex: 0,
    downloadTotalCount: 0,
    downloadExcelError: null,
    updateAutoAllocationLoading: false,
    updateAutoAllocationSuccess: false,
    updateAutoAllocationError: false,
    styleMappingNextIndex: 0,
    styleMappingTotalCount: 0
}