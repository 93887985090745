import React, { useState } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import {
  BASE_ROUTE,
  STORE_PAGE,
  DASHBOARD_PAGE,
  USER_MANAGEMENT_PAGE,
} from "../constants/routeConstants";

const PrivateRoutes = ({
  component: Component,
  groupId,
  ...rest
}) => {
  
  const getToRoute = (p_groupId) => {
    if(p_groupId == 2)
     return USER_MANAGEMENT_PAGE
    if(p_groupId == 3)
      return DASHBOARD_PAGE
    if(!p_groupId)
      return BASE_ROUTE
  }

  let l_groupId = localStorage.getItem("group_id") ? Number(localStorage.getItem("group_id")) : null
  let to = getToRoute(l_groupId)

  return (
    <Route
      {...rest}
      render={(props) =>
       groupId.includes(l_groupId) ? <Component {...props} /> : <Redirect to={to} />
      }
    />
  );
};

PrivateRoutes.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  to: PropTypes.string,
};

// PrivateRoutes.defaultProps = {
//   to: BASE_ROUTE,
// };

export default PrivateRoutes;




// import React from "react";
// import PropTypes from "prop-types";
// import { Route, Redirect } from "react-router-dom";
// import {
//   fetchUserPermission,
//   fetchUserRole,
//   fetchUserID,
//   fetchUserName,
// } from "../containers/Login/LoginActions";
// import { connect } from "react-redux";
// import { isEmpty } from "lodash";

// var jwt_decode = require("jwt-decode");

// const RoutePrivate = function ({
//   component: Component,
//   isAuthenticated,
//   to,
//   ...rest
// }) {
//   var decoded = localStorage.getItem("access_token")
//       ? jwt_decode(localStorage.getItem("access_token"))
//       : {},
//     redirect = false,
//     permissionArray = [],
//     userRoleArray = [],
//     userNameArray = [],
//     userIdArray = null;

//   console.log("decodedata", decoded);

//   if (!isEmpty(decoded)) {
//     decoded.groups.map((roles) => {
//       console.log("rolesname,", roles, decoded);

//       return userRoleArray.push(roles.name);
//     });
//     // decoded.map((items) => {
//     userNameArray = decoded.name;
//     // });
//     userIdArray = decoded.user_id;

//     decoded.groups.forEach((data) => {
//       data.permissions.forEach((permission) => {
//         permissionArray.push(permission);
//       });
//     });
//   } else {
//     redirect = true;
//   }
//   var uniquePermissionArray = [...new Set(permissionArray)];

//   rest.fetchUserPermission(uniquePermissionArray);
//   rest.fetchUserRole(userRoleArray);
//   rest.fetchUserID(userIdArray);
//   rest.fetchUserName(userNameArray);

//   isAuthenticated = true;

//   return (
//     <Route
//       exact={true}
//       {...rest}
//       render={(props) =>
//         rest.condition.every(function (permission) {
//           return uniquePermissionArray.indexOf(permission) >= 0;
//         }) && !redirect ? (
//           <Component {...props} {...rest} />
//         ) : (
//           <Redirect
//             to={{
//               pathname: to,
//               state: { redirect: props.location.pathname, isAuthenticated },
//             }}
//           />
//         )
//       }
//     />
//   );
// };

// RoutePrivate.propTypes = {
//   component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
//   isAuthenticated: PropTypes.bool,
//   location: PropTypes.object,
//   to: PropTypes.string,
// };

// RoutePrivate.defaultProps = {
//   to: "/",
// };

// const mapDispatchToProps = (dispatch) => ({
//   fetchUserPermission: (payload) => dispatch(fetchUserPermission(payload)),
//   fetchUserRole: (payload) => dispatch(fetchUserRole(payload)),
//   fetchUserID: (payload) => dispatch(fetchUserID(payload)),
//   fetchUserName: (payload) => dispatch(fetchUserName(payload)),
// });

// export default connect(null, mapDispatchToProps)(RoutePrivate);
