import React from "react";
import Modal from "react-awesome-modal";

const ModalComponent = ({ height, width, children, showAlert, closeAlert }) => {
  return (
    <Modal
      visible={showAlert}
      width={width}
      height={height}
      effect="fadeInDown"
      onClickAway={closeAlert}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
