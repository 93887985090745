import * as actionTypes from "./ActionTypes"

export const getStoreCapacity = (payload) => ({
    type: actionTypes.GET_STORE_CAPACITY,
    payload,
});

export const saveStoreCapacity = (payload) => ({
    type: actionTypes.SAVE_STORE_CAPACITY,
    payload
})

export const reset = () => ({
    type: actionTypes.RESET
})