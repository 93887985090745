import React, { useEffect, useState } from 'react'
import LevelFilters from './LevelFilters';
import SetAttributesFilter from './SetAttributesFilter';
import SelectProducts from './SelectProducts';
import ProfiledProducts from './ProfiledProducts';
import moment from "moment";
import { connect } from 'react-redux';
import { fetchCreateFilterData, fetchProductListContribution, fetchProfiledProduct, fetchSavedData, resetFiltersOptions, resetProductProfileData } from '../ProductProfileAction';
import PageLoader from '../../../components/Loader/PageLoader';
import { useHistory } from "react-router-dom";
import { CREATE_CONFIGURATION, PROFILE_PAGE } from '../../../constants/routeConstants';
import * as Notify from '../../../components/Notification/Notifications'

function CreateEditProductprofile(props) {
    const [levelFilters, setLevelFilters] = useState({})
    const [selectedFilters, setSelectedFilters] = useState({})
    const [showSetAttributeFilter, setShowSetAttributeFilter] = useState(false)
    const [showSetSelectProducts, setShowSetSelectProducts] = useState(false)
    const [showProfiledProducts, setShowProfiledProducts] = useState(false)
    const [existingData, setExistingData] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [profileName, setProfileName] = useState(null)
    const [profileDescription, setProfileDescription] = useState(null)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErroMessage] = useState('')
    const [isProductConfigFlow, setIsProductCoinfig] = useState(false)

    let history = useHistory();

    useEffect(() => {
        resetFiltersOptions([])
        props.resetProductProfileData()
        if(props?.match?.params?.profileId){
            setIsEdit(true)
            props.fetchSavedData(props?.match?.params?.profileId)
            setShowSetAttributeFilter(true)
            setShowSetSelectProducts(true)
            // setShowProfiledProducts(true)
            setSuccessMessage('Product Profile Edited Successfully!!')
            setErroMessage('Error in Editing Product Profile!!')
        }
        else{
            setSuccessMessage('Product Profile Created Successfully!!')
            setErroMessage('Error in Creating Product Profile!!')
        }
    },[])


    useEffect(() => {
        if(props.saveSuccess){
            Notify.success(successMessage)
            setTimeout(() => {
                if(props.location?.state?.selectedFilters) {
                    goBackToCreateConfig()
                }
                else {
                    history.push(`${PROFILE_PAGE}`)
                }
            }, 2000);  
        }
        else if(props.saveError){
            Notify.error(props.saveError === "Duplicate" ? `${profileName} is already defined!`
            : errorMessage)
        }
    },[props.saveSuccess,props.saveError])

    const goBackToCreateConfig = () => {
        history.push(`${CREATE_CONFIGURATION}`, {isPPCreated: true, selectedFilters: props.location?.state?.selectedFilters})
    }

    useEffect(() => {
        return () => {
          props.resetFiltersOptions()
        }
      }, [])

    const onProfileNameChange = (val) => {
        setProfileName(val)
    }

    const onProfileDescriptionChange = (val) => {
        setProfileDescription(val)
    }

    const prepareReq = (l_req_body,l_level) => {
        if(l_level === "levelFilter") {
            setLevelFilters(l_req_body)
            let country = l_req_body?.country?.map(val => val.value)
            let filterReq = {
                "l1_name": [l_req_body?.level1?.value],
                "l2_name": [l_req_body?.level2?.value],
            }
            if(country?.length) {
                filterReq["country"] = country
            }
            props.fetchCreateFilterData(filterReq)
            setShowSetAttributeFilter(!showSetAttributeFilter)
        }
        if(l_level === "setAttributeFilter") {
            setLevelFilters((old) => {
                return {
                        ...old,
                        ...l_req_body
                    }
            })
            props.fetchProductListContribution({...levelFilters,...l_req_body})
            setShowSetSelectProducts(!showSetSelectProducts)
        }
        if(l_level === "selectProduct") {
            setLevelFilters((old) => {
                return {
                        ...old,
                        ...l_req_body
                    }
            })
            props.fetchProfiledProduct({...levelFilters,...l_req_body})
            setShowProfiledProducts(!showProfiledProducts)
        }
        
    }

    useEffect(()=> {
        if(Object.keys(props.savedData).length){
            setExistingData(props.savedData)
            setProfileDescription(props.savedData.description)
            setProfileName(props.savedData.name)
        }
    },[props.savedData])

    useEffect(() => {
        let filters = {}
        if(props.location?.state?.selectedFilters?.l1_name) {
            setIsProductCoinfig(true)
            filters.level1 = {value: props.location?.state?.selectedFilters?.l1_name, label: props.location?.state?.selectedFilters?.l1_name}
        }
        if(props.location?.state?.selectedFilters?.l2_name) {
            filters.level2 = {value: props.location?.state?.selectedFilters?.l2_name, label: props.location?.state?.selectedFilters?.l2_name}
        }
        if(props.location?.state?.selectedFilters?.l3_name) {
            filters.level3 = [{value: props.location?.state?.selectedFilters?.l3_name, label: props.location?.state?.selectedFilters?.l3_name}]
        }
        if(props.location?.state?.selectedFilters?.l4_name) {
            filters.level4 = [{value: props.location?.state?.selectedFilters?.l4_name, label: props.location?.state?.selectedFilters?.l4_name}]
        }
        if(props.location?.state?.selectedFilters?.color) {
            filters.color = [{value: props.location?.state?.selectedFilters?.color, label: props.location?.state?.selectedFilters?.color}]
        }
        setSelectedFilters(filters)
    }, [props.location?.state?.selectedFilters])

    const onChange = (param) => {
        setIsEdit(false)
        if(param === "levelFilter"){
            setShowSetAttributeFilter(false);
            setShowSetSelectProducts(false);
            setShowProfiledProducts(false)
        }
        if(param === "setAttributesFilter"){
            setShowSetSelectProducts(false);
            setShowProfiledProducts(false)
        }
        // if(param === "selectProduct"){
        //     console.log('setting false')
        //     setShowProfiledProducts(false)
        // }
    }

    return (
        <PageLoader loader={props.saveLoading}>
         <div className="create__edit__product__profile_container">
                <LevelFilters goBackToCreateConfig = {goBackToCreateConfig} selectedFilters = {selectedFilters} prepareReq={prepareReq} onChanged={onChange} existingData={existingData} isEdit={isEdit} onProfileNameChange={onProfileNameChange} onProfileDescriptionChange={onProfileDescriptionChange} history={history}/>
                {showSetAttributeFilter && <SetAttributesFilter goBackToCreateConfig = {goBackToCreateConfig} selectedFilters = {selectedFilters} prepareReq={prepareReq} onChanged={onChange} levelFilters={levelFilters} existingData={existingData} isEdit={isEdit} history={history}/>}
                {showSetSelectProducts && <SelectProducts isProductConfig = {isProductConfigFlow} goBackToCreateConfig = {goBackToCreateConfig} prepareReq={prepareReq} onChanged={onChange} existingData={existingData} isEdit={isEdit} history={history}/>}
                {showProfiledProducts && <ProfiledProducts isProductConfig = {isProductConfigFlow} goBackToCreateConfig = {goBackToCreateConfig} levelFilters={levelFilters} existingData={existingData} isEdit={isEdit} profileId ={props?.match?.params?.profileId} profileName={profileName} profileDescription={profileDescription} history={history}/>}
        </div>
        </PageLoader>
    )
}


const mapStateToProps = ({ productProfile }) => ({
    saveLoading: productProfile.saveLoading,
    saveError: productProfile.saveError,
    saveSuccess: productProfile.saveSuccess,
    savedData: productProfile.savedData
});

const mapDispatchToProps = (dispatch) => ({
    resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
    fetchCreateFilterData: (payload) => dispatch(fetchCreateFilterData(payload)),
    fetchProductListContribution: (payload) => dispatch(fetchProductListContribution(payload)),
    fetchProfiledProduct: (payload) => dispatch(fetchProfiledProduct(payload)),
    fetchSavedData: (payload) => dispatch(fetchSavedData(payload)),
    resetProductProfileData: (payload) => dispatch(resetProductProfileData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditProductprofile)
