export const FETCH_STORE_GRADE_DATA = "@@CONSTRAINTS/FETCH_STORE_GRADE_DATA";
export const FETCH_STORE_GRADE_DATA_SUCCESS = "@@CONSTRAINTS/FETCH_STORE_GRADE_DATA_SUCCESS";
export const FETCH_STORE_GRADE_DATA_ERROR = "@@CONSTRAINTS/FETCH_STORE_GRADE_DATA_ERROR";

export const FETCH_STORE_GROUP_DATA = "@@CONSTRAINTS/FETCH_STORE_GROUP_DATA";
export const FETCH_STORE_GROUP_DATA_SUCCESS = "@@CONSTRAINTS/FETCH_STORE_GROUP_DATA_SUCCESS";
export const FETCH_STORE_GROUP_DATA_ERROR = "@@CONSTRAINTS/FETCH_STORE_GROUP_DATA_ERROR";

export const FETCH_STORE_DATA = "@@CONSTRAINTS/FETCH_STORE_DATA";
export const FETCH_STORE_DATA_SUCCESS = "@@CONSTRAINTS/FETCH_STORE_DATA_SUCCESS";
export const FETCH_STORE_DATA_ERROR = "@@CONSTRAINTS/FETCH_STORE_DATA_ERROR";

export const FETCH_FILTER_DATA = "@@CONSTRAINTS/FETCH_FILTER_DATA";
export const FETCH_FILTER_SUCCESS = "@@CONSTRAINTS/FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "@@CONSTRAINTS/FETCH_FILTER_ERROR";

export const FETCH_CONSTRAINTS_POPUP = "@@CONSTRAINTS/FETCH_CONSTRAINTS_POPUP";
export const FETCH_CONSTRAINTS_POPUP_SUCCESS = "@@CONSTRAINTS/FETCH_CONSTRAINTS_POPUP_SUCCESS";
export const FETCH_CONSTRAINTS_POPUP_ERROR = "@@CONSTRAINTS/FETCH_CONSTRAINTS_POPUP_ERROR";

export const FETCH_MIN_PER_STORE = "@@CONSTRAINTS/FETCH_MIN_PER_STORE";
export const FETCH_MIN_PER_STORE_SUCCESS = "@@CONSTRAINTS/FETCH_MIN_PER_STORE_SUCCESS";
export const FETCH_MIN_PER_STORE_ERROR = "@@CONSTRAINTS/FETCH_MIN_PER_STORE_ERROR";

export const UPDATE_TABLEDATA = "@@CONSTRAINTS/UPDATE_TABLEDATA";
export const UPDATE_TABLEDATA_SUCCESS = "@@CONSTRAINTS/UPDATE_TABLEDATA_SUCCESS";
export const UPDATE_TABLEDATA_ERROR = "@@CONSTRAINTS/UPDATE_TABLEDATA_ERROR";
export const RESET_UPDATE_TABLEDATA = "@@CONSTRAINTS/RESET_UPDATE_TABLEDATA";

export const RESET_FILTERS_DATA = "@@CONSTRAINTS/RESET_FILTERS_DATA";
export const RESET_ALL = "@@CONSTRAINTS/RESET_ALL";
export const RESET_STYLE_INDEX = "@@CONSTRAINTS/RESET_STYLE_INDEX";

export const FETCH_DEFAULT_CONS_DATA = "@@CONSTRAINTS/FETCH_DEFAULT_CONS_DATA";
export const FETCH_DEFAULT_CONS_DATA_SUCCESS = "@@CONSTRAINTS/FETCH_DEFAULT_CONS_DATA_SUCCESS";
export const FETCH_DEFAULT_CONS_DATA_ERROR = "@@CONSTRAINTS/FETCH_DEFAULT_CONS_DATA_ERROR";

export const FETCH_DEFAULT_CONS_FILTER_DATA = "@@CONSTRAINTS/FETCH_DEFAULT_CONS_FILTER_DATA";
export const FETCH_DEFAULT_CONS_SUCCESS = "@@CONSTRAINTS/FETCH_DEFAULT_CONS_SUCCESS";
export const FETCH_DEFAULT_CONS_ERROR = "@@CONSTRAINTS/FETCH_DEFAULT_CONS_ERROR";
export const GET_CHECK_ALL_DATA_SUCCESS  = "@@CONSTRAINTS/GET_CHECK_ALL_DATA_SUCCESS";

export const UPDATE_DEFAULT_DATA = "@@CONSTRAINTS/UPDATE_DEFAULT_DATA";
export const UPDATE_DEFAULT_DATA_SUCCESS = "@@CONSTRAINTS/UPDATE_DEFAULT_DATA_SUCCESS";
export const UPDATE_DEFAULT_DATA_ERROR = "@@CONSTRAINTS/UPDATE_DEFAULT_DATA_ERROR";

export const GENERATE_EXCEL = "@@CONSTRAINTS/GENERATE_EXCEL";
export const GENERATE_EXCEL_SUCCESS = "@@CONSTRAINTS/GENERATE_EXCEL_SUCCESS";
export const GENERATE_EXCEL_ERROR = "@@CONSTRAINTS/GENERATE_EXCEL_ERROR";
export const RESET_DOWNLOAD_EXCEL = "@@CONSTRAINTS/RESET_DOWNLOAD_EXCEL";

export const resetAll = () => ({
  type: RESET_ALL,
});

export const resetStyleIndex = () => ({
  type: RESET_STYLE_INDEX,
})

export const resetFiltersOptions = (payload) => ({
  type: RESET_FILTERS_DATA,
  payload,
})

export const fetchStoreGradeData = (payload) => ({
  type: FETCH_STORE_GRADE_DATA,
  payload,
});

export const fetchStoreGroupData = (payload) => ({
  type: FETCH_STORE_GROUP_DATA,
  payload,
});

export const fetchStoreData = (payload) => ({
  type: FETCH_STORE_DATA,
  payload,
});

export const fetchMinPerStore = (payload) => ({
  type: FETCH_MIN_PER_STORE,
  payload,
})

export const fetchMinPerStoreSuccess = (payload) => ({
  type: FETCH_MIN_PER_STORE_SUCCESS,
  payload,
})

export const fetchMinPerStoreError = (payload) => ({
  type: FETCH_MIN_PER_STORE_ERROR,
  payload,
})

export const fetchStoreGroupDataSuccess = (payload) => ({
  type: FETCH_STORE_GROUP_DATA_SUCCESS,
  payload,
});

export const fetchStoreGroupDataError = (payload) => ({
  type: FETCH_STORE_GROUP_DATA_ERROR,
  payload,
});

export const fetchStoreGradeDataSuccess = (payload) => ({
  type: FETCH_STORE_GRADE_DATA_SUCCESS,
  payload,
});

export const fetchStoreGradeDataError = (payload) => ({
  type: FETCH_STORE_GRADE_DATA_ERROR,
  payload,
});

export const fetchStoreDataSuccess = (payload) => ({
  type: FETCH_STORE_DATA_SUCCESS,
  payload,
});

export const fetchStoreDataError = (payload) => ({
  type: FETCH_STORE_DATA_ERROR,
  payload,
});

export const fetchFilterData = (payload,filterKey,filterType) => ({
  type: FETCH_FILTER_DATA,
  payload,
  filterKey,
  filterType
});

export const fetchFilterDataSuccesss = (payload) => ({
  type: FETCH_FILTER_SUCCESS,
  payload,
});

export const fetchFilterDataError = (payload) => ({
  type: FETCH_FILTER_ERROR,
  payload,
});

export const fetchPopUpData = (payload) => ({
  type: FETCH_CONSTRAINTS_POPUP,
  payload,
});

export const fetchPopUpDataSuccess = (payload) => ({
  type: FETCH_CONSTRAINTS_POPUP_SUCCESS,
  payload,
});

export const fetchPopUpDataError = (payload) => ({
  type: FETCH_CONSTRAINTS_POPUP_ERROR,
  payload,
});

export const updateTableData = (payload) => ({
  type: UPDATE_TABLEDATA,
  payload,
});

export const updateTableDataSuccess = (payload) => ({
  type: UPDATE_TABLEDATA_SUCCESS,
  payload,
});

export const updateTableDataError = (payload) => ({
  type: UPDATE_TABLEDATA_ERROR,
  payload,
});

export const resetUpdateTableData = () => ({
  type: RESET_UPDATE_TABLEDATA
})

export const fetchDefaultConstraintsData = (payload) => ({
  type: FETCH_DEFAULT_CONS_DATA,
  payload,
});

export const fetchDefaultConstraintsDataSuccess = (payload) => ({
  type: FETCH_DEFAULT_CONS_DATA_SUCCESS,
  payload,
});

export const fetchDefaultConstraintsError = (payload) => ({
  type: FETCH_DEFAULT_CONS_DATA_ERROR,
  payload,
});

export const fetchDefaultConstFilterData = (payload,filterKey,filterType) => ({
  type: FETCH_DEFAULT_CONS_FILTER_DATA,
  payload,
  filterKey,
  filterType
});

export const fetchDefaultConstFilterDataSuccess = (payload) => ({
  type: FETCH_DEFAULT_CONS_SUCCESS,
  payload,
});

export const fetchDefaultConstFilterDataError = (payload) => ({
  type: FETCH_DEFAULT_CONS_ERROR,
  payload,
});

export const updateDefaultData = (payload) => ({
  type: UPDATE_DEFAULT_DATA,
  payload,
});
export const updateDefaultDataSuccess = (payload) => ({
  type: UPDATE_TABLEDATA_SUCCESS,
  payload,
});

export const updateDefaultDataError = (payload) => ({
  type: UPDATE_TABLEDATA_ERROR,
  payload,
});

/*
Function to generate excel
*/

export const generateExcel = (payload) => ({
  type: GENERATE_EXCEL,
  payload
})

export const fetchExcelDataSuccess = (payload) => ({
  type: GENERATE_EXCEL_SUCCESS,
  payload,
});

export const fetchExcelDataError = (payload) => ({
  type: GENERATE_EXCEL_ERROR,
  payload,
})
export const resetDownloadExcel = () => ({
  type: RESET_DOWNLOAD_EXCEL
})