import React from 'react';
import classnames from "classnames";
import Select from '../../../components/Filters/SingleSelect';
import MultiSelect from '../../../components/Filters/MultiSelect';

function CreateEditUserForm(props) {

    return (
        props.data.map(prop => {
            switch (prop.elem_type) {
                case 'input':
                    return (!prop.hide && <div className={classnames("col-sm-12 my-5", prop.isRequired ? 'required' : '')} key={prop.id}>
                        <div className="row">
                            <div className="col-sm-4 align-middle d-sm-flex justify-content-end align-items-end">
                                {
                                    prop.label ?
                                        (<label className="fnt-md">{prop.label}</label>) :
                                        ('')
                                }
                            </div>
                            <div className="col-sm-7">
                                <div className="input-group">
                                    <input type={prop.input_type} className={classnames("form-control")} placeholder={prop.placeholder} name="search" {...prop} />
                                </div>
                            </div>
                        </div>
                        {(prop?.validation?.email || prop?.validation?.password) ? <div className="row">
                            <div className="col-sm-4"/>
                              <div className="col-sm-7" style = {{"marginTop":"1rem" , "color":"red"}}>
                                {prop.input_type=="email" && prop.validation.email && <span>{prop?.validation?.email}</span>}
                                {prop.input_type=="password" && prop.validation.password && <span>{prop?.validation?.password}</span>}
                              </div>
                           </div> : null}

                    </div>)
                case 'select':
                    return <div className={classnames("col-sm-12 my-5", prop.isRequired ? 'required' : '')} key={prop.id}>
                        <div className="row">
                            <div className="col-sm-4 align-middle d-sm-flex justify-content-end align-items-end">
                                {
                                    prop.label ?
                                        (<label className="fnt-md">{prop.label}</label>) :
                                        ('')
                                }
                            </div>
                            <div className="col-sm-7">
                                {!prop.isMultiSelect ? <Select {...prop} /> : <MultiSelect {...prop} />}
                            </div>

                        </div>


                    </div>
                case 'radio':
                    return <div className={classnames("col-sm-12 my-5", prop.isRequired ? 'required' : '')} key={prop.id}>
                        <div className="row">
                            <div className="col-sm-4 align-middle d-sm-flex justify-content-end align-items-end">
                                {
                                    prop.label ?
                                        (<label className="fnt-md">{prop.label}</label>) :
                                        ('')
                                }
                            </div>
                            <div className="col-sm-7">
                                    <div class="form-check form-check-inline">
                                        <input style={{width:"2rem",height:"1.5rem"}} class="form-check-input" checked={prop.status == "active"} type="radio" name="status" id="inlineRadio1" value="active" {...prop} />
                                        <span style={{fontSize:"1.4rem"}} class="form-check-label" for="inlineRadio1">Active</span>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input style={{width:"2rem",height:"1.5rem"}} class="form-check-input" checked={prop.status == "inActive"} type="radio" name="status" id="inlineRadio2" value="inActive" {...prop} />
                                        <span style={{fontSize:"1.4rem"}} class="form-check-label" for="inlineRadio2">Inactive</span>
                                    </div>
                            </div>
                        </div>
                    </div>
                default:
                    break;
            }
        })

    )

}

export default CreateEditUserForm