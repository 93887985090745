export const RESET_STRATEGY_CONFIG = "RESET_STRATEGY_CONFIG";
export const FILTER_CLICKED = "FILTER_CLICKED";
export const GET_STRATEGY_CONFIG_FILTER_REQUEST = "GET_STRATEGY_CONFIG_FILTER_REQUEST";
export const GET_STRATEGY_CONFIG_FILTER_FAILED = "GET_STRATEGY_CONFIG_FILTER_FAILED";
export const GET_STRATEGY_CONFIG_FILTER_SUCCESS = "GET_STRATEGY_CONFIG_FILTER_SUCCESS";
export const GET_STRATEGY_CONFIG_TABLE_REQUEST = "GET_STRATEGY_CONFIG_TABLE_REQUEST";
export const GET_STRATEGY_CONFIG_TABLE_FAILED = "GET_STRATEGY_CONFIG_TABLE_FAILED";
export const GET_STRATEGY_CONFIG_TABLE_SUCCESS = "GET_STRATEGY_CONFIG_TABLE_SUCCESS";
export const GET_STRATEGY_CONFIG_DATA_REQUEST = "GET_STRATEGY_CONFIG_DATA_REQUEST";
export const GET_STRATEGY_CONFIG_DATA_FAILED = "GET_STRATEGY_CONFIG_DATA_FAILED";
export const GET_STRATEGY_CONFIG_DATA_SUCCESS = "GET_STRATEGY_CONFIG_DATA_SUCCESS";
export const SET_ROWS_SELECTED = "SET_ROWS_SELECTED";
export const SET_EDITED_STRATEGY = "SET_EDITED_STRATEGY";
export const SAVE_STRATEGY_CONFIG_DATA_REQUEST = "SAVE_STRATEGY_CONFIG_DATA_REQUEST";
export const SAVE_STRATEGY_CONFIG_DATA_FAILED = "SAVE_STRATEGY_CONFIG_DATA_FAILED";
export const SAVE_STRATEGY_CONFIG_DATA_SUCCESS = "SAVE_STRATEGY_CONFIG_DATA_SUCCESS";
export const RESET_SAVE_STRATEGIES = "RESET_SAVE_STRATEGIES";
export const RESET_ROWS_SELECTED = "RESET_ROWS_SELECTED";

export const DELETE_STRATEGY_CONFIG = "DELETE_STRATEGY_CONFIG";
export const DELETE_STRATEGY_CONFIG_SUCCESS = "DELETE_STRATEGY_CONFIG_SUCCESS";
export const DELETE_STRATEGY_CONFIG_ERROR = "DELETE_STRATEGY_CONFIG_ERROR";
