// CUSTOM HOOK TO SELECT ROW & IT'S SUBROWS
import React, { useEffect } from "react";

// ACTIONS
const actionToggleColumnExpanded = "actionToggleColumnExpanded";
const hideColumns = "hideColumns";
const LIMITER = 0; // show 0th index and hide rest of the indexes

// CUSTOM REDUCER
function reducer(state, action, previousState, instance) {
  if (action.type === hideColumns) {
    const { columns } = instance;
    let getHiddenColumns = [];
    columns.forEach((col) => {
      if (col.enableColumnExpand === true) {
        if (col.columns) {
          col.columns.forEach((subCols, i) => {
            if (subCols && i > LIMITER) {
              getHiddenColumns.push(subCols.accessor);
            }
          });
        }
      }
    });

    return {
      ...state,
      hiddenColumns: [...state.hiddenColumns, ...getHiddenColumns],
    };
  }

  if (action.type === actionToggleColumnExpanded) {
    const { allColumns } = instance;
    const { column, shouldCollpase, number } = action;
    const newState = [];

    let getHiddenColumns = [];

    column.columns.forEach((col, i) => {
      if (i > LIMITER) {
        getHiddenColumns.push(col.id);
      }
    });

    if (shouldCollpase === true) {
      getHiddenColumns = [...state.hiddenColumns, ...getHiddenColumns];
    } else if (shouldCollpase === false) {
      let filteredCol = state.hiddenColumns.filter((col) => {
        return !getHiddenColumns.includes(col);
      });
      getHiddenColumns = filteredCol;
    }
    return {
      ...state,
      hiddenColumns: [...getHiddenColumns],
    };
  }

  return { ...state };
}

const useInstance = (instance) => {
  const { dispatch, columns } = instance;

  const dispatchHideColumns = React.useCallback((c) =>
    dispatch({ type: hideColumns, hideColumns: c }, [dispatch])
  );

  useEffect(() => {
    dispatchHideColumns();
    columns.forEach((col) => {
      if (col.enableColumnExpand === true) {
        col.isColumnsCollapsed = true;
      }
    });
  }, [columns]);

  const toggleColumnExpandedProps = React.useCallback(
    ({ column }) => {
      for (let i = 0; i < columns.length; i++) {
        if (columns[i].id === column.originalId) {
          columns[i].isColumnsCollapsed = !columns[i].isColumnsCollapsed;
        }
      }
      return dispatch({
        type: actionToggleColumnExpanded,
        column,
        shouldCollpase: !column.isColumnsCollapsed,
      });
    },
    [dispatch, columns]
  );

  Object.assign(instance, {
    toggleColumnExpandedProps,
  });
};

export const useColumnExapander = (hooks) => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};
