import React from "react";
import { transitions, positions } from "react-alert";
import alertSuccessIcon from "./img/success.gif";
import alertDangerIcon from "./img/alertDanger.gif";
import alertErrorIcon from "./img/error.gif";

const alertStyle = {
  backgroundColor: "white",
  borderRadius: "2px",
  display: "flex",
  //justifyContent: "space-between",
  alignItems: "center",
  textAlign: "center",
  boxShadow: "0px 2px 2px 2px rgba(0, 0, 0, 0.03)",
  //width: '230px',
  // height: '80px',
  lineHeight: 1.5,
  marginTop: "120px",
  fontSize: "13px",
};

export const AlertTemplate = ({ style, options, message, close }) => (
  <div style={{ ...alertStyle, ...style }}>
    {options.type === "info" && (
      <div
        style={{
          backgroundColor: "white",
          color: "#ff9900",
          borderRadius: "2px",
          zIndex: 11000,
          padding: "5px",
          boxShadow: "0 1px 15px 1px #6c757d40",
          textAlign: "center",
          alignItems: "center",
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <img
          style={{
            width: "50px",
            height: "50px",
            justifyContent: "center",
            alignItems: "center",
            flex: "3",
          }}
          src={alertDangerIcon}
          alt=""
        />
        <span style={{ flex: "2", justifyContent: "center" }}>{message}</span>
      </div>
    )}

    {options.type === "success" && (
      <div
        style={{
          backgroundColor: "white",
          color: "green",
          borderRadius: "2px",
          zIndex: 11000,
          padding: "5px",
          boxShadow: "0 1px 15px 1px #6c757d40",
          textAlign: "center",
          alignItems: "center",
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <img
          style={{
            width: "50px",
            height: "50px",
            justifyContent: "center",
            alignItems: "center",
            flex: "3",
          }}
          src={alertSuccessIcon}
          alt=""
        />
        <span style={{ flex: "2", justifyContent: "center" }}>{message}</span>
      </div>
    )}

    {options.type === "error" && (
      <div
        style={{
          backgroundColor: "white",
          color: "red",
          borderRadius: "2px",
          zIndex: 11000,
          padding: "5px",
          boxShadow: "0 1px 15px 1px #6c757d40",
          textAlign: "center",
          alignItems: "center",
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <img
          style={{
            width: "50px",
            height: "50px",
            justifyContent: "center",
            alignItems: "center",
            flex: "3",
          }}
          src={alertErrorIcon}
          alt=""
        />
        <span style={{ flex: "2", justifyContent: "center" }}>{message}</span>
      </div>
    )}
  </div>
);

export const options = {
  position: positions.TOP_CENTER,
  timeout: 2000,
  offset: "300px",
  transition: transitions.SCALE,
};
