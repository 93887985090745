import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { DASHBOARD_PAGE } from "../../constants/routeConstants";
import jwt_decode from 'jwt-decode'
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchJwtAfterSSOLoginApi } from "./LoginActions";
import { logoutRedirect, setColumnPreferenceAfterLogin, setLocalStorageAfterLogin } from "../../utils/commonUtilities";
import * as Notify from '../../components/Notification/Notifications'
import Notification from '../../components/Notification/Notifications'

const cookies = new Cookies();

const IntermediatePage = (props) => {
  const history = useHistory()

  useEffect(() => {
    console.log(localStorage.getItem('access_token'),'access_token')
    let accessTokenLS = localStorage.getItem('access_token')

      if (accessTokenLS) {
        let decoded = jwt_decode(accessTokenLS)
        let user_id = decoded?.user_id
        if (user_id) {
          props.fetchJwtAfterSSOLoginApi({ user_id });
        }
      }
      else{
        let accessToken = cookies.get("access_token");
        setLocalStorageAfterLogin(accessToken)
        let decoded = jwt_decode(accessToken)
        let user_id = decoded?.user_id
          if (user_id) {
            props.fetchJwtAfterSSOLoginApi({ user_id });
          }
      }
  }, [])

  useEffect(() => {
    if(!props.jwtAfterSSO){
      Notify.error("Error in fetching user preference!!")
      setTimeout(() => {
        logoutRedirect();
      },[3000])    
    }
    else {
      let jwt = props?.jwtAfterSSO
      if (jwt) {
        setColumnPreferenceAfterLogin(props?.jwtAfterSSO);
        setTimeout(() => {
          history.push(`${DASHBOARD_PAGE}`);
        },[3000])
      }
    }
  }, [props.jwtAfterSSO])

  return (
    <div>
      {/* <Notification /> */}
      <div className="mainDIv35 text-center">
        <div className="intermideateClass">
          {/* <img className="imgLoad" src={Loadinggif} alt="" /> */}
          <i
            className="fa fa-spinner fa-spin"
            aria-hidden="true"
            style={{ fontSize: "25px" }}
          ></i>
        </div>
        <p className="font-size-15 mt-2 font-bold">Loading please wait...</p>
      </div>
    </div>
  );
}

// export default IntermediatePage

const mapStateToProps = ({ login }) => ({
  jwtAfterSSO: login.jwtAfterSSO,
});

const mapDispatchToProps = (dispatch) => ({
  fetchJwtAfterSSOLoginApi: (payload) => dispatch(fetchJwtAfterSSOLoginApi(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IntermediatePage));
