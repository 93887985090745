import React from "react";
import { REDIRECT_SSO_LOGIN } from "../../constants/apiConstants";
import { capitalize, logoutUser } from "../../utils/commonUtilities";
import './Login.css';

const SSOLogin = () => {
  function onSiginClick() {
    logoutUser();
    localStorage.setItem("isSSO", true)
    window.location.href = REDIRECT_SSO_LOGIN;
  }

  return (
    <div className="mfa__container">
     <h2 id="signIntext">
        Sign in to <span id="promoColor1">Allocate Smart</span> with Multi-Factor Authentication (MFA)
      </h2>
      {/* <h2 id="signIntext" className="mb-4">
        Sign in to{" "}
        <span id="promoColor1">
          {capitalize(process.env.REACT_APP_CLIENT)} AllocateSmart
        </span>
      </h2> */}
      {/* <div id="btndiv2">
        <button
          type="submit"
          className="btn btn-primary btn-square shadow-sm btnsignin btn-sso"
          onClick={onSiginClick}
        >
          Sign in <i class="fa fa-sign-in" aria-hidden="true"></i>
        </button>
      </div> */}
      <div id="btndiv2">
        <button type="submit" className="btn btn-primary btn-square shadow-sm btnsignin"  onClick={onSiginClick}>
          Sign in
        </button>
      </div>
    </div>
  );
};

export default SSOLogin;
