import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { inventoryDropInitialSate } from './initialState';
import * as inventoryDropActionTypes from '../../actions/InventoryDropActions/actionTypes';

const inventoryDropReducer = {
    inventoryDrop: handleActions(
        {
            [inventoryDropActionTypes.INVENTORY_DROP_CHECK]: (state, { payload }) => {
                return immutable(state, {
                    isInevntoryDropLoading: { $set: true },
                });
            }
        },
        inventoryDropInitialSate
    ),
};

export default inventoryDropReducer;