import React, { useEffect } from "react";
import { useAsyncDebounce } from "react-table";
import Select from "../../Filters/SingleSelect";

export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  label,
}) {
  const count = preGlobalFilteredRows && preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-sm">
            Search {label}
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
          placeholder={`${count} records...`}
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
      </div>
    </span>
  );
}

export function SelectFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  label,
  filterOptions,
  dispatch,
}) {
  const [value, setValue] = React.useState(null);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value.value || undefined);
    dispatch({
      type: "SET_SELECTED_COMMON_FILTER",
      payload: value || undefined,
    });
  }, 200);

  useEffect(() => {
    if (value === null) setValue(filterOptions[0]);
  }, [filterOptions]);

  return (
    <div className="col-md-6 row">
      <div class="input-group input-group-sm mb-3">
        <div className="col-6">
          <label class="input-label m-0">{label}</label>
          <Select
            value={value}
            options={filterOptions}
            onChange={(val) => {
              setValue(val);
              onChange(val);
            }}
          />
        </div>
      </div>
    </div>
  );
}

{
  /* <select
value={value}
onChange={(e) => {
  setValue(e.target.value);
  onChange(e.target.value);
}}
>
{filterOptions.map((item) => {
  return <option value={item.value}>{item.label}</option>;
})}
</select> */
}
