import { all } from "redux-saga/effects";
import { demandSaga } from "./DemandSaga"
import { storeConfigSaga } from './StoreDetailsConfigSagas/StoreConfigSagas';
import { dcConfigSaga } from "./DCConfigSaga"


/**
 * Root saga
 */
export function* newStoreSetUpSagas() {
    yield all([
        storeConfigSaga(),
        demandSaga(),
        dcConfigSaga()
    ]);
}