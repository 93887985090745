import * as actionTypes from "./ActionTypes"


/*
Function to get store view for daily summary
*/
export const getDailySummaryStoreView = (payload) => ({
    type: actionTypes.GET_DAILY_SUMMARY_STORE_VIEW,
    payload
})

/*
Function to get details of store
*/
export const getDailySummaryStoreDetails = (payload) => ({
    type: actionTypes.GET_DAILY_SUMMARY_STORE_DETAILS,
    payload
})