// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

function NumberRangeColumnFilterServerSide({
    column: { id },clickHandler,minSearchTermValue,maxSearchTermValue}) {
    
    const[minFilter, setMinFilterValue] = useState(null) 
    const[maxfilter, setMaxFilterValue] = useState(null) 

    useEffect(() => {
      setMinFilterValue(minSearchTermValue)
      setMaxFilterValue(maxSearchTermValue)
    }, [minSearchTermValue,maxSearchTermValue])

    console.log(minFilter,maxfilter,minSearchTermValue,maxSearchTermValue,'hbjb')

    const onClickHandler = () => {
      clickHandler(minFilter ,maxfilter,id)
    }
    
    return (
      <div
        style={{
          display: 'flex',
          width: '96%'
        }}
      >
        <input
          value={minFilter ? minFilter : null}
          type="number"
          onChange={e => {
            const val = e.target.value
            setMinFilterValue(val)
          }}
          placeholder={`Min`}
          style={{width:"70%", margin:"4px 0 4px 4px", paddingLeft:'2px', borderRadius:"2rem 0 0 2rem", outline:'none', borderColor:'#343a40'}}
        />
        {/* <span style={{margin:"auto", fontSize:"1.3rem"}}>to</span> */}
        <input
          value={maxfilter ? maxfilter : null}
          type="number"
          onChange={e => {
            const val = e.target.value
            setMaxFilterValue(val)
          }}
          placeholder={`Max`}
          style={{width:"70%", margin:"4px 4px 4px 0", paddingLeft:'2px', borderRadius:"0 2rem 2rem 0", outline:'none', borderColor:'#343a40'}}
        />
          <div onClick={() => onClickHandler()} style={{cursor: 'pointer',position:'relative', top:'5px'}}>
             <i class="fa fa-search" aria-hidden="true"></i>
          </div>
      </div>
    )
  }

  export default NumberRangeColumnFilterServerSide
  