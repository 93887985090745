export const IneventoryReservationInitialState = {
    isInventoryReservationFilterLoading: false,
    isInventoryReservationFilterFailed: false,
    isInventoryReservationFilterSuccess: false,
    level1Options: [],
    level2Options: [],
    level3Options: [],
    level4Options: [],
    checkboxOptions: [],
    filterLabels: {},
    isInventoryReservationTableLoading: false,
    isInventoryReservationTableFailed: false,
    isInventoryReservationTableSuccess: false,
    isInventoryReservationTableData: [],
    tableRowsSelected: {},
    isInventoryReservationDataLoading: false,
    isInventoryReservationDataFailed: false,
    isInventoryReservationDataSuccess: false,
    isInventoryReservationData: [],
    editedConfigReq: {},
    isInventoryReservationSaveLoading: false,
    isInventoryReservationSaveFailed: false,
    isInventoryReservationSaveSuccess: false,
    totalCount: 0,
    nextIndex: 0,
    searchTermRequest:{}
}