export const GET_STORE_CONFIG_DETAILS_REQUEST = "GET_STORE_CONFIG_DETAILS_REQUEST";
export const GET_STORE_CONFIG_DETAILS_FAILED = "GET_STORE_CONFIG_DETAILS_FAILED";
export const GET_STORE_CONFIG_DETAILS_SUCCESS = "GET_STORE_CONFIG_DETAILS_SUCCESS";
export const SET_SELECTED_NEW_STORE_DETAIL = "SET_SELECTED_NEW_STORE_DETAIL";
export const SET_SELECTED_NEW_STORE_CODE = "SET_SELECTED_NEW_STORE_CODE";
export const SET_SELECTED_SISTER_STORE_DETAIL = "SET_SELECTED_SISTER_STORE_DETAIL";
export const SET_SELECTED_SISTER_STORE_CODE = "SET_SELECTED_SISTER_STORE_CODE";
export const GET_DC_SOURCE_DATA_REQUEST = "GET_DC_SOURCE_DATA_REQUEST";
export const GET_DC_SOURCE_DATA_FAILED = "GET_DC_SOURCE_DATA_FAILED";
export const GET_DC_SOURCE_DATA_SUCCESS = "GET_DC_SOURCE_DATA_SUCCESS";
export const SET_SELECTED_DC_ROWS_DETAIL = "SET_SELECTED_DC_ROWS_DETAIL";
export const SET_DC_ROWS_SELECTED = "SET_DC_ROWS_SELECTED";
export const EDIT_OPENING_DATE = "EDIT_OPENING_DATE";
export const EDIT_DC_SOURCE_LEAD_TIME = "EDIT_DC_SOURCE_LEAD_TIME";
export const RESET_STORE_DETAILS_CONFIG = "RESET_STORE_DETAILS_CONFIG";
export const UPDATE_DC_SOURCE_LEAD_TIME_REQ = "UPDATE_DC_SOURCE_LEAD_TIME_REQ";
export const UPDATE_DC_SOURCE_LEAD_TIME_FAILED = "UPDATE_DC_SOURCE_LEAD_TIME_FAILED";
export const UPDATE_DC_SOURCE_LEAD_TIME_SUCCESS = "UPDATE_DC_SOURCE_LEAD_TIME_SUCCESS";
export const SET_EDIT_MODE_DETAILS = "SET_EDIT_MODE_DETAILS";