import React, { useEffect } from "react";
import Styles from "../ReactTableStyles";
import { SelectFilter, GlobalFilter } from "../FilterMarkups/TableFilters";
import { useGlobalTableReducer } from "../Shared/StateReducers";

const GlobalFilterMarkup = React.memo(
  ({
    page,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows,
    height,
    preGlobalFilteredRows,
    globalFilter,
    defaultSelectFilter,
    setGlobalFilter,
    state,
    label,
    keyRT,
    dispatch,
    header,
    // setCommonFilterandOptions,
    customFilterOptions,
    shouldPagination,
    customSelectedFilter,
    settabledata
  }) => {
    // const {
    //   customStore,
    //   customDisptach,
    //   setCommonFilterandOptions,
    // } = useGlobalTableReducer();
    // HANDLE FILTER COMPONENT `null` for no filter
    let FilterComp = null;
    // HANDLE FILTER COMPONENT ACCORDING TO KEYRT
    if (
      keyRT === "PlanCategoryLevel" ||
      keyRT === "BudgetClusterLevel" ||
      keyRT === "PlanDepth" ||
      keyRT === "PlanOptimizeConstraint" ||
      keyRT === "BudgetLevelThreeComponent"
    ) {
      FilterComp = (
        <SelectFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={customSelectedFilter || null}
          setGlobalFilter={setGlobalFilter}
          label={label}
          filterOptions={customFilterOptions || []}
          dispatch={dispatch}
          // setCommonFilterandOptions={setCommonFilterandOptions}
        />
      );
    }
    // DEFAULT FILTER is GlobalFilter
    else {
      FilterComp = (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          label={label}
        />
      );
    }

    const rowsToRender = shouldPagination ? page : rows

    return (
      <div>
        <Styles>
          <div
            {...getTableProps({
              style: {
                height: height,
                width: "100%",
              },
            })}
            className="table"
          >
            {rows.length ? (
              <>
                <div>
                  {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className="tr">
                      {headerGroup.headers.map((column) => (
                        <div {...column.getHeaderProps()} className="th">
                          {column.render("Header")}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>

                <div {...getTableBodyProps()}>
                  {rowsToRender.map((row, i) => {
                    prepareRow(row);
                    return (
                      <div {...row.getRowProps()} className="tr">
                        {row.cells.map((cell) => {
                          return (
                            <div {...cell.getCellProps()} className="td">
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="hr-dotted">
                <div className="m-3 lead" style={{ fontSize: "18px" }}>
                  No records found!
                </div>
              </div>
            )}
          </div>
        </Styles>
      </div>
    );
  }
);

export default GlobalFilterMarkup;
