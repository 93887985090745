import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./CellRenderer.scss";
import {customStyles} from "./selecetCellCustomStyle";


const SelectCell = React.memo(({ ...instance }) => {
  const { value, dropdownOptions, updateMyData, defaultValue,changeHandler,  } = instance;
  const [dropdownValue, setDropdownValue] = useState({});
  
  useEffect(() => {
    if (Array.isArray(value)) {
      setDropdownValue(() => value);
    } else {
      setDropdownValue({ value: value.value, label: value.label });
    }
  }, [value]);

  const dropdownValueChange = (value) => {
    setDropdownValue(value);
   updateMyData(instance.row.index, instance.column.id, value);
   if(changeHandler){
    changeHandler(instance.row.index, instance.column.id, value);
  }
  };

  return (
    <>
      <Select
      // menuIsOpen={true}
        styles={customStyles}
        // menuPosition="fixed"
        menuShouldBlockScroll={true}
        className="single-select"
        classNamePrefix="select"
        value={dropdownValue}
        name="cell-select"
        menuPortalTarget={document.body}
        options={dropdownOptions}
        onChange={dropdownValueChange}
      />
    </>
  );
});

export default SelectCell;
