export const GET_NEW_STORE_LIST = "@@nsApprove/GET_NEW_STORE_LIST";
export const GET_NEW_STORE_LIST_SUCCESS = "@@nsApprove/GET_NEW_STORE_LIST_SUCCESS";
export const GET_NEW_STORE_LIST_ERROR = "@@nsApprove/GET_NEW_STORE_LIST_ERROR";

export const GET_NEW_STORE_ARTICLE_DETAILS = "@@nsApprove/GET_NEW_STORE_ARTICLE_DETAILS";
export const GET_NEW_STORE_ARTICLE_DETAILS_SUCCESS = "@@nsApprove/GET_NEW_STORE_ARTICLE_DETAILS_SUCCESS";
export const GET_NEW_STORE_ARTICLE_DETAILS_ERROR = "@@nsApprove/GET_NEW_STORE_ARTICLE_DETAILS_ERROR";

export const APPROVE_NEW_STORE_ARTICLES = "@@nsApprove/APPROVE_NEW_STORE_ARTICLES";
export const APPROVE_NEW_STORE_ARTICLES_SUCCESS = "@@nsApprove/APPROVE_NEW_STORE_ARTICLES_SUCCESS";
export const APPROVE_NEW_STORE_ARTICLES_ERROR = "@@nsApprove/APPROVE_NEW_STORE_ARTICLES_ERROR";

export const RESET_NEW_STORE_APPROVE = "@@nsApprove/RESET_NEW_STORE_APPROVE";
export const RESET_APPROVE_DETAILS = "@@nsApprove/RESET_APPROVE_DETAILS";