import * as actionTypes from "./actionTypes"

/*
Function to dispatch action to get po allocation data
*/

export const getPoData = (payload) => ({
    type: actionTypes.GET_PO_DATA,
    payload
})
