import * as actionTypes from "./NewApproveActionTypes"

/*
Dispatch action to get new store list to be approved
*/
export const getNewStoreApproveList = (payload) => ({
    type: actionTypes.GET_NEW_STORE_LIST,
    payload
})

/*
Dispatch action to get article details of a new store
*/
export const getNewStoreArticleDetails = (payload) => ({
    type: actionTypes.GET_NEW_STORE_ARTICLE_DETAILS,
    payload
})

/*
Dispatch action to approve the articles for a new store
*/
export const approveNewStoreArticles = (payload) => ({
    type: actionTypes.APPROVE_NEW_STORE_ARTICLES,
    payload
})

/*
Reset the state
*/

export const resetNSApprove = () => ({
    type: actionTypes.RESET_NEW_STORE_APPROVE
})

export const resetApproveDetails = () => ({
    type: actionTypes.RESET_APPROVE_DETAILS
})