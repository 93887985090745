export const handleSelectAll = (value,event,that) => {
    let val = [...value]
    let selectedOptions = []
    if (event.action === "select-option" && event.option.value === "*") {
      val = that.options.filter((o) => o.value !== "*")
      selectedOptions = that.options;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      val = []
      selectedOptions = [];
    } else if (event.action === "deselect-option") {
      val = value.filter((o) => o.value !== "*")
      selectedOptions = value.filter((o) => o.value !== "*");
    } else if (value.length === that?.options?.length - 1) {
      selectedOptions = that?.options;
    } else {
      selectedOptions = value;
    }
    return [val,selectedOptions]
}


export const handleSelectAllAsycPaginate = (value,event,that) => {
  let val = [...value]
  let selectedOptions = []
  if (event.action === "select-option" && event.option.value === "*") {
    val = that.filter((o) => o.value !== "*")
    selectedOptions = that;
  } else if (
    event.action === "deselect-option" &&
    event.option.value === "*"
  ) {
    val = []
    selectedOptions = [];
  } else if (event.action === "deselect-option") {
    val = value.filter((o) => o.value !== "*")
    selectedOptions = value.filter((o) => o.value !== "*");
  } else if (value.length === that.length - 1) {
    selectedOptions = that;
  } else {
    selectedOptions = value;
  }
  return [val,selectedOptions]
}
