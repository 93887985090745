import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { IneventoryReservationInitialState } from "./initialState";
import { cloneDeep } from 'lodash';
import * as inventoryReservationActionTypes from "../Actions/ActionTypes";


const InventoryReservationReducers = {
    InventoryReservation: handleActions(
        {
            [inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_FILTER_REQUEST]: (state) => {
                return immutable(state, {
                    isInventoryReservationFilterLoading: { $set: true },
                    isInventoryReservationFilterFailed: { $set: false },
                });
            },
            [inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_FILTER_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isInventoryReservationFilterLoading: { $set: false },
                    isInventoryReservationFilterFailed: { $set: true },
                });
            },
            [inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_FILTER_SUCCESS]: (state, { payload }) => {
                switch (payload.key) {
                    case "department":
                        return immutable(state, {
                            level1Options: {
                                $set:
                                    payload && payload.data.level1Options
                                        ? payload.data.level1Options
                                        : state.level1Options,
                            },
                            level2Options: [],
                            level3Options: [],
                            level4Options: [],
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "gender":
                        return immutable(state, {
                            level2Options: {
                                $set:
                                    payload && payload.data.level2Options
                                        ? payload.data.level2Options
                                        : state.level2Options,
                            },
                            level3Options: {
                                $set:
                                    payload && payload.data.level3Options
                                        ? payload.data.level3Options
                                        : state.level3Options,
                            },
                            level4Options: {
                                $set:
                                    payload && payload.data.level4Options
                                        ? payload.data.level4Options
                                        : state.level4Options,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "sub_category":
                        return immutable(state, {
                            level3Options: {
                                $set:
                                    payload && payload.data.level3Options
                                        ? payload.data.level3Options
                                        : state.level3Options,
                            },
                            level4Options: {
                                $set:
                                    payload && payload.data.level4Options
                                        ? payload.data.level4Options
                                        : state.level4Options,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "dcs":
                        return immutable(state, {
                            level4Options: {
                                $set:
                                    payload && payload.data.level4Options
                                        ? payload.data.level4Options
                                        : state.level4Options,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    default:
                        return immutable(state, {
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            },
                            isInventoryReservationFilterLoading: { $set: false },
                            isInventoryReservationFilterFailed: { $set: true },
                        });
                };
            },
            [inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_TABLE_REQUEST]: (state) => {
                return immutable(state, {
                    isInventoryReservationTableLoading: { $set: true },
                    isInventoryReservationTableFailed: { $set: false },
                    isInventoryReservationTableSuccess: { $set: false },
                });
            },
            [inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_TABLE_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isInventoryReservationTableLoading: { $set: false },
                    isInventoryReservationTableFailed: { $set: true },
                    isInventoryReservationTableSuccess: { $set: false },
                });
            },
            [inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_TABLE_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    isInventoryReservationTableLoading: { $set: false },
                    isInventoryReservationTableFailed: { $set: false },
                    isInventoryReservationTableSuccess: { $set: true },
                    isInventoryReservationTableData: { $set: response},
                    totalCount: { $set: (response && response?.totalCount) || 0 },
                    nextIndex: { $set: (response && response?.nextIndex) || 0 },
                    // searchTermRequest:{ $set: response.searchColumns }
                });
            },
            [inventoryReservationActionTypes.SET_ROWS_SELECTED]: (state, { payload }) => {
                return immutable(state, {
                    tableRowsSelected: { $set: payload },
                });
            },
            [inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isInventoryReservationDataLoading: { $set: true },
                    isInventoryReservationDataFailed: { $set: false },
                    isInventoryReservationDataSuccess: { $set: false },
                });
            },
            [inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isInventoryReservationDataLoading: { $set: false },
                    isInventoryReservationDataFailed: { $set: true },
                    isInventoryReservationDataSuccess: { $set: false },
                });
            },
            [inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    isInventoryReservationDataLoading: { $set: false },
                    isInventoryReservationDataFailed: { $set: false },
                    isInventoryReservationDataSuccess: { $set: true },
                    isInventoryReservationData: { $set: response.data },
                    editedConfigReq: { $set: response.configBody },
                });
            },
            [inventoryReservationActionTypes.SET_EDITED_INVENTORY_RESERVATION]: (state, { payload }) => {
                const { key, value } = payload;
                let updatedData = {
                    ...state.editedConfigReq,
                };
                updatedData[key] = value;

                return immutable(state, {
                    editedConfigReq: { $set: updatedData },
                });
            },
            [inventoryReservationActionTypes.SAVE_INVENTORY_RESERVATION_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isInventoryReservationSaveLoading: { $set: true },
                    isInventoryReservationSaveFailed: { $set: false },
                    isInventoryReservationSaveSuccess: { $set: false },
                });
            },
            [inventoryReservationActionTypes.SAVE_INVENTORY_RESERVATION_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isInventoryReservationSaveLoading: { $set: false },
                    isInventoryReservationSaveFailed: { $set: true },
                    isInventoryReservationSaveSuccess: { $set: false },
                });
            },
            [inventoryReservationActionTypes.SAVE_INVENTORY_RESERVATION_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    isInventoryReservationSaveSuccess: { $set: true },
                    isInventoryReservationSaveLoading: { $set: false },
                    isInventoryReservationSaveFailed: { $set: false },
                   
                });
            },
            [inventoryReservationActionTypes.RESET_SAVE_INVENTORY_RESERVATION]: (state) => {
                return immutable(state, {
                    tableRowsSelected: { $set: {} },
                    isInventoryReservationTableData: { $set: [] },
                    editedConfigReq: { $set: [] },
                    isInventoryReservationSaveLoading: { $set: false },
                    isInventoryReservationSaveFailed: { $set: false },
                    isInventoryReservationSaveSuccess: { $set: false },
                });
            },
            [inventoryReservationActionTypes.RESET_INVENTORY_RESERVATION]: (state) => {
                return immutable(state, {
                    isInventoryReservationFilterLoading: { $set: false },
                    isInventoryReservationFilterFailed: { $set: false },
                    level1Options: { $set: [] },
                    level2Options: { $set: [] },
                    level3Options: { $set: [] },
                    level4Options: { $set: [] },
                    filterLabels: { $set: {} },
                    isInventoryReservationTableLoading: { $set: false },
                    isInventoryReservationTableFailed: { $set: false },
                    isInventoryReservationTableSuccess: { $set: false },
                    strategyTableData: { $set: [] },
                    tableRowsSelected: { $set: {} },
                    isInventoryReservationDataLoading: { $set: false },
                    isInventoryReservationDataFailed: { $set: false },
                    isInventoryReservationDataSuccess: { $set: false },
                    InventoryReservationData: { $set: [] },
                    editedConfigReq: { $set: [] },
                    isInventoryReservationSaveLoading: { $set: false },
                    isInventoryReservationSaveFailed: { $set: false },
                    isInventoryReservationSaveSuccess: { $set: false },
                    totalCount:{$set:0},
                    nextIndex:{$set:0}
                });
            },
        },
        IneventoryReservationInitialState
    ),
};

export default InventoryReservationReducers;