export const DEMO_ACTION = "@@demo/DEMO_ACTION";
export const DEMO_ACTION_SUCCESS = "@@demo/DEMO_ACTION_SUCCESS";
export const DEMO_ACTION_ERROR = "@@demo/DEMO_ACTION_ERROR";

export const demoAction = () => ({
  type: DEMO_ACTION,
});

export const demoActionSuccess = () => ({
  type: DEMO_ACTION_SUCCESS,
});

export const demoActionError = () => ({
  type: DEMO_ACTION_ERROR,
});
