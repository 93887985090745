import React, { useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { InputSwitch } from 'primereact/inputswitch';
import BarChartWithDataLabels from '../../../components/UtilCharts/BarChartWithDataLabels';
import BarChartWithDataPercentLabels from '../../../components/UtilCharts/BarChartWithDataPercentLabels';
import * as lostSalesActions from './actions/LostSalesReportActions';
import {
    LOST_SALES_REPORT_BAR_GRAPH_PARAMS, LOST_SALES_OPP_LABEL, LOST_SALES_NETWORK_ERROR_MSG,
    LOST_SALES_NO_RECORDS_MSG, LOST_SALES_NO_RECORDS_HELPER_MSG,
} from './LostSalesConstants';

/**
 * Function component with Lost Sales Graph View
 * @param {*} props 
 * @returns 
 */
const LostSalesGraphView = (props) => {
    const [showPercentageGraph, setShowPercentageGraph] = useState(false);
    const { lostSalesGraphData, isLostSalesAllDataFailed } = props;
    LOST_SALES_REPORT_BAR_GRAPH_PARAMS.labels = [];
    LOST_SALES_REPORT_BAR_GRAPH_PARAMS.units.series[0].data = [];
    LOST_SALES_REPORT_BAR_GRAPH_PARAMS.percentage.series[0].data = [];

    const sortLoastSaleData = () => {
        return lostSalesGraphData.sort((op1, op2) => {
            return op1.year - op2.year
        })
    }
    // Sort the data by years
    let sortedLostSalesGraphData = sortLoastSaleData();

    sortedLostSalesGraphData.forEach((item) => {
        LOST_SALES_REPORT_BAR_GRAPH_PARAMS.labels.push(item.week);
        //Units Graph data
        LOST_SALES_REPORT_BAR_GRAPH_PARAMS.units.series[0].data.push(item.lost_units);
        //Percentage Graph data
        LOST_SALES_REPORT_BAR_GRAPH_PARAMS.percentage.series[0].data.push(item.lost_perc);
    });

    return (
        <>
            <div id="LOST_SALES_REPORT_GRAPH_CONTAINER" className="lost_sales_graph_container shadow-sm">
                {lostSalesGraphData && lostSalesGraphData.length > 0
                    ?
                    <>
                        <h4 className="line-chart__heading">{LOST_SALES_OPP_LABEL}</h4>
                        <div className="graph-toggle-container">
                            <label className="graph-toggle-label">Display as Percentage</label>
                            <InputSwitch checked={showPercentageGraph} onChange={(e) => setShowPercentageGraph(!showPercentageGraph)} />
                        </div>
                        <div id="LOST_SALES_GRAPH_VIEW" className="lost_sales_graph_view">
                            <div id="LINE_CHART_LOST_SALES">
                                {showPercentageGraph ?
                                    <BarChartWithDataPercentLabels
                                        labels={LOST_SALES_REPORT_BAR_GRAPH_PARAMS.labels}
                                        data={LOST_SALES_REPORT_BAR_GRAPH_PARAMS.percentage}
                                        height={390}
                                        titleText=""
                                        showPercentage={true}
                                        sortedLostSalesGraphData={sortedLostSalesGraphData}
                                    />
                                    :
                                    <BarChartWithDataLabels
                                        labels={LOST_SALES_REPORT_BAR_GRAPH_PARAMS.labels}
                                        data={LOST_SALES_REPORT_BAR_GRAPH_PARAMS.units}
                                        height={390}
                                        titleText=""
                                        showPercentage={false}
                                        sortedLostSalesGraphData={sortedLostSalesGraphData}
                                    />
                                }
                            </div>
                        </div>
                    </>
                    : (isLostSalesAllDataFailed ?
                        <div className="error">{LOST_SALES_NETWORK_ERROR_MSG}</div>
                        : <div className="empty">{LOST_SALES_NO_RECORDS_MSG}
                            <p>{LOST_SALES_NO_RECORDS_HELPER_MSG}</p>
                        </div>)
                }
            </div>
        </>
    );
}

const mapStateToProps = ({ lostSalesReport }) => ({
    lostSalesGraphData: lostSalesReport.lostSalesGraphData,
    isLostSalesAllDataFailed: lostSalesReport.isLostSalesAllDataFailed,
});

const mapDispatchToProps = (dispatch) => ({
    lostSalesActionsProp: bindActionCreators(lostSalesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LostSalesGraphView);

