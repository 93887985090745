import React from 'react';
import { useHistory } from 'react-router-dom';
import EditPreSeason from './EditPreSeason';
import EditInSeason from './EditInSeason';
import EditClearanceSeason from './EditClearanceSeason';
import { STRATEGY_CONFIGURAION_PATH,PRODUCT_STORE_STATUS} from "../../../../constants/routeConstants";
import { SET_STRATEGY_PAGE_HEADER, SET_STRATEGY_HEADER, SAVE_STRATEGY_BUTTON, BACK_BUTTON } from '../../StrategyConfigConstants';
import "../../StrategyConfiguration.css";

/**
 * Set Strategy View Component
 * @param {*} props 
 * @returns 
 */
const SetStrategyView = (props) => {
    const { handleSaveStrategies, strategyConfigData, strategyConfigActionsProp, editedConfigReq } = props;
    const history = useHistory();

    const handleBackBtn = () => {
        strategyConfigActionsProp.resetSaveStrategyScreen();
        history.push({pathname:`${PRODUCT_STORE_STATUS}`,state: {data : 'strategyConfig'}});
        
    }

    return (
        <>
            <div className="set_strategy_container">
                <div className="container__header" style={{ marginLeft: "2rem" }}>
                    <h1 className="fnt-lg fnt-bold">{SET_STRATEGY_PAGE_HEADER}</h1>
                </div>
                <div className="section_container">
                    <div className="container__body"></div>
                    <div className="container__header m-0">
                        <h3 className="fnt-bold">{SET_STRATEGY_HEADER}</h3>
                    </div>
                    <hr />
                    <EditPreSeason editedConfigReq={editedConfigReq} strategyConfigData={strategyConfigData} strategyConfigActionsProp={strategyConfigActionsProp} />
                    <hr />
                    <EditInSeason editedConfigReq={editedConfigReq} strategyConfigData={strategyConfigData} strategyConfigActionsProp={strategyConfigActionsProp} />
                    <hr />
                    <EditClearanceSeason editedConfigReq={editedConfigReq} strategyConfigData={strategyConfigData} strategyConfigActionsProp={strategyConfigActionsProp} />
                    <hr />
                    <div style={{ textAlign: "center" }}>
                        <button
                            className="btn btn-primary"
                            onClick={handleSaveStrategies}
                        // disabled={saveIsDisabled}
                        >
                            {SAVE_STRATEGY_BUTTON}
                        </button>
                    </div>
                </div>
                <div style={{ textAlign: "center" }}>
                    <button
                        className="btn btn-primary"
                        onClick={handleBackBtn}
                    >
                        {BACK_BUTTON}
                    </button>
                </div>
            </div>
        </>
    );
}

export default SetStrategyView;