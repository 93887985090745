import React from "react";
import "./Marquee.css"

const Marquee = React.memo((props) => {

    return (
        <div class="marquee">
            <p>{props.text1} &nbsp;&nbsp; {props.text2}</p>
        </div>
    )
}
)

export default Marquee