import React, { useCallback, useEffect, useState } from 'react'
import Slider from '../../../components/Slider/Slider'
import debounce from "lodash.debounce"
import Select from '../../../components/Filters/SingleSelect'
import MultiSelect from '../../../components/Filters/MultiSelect'
import * as Notify from '../../../components/Notification/Notifications'
import { intersectionWith, isEqual, min } from 'lodash'
import { connect } from 'react-redux'
import Notification from '../../../components/Notification/Notifications'
import moment from "moment";
import { PROFILE_PAGE } from '../../../constants/routeConstants';
import { handleSelectAll } from '../../../utils/SelectAllHandler/SelectAllHander'
import { fetchCreateFilterData, fetchFilterData } from '../ProductProfileAction'
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping"
import { shouldHideForCurrentClient } from '../../../utils/commonUtilities'
import AsyncMulti from '../../../components/Filters/AsyncMulti'

// function SetAttributesFilter({colourOptions,seasonCodeOptions,level4Options,maxPrice,...props}) {
    function SetAttributesFilter({maxPrice,...props}) {
    // const sample = {
    //     min: 0,
    //     max: 500,
    //     value: { min: 40, max: 400 }
    // }
    // const [inputrange, setInputrange] = useState(sample)
    const [min_price, setMin] = useState(0)
    const [max_price, setMax] = useState(0)
    const [color_description, setColour] = useState(null)
    const [season_code, setSeasonCode] = useState(null)
    const [level3, setLevel3] = useState(null)
    const [level4, setLevel4] = useState(null)
    const [level5, setLevel5] = useState(null)
    const [level6, setLevel6] = useState(null)
    const [level7, setLevel7] = useState(null)
    const [showButton, setShowButton] = useState(true)
    const [sales_attribute, setCheckedItems] = useState({"Regular":true, "Clearance":true,"Promo":true});
    const [seasonCodeOptions, setSeasonCodeOptions] = useState(props.seasonCodeOptions)
    const [colourOptions, setColourOptions] = useState(props.colourOptions)
    const [level3Options, setLevel3Options] = useState(props.level3Options)
    const [level4Options, setLevel4Options] = useState(props.level4Options)
    const [level5Options, setLevel5Options] = useState(props.level5Options)
    const [level6Options, setLevel6Options] = useState(props.level6Options)
    const [level7Options, setLevel7Options] = useState(props.level7Options)
    const [isEditing, setIsEditing] = useState(false)
    const [levelFilters,setLevelFilters] = useState({})
    const [request,setRequest] = useState(null)
    const [showMessage, setShowMessage] = useState(false)
    const [disableFilters, setDisableFilters] = useState({level3: false, level4: false, color: false})


    useEffect(() => {
        if(Object.keys(props.existingData).length && props.isEdit){
            let existingData = props.existingData 
            let savedData  = props.existingData?.savedData
            setMin(existingData?.min_price)
            setMax(existingData?.max_price)
            setColourOptions(savedData?.colourOptions)
            setSeasonCodeOptions(savedData?.seasonCodeOptions)
            setLevel3Options(savedData?.level3Options)
            setLevel4Options(savedData?.level4Options)
            setLevel5Options(savedData?.level5Options)
            setLevel6Options(savedData?.level6Options)
            setLevel7Options(savedData?.level7Options)
            setColour(savedData?.color_description?.length ? savedData?.color_description?.map(val => ({
                label:val,
                value:val
            })):[])
            setSeasonCode(savedData?.season_code)
            setLevel3(savedData?.level3)
            setLevel4(savedData?.level4)
            setLevel5(savedData?.level5)
            setLevel6(savedData?.level6)
            setLevel7(savedData?.level7)
            setCheckedItems({Regular: existingData?.regular, Clearance: existingData?.clearance, Promo: existingData?.promo} )
            setLevelFilters({
                profileName:savedData?.profileName,
                profileDescription:savedData?.profileName,
                level1:savedData?.level1,
                level2:savedData?.level2,
                level3:savedData?.level3,
                days:savedData?.days,
                min_date: savedData?.min_date ? moment(savedData?.min_date).format('YYYY-MM-DD') : null,
                max_date: savedData?.max_date ? moment(savedData?.max_date).format('YYYY-MM-DD') : null,
                static_dynamic: savedData?.static_dynamic,
               })
            setRequest({
                level3: savedData?.level3,
                level4: savedData?.level4,
                level5: savedData?.level5,
                level6: savedData?.level6,
                level7: savedData?.level7,
                color: (savedData?.color_description?.length ? savedData?.color_description?.map(val => ({
                    label:val,
                    value:val
                })):[]),
                season_code: savedData?.season_code
            })
        }
    },[props.existingData])

    useEffect(() => {
        if(props?.colourOptions?.length)
            setColourOptions(props?.colourOptions)
        if(props?.seasonCodeOptions?.length)
            setSeasonCodeOptions([
                // {label:'All',value:'All'},
                ...props?.seasonCodeOptions]) 
        if(props?.level4Options?.length)
            setLevel4Options([
                // {label:'All',value:'All'},
                ...props?.level4Options])
        if(props?.level5Options?.length){
            console.log(props.level5Options,'vsvfxvfdvdfvdf')
            setLevel5Options([...props?.level5Options]) 
        }
        if(props?.level6Options?.length) {
            setLevel6Options([...props?.level6Options])   
        }
        if(props?.level7Options?.length) {
            setLevel7Options([...props?.level7Options])   
        }
        if(props?.level3Options?.length)
            setLevel3Options([
                // {label:'All',value:'All'},
            ...props?.level3Options])   
    },[props?.colourOptions,props?.seasonCodeOptions,props?.level3Options,props?.level4Options,props?.level5Options,props?.level6Options,props?.level7Options])

    const handleChange = event => {
        // if(props.isEdit){
            setIsEditing(true)
            props.onChanged('setAttributesFilter')
        // }
      setCheckedItems((old) => {
       return{ 
            ...old,
            [event.target.name]: event.target.checked
        }
      });
    };

    useEffect(()=>{
    },[sales_attribute])

    useEffect(() => {
        setMax(maxPrice)
    }, [maxPrice])

    // const debouncedSetMinValue = useCallback(debounce((data,p_maximum) => {
    //     console.log(data)
    //     console.log(p_maximum)
    //         if(Number(data) >= Number(p_maximum)) {
    //             console.log(Number(data+1),'setting')
    //             setMax(Number(data)+1)
    //         }
    // },1000),[]);

    const debouncedSetMinValue = useCallback(debounce((data,p_maximum) => {
            if(Number(data) >= Number(p_maximum)) {
                setMax(Number(data)+1)
            }
    },1000),[]);

    // const debouncedSetMaxValue = useCallback(debounce((data,p_minimum) => {
    //     console.log(data,'simply')
    //     console.log(p_minimum,'the min')
    //     if(Number(data) <= Number(p_minimum)) {
    //         console.log(data,'data is lesser')
    //         if(data == 0){
    //             setMin(0)
    //         }
    //         else {
    //             console.log(data,'not equal to 0')
    //             setMin(Number(data)-1)
    //         }   
    //     }
    // },1000),[])

    const debouncedSetMaxValue = useCallback(debounce((data,p_minimum) => {
        if(Number(data) <= Number(p_minimum)) {
            if(data == 0){
                setMin(0)
            }
            else {
                setMin(Number(data)-1)
            }   
        }
    },1000),[])

    const onColourChange = (value,event,that) => {

        let valuesArray = handleSelectAll(value,event,that)
        setRequest(old => {
            return {
            ...old,
            'color': valuesArray[0]
            }
        })
        setColour(valuesArray[1]);
        // if(props.isEdit){
            setIsEditing(true)
            props.onChanged('setAttributesFilter')
        // }
        // let value = intersectionWith(val, colourOptions, isEqual);
        // setColour(value)
        //API call to get next filter
    }

    const onSeasonCodeChange = (value,event,that) => {
        let valuesArray = handleSelectAll(value,event,that)
        setRequest(old => {
            return {
            ...old,
            'season_code': valuesArray[0]
            }
        })
        setSeasonCode(valuesArray[1]);
        // if(props.isEdit){
            setIsEditing(true)
            props.onChanged('setAttributesFilter')
    }

    const onLevel3Change = (value,event,that) => {  
        color_description && setColour(null);
        season_code && setSeasonCode(null);
        level4 && setLevel4(null);
        level5 && setLevel5(null);
        level6 && setLevel6(null);
        level7 && setLevel7(null);
        let valuesArray = handleSelectAll(value,event,that)
        setRequest(old => {
            return {
            ...old,
            'level3': valuesArray[0],
            'level4': null,
            'level5': null,
            'level6': null,
            'level7': null,
            'color': null,
            'season_code': null
            }
        })
        let req_val = intersectionWith(valuesArray[0], level3Options, isEqual);
        let req = {
            "l1_name": [props.levelFilters.level1?.value || levelFilters.level1?.value],
            "l2_name": [props.levelFilters.level2?.value || levelFilters.level2?.value],
            "l3_name_options": level3Options,
        }
        if(req_val?.map(val => val.value).length) {
            req["l3_name"] =  req_val?.map(val => val.value)
        }
        props.fetchCreateFilterData(req)
        // if(props.isEdit){
            setIsEditing(true)
            props.onChanged('setAttributesFilter')
        // }
        if (props.selectedFilters?.level3) {
            let level3Values = []
            level3Values = level3Values.concat(props.selectedFilters?.level3)
            level3Values = level3Values.concat(valuesArray[1])
            const unique = [...new Set(level3Values.map(item => item.value))];
            let arrObj = unique.map(item => ({ label: item, value: item }))
            setLevel3(arrObj)
        }
        else {
            setLevel3(valuesArray[1])
        }
    }

    const onLevel4rChange = (value,event,that) => {
        color_description && setColour(null);
        season_code && setSeasonCode(null);
        level5 && setLevel5(null);
        level6 && setLevel6(null);
        level7 && setLevel7(null);
        let valuesArray = handleSelectAll(value,event,that)
        setRequest(old => {
            return {
            ...old,
            'level4': valuesArray[0],
            'level5': null,
            'level6': null,
            'level7': null,
            'color': null,
            'season_code': null
            }
        })

        let req_val = intersectionWith(valuesArray[0], level4Options, isEqual);
        let req = {
            "l1_name": [props.levelFilters.level1?.value || levelFilters.level1?.value],
            "l2_name": [props.levelFilters.level2?.value || levelFilters.level2?.value],
            "l3_name_options": level3Options,
            "l4_name_options": level4Options,
        }
        if(req_val?.map(val => val.value).length) {
            req["l4_name"] =  req_val?.map(val => val.value)
        }
        if(request?.level3?.map(val => val.value).length){
            req["l3_name"] =  request?.level3.map(val => val.value)
        }

        props.fetchCreateFilterData(req)
        // if(props.isEdit){
            setIsEditing(true)
            props.onChanged('setAttributesFilter')
        // }
        setLevel4(valuesArray[1])

    }

    const onLevel5Change = (value,event,that) => {
        color_description && setColour(null);
        season_code && setSeasonCode(null);
        level6 && setLevel6(null);
        level7 && setLevel7(null);

        let valuesArray = handleSelectAll(value,event,that)
        setRequest(old => {
            return {
            ...old,
            'level5': valuesArray[0],
            'level6': null,
            'level7': null,
            'color': null,
            'season_code': null
            }
        })

        let req_val = intersectionWith(valuesArray[0], level5Options, isEqual);
        let req = {
            "l1_name": [props.levelFilters.level1?.value || levelFilters.level1?.value],
            "l2_name": [props.levelFilters.level2?.value || levelFilters.level2?.value],
            "l3_name_options": level3Options,
            "l4_name_options": level4Options,
            "l5_name_options": level5Options,
        }
        if(req_val?.map(val => val.value).length) {
            req["l5_name"] =  req_val?.map(val => val.value)
        }
        if(request?.level3?.map(val => val.value).length){
            req["l3_name"] =  request?.level3.map(val => val.value)
        }
        if(request?.level4?.length && request?.level4?.map(val => val.value).length){
            req["l4_name"] =  request?.level4.map(val => val.value)
        }

        props.fetchCreateFilterData(req)
        // if(props.isEdit){
            setIsEditing(true)
            props.onChanged('setAttributesFilter')
        // }
        setLevel5(valuesArray[1])

    }

    // const onLevel5Change = (val) => {
    //     color_description && setColour(null);
    //     season_code && setSeasonCode(null);
    //     level7 && setLevel7(null);

    //     let req_val = intersectionWith(val, level5Options, isEqual);
        
    //     setRequest(old => {
    //         return {
    //         ...old,
    //         'level5': req_val
    //         }
    //     })

    //     let req = {
    //         "level1": [props.levelFilters.level1?.value || levelFilters.level1?.value],
    //         "level2": [props.levelFilters.level2?.value || levelFilters.level2?.value],
    //         "l3_name_options": level3Options,
    //         "l4_name_options": level4Options,
    //         "l5_name_options": level5Options,
    //         "l6_name_options": level6Options,
    //         "l7_name_options": level7Options,
    //     }
    //     if(req_val?.map(val => val.value).length) {
    //         req["l5_name"] =  req_val?.map(val => val.value)
    //     }
    //     if(request?.level3?.length && request?.level3?.map(val => val.value).length){
    //         req["l3_name"] =  request?.level3.map(val => val.value)
    //     }
    //     if(request?.level4?.length && request?.level4?.map(val => val.value).length){
    //         req["l4_name"] =  request?.level4.map(val => val.value)
    //     }

    //     props.fetchCreateFilterData(req)
    //     // if(props.isEdit){
    //         setIsEditing(true)
    //         props.onChanged('setAttributesFilter')
    //     // }
    //     setLevel5(req_val)

    // }

    const onLevel6Change = (value,event,that) => {
        color_description && setColour(null);
        season_code && setSeasonCode(null);
        level7 && setLevel7(null);

        let valuesArray = handleSelectAll(value,event,that)
        setRequest(old => {
            return {
            ...old,
            'level6': valuesArray[0],
            'level7': null,
            'color': null,
            'season_code': null
            }
        })

        let req_val = intersectionWith(valuesArray[0], level6Options, isEqual);
        let req = {
            "l1_name": [props.levelFilters.level1?.value || levelFilters.level1?.value],
            "l2_name": [props.levelFilters.level2?.value || levelFilters.level2?.value],
            "l3_name_options": level3Options,
            "l4_name_options": level4Options,
            "l5_name_options": level5Options,
            "l6_name_options": level6Options,
            "l7_name_options": level7Options,
        }
        if(req_val?.map(val => val.value).length) {
            req["l6_name"] =  req_val?.map(val => val.value)
        }
        if(request?.level3?.length && request?.level3?.map(val => val.value).length){
            req["l3_name"] =  request?.level3.map(val => val.value)
        }
        if(request?.level4?.length && request?.level4?.map(val => val.value).length){
            req["l4_name"] =  request?.level4.map(val => val.value)
        }
        if(request?.level5?.length && request?.level5?.map(val => val.value).length){
            req["l5_name"] =  request?.level5.map(val => val.value)
        }

        props.fetchCreateFilterData(req)
        // if(props.isEdit){
            setIsEditing(true)
            props.onChanged('setAttributesFilter')
        // }
        setLevel6(valuesArray[1])

    }

    const onLevel7Change = (value,event,that) => {
        color_description && setColour(null);
        season_code && setSeasonCode(null);

        let valuesArray = handleSelectAll(value,event,that)
        setRequest(old => {
            return {
            ...old,
            'level7': valuesArray[0],
            'color': null,
            'season_code': null
            }
        })

        let req_val = intersectionWith(valuesArray[0], level7Options, isEqual);
        let req = {
            "l1_name": [props.levelFilters.level1?.value || levelFilters.level1?.value],
            "l2_name": [props.levelFilters.level2?.value || levelFilters.level2?.value],
            "l3_name_options": level3Options,
            "l4_name_options": level4Options,
            "l5_name_options": level5Options,
            "l6_name_options": level6Options,
            "l7_name_options": level7Options,
        }
        if(req_val?.map(val => val.value).length) {
            req["l7_name"] =  req_val?.map(val => val.value)
        }
        if(request?.level3?.length && request?.level3?.map(val => val.value).length){
            req["l3_name"] =  request?.level3.map(val => val.value)
        }
        if(request?.level4?.length && request?.level4?.map(val => val.value).length){
            req["l4_name"] =  request?.level4.map(val => val.value)
        }
        if(request?.level5?.length && request?.level5?.map(val => val.value).length){
            req["l5_name"] =  request?.level5.map(val => val.value)
        }
        if(request?.level6?.length && request?.level6?.map(val => val.value).length){
            req["l6_name"] =  request?.level6.map(val => val.value)
        }

        props.fetchCreateFilterData(req)
        // if(props.isEdit){
            setIsEditing(true)
            props.onChanged('setAttributesFilter')
        // }
        setLevel7(valuesArray[1])

    }

    // const onLevel7Change = (val) => {
    //     let req_val = intersectionWith(val, level7Options, isEqual);
        
    //     setRequest(old => {
    //         return {
    //         ...old,
    //         'level7': req_val
    //         }
    //     })

    //     let req = {
    //         "level1": [props.levelFilters.level1?.value || levelFilters.level1?.value],
    //         "level2": [props.levelFilters.level2?.value || levelFilters.level2?.value],
    //         "l3_name_options": level3Options,
    //         "l4_name_options": level4Options,
    //         "l5_name_options": level5Options,
    //         "l6_name_options": level6Options,
    //         "l7_name_options": level7Options,
    //     }
    //     if(req_val?.map(val => val.value).length) {
    //         req["l7_name"] =  req_val?.map(val => val.value)
    //     }
    //     if(request?.level3?.length && request?.level3?.map(val => val.value).length){
    //         req["l3_name"] =  request?.level3.map(val => val.value)
    //     }
    //     if(request?.level4?.length && request?.level4?.map(val => val.value).length){
    //         req["l4_name"] =  request?.level4.map(val => val.value)
    //     }
    //     if(request?.level5?.length && request?.level5?.map(val => val.value).length){
    //         req["l5_name"] =  request?.level5.map(val => val.value)
    //     }
    //     if(request?.level6?.length && request?.level6?.map(val => val.value).length){
    //         req["l6_name"] =  request?.level6.map(val => val.value)
    //     }

    //     props.fetchCreateFilterData(req)
    //     // if(props.isEdit){
    //         setIsEditing(true)
    //         props.onChanged('setAttributesFilter')
    //     // }
    //     setLevel7(req_val)

    // }

    // const setMaxValue = value => {
    //     // if(props.isEdit){
    //         setIsEditing(true)
    //         props.onChanged('setAttributesFilter')
    //     // }
    //     if(Number(value) > Number(max_price)){
    //         setMax(max_price)
    //     }
    //     else{
    //         setMax(value)
    //         debouncedSetMaxValue(value,min_price)
    //     } 
    // }

    const setMaxValue = value => {
            setIsEditing(true)
            props.onChanged('setAttributesFilter')
            if(Number(value) > Number(maxPrice)) {
                setShowMessage(true) 
                return;
            };
            setShowMessage(false)
            setMax(value)
            debouncedSetMaxValue(value,min_price)
    }

    // const setMinValue = value => {
    //     // if(props.isEdit){
    //         setIsEditing(true)
    //         props.onChanged('setAttributesFilter')
    //     // }
    //     setMin(value)
    //     debouncedSetMinValue(value,max_price)
    // }

    const setMinValue = value => {
            setIsEditing(true)
            props.onChanged('setAttributesFilter')
            if(Number(value) >= Number(maxPrice)) {
                setShowMessage(true) 
                return;
            };
            setShowMessage(false) 
        setMin(value)
        debouncedSetMinValue(value,max_price)
    }

    useEffect(() => {
        // if(props.isEdit){
            setIsEditing(false)
        // }
    },[props.isEdit])

    useEffect(() => {
        if(props.selectedFilters) {
          let filterObj = {
              level3: false,
              level4: false,
              level5: false,
              level6: false,
              level7: false,
              color: false
          }
          if(props.selectedFilters?.level3) {
            setLevel3(props.selectedFilters?.level3)
            filterObj.level3 = true
            setRequest(old => {
                return {
                ...old,
                'level3': props.selectedFilters?.level3,
                }
            })
          }
          if(props.selectedFilters?.level4) {
            setLevel4(props.selectedFilters?.level4)
            filterObj.level4 = true
            setRequest(old => {
                return {
                ...old,
                'level4': props.selectedFilters?.level4,
                }
            })
          }
          if(props.selectedFilters?.level5) {
            setLevel5(props.selectedFilters?.level5)
            filterObj.level5 = true
            setRequest(old => {
                return {
                ...old,
                'level5': props.selectedFilters?.level5,
                }
            })
          }
          if(props.selectedFilters?.level6) {
            setLevel6(props.selectedFilters?.level6)
            filterObj.level6 = true
            setRequest(old => {
                return {
                ...old,
                'level6': props.selectedFilters?.level6,
                }
            })
          }
          if(props.selectedFilters?.level7) {
            setLevel7(props.selectedFilters?.level7)
            filterObj.level7 = true
            setRequest(old => {
                return {
                ...old,
                'level7': props.selectedFilters?.level7,
                }
            })
          }
          if(props.selectedFilters?.color) {
            setColour(props.selectedFilters?.color)
            filterObj.color = true
            setRequest(old => {
                return {
                ...old,
                'color': props.selectedFilters?.color,
                }
            })
          }
          setDisableFilters(filterObj)
        }
      }, [props.selectedFilters])

    const prepareReq = () => {
        let errMessage = ""
        if((Object.values(sales_attribute).includes(true) || Object.values(sales_attribute).includes(1)) && max_price > 0) {
           setShowButton(false)
           setIsEditing(false)
           let req = {
               ...levelFilters,
                min_price,
                max_price,
                color_description: request?.color,
                season_code: request?.season_code,
                level4: request?.level4,
                level5: request?.level5,
                level6: request?.level6,
                level7: request?.level7,
                sales_attribute
            }
             
           if(!shouldHideForCurrentClient("l3_name", "createPP")) {
               req = {
                    ...req,
                    level3: request?.level3,
                }
           }
           props.prepareReq(req,'setAttributeFilter')
        }
        else{
            if(!(Object.values(sales_attribute).includes(true) || Object.values(sales_attribute).includes(1))){
                errMessage = "Please Select Atleast one sales attribute"
            }
            else{
                errMessage=`Max Price should be greater than 0`
            }
           Notify.error(errMessage);
       }
    }

    const Checkbox = ({ type = "checkbox", name, checked = false, onChange, id }) => {
        return (
          <input id={id} type={type} name={name} checked={checked} onChange={onChange} />
        );
      };

    const checkboxes = [
        {
          name: "Regular",
          key: "checkBox1",
          label: "Regular"
        },
        {
          name: "Clearance",
          key: "checkBox2",
          label: "Clearance"
        },
        {
            name: "Promo",
            key: "checkBox3",
            label: "Promo"
        }
      ];

    return (
        <React.Fragment>
            {/* <Notification/> */}
             <div className="container__header">
                        <h1 className="fnt-lg fnt-bold">Set Attributes</h1>
                </div>
                <div className="container__body">
                    <div className="set__attributes__filters">
                        <div className="ml-2 p-2 required">
                            <label className="fnt-md fnt-bold fnt-bold">
                            Sales Attributes
                            </label>
                        </div>  
                        <hr/> 
                        <div className="row section">
                        <div className="checkbox__group">
                            {checkboxes.map(item => (
                                <div className="checkbox" key={item.key}>
                                    <Checkbox
                                        name={item.name}
                                        checked={sales_attribute[item.name]}
                                        onChange={handleChange}
                                        id={item.key}
                                    />
                                    <label for={item.key}>{item.label}</label>
                                </div>
                            ))}
                        </div>
                        </div>   
                        <div className="ml-2 p-2">
                            <label className="fnt-md fnt-bold fnt-bold">
                            Product Attributes
                            </label>
                        </div>  
                        <hr/> 
                        <div className="row section">
                            <div>
                                <div className="checkbox__group required">
                                    <div className="checkbox">
                                        <input id="price" value="price" defaultChecked type="checkbox"/>
                                        <label for="price">Price</label>
                                    </div>
                                </div>
                            </div>
                            <div className="set__attributes__filters__price">
                                {/* <div> <label>Min: </label> <input  min={0} value={min_price} onChange={e => setMinValue(e.target.value)} type="number"/></div>
                                <div> <label>Max: </label> <input  min={0} max={max_price} value={max_price} onChange={e => setMaxValue(e.target.value)} type="number"/></div> */}
                                <div> <label>Min: </label> <input  min={0} value={min_price} onChange={e => setMinValue(e.target.value)} type="number"/></div>
                                <div> <label>Max: </label> <input  min={0} value={max_price} onChange={e => setMaxValue(e.target.value)} type="number"/></div>
                            </div>
                           {showMessage && <label style={{color:"red"}}>Max value cannot be more than {maxPrice} and Min cannot be more than {Number(maxPrice) - 1}</label>}
                        </div>
                        <hr/> 
                        <div className="row section">
                        {
                            !shouldHideForCurrentClient("l3_name", "createPP") &&
                            <div className="col-md-3">
                                    <label className="fnt-md fnt-bold fnt-bold">{props.filterlabels?.level3}</label>
                                    <MultiSelect
                                        // isDisabled = {disableFilters.level3}
                                        name="level3"
                                        dropdownValue={level3}
                                        onDropDownChange={onLevel3Change}
                                        placeHolder={`Select ${props.filterlabels?.level3}`}
                                        optionsArray={(level3Options?.length) ? level3Options : []}
                                    />
                                </div>
                        }
                        {
                            !shouldHideForCurrentClient("l4_name", "createPP") &&
                                <div className="col-md-3">
                                    <label className="fnt-md fnt-bold fnt-bold">{props.filterlabels?.level4}</label>
                                    <MultiSelect
                                        // isDisabled = {disableFilters.level4}
                                        name="level4"
                                        dropdownValue={level4}
                                        onDropDownChange={onLevel4rChange}
                                        placeHolder={`Select ${props.filterlabels?.level4}`}
                                        optionsArray={(level4Options?.length) ? level4Options : []}
                                    />
                                </div>
                        }
                            {
                            !shouldHideForCurrentClient("l5_name") &&
                                <div className="col-md-3  fill">
                                <label className="fnt-md fnt-bold fnt-bold">{props.filterlabels?.level5}</label>                                    <MultiSelect
                                        name="level5"
                                        dropdownValue={level5}
                                        onDropDownChange={onLevel5Change}
                                        placeHolder={`Select ${props.filterlabels?.level5}`}
                                        optionsArray={(level5Options?.length) ? level5Options : []}
                                    />
                                </div>
                            }
                            {
                                !shouldHideForCurrentClient("l6_name") &&
                                <div className="col-md-3">
                                    <label className="fnt-md fnt-bold fnt-bold">{props.filterlabels?.level6}</label>
                                    <MultiSelect
                                        // isDisabled = {disableFilters.level6}
                                        name="level6"
                                        dropdownValue={level6}
                                        onDropDownChange={onLevel6Change}
                                        placeHolder={`Select ${props.filterlabels?.level6}`}
                                        optionsArray={(level6Options?.length) ? level6Options : []}
                                    />
                                </div>
                            }
                            {
                                !shouldHideForCurrentClient("l7_name") &&
                                <div className="col-md-3">
                                    <label className="fnt-md fnt-bold fnt-bold">{props.filterlabels?.level7}</label>
                                    <MultiSelect
                                        // isDisabled = {disableFilters.level7}
                                        name="level7"
                                        dropdownValue={level7}
                                        onDropDownChange={onLevel7Change}
                                        placeHolder={`Select ${props.filterlabels?.level7}`}
                                        optionsArray={(level7Options?.length) ? level7Options : []}
                                    />
                                </div>
                            }
                            <div className="col-md-3">
                                <label className="fnt-md fnt-bold">Color</label>
                                <MultiSelect
                                    // isDisabled = {disableFilters.color}
                                    name="color_description"
                                    dropdownValue={color_description}
                                    onDropDownChange={onColourChange}
                                    placeHolder={"Select Color"}
                                    optionsArray={(colourOptions?.length) ? colourOptions : []}
                                />
                            </div>
                            {
                            !shouldHideForCurrentClient("season_code") &&
                            <div className="col-md-3">
                                <label className="fnt-md fnt-bold fnt-bold">Season Code</label>
                                <MultiSelect
                                    name="season_code"
                                    dropdownValue={season_code}
                                    onDropDownChange={onSeasonCodeChange}
                                    placeHolder={"Select Season Code"}
                                    optionsArray={(seasonCodeOptions?.length) ? seasonCodeOptions : []}
                                />
                            </div>
                            }
                        </div>
                        {/* <div style={{padding:'0 1rem', margin:'0 1rem'}}>
                            <Slider onChange={onChange} data={inputrange}/> 
                        </div> */}
                    </div>
                    </div>
                    {((showButton && !props.isEdit)|| isEditing) ? <div style= {{margin:'auto', marginTop:'3rem'}} className="create__edit_product_profile_proceed__button">
                        <button onClick = {prepareReq} className="btn btn-primary" title="Proceed">Proceed</button>
                        <button onClick = {() => props.selectedFilters?.level1 ? props.goBackToCreateConfig() : props.history.push(`${PROFILE_PAGE}`, {isPPCreated: true, selectedFilters: props.selectedFilters})} className="btn btn-primary" title="Back">Back</button>
                        </div>: null}
        </React.Fragment>
    )
}

const mapStateToProps = ({ productProfile }) => ({
    colourOptions: productProfile?.createFilterData?.colorFilterData,
    seasonCodeOptions: productProfile?.createFilterData?.launchDateFilterData,
    level3Options: productProfile?.createFilterData?.l3_name,
    level4Options: productProfile?.createFilterData?.l4_name,
    level5Options: productProfile?.createFilterData?.l5_name,
    level6Options: productProfile?.createFilterData?.l6_name,
    level7Options: productProfile?.createFilterData?.l7_name,
    maxPrice: productProfile?.createFilterData?.max_price,
    createFilterloading: productProfile?.createFilterData?.createFilterloading,
    filterlabels: productProfile.filterlabels
});


const mapDispatchToProps = (dispatch) => ({
    fetchFilterData: (payload,filterType) => dispatch(fetchFilterData(payload,filterType)),
    fetchCreateFilterData: (payload) => dispatch(fetchCreateFilterData(payload)),
  });


export default connect(mapStateToProps, mapDispatchToProps)(SetAttributesFilter)
