import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import {
  fetchDeepDiveDataError,
  fetchDeepDiveDataSuccess,
  fetchExcelDataError,
  fetchExcelDataSuccesss,
  fetchFilterDataError,
  fetchFilterDataSuccesss,
  FETCH_FILTER_DATA,
  FETCH_TABLE_DATA,
  GENERATE_EXCEL,
} from "./DeepDiveAction";


import { isEmpty } from "lodash";
import { getDeepDiveData, getDeepDiveFilterData } from "../../../routes/api";
import { CARDS, DEEP_DIVE_TABLE_HEADERS } from "./DeepDiveConstants";
import { rowCount } from "./DeepDive";
import { appendPerc, flattenArray, isAshley } from "../../../utils/commonUtilities";
import { ERROR_MSG } from "../../../constants/globalConstant";

const level_ln_map = {
  "level1": "l1_name",
  "level2": "l2_name",
  "level3": "l3_name",
  "level4": "l4_name",
  "level5": "l5_name",
  "level6":"l6_name",
  "level7":"l7_name",
  "article":"article",
  "color":"color",
}

function* fetchStoreDataWorker(action) {
  try {
    const {payload} = action;
    const {request} = payload;
    const req = {}
    for(let i in request){
      if(!isEmpty(request[i])){
         if(Array.isArray(request[i])){
            req[i] = request[i].map(val => val.value)
         }
         else{
              req[i] = request[i]
         }
      }
    }
    req['rowIndex'] = payload.rowIndex
    req['rowCount'] = payload.rowCount
    if(!isEmpty(payload?.searchTermReq)) {
      req["searchColumns"] = {...payload?.searchTermReq}
    }
    if(!isEmpty(payload?.sortReq)) {
        req["sortColumn"] = {...payload?.sortReq[0]}
    }

    req["refresh_aggregate_data"] = payload?.refresh_aggregate_data

    const res = yield call(getDeepDiveData,req);

    if (res.data.status) {
      const {actual_vs_sales_qty_match,plan_vs_actual_qty_match,tot_actual_allocation,tot_planned_allocaton,plan_vs_actual_size_match,actual_vs_sales_size_match} = res?.data
      const cardData = [
      {
        'title': CARDS.CARD1,
        value:  tot_planned_allocaton
      },
      {
        'title': CARDS.CARD2,
        value:  tot_actual_allocation
      },
      {
        'title': CARDS.CARD3,
        value: appendPerc(plan_vs_actual_qty_match)
      },
      {
        'title': CARDS.CARD4,
        value: appendPerc(actual_vs_sales_qty_match)
      },
      {
        'title': CARDS.CARD5,
        value: appendPerc(plan_vs_actual_size_match)
      },
      {
        'title': CARDS.CARD6,
        value: appendPerc(actual_vs_sales_size_match)
      }]

      yield put(fetchDeepDiveDataSuccess({ data: res.data.deepdive_data, cardData:cardData, totalCount: res.data.totalCount, nextIndex: payload.rowIndex + rowCount, refresh_aggregate_data: payload?.refresh_aggregate_data }));
    } 
    else {
      yield put(fetchDeepDiveDataError({ error: ERROR_MSG }));
    }
  }
  catch (error) {
    console.log(error,'dcsdc')
    yield put(fetchDeepDiveDataError({ error: ERROR_MSG }));
  }
 
}

function* fetchFilterDataWorker(action) {
  try {
    const { payload, filterType } = action;
    let req = {};
    if (payload) {
      for (const key in payload) {
          !isEmpty(payload[key]) && (req[level_ln_map[key]] = flattenArray(payload[key]).map((ele) => ele.value))
      }
    }
    if(filterType){
      req["filter_type"] = filterType;
    }
    const res = yield call(getDeepDiveFilterData, req);
    if (res.data.status) {
      const data = {};
      data["filterMapping"] = res.data?.filterMapping
      for (const key in res.data.data[0]) {
      let k = "";
        if (key === "l1_name") {
          k = "departmentOptions";
        } else if (key === "l2_name") {
          k = "genderOptions";
        } else if (key === "l3_name") {
          k = "subCatOptions";
        } else if (key === "l4_name") {
          k = "dcsOptions";
        } else if (key === "l5_name") {
          k = "level5Options";
        } else if (key === "l6_name") {
          k = "level6Options";
        } else if (key === "l7_name") {
          k = "level7Options";
        } else if(key === "color") {
            k = "colorOptions";
        } else if(key === "article") {
          k = "articleIdOptions";
        } 
        else if(key === "style") {
            k = "styleOptions";
        }
         else if(key === "store") {
          k = "storeIdOptions";
        } else if(key === "season_code_active") {
          k = "seasonCodeOptions";
        } else if(key === "season_code_opened") {
          k = "seasonCodeOpenOptions";
        }

        if(Array.isArray(res?.data?.data?.[0]?.[key])) {
          data[k] = res.data.data[0][key]?.filter(val =>val).map((element) => ({
              value: element,
              label: element,
            }));
        }
        else{
          data[k] = []
          for (const [label, value] of Object.entries(res?.data?.data?.[0]?.[key])) {
            data[k].push({
              "label": label,
              "value": value
            })
          }        
      }

      }
        yield put(fetchFilterDataSuccesss({ data: data, key: action?.filterKey?.key }));
      } 
  }
  catch (error) {
    console.log('dd',error)
    yield put(fetchFilterDataError({ error: ERROR_MSG }));
  }
}

function* generateExcelWorker(action) {
  try {
      const {payload} = action;
      const {request} = payload;

      const req = {}
      for(let i in request){
        if(request[i]){
          if(Array.isArray(request[i])){
              req[i] = request[i].map(val => val.value)
          }
          else{
                req[i] = request[i]
          }
        }
      }    
      req['rowIndex'] = payload.rowIndex
      req['rowCount'] = payload.rowCount

      if(!isEmpty(payload?.searchTermReq)) {
        req["searchColumns"] = {...payload?.searchTermReq}
      }
      const res = yield call(getDeepDiveData, req);
      if (res.data.status) {
          let excelData = []
          res.data.deepdive_data.forEach(item => {
              excelData.push({
                [DEEP_DIVE_TABLE_HEADERS.ARTICLE_ID]: item.article,
                [DEEP_DIVE_TABLE_HEADERS.STYLE_DESCRIPTION]: item.style_name,
                [DEEP_DIVE_TABLE_HEADERS.STYLE_COLOR]: item.color_desc,
                [DEEP_DIVE_TABLE_HEADERS.COLOR]: item.color,
                [DEEP_DIVE_TABLE_HEADERS.CHANNEL]: item.l1_name,
                [DEEP_DIVE_TABLE_HEADERS.DEPARTMENT]: item.l2_name,
                [DEEP_DIVE_TABLE_HEADERS.CLASS]: item.l3_name,
                [DEEP_DIVE_TABLE_HEADERS.STYLEGROUP]: item.l4_name,
                [DEEP_DIVE_TABLE_HEADERS.SILHOUETTE]: item.l5_name,
                [DEEP_DIVE_TABLE_HEADERS.SUBCOLLECT]: item.l6_name,
                [DEEP_DIVE_TABLE_HEADERS.STORE_ID]: item.store_code,
                [DEEP_DIVE_TABLE_HEADERS.STORE_NAME]: item.store_name,
                ... isAshley() && {[DEEP_DIVE_TABLE_HEADERS.SEASON_CODE_ACTIVE]: item.season_code_active},
                [DEEP_DIVE_TABLE_HEADERS.SEASON_CODE_OPEN]: item.season_code_opened,
                [DEEP_DIVE_TABLE_HEADERS.STORE_GRADE]: item.store_grade,
                [DEEP_DIVE_TABLE_HEADERS.PLANNED_ALLOCATION]: item.planned_units,
                [DEEP_DIVE_TABLE_HEADERS.ACTUAL_ALLOCATION]: item.allocated_units,
                [DEEP_DIVE_TABLE_HEADERS.UNIT_SALES]: item.units,
                [DEEP_DIVE_TABLE_HEADERS.QTY_MATCH_PER_EXCEL]: item.planned_perc,
                [DEEP_DIVE_TABLE_HEADERS.SIZE_MATCH_PER_EXCEL]: item.size_perc_planned,
                [DEEP_DIVE_TABLE_HEADERS.ALLOCATION_MATCH_EXCEL]: item.match_perc,
                [DEEP_DIVE_TABLE_HEADERS.SIZE_MATCH_PER_SALES_EXCEL]: item.match_perc_size,
                [DEEP_DIVE_TABLE_HEADERS.SKU_DEPTH_EXCEL]: item.sku_depth_store,
                [DEEP_DIVE_TABLE_HEADERS.AVG_GRADE_REVENUE_EXCEL]: item.grade_revenue,
                [DEEP_DIVE_TABLE_HEADERS.STORE_REVENUE_EXCEL]: item.revenue,
                [DEEP_DIVE_TABLE_HEADERS.AVG_GRADE_MARGIN_EXCEL]: item.grade_margin,
                [DEEP_DIVE_TABLE_HEADERS.STORE_MARGIN_EXCEL] : item.margin,
              })
          })
          yield put(fetchExcelDataSuccesss({data:excelData, totalCountExcel: res.data.totalCount, nextIndexExcel: payload.rowIndex + rowCount }));

      }
      else {
          yield put(fetchExcelDataError({error: ERROR_MSG }));
      }
  }
  catch (error) {
      yield put(fetchExcelDataError({error: ERROR_MSG }));
  }
}

function* fetchTableData() {
  yield takeLatest(FETCH_TABLE_DATA, fetchStoreDataWorker);
}

function* fetchFilterDataWatcher() {
  yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* generateExcelWatcher() {
  yield takeLatest(GENERATE_EXCEL, generateExcelWorker)
}

export function* deepDiveSaga() {
  yield all([,
    fetchTableData(),
    fetchFilterDataWatcher(),
    generateExcelWatcher(),
  ]);
}
