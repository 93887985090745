import { cloneDeep, isEmpty } from "lodash";
import { takeLatest, put, call, all } from "redux-saga/effects";
import { ERROR_MSG } from "../../../constants/globalConstant";
import {
  getStoreCapacityData,
  updatePacksEachesForStoresAPI,
} from "../../../routes/api";
import { SET_EDIT_ALLOCATION_CODE } from "../FinalizeAction";
import * as actionTypes from "./actions/ActionTypes";

function* getStoreCapacityWorker(action) {
  const response = yield call(getStoreCapacityData, { ...action.payload });
  try {
    if (response.data.status) {
      let data = cloneDeep(response.data.storeViewData?.data)
      data.forEach(row => {
        for(let dcKey in row.dc_available_size_final) {
          row[`dcavailable${dcKey}`] = row.dc_available_size_final[dcKey]
        }
        row.subRows.forEach(sub => {
          for(let dcKey in sub.dc_available_size_final) {
            sub[`dcavailable${dcKey}`] = sub.dc_available_size_final[dcKey]
          }
        })
      })
      yield put({
        type: actionTypes.GET_STORE_CAPACITY_SUCCESS,
        data: {...response.data.storeViewData, data},
      });
    } else {
      yield put({
        type: actionTypes.GET_STORE_CAPACITY_ERROR,
        error: response.message ? response.message : ERROR_MSG,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_STORE_CAPACITY_ERROR,
      error: ERROR_MSG,
    });
  }
}

function* saveStoreCapacityChanges(action) {
  const {
    allocationCode,
    dcAvailableMap,
    packsAvailable,
    editData,
    packEditData,
    originalAllocationCode,
    index,
    API_COUNT,
  } = action.payload;
  let requests = [];
  let allArticles = [];
  if(!isEmpty(editData)) {
    allArticles = Object.keys(editData)
  }
  if(!isEmpty(packEditData)) {
    allArticles = [...allArticles, ...Object.keys(packEditData)]
  }
  allArticles = [...new Set(allArticles)];
  const articlesArr = allArticles.slice(index, index + API_COUNT);
  //Generating requests array to make parallel api calls
  articlesArr.forEach((article, index) => {
    let obj = {
      allocation_code: allocationCode,
      original_allocation_code: originalAllocationCode,
      article,
      stores: [],
    };
    if (dcAvailableMap[article]) {
      obj["available_eaches"] = dcAvailableMap[article];
    }
    if (packsAvailable[article]) {
      obj["available_packs"] = packsAvailable[article];
    }
    let storeKeys = [];
    
    if(editData?.[article]){
        storeKeys = Object.keys(editData?.[article]);
    }
    if(packEditData?.[article]) {
        storeKeys = [...storeKeys, ...Object.keys(packEditData?.[article])];
    }
    storeKeys = [...new Set(storeKeys)];
    storeKeys.forEach((store) => {
      let storeObj = {
          store
      };
      if (editData?.[article]?.[store]) {
        storeObj["updated_eaches"] = editData[article][store];
      }
      if (packEditData?.[article]?.[store]) {
        storeObj["updated_packs"] = packEditData[article][store];
      }
      obj.stores.push(storeObj);
    });
    requests.push(call(updatePacksEachesForStoresAPI, obj));
  });

  const response = yield all(requests);

  try {
    if (response.every((res) => res.data.status)) {
      yield put({
        type: SET_EDIT_ALLOCATION_CODE,
        allocationCode: response[0]?.data?.data?.allocation_code,
      });
      yield put({ type: actionTypes.SAVE_STORE_CAPACITY_SUCCESS, data: true });
    } else {
      yield put({
        type: actionTypes.SAVE_STORE_CAPACITY_ERROR,
        error: "Error in save",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.SAVE_STORE_CAPACITY_ERROR,
      error: "Error in save",
    });
  }
}

export function* getStoreCapacityWatcher() {
  yield takeLatest(actionTypes.GET_STORE_CAPACITY, getStoreCapacityWorker);
}

export function* saveStoreCapacityWatcher() {
  yield takeLatest(actionTypes.SAVE_STORE_CAPACITY, saveStoreCapacityChanges);
}

export function* storeCapacitySaga() {
  yield all([getStoreCapacityWatcher(), saveStoreCapacityWatcher()]);
}
