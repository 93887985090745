import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { strategyConfigInitialState } from './initialState';
import { cloneDeep } from 'lodash';
import * as strategyConfigActionTypes from "../actions/actionTypes";

const StrategyConfigReducers = {
    strategyConfiguration: handleActions(
        {
            [strategyConfigActionTypes.GET_STRATEGY_CONFIG_FILTER_REQUEST]: (state) => {
                return immutable(state, {
                    isStrategyConfigFilterLoading: { $set: true },
                    isStrategyConfigFilterFailed: { $set: false },
                });
            },
            [strategyConfigActionTypes.GET_STRATEGY_CONFIG_FILTER_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isStrategyConfigFilterLoading: { $set: false },
                    isStrategyConfigFilterFailed: { $set: true },
                });
            },
            [strategyConfigActionTypes.GET_STRATEGY_CONFIG_FILTER_SUCCESS]: (state, { payload }) => {
                switch (payload.key) {
                    case "department":
                        return immutable(state, {
                            level1Options: {
                                $set:
                                    payload && payload.data.level1Options
                                        ? payload.data.level1Options
                                        : state.level1Options,
                            },
                            level2Options: [],
                            level3Options: [],
                            level4Options: [],
                            level5Options: [],
                            level6Options: [],
                            level7Options: [],
                            styleOptions: [],
                            colorOptions: [],
                            seasonOptions: [],
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "gender":
                        return immutable(state, {
                            level2Options: {
                                $set:
                                    payload && payload.data.level2Options
                                        ? payload.data.level2Options
                                        : state.level2Options,
                            },
                            level3Options: {
                                $set:
                                    payload && payload.data.level3Options
                                        ? payload.data.level3Options
                                        : state.level3Options,
                            },
                            level4Options: {
                                $set:
                                    payload && payload.data.level4Options
                                        ? payload.data.level4Options
                                        : state.level4Options,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            seasonOptions: {
                                $set:
                                    payload && payload.data.seasonOptions
                                        ? payload.data.seasonOptions
                                        : state.seasonOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "sub_category":
                        return immutable(state, {
                            level3Options: {
                                $set:
                                    payload && payload.data.level3Options
                                        ? payload.data.level3Options
                                        : state.level3Options,
                            },
                            level4Options: {
                                $set:
                                    payload && payload.data.level4Options
                                        ? payload.data.level4Options
                                        : state.level4Options,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            seasonOptions: {
                                $set:
                                    payload && payload.data.seasonOptions
                                        ? payload.data.seasonOptions
                                        : state.seasonOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "dcs":
                        return immutable(state, {
                            level4Options: {
                                $set:
                                    payload && payload.data.level4Options
                                        ? payload.data.level4Options
                                        : state.level4Options,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            seasonOptions: {
                                $set:
                                    payload && payload.data.seasonOptions
                                        ? payload.data.seasonOptions
                                        : state.seasonOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "level5":
                        return immutable(state, {
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            seasonOptions: {
                                $set:
                                    payload && payload.data.seasonOptions
                                        ? payload.data.seasonOptions
                                        : state.seasonOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "l10":
                        return immutable(state, {
                            level2Options: {
                                $set:
                                    payload && payload.data.level2Options
                                        ? payload.data.level2Options
                                        : state.level2Options,
                            },
                            level3Options: {
                                $set:
                                    payload && payload.data.level3Options
                                        ? payload.data.level3Options
                                        : state.level3Options,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "l11":
                        return immutable(state, {
                            level3Options: {
                                $set:
                                    payload && payload.data.level3Options
                                        ? payload.data.level3Options
                                        : state.level3Options,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "level6":
                        return immutable(state, {
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            seasonOptions: {
                                $set:
                                    payload && payload.data.seasonOptions
                                        ? payload.data.seasonOptions
                                        : state.seasonOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "level7":
                        return immutable(state, {
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            seasonOptions: {
                                $set:
                                    payload && payload.data.seasonOptions
                                        ? payload.data.seasonOptions
                                        : state.seasonOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "style_name":
                        return immutable(state, {
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            seasonOptions: {
                                $set:
                                    payload && payload.data.seasonOptions
                                        ? payload.data.seasonOptions
                                        : state.seasonOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "color":
                        return immutable(state, {
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            seasonOptions: {
                                $set:
                                    payload && payload.data.seasonOptions
                                        ? payload.data.seasonOptions
                                        : state.seasonOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "season":
                        return immutable(state, {
                            seasonOptions: {
                                $set:
                                    payload && payload.data.seasonOptions
                                        ? payload.data.seasonOptions
                                        : state.seasonOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    default:
                        return immutable(state, {
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            },
                            isStrategyConfigFilterLoading: { $set: false },
                            isStrategyConfigFilterFailed: { $set: true },
                        });
                };
            },
            [strategyConfigActionTypes.GET_STRATEGY_CONFIG_TABLE_REQUEST]: (state) => {
                return immutable(state, {
                    isStrategyConfigTableLoading: { $set: true },
                    isStrategyConfigTableFailed: { $set: false },
                    isStrategyConfigTableSuccess: { $set: false },
                    totalCount: { $set: 0 },
                    nextIndex: { $set: 0 },
                });
            },
            [strategyConfigActionTypes.GET_STRATEGY_CONFIG_TABLE_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isStrategyConfigTableLoading: { $set: false },
                    isStrategyConfigFilterFailed: { $set: true },
                    isStrategyConfigTableSuccess: { $set: false },
                });
            },
            [strategyConfigActionTypes.GET_STRATEGY_CONFIG_TABLE_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    filterClicked: { $set: false },
                    isStrategyConfigTableLoading: { $set: false },
                    isStrategyConfigTableFailed: { $set: false },
                    isStrategyConfigTableSuccess: { $set: true },
                    strategyTableData: { $set: response.data },
                    totalCount: { $set: (response && response.totalCount) || 0 },
                    nextIndex: { $set: (response && response.nextIndex) || 0 },
                    searchTermRequest: { $set: response.searchColumns }
                });
            },
            [strategyConfigActionTypes.SET_ROWS_SELECTED]: (state, { payload }) => {
                return immutable(state, {
                    tableRowsSelected: { $set: payload },
                });
            },
            [strategyConfigActionTypes.RESET_ROWS_SELECTED]: (state, { payload }) => {
                return immutable(state, {
                    tableRowsSelected: { $set: {} },
                });
            },
            [strategyConfigActionTypes.GET_STRATEGY_CONFIG_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isStrategyConfigDataLoading: { $set: true },
                    isStrategyConfigDataFailed: { $set: false },
                    isStrategyConfigDataSuccess: { $set: false },
                });
            },
            [strategyConfigActionTypes.GET_STRATEGY_CONFIG_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isStrategyConfigDataLoading: { $set: false },
                    isStrategyConfigDataFailed: { $set: true },
                    isStrategyConfigDataSuccess: { $set: false },
                });
            },
            [strategyConfigActionTypes.GET_STRATEGY_CONFIG_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    isStrategyConfigDataLoading: { $set: false },
                    isStrategyConfigDataFailed: { $set: false },
                    isStrategyConfigDataSuccess: { $set: true },
                    strategyConfigData: { $set: response.data },
                    editedConfigReq: { $set: response.configBody },
                });
            },

            [strategyConfigActionTypes.DELETE_STRATEGY_CONFIG]: (state) => {
                return immutable(state, {
                    isStrategyConfigDeleteLoading: { $set: true },
                    isStrategyConfigDeleteFailed: { $set: false },
                    isStrategyConfigDeleteSuccess: { $set: false },
                });
            },
            [strategyConfigActionTypes.DELETE_STRATEGY_CONFIG_ERROR]: (state, { error }) => {
                return immutable(state, {
                    isStrategyConfigDeleteLoading: { $set: false },
                    isStrategyConfigDeleteFailed: { $set: true },
                    isStrategyConfigDeleteSuccess: { $set: false },
                });
            },
            [strategyConfigActionTypes.DELETE_STRATEGY_CONFIG_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    isStrategyConfigDeleteLoading: { $set: false },
                    isStrategyConfigDeleteFailed: { $set: false },
                    isStrategyConfigDeleteSuccess: { $set: true },
                });
            },

            [strategyConfigActionTypes.SET_EDITED_STRATEGY]: (state, { payload }) => {
                const { key, value } = payload;
                let updatedData = {
                    ...state.editedConfigReq,
                };
                updatedData[key] = value;

                return immutable(state, {
                    editedConfigReq: { $set: updatedData },
                });
            },
            [strategyConfigActionTypes.SAVE_STRATEGY_CONFIG_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isStrategyConfigSaveLoading: { $set: true },
                    isStrategyConfigSaveFailed: { $set: false },
                    isStrategyConfigSaveSuccess: { $set: false },
                });
            },
            [strategyConfigActionTypes.SAVE_STRATEGY_CONFIG_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isStrategyConfigSaveLoading: { $set: false },
                    isStrategyConfigSaveFailed: { $set: true },
                    isStrategyConfigSaveSuccess: { $set: false },
                });
            },
            [strategyConfigActionTypes.SAVE_STRATEGY_CONFIG_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    isStrategyConfigSaveSuccess: { $set: true },
                    isStrategyConfigSaveLoading: { $set: false },
                    isStrategyConfigSaveFailed: { $set: false },

                });
            },
            [strategyConfigActionTypes.RESET_SAVE_STRATEGIES]: (state) => {
                return immutable(state, {
                    tableRowsSelected: { $set: {} },
                    strategyConfigData: { $set: [] },
                    editedConfigReq: { $set: [] },
                    isStrategyConfigSaveLoading: { $set: false },
                    isStrategyConfigSaveFailed: { $set: false },
                    isStrategyConfigSaveSuccess: { $set: false },
                });
            },
            [strategyConfigActionTypes.RESET_STRATEGY_CONFIG]: (state) => {
                return immutable(state, {
                    isStrategyConfigFilterLoading: { $set: false },
                    isStrategyConfigFilterFailed: { $set: false },
                    level1Options: { $set: [] },
                    level2Options: { $set: [] },
                    level3Options: { $set: [] },
                    level4Options: { $set: [] },
                    level5Options: { $set: [] },
                    level6Options: { $set: [] },
                    level7Options: { $set: [] },
                    styleOptions: { $set: [] },
                    colorOptions: { $set: [] },
                    seasonOptions: { $set: [] },
                    filterLabels: { $set: {} },
                    isStrategyConfigTableLoading: { $set: false },
                    isStrategyConfigTableFailed: { $set: false },
                    isStrategyConfigTableSuccess: { $set: false },
                    strategyTableData: { $set: [] },
                    tableRowsSelected: { $set: {} },
                    isStrategyConfigDataLoading: { $set: false },
                    isStrategyConfigDataFailed: { $set: false },
                    isStrategyConfigDataSuccess: { $set: false },
                    isStrategyConfigDeleteLoading: { $set: false },
                    isStrategyConfigDeleteFailed: { $set: false },
                    isStrategyConfigDeleteSuccess: { $set: false },
                    strategyConfigData: { $set: [] },
                    editedConfigReq: { $set: [] },
                    isStrategyConfigSaveLoading: { $set: false },
                    isStrategyConfigSaveFailed: { $set: false },
                    isStrategyConfigSaveSuccess: { $set: false },
                    totalCount: { $set: 0 },
                    nextIndex: { $set: 0 },
                    filterClicked: { $set: false }
                });
            },
            [strategyConfigActionTypes.FILTER_CLICKED]: (state) => {
                return immutable(state, {
                    filterClicked: { $set: true }
                });
            },
        },
        strategyConfigInitialState
    ),
};

export default StrategyConfigReducers;