import {
    GET_ALLOCATION_PLANS,
    GET_ALLOCATION_PLANS_SUCCESS,
    GET_ALLOCATION_PLANS_ERROR,
    RESET_ALL,
    RESET_STYLE_INDEX
} from './ViewAllocationActions'

import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export const viewAllocationState = {
    allocationPlansLoading: false,
    allocationPlans: [],
    allocationPlansError: null,
    pageIndex: 0,
    style_index: 0,
    out_of_data: false,
    nextIndex: 0
}

export default {
    viewAllocation: handleActions(
        {
            [GET_ALLOCATION_PLANS]: (state) => {
                return immutable(state, {
                    allocationPlansLoading: { $set: true },
                    allocationPlans: { $set: [] },
                });
            },
            [GET_ALLOCATION_PLANS_SUCCESS]: (state, { payload }) => {
                return immutable(state, {
                    allocationPlans: { $set: (payload && payload.data) || [] },
                    allocationPlansLoading: { $set: false },
                    allocationPlansError: { $set: null },
                    pageIndex: { $set: (payload && state.pageIndex + payload?.data?.length) || 0 },
                    out_of_data: { $set: (payload && payload.data && payload.out_of_data) || false },
                    style_index: { $set: (payload && payload.data && payload.styleIndex) || 0 },
                    nextIndex: { $set: (payload && payload.data && payload.nextIndex) || 0 }
                })
            },
            [GET_ALLOCATION_PLANS_ERROR]: (state, { payload }) => {
                return immutable(state, {
                    allocationPlansError: { $set: (payload && payload.message) || null },
                    allocationPlansLoading: { $set: false },
                    allocationPlans: { $set: [] }
                })
            },
            [RESET_ALL]: (state => {
                return immutable(state, {
                    allocationPlansLoading: { $set: false },
                    allocationPlans: { $set: [] },
                    allocationPlansError: { $set: null },
                    style_index: { $set: 0 },
                    pageIndex: {$set: 0},
                    nextIndex: {$set: 0},
                    out_of_data: {$set: false}
                })
            }),
            [RESET_STYLE_INDEX]: (state, { payload }) => {
                return immutable(state, {
                    out_of_data: { $set: false },
                    style_index: { $set: 0 },
                    pageIndex: { $set: 0 },
                });
            },
        },
        viewAllocationState
    )
}