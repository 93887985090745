import {
    GET_DRAFTS,
    GET_DRAFTS_SUCCESS,
    GET_DRAFTS_ERROR,
    RESET_ALL
} from "./DraftsAction"
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export const draftsState = {
    draftsDataLoading: false,
    draftsData: [],
    draftsDataError: null
}

export default {
    drafts: handleActions(
        {
            [GET_DRAFTS]: (state, { payload }) => {
                return immutable(state, {
                    draftsDataLoading: { $set: true },
                    draftsData: { $set: [] },
                    draftsDataError: { $set: null }
                })
            },
            [GET_DRAFTS_SUCCESS]: (state, { payload }) => {
                return immutable(state, {
                    draftsDataLoading: { $set: false },
                    draftsData: { $set: (payload && payload.data) || [] },
                    draftsDataError: { $set: null }
                })
            },
            [GET_DRAFTS_ERROR]: (state, { payload }) => {
                return immutable(state, {
                    draftsDataLoading: { $set: false },
                    draftsData: { $set: [] },
                    draftsDataError: { $set: (payload && payload.message) || null },
                })
            },
            [RESET_ALL]: (state, { payload }) => {
                return immutable(state, {
                    draftsDataLoading: { $set: false },
                    draftsData: { $set: [] },
                    draftsDataError: { $set: null }
                })
            }
        },
        draftsState
    )
};