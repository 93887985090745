import React, { Component } from "react";
import "./Login.css";
import { useHistory } from "react-router";

const InvalidUserPage = () => {
    const history = useHistory()

    const onBackClick = () => {
        history.push("/login");
    };

    return (
        <div className="mainDIv34 text-center">
            <h3 className="font-size-15 mt-4">
                This Account is not valid Please try again!
            </h3>
            <button
                className="btn btn-primary btn-square shadow-sm btnSubmit mt-3 mb-3"
                onClick={onBackClick}
            >
                Go Back To Login
            </button>
        </div>
    );
}

export default InvalidUserPage