import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ToggleComponent from "../Toggle/ToggleComponent";
import ClusterFiltersInModal from "./ClusterFiltersInModal";
import { isEmpty } from "lodash";
import * as Notify from "../Notification/Notifications";
import Notification from "../Notification/Notifications";
import _ from "lodash";
import { uniq, find } from "lodash";
import { createStoreGroupStoreDataAction, 
  setstoreDataInFirstOrSecondTable 
} from "../../containers/StoreGroup/StoreGroupAction";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class CreateStoreGroupFIlter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeFilter: ["non-toggle"],
      toggleStoreLeft: [true, true],
      firstFilterSelected: { label: null, value: null },
      secondFilterSelected: { label: null, value: null },
      buttonLabel: "Apply",
      buttonDisabled: false
    };
  }

  componentDidMount() {
    if (!isEmpty(this.props.existingStoreCode)) {
      console.log("zxczxc", this.props);
      this.props.getStoreDataApi(
        {
          stores: this.props.existingStoreCode.storeCode,
        },
        "secondTable"
      );
      this.props.getCallback(
        {
          Store_Code: this.props.existingStoreCode.storeCode,
        },
        "secondTable"
      );
    }
  }

  addStoreFilter = () => {
    let { storeFilter } = this.state;
    if (storeFilter.length < 3) {
      storeFilter.push("toggle");
    } else {
      Notify.error("A maximum of 3 filters can be selected!");
    }
    this.setState({
      storeFilter,
      buttonLabel:'Apply',
      buttonDisabled: false
    });
  };
  onToggle = (index) => {
    let { toggleStoreLeft } = this.state;
    toggleStoreLeft[index] = !toggleStoreLeft[index];
    this.setState({
      toggleStoreLeft,
    });
  };
  getStores = (p_req) => {
    this.props.setstoreDataInFirstOrSecondTable("firstTable");
    let req = {};
    if (!isEmpty(p_req)) {
      for (const key in p_req) {
        console.log(p_req, "inside");
        req[key] = p_req[key].map(function mapper(ele) {
          if (Array.isArray(ele)) {
            return ele.map(mapper);
          } else {
            return ele.value;
          }
        });
      }
      for (let i in req) {
        req[i] = req[i].flat(Infinity);
      }
    }
    console.log(req,';;;;;')
      this.props.getStoreDataApi(req, "firstTable");
      this.props.getCallback(req, "firstTable");
  };
  selectedStoreFilters = [];

  getReqObject = (index, label, value) => {
    console.log("valuesforselect", index, label, value);

    if(value && value.length > 0 && label !== null){
      this.selectedStoreFilters[index] = {
        label: label,
        value: value.map((items) => items),
      };
      this.setState({
        buttonLabel:'Apply',
        buttonDisabled: false
      });
    }else{
      this.selectedStoreFilters[index] = {
        label: null,
        value: [],
      };
    }
    console.log("reqobj", this.selectedStoreFilters);
  };

  render() {
    return (
      <div>
                <div style={{display:"flex",flexWrap:"wrap"}}>
                  <ClusterFiltersInModal
                    getStores={this.getStores}
                    resetTableData= {this.props.resetTableData}
                    department={this.props?.department?.[0]?.description}
                    storeFilterData={
                      this.props.storeFilters.status
                        ? this.props.storeFilters
                        : []
                    }
                  ></ClusterFiltersInModal>
                </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    storeFilters: store.storeGroup?.storeGroupFiltersData,
    department: store.storeGroup?.department,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStoreDataApi: (payload, editingOrCreatingStoreGroup) =>
    dispatch(createStoreGroupStoreDataAction(payload, editingOrCreatingStoreGroup)),
  setstoreDataInFirstOrSecondTable: (p) =>
    dispatch(setstoreDataInFirstOrSecondTable(p)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateStoreGroupFIlter));
