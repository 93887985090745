import React, { useEffect } from 'react';
import NewStoreView from "./views/NewStoreView"

const NewStoreSetUpContainer = (props) => {
    const storeDetails  = props?.storeDetails

    return (
        <NewStoreView {...storeDetails} />
    )
}



export default NewStoreSetUpContainer