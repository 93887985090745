import React, { useEffect } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { PROFILE_PAGE } from '../../../constants/routeConstants'
import { saveProductProfile } from '../ProductProfileAction'
import ProfileTable from '../ProductProfileTable/ProfileTable'

function ProfiledProducts(props) {
    const maxWidth = '100%'
    const [column, setColumn] = useState([])
    const [tabData, setTabData] = useState([])
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        setColumn(props.profiledProductColumns)
    },[props.profiledProductColumns])

    useEffect(() => {
        if(Object.keys(props.existingData).length){
            setIsEdit(true)
            let savedData  = props.existingData?.savedData
            // setTabData(savedData?.profiledProductData) 
            // setColumn(savedData?.profiledProductColumns)
        }
    },[props.existingData])

    useEffect(() => {
        if(props?.profiledProductData?.length){
            setTabData(props?.profiledProductData)
        }    
    },[props?.profiledProductData])

    const saveResults = () => {
        let req = {}
        if(isEdit){
            let savedData  = props.existingData?.savedData
            req = {
                filters:{...props.levelFilters,"product_profile_code":props.profileId,"profileName":props.profileName,"profileDescription":props.profileDescription},
                storeData:props.storeData,
                profiledProductData:props.profiledProductData,
                profiledProductColumns:props.profiledProductColumns,
                colourOptions:props.colourOptions?.length?props.colourOptions:savedData?.colourOptions,
                seasonCodeOptions:(props.seasonCodeOptions && Object.keys(props.seasonCodeOptions))?.length?props.seasonCodeOptions:savedData?.seasonCodeOptions,
                level7Options:(props.level7Options && Object.keys(props.level7Options))?.length?props.level7Options:savedData?.level7Options,
                level6Options:(props.level6Options && Object.keys(props.level6Options))?.length?props.level6Options:savedData?.level6Options,
                level5Options:(props.level5Options && Object.keys(props.level5Options))?.length?props.level5Options:savedData?.level5Options,
                level4Options:(props.level4Options && Object.keys(props.level4Options))?.length?props.level4Options:savedData?.level4Options,
                level3Options:(props.level3Options && Object.keys(props.level3Options))?.length?props.level3Options:savedData?.level3Options,
                level2Options:(props.level2Options && Object.keys(props.level2Options))?.length?props.level2Options:savedData?.level2Options,
                "user_created_by": localStorage?.getItem("email"),
            }
        }
        else {
           req = { 
            filters:{...props.levelFilters,"profileName":props.profileName,"profileDescription":props.profileDescription},
            storeData:props.storeData,
            profiledProductData:props.profiledProductData,
            profiledProductColumns:props.profiledProductColumns,
            colourOptions:props.colourOptions,
            seasonCodeOptions:props.seasonCodeOptions,
            level7Options:props.level7Options,
            level6Options:props.level6Options,
            level5Options:props.level5Options,
            level4Options:props.level4Options,
            level3Options:props.level3Options,
            level2Options:props.level2Options,
            "user_created_by": localStorage?.getItem("email"),
        }
        }
        props.saveProductProfile({
            ...req
        })
    }

    return (
        <React.Fragment>
            <div className="container__header">
                <h1 className="fnt-lg fnt-bold"></h1>
            </div>
                    <div className="container__body">
                        <div className="profiled__products">
                            <ProfileTable
                                tabColumns = {column}
                                tabData = {tabData} 
                                maxWidth = {maxWidth}  
                                tabDataLoading = {props.profiledProductLoading}
                                tabDataError = {props.profiledProductError}
                                isShowTabs = {true}
                            />
                            <div className="d-flex align-items-center">
                                <div className = "indicate-div sister-store__map"></div>
                                <span className = "ml-2 font-italic mb-0"> Imputed using sister store</span>
                            </div>
                        </div>
                    </div>
                <div style= {{margin:'auto', marginTop:'3rem'}} className="create__edit_product_profile_proceed__button">
                    <button onClick={saveResults} className="btn btn-primary" title="Save">Save</button>
                    <button onClick = {() => props.isProductConfig ? props.goBackToCreateConfig() : props.history.push(`${PROFILE_PAGE}`)} className="btn btn-primary" title="Back">Back</button>
                </div>
        </React.Fragment>
    )
}

const mapStateToProps = ({ productProfile }) => ({
    profiledProductColumns: productProfile.profiledProductColumn,
    profiledProductData: productProfile.profiledProductData,
    profiledProductLoading: productProfile.profiledProductLoading,
    profiledProductError: productProfile.profiledProductError,
    storeData: productProfile.originalProfiledProductData,
    colourOptions: productProfile?.createFilterData?.colorFilterData,
    seasonCodeOptions: productProfile?.createFilterData?.launchDateFilterData,
    level7Options: productProfile?.createFilterData?.l7_name,
    level6Options: productProfile?.createFilterData?.l6_name,
    level5Options: productProfile?.createFilterData?.l5_name,
    level4Options: productProfile?.createFilterData?.l4_name,
    level3Options: productProfile?.createFilterData?.l3_name,
    level2Options: productProfile?.level2Options,
  });


const mapDispatchToProps = (dispatch) => ({
    saveProductProfile: (payload) => dispatch(saveProductProfile(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfiledProducts)
