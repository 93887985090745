import React, { useEffect, useState } from 'react'
import classnames from "classnames";
import UserManagementCSS from '../UserManagement.module.scss';
import CreateEditUserForm from './CreateEditUserForm';
import { connect } from 'react-redux';
import { createUser, editUser, getFormData ,createUserError } from '../UserManagementActions';
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import { intersectionWith, isEmpty } from 'lodash';
import { isEqual } from 'lodash';
import { useHistory } from "react-router-dom";
import { USER_MANAGEMENT_PAGE } from '../../../constants/routeConstants';
import PageLoader from '../../../components/Loader/PageLoader';
import { handleSelectAll } from '../../../utils/SelectAllHandler/SelectAllHander';
import { levelFiltersLabelFormatter } from '../../../utils/filterLevelMapping';
import { currentClientName, getDefaultFiltersCondition, hideFeatureForCurrentClient, isAshley, removeUserPreference, shouldHideForCurrentClient, USER_PREFERENCE_FILTERS } from '../../../utils/commonUtilities';
import { ERROR_MSG } from '../../../constants/globalConstant';

const CreateEditUserManagement = (props) => {

    const [fullName, setFullName] = useState(null)
    const [level1Options, setLevel1Options] = useState([])
    const [level2Options, setLevel2Options] = useState([])
    const [level3Options, setLevel3Options] = useState([])
    const [roleOptions, setRoleOptions] = useState([])
    const [level1, setLevel1] = useState(null)
    const [level2, setLevel2] = useState(null)
    const [level3, setLevel3] = useState(null)
    const [email, setEmail] = useState(null)
    const [role, setRole] = useState(null)
    const [status, setStatus] = useState(null)
    const [password, setPassword] = useState(null)
    const [validation, setValidation] = useState({ email: null, password: null })
    const [buttonLabel, setButtonLabel] = useState("Create User")
    const [successMessage, setSuccessMessage] = useState("User Created Successfully!!")
    const [heading, setHeading] = useState("Add New User")
    const [retailId, setRetailId] = useState(null)

    let history = useHistory();
    let userId = props?.match?.params?.userId

    useEffect(() => {
        if (userId) {
            props.getFormData({ "uid": userId })
            setButtonLabel("Save Changes")
            setSuccessMessage("User Edited Successfully!!")
            setHeading("Edit User")
        }
        else {
            props.getFormData({})
        }
    }, [])


    useEffect(() => {
        props.roleOptions && setRoleOptions(props.roleOptions)
        props.level1Options && setLevel1Options(props.level1Options)
        props.level2Options && setLevel2Options(props.level2Options)
        props.level3Options && setLevel3Options(props.level3Options)
    }, [props.roleOptions, props.level1Options. props?.level2Options, props?.level3Options])

    useEffect(() => {
        if (props.userResult) {
            let userResult = props.userResult
            let userAttributes = props?.userResult?.attributes
            setFullName(userResult?.name)
            setEmail(userResult?.email)
            setRole({ label: userResult?.group_name, value: userResult.group_id })
            setStatus(userResult?.status)
            setRetailId(userResult?.retail_pro_id)
            if (userAttributes?.l1_name) {
                setLevel1(userAttributes?.l1_name.map((ele) => {
                    return {
                        label: ele,
                        value: ele
                    }
                }))
                setLevel2(userAttributes?.l2_name.map((ele) => {
                    return {
                        label: ele,
                        value: ele
                    }
                }));
                // setLevel3(userAttributes?.l3_name.map((ele) => {
                //     return {
                //         label: ele,
                //         value: ele
                //     }
                // }))
            }
        }
    }, [props.userResult])

    useEffect(() => {
        if (props.createEditSuccess) {
            Notify.success(successMessage)
            setTimeout(() => {
                history.goBack()
            }, 2000);
        }
        if (props.createEditError) {
            Notify.error(props.createEditError || ERROR_MSG) 
        }
        props.createUserError({error:null})

    }, [props.createEditError, props.createEditSuccess])

    const onNameChange = (e) => {
        setFullName(e.target.value)
    }

    const onLevel1Change = (value, event, that) => {
        let valuesArray = handleSelectAll(value, event, that)
        // let value = intersectionWith(val, level4Options, isEqual);
        setLevel1(() => {
            return valuesArray[1];
        });
        // let value = intersectionWith(val, level1Options, isEqual);
        // setLevel1(value)
    }

    const onLevel2Change = (value, event, that) => {
        let valuesArray = handleSelectAll(value, event, that)
        // let value = intersectionWith(val, level4Options, isEqual);
        setLevel2(() => {
            return valuesArray[1];
        });
        // let value = intersectionWith(val, level1Options, isEqual);
        // setLevel1(value)
    }

    const onLevel3Change = (value, event, that) => {
        let valuesArray = handleSelectAll(value, event, that)
        // let value = intersectionWith(val, level4Options, isEqual);
        setLevel3(() => {
            return valuesArray[1];
        });
        // let value = intersectionWith(val, level1Options, isEqual);
        // setLevel1(value)
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const onRetailIdChange = (e) => {
        setRetailId(e.target.value)
    }

    function CheckPassword(inputtxt) {
        var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        if (inputtxt.match(passw)) {
            return true;
        } else {
            return false;
        }
    }

    const onBlur = (e) => {
        let name = e.target.name
        if (name === "email") {
            let value = e.target.value
            let atposition = value.indexOf("@");
            let dotposition = value.lastIndexOf(".");
            if (atposition < 1 || dotposition < atposition + 2 || dotposition + 2 >= value.length) {
                setValidation({ ...validation, "email": "Please enter a valid e-mail address" })
            }
        }
        if (name == "password") {
            if (!CheckPassword(e.target.value)) {
                setValidation({ ...validation, "password": "Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter" })
            }
        }

    }

    const onFocus = (e) => {
        let name = e.target.name
        if (name === "email") {
            setValidation({ ...validation, "email": null })
        }
        if (name === "password") {
            setValidation({ ...validation, "password": null })
        }
    }

    const onRoleChange = (val) => {
        setRole(val)
    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value)
    }

    const onStatusChange = (e) => {
        setStatus(e.target.value)
    }

    const getLevelReqBody = (p_levelFilters) => {
        let l_userPreferenceFilters =  USER_PREFERENCE_FILTERS[currentClientName] || ["l1_name"]
        let l_preferenceFilter = {}
        l_userPreferenceFilters?.forEach(val => {
            p_levelFilters?.[val] && (l_preferenceFilter[val] = p_levelFilters?.[val] ?.filter((o) => o.value !== "*")?.map(ele => ele.value))
        })
        return {"attributes": l_preferenceFilter}
    }

    const handleCreateEdit = () => {
        if (fullName && email && getDefaultFiltersCondition({l1_name: level1, l2_name: level2}) && role && !validation.email) {
            let levelReqBody = getLevelReqBody({l1_name: level1, l2_name: level2})
            let reqBody = {
                "name": fullName,
                "email": email,
                "retail_pro_id": "-",
                "group_id": role.value,
                ...levelReqBody,                
                // "password": password,
                "status": status
            }
            if (userId) {
                reqBody.uid = userId
                reqBody.password = ""
                removeUserPreference(reqBody)
                props.editUser(reqBody)
            }
            else {
                props.createUser(reqBody)
            }
        }
        else if (validation.email) {
            Notify.error("Please enter a valid e-mail address!!");
        }
        else {
            Notify.error("Please fill all Mandatory details!!");
        }
    }


    const prep_basic = [
        {
            id: 1,
            elem_type: 'input',
            isRequired: true,
            input_type: 'text',
            isMultiSelect: false,
            label: "Full Name",
            name: "fullName",
            value: fullName,
            onChange: onNameChange,
            placeholder: "Full Name",
        },
        {
            id: 2,
            elem_type: 'input',
            isRequired: true,
            input_type: 'email',
            isMultiSelect: false,
            label: 'Email ID',
            name: "email",
            value: email,
            onChange: onEmailChange,
            onBlur: onBlur,
            onFocus: onFocus,
            validation: validation,
            placeholder: "Email ID",
        },
        // {
        //     id: 3,
        //     elem_type: 'input',
        //     isRequired: true,
        //     input_type: 'text',
        //     isMultiSelect: false,
        //     label: 'Retail Pro ID',
        //     name: "retailProId",
        //     value: retailId,
        //     onChange: onRetailIdChange,
        //     onBlur: onBlur,
        //     onFocus: onFocus,
        //     placeholder: "Retail Pro ID",
        //     hide: hideFeatureForCurrentClient("retailProId"),
        // },
        {
            id: 4,
            elem_type: 'select',
            isRequired: true,
            input_type: 'dropdown',
            isMultiSelect: false,
            label: 'Role',
            name: "role",
            value: role,
            onChange: onRoleChange,
            placeholder: "Select Role",
            options: (roleOptions.length) ? roleOptions : []
        },
        // {
        //     id: 4,
        //     elem_type: 'input',
        //     isRequired: true,
        //     input_type: 'password',
        //     isMultiSelect: false,
        //     label: 'Password',
        //     name: "password",
        //     value:password,
        //     onChange:onPasswordChange,
        //     onBlur: onBlur,
        //     validation: validation,
        //     onFocus: onFocus,
        //     placeholder: "Password",
        // },
        {
            id: 5,
            elem_type: 'radio',
            isRequired: true,
            input_type: 'radio',
            isMultiSelect: false,
            label: 'Status',
            name: "level2",
            onChange: onStatusChange,
            placeholder: "Status",
            status: status
        },
    ]

    const prep_level_access = [
        {
            id: 1,
            elem_type: 'select',
            isRequired: true,
            input_type: 'dropdown',
            isMultiSelect: true,
            label: levelFiltersLabelFormatter("level1"),
            name: "level1",
            dropdownValue: level1,
            onDropDownChange: onLevel1Change,
            placeHolder: `Select ${levelFiltersLabelFormatter("level1")}`,
            optionsArray: (level1Options.length) ? level1Options : []
        },
        {
            id: 2,
            elem_type: 'select',
            isRequired: true,
            input_type: 'dropdown',
            isMultiSelect: true,
            label: levelFiltersLabelFormatter("level2"),
            name: "level2",
            dropdownValue: level2,
            onDropDownChange: onLevel2Change,
            placeHolder: `Select ${levelFiltersLabelFormatter("level2")}`,
            optionsArray: (level2Options.length) ? level2Options : [],
            hide: shouldHideForCurrentClient("l2_name", "createEditUser"),
          },
        //   {
        //     id: 3,
        //     elem_type: 'select',
        //     isRequired: true,
        //     input_type: 'dropdown',
        //     isMultiSelect: true,
        //     label: levelFiltersLabelFormatter("level3"),
        //     name: "level3",
        //     dropdownValue: level3,
        //     onDropDownChange: onLevel3Change,
        //     placeHolder: `Select ${levelFiltersLabelFormatter("level3")}`,
        //     optionsArray: (level3Options.length) ? level3Options : [],
        //     hide: shouldHideForCurrentClient("l3_name", "createEditUser"),
        //   },
    ]

    return (
        <div className={UserManagementCSS.user_management__container}>
            {/* <Notification /> */}
            <div className="container__header">
                <h1 className="fnt-lg fnt-bold">User Management / {heading}</h1>
            </div>
            <PageLoader gridLoader loader={props.loading}>
                <section className={classnames("", UserManagementCSS.user_management__container_body)}>
                    <div className={classnames("row", UserManagementCSS.user_management__align_baseline)}>
                        <div className="col-sm-6">
                            <h3 className="fnt-bold">Basic Info</h3>
                            <CreateEditUserForm data={prep_basic} />
                        </div>
                        {/* {
                            !isAshley() && */}
                            <div className="col-sm-6">
                                <h3 className="fnt-bold">Access Level</h3>
                                <CreateEditUserForm data={prep_level_access} />
                            </div>
                        {/* } */}
                        <div className="col-sm-12">
                            <div className="d-sm-flex justify-content-center">
                                <div className="m-sm-4 m-2">
                                    <button onClick={() => handleCreateEdit()} className="btn btn-primary w-100 m-sm-2 px-2">
                                        {buttonLabel}
                                        {/* &amp; Send mail */}
                                    </button>
                                </div>
                                <div className="m-sm-4 m-2">
                                    <button onClick={() => history.push(USER_MANAGEMENT_PAGE)} className="btn btn-primary w-100 m-sm-2 px-2">
                                        Cancel
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </PageLoader>
        </div>
    )
}

const mapStateToProps = ({ userManagement }) => ({
    level1Options: userManagement.level1Options,
    level2Options: userManagement.level2Options,
    level3Options: userManagement.level3Options,
    roleOptions: userManagement.roleOptions,
    createEditSuccess: userManagement.createEditSuccess,
    createEditError: userManagement.createEditError,
    userResult: userManagement.userResult,
    loading: userManagement.loading
})

const mapDispatchToProps = (dispatch) => ({
    getFormData: (payload) => dispatch(getFormData(payload)),
    createUser: (payload) => dispatch(createUser(payload)),
    editUser: (payload) => dispatch(editUser(payload)),
    createUserError: (payload) => dispatch(createUserError(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(CreateEditUserManagement)
