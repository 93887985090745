import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { REVIEW_HEADER } from "./ReviewAllocationConstants"
import ReviewAllocationTable from "./ReviewAllocationTable";
import PageLoader from "../../../../components/Loader/PageLoader";
import { getReviewData } from "../../actions/DemandAction/DemandAction"


const ReviewAllocation = (props) => {
    const { getReviewData, reviewData, reviewDataError, reviewDataLoading, setReviewRows,
        sister_store_code, allocation_code } = props
    useEffect(() => {
        if (allocation_code) {
            getReviewTableData()
        }
    }, [allocation_code])

    const getReviewTableData = () => {
        getReviewData({ allocation_code })
    }

    return (
        <div>
            <section className="section mr-4">
                <div className="container__header m-0">
                    <h3 className="fnt-bold">{REVIEW_HEADER}</h3>
                </div>
                <hr />
                <PageLoader loader={reviewDataLoading} gridLoader={true}>
                    {(reviewDataError) ? (<div className="error">{reviewDataError}</div>) :
                        (reviewData ? (
                            <ReviewAllocationTable data={reviewData} getReviewTableData = {getReviewTableData}
                                setReviewRows={setReviewRows} allocation_code={allocation_code}
                                store={sister_store_code} />
                        ) : null)}
                </PageLoader>
            </section>
        </div>
    )
}

const mapStateToProps = ({ demandConstraints }) => {
    return {
        reviewDataLoading: demandConstraints.reviewDataLoading,
        reviewData: demandConstraints.reviewData,
        reviewDataError: demandConstraints.reviewDataError
    }
}

const mapDispatchToProps = (dispatch) => ({
    getReviewData: (payload) => dispatch(getReviewData(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewAllocation)