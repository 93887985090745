import React, { Component } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./ToggleStyling.css";

class ToggleComponent extends Component {
  render() {
    return (
      <div className="row mr-3 mt-2 btn-group">
        {this.props.hideText ? null : (
          <span
            className={
              this.props.primaryToggleState
                ? "toggle-active-class font-bold"
                : ""
            }
          >
            &nbsp; {this.props.primaryToggleText} &nbsp;
          </span>
        )}

        <Toggle
          defaultChecked={!this.props.primaryToggleState}
          icons={false}
          onChange={this.props.onToggle}
          disabled={this.props.disabled}
        />
        {this.props.hideText ? null : (
          <span
            className={
              this.props.primaryToggleState
                ? ""
                : "toggle-active-class font-bold"
            }
          >
            &nbsp; {this.props.secondaryToggleText} &nbsp;
          </span>
        )}
      </div>
    );
  }
}

export default ToggleComponent;
