import * as actionTypes from "./ActionTypes"

export const getExcelData = (payload, apiURL) => ({
    type: actionTypes.GET_EXCEL_DATA,
    payload,
    api: apiURL
})

export const resetDownloadExcel = () => ({
    type: actionTypes.RESET_DOWNLOAD_EXCEL
})