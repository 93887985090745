import Api from "../configs/apiConfig";
import API from "../configs/api_config"
import {
  STRATEGY_TABLE_API,
  FILTER_DATA_API,
  STRATEGY_TABLE_DROPDOWN,
  STRATEGY_TABLE_SG_MIN_STOCK,
  STORE_TABLE_DATA,
  EDIT_TABLE_ADA,
  CONFIRM_DATA_ADA,
  DC_TABLE_DATA,
  CREATE_ALLOCATION,
  UPDATE_RESERVED_QTY,
  SAVE,
  GET_STORE_GROUP_MIN_STOCK,
  GET_SIZE_DC,
  SAVE_DRAFT,
  GET_DRAFTS,
  DELETE_DRAFT,
  VALIDATE_DRAFT_NAME,
  OPTIMIZATION_ALGO_INPUT_DATA,
  REVIEW_ADA
} from "../constants/apiConstants";

export const getStrategyTableData = (params) =>
  Api.post(STRATEGY_TABLE_API, params);

export const getFilterData = (params) => Api.post(FILTER_DATA_API, params);

export const getStrategyDropdownData = (params) =>
  Api.post(STRATEGY_TABLE_DROPDOWN, params);

export const getStrategyTableMinStockData = (params) =>
  Api.post(STRATEGY_TABLE_SG_MIN_STOCK, params);

export const getStoreTableData = (params) => Api.post(STORE_TABLE_DATA, params);

export const editDataAda = (params) => API.put(EDIT_TABLE_ADA, params);

export const reviewAda = (params) => Api.post(REVIEW_ADA, params);

export const getDcTableData = (params) => Api.post(DC_TABLE_DATA, params);

export const createAllocation = (params) => Api.post(CREATE_ALLOCATION, params);

export const optimizationAlgoInputData = (params) => Api.post(OPTIMIZATION_ALGO_INPUT_DATA, params);

export const updateResQty = (params) => Api.post(UPDATE_RESERVED_QTY, params);

export const save = (params) => Api.post(SAVE, params);

export const getstoregroupminstock1 = (params) => Api.post(GET_STORE_GROUP_MIN_STOCK, params);

export const getSizeDc = (params) => Api.post(GET_SIZE_DC, params);

export const saveDraft = (params) => Api.post(SAVE_DRAFT, params)

export const getDrafts = (params) => Api.post(GET_DRAFTS, params)

export const deleteDraft = (params) => Api.post(DELETE_DRAFT, params)

export const validateDraftName = (params) => Api.post(VALIDATE_DRAFT_NAME, params)
