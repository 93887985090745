import React, { useEffect, useState }  from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { InputNumber } from 'primereact/inputnumber';
import * as adaVisualActions from './actions/AdaVisualActions';
import { EMPTY_FORECAST_MSG, EMPTY_DATA_HELPER_MSG  } from './AdaVisualConstants';
import "./TableStyle.css";
import { CSVLink } from "react-csv";
import ExportFile from '../../components/ExportFile/ExportFile'

/**
 * Forecast Table View component
 * @param {table data and headers} props 
 * @returns 
 */
const ForecastTableView = (props) => {
    const { adaData, keys, headers, currentWeek, handleAdjustedOnChange, handleForecastOnChange ,saveSuccess,adaVisualActions,downloadCsvData,isForecastModified} = props;
    const [excelData, setExcelData] = useState([])
    const [downloadExcel, setDownloadExcel] = useState(false)
    // const [csvHeader, setCsvHeader] = useState([])


    // useEffect(() => {
    //     if (!isEmpty(csvHeader) && !isEmpty(csvData)) {
    //       setTimeout(() => {
    //         csvRef?.link?.click()
    //       }, 200)
    //     }
    //   }, [csvHeader, csvData])

      useEffect(() => {
      if(downloadCsvData.length!==0){
        setDownloadExcel(true)
          setExcelData(downloadCsvData)
      }
      },[downloadCsvData])

      useEffect(() => {
        if(adaData.length!==0){
        setDownloadExcel(true)
            setExcelData([])
        }
    },[adaData])

      const generateExcel = () => {
          setExcelData([])
          setDownloadExcel(false)
          if(adaData.length!==0){
        adaVisualActions.downloadCsv(adaData)
          }
        }
      
    return (
        <>
         {  (!isForecastModified || saveSuccess) &&
                <div style={{float:"right",marginTop:"0px"}}>
                     <ExportFile text={"Excel"}  downloadExcelData={downloadExcel} data={excelData} sheetName={"Forecast Multiplier"} callFunc={generateExcel} fileName={'forecastMultiplier'} type={3} /> 
                </div>
            }
        <div style={(!isForecastModified || saveSuccess) ? {padding:"2px",marginTop:"35px"}:{padding:"2px"}} >
            {adaData.length === 0 ?
                <div className="empty">{EMPTY_FORECAST_MSG}
                    <p>{EMPTY_DATA_HELPER_MSG}</p>
                </div>
                :
                <table className="table-set">
                    <thead>
                        <tr>
                            <th className="th-set sticky-col" key="0">
                                <span></span>
                            </th>
                            {adaData.map((weekHead) => (
                                <th className="th-set" key={weekHead.week}>
                                    <span style={{ border: "none" }}>{`W${(weekHead.week)}`}</span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="">
                        {keys.map((key, keyIndex) => (
                            <tr key={keyIndex}>
                                <td className="td-set sticky-col" style={{ minWidth: "115px" }} key={0}>
                                    {headers[key]}
                                </td>
                                {adaData.map((value, index) => (
                                    parseInt(value.fy_fw) < parseInt(currentWeek) ?
                                        <td className="td-set data_disabled" key={index + 1}>
                                            <InputNumber
                                                className="span-data"
                                                value={key === 'forecast_multiplier' ? value[key].toFixed(2) : value[key]}
                                                mode='decimal'
                                                minFractionDigits={key === 'forecast_multiplier' ? 1 : 0}
                                                maxFractionDigits={key === 'forecast_multiplier' ? 2 : 0}
                                                key={value.fy_fw}
                                                disabled
                                            />
                                        </td>
                                        : 
                                        <td className="td-set data_input" key={index + 1}>
                                         <InputNumber
                                                mode='decimal'
                                                min={0}
                                                minFractionDigits={key === 'forecast_multiplier' ? 1 : 0}
                                                maxFractionDigits={key === 'forecast_multiplier' ? 2 : 0}
                                                value={key === 'forecast_multiplier' ? value[key].toFixed(2) : key === 'actual_sales' ? "-" : value[key]}
                                                disabled={key === 'ia_forecast' ? 'ia_forecast' : key=== 'actual_sales' && "actual_sales"}
                                                key={value.fy_fw}
                                                name={value.fy_fw}
                                                onChange={key === 'adjusted_forecast' ? handleAdjustedOnChange : handleForecastOnChange}
                                                className={(key === 'ia_forecast' || key === 'actual_sales')
                                                    ? "span-data" : "edit-span"}
                                            /> 
                                        </td>

                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </div>
        </>
    )
}

const mapStateToProps = ({ adaVisual }) => ({
    downloadCsvData: adaVisual.downloadCsvData,
    isForecastModified: adaVisual.isForecastModified
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForecastTableView);