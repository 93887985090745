import React from "react";
import { useFlexLayout } from "react-table";
import Styles from "../ReactTableStyles";

const BlockLayoutMarkup = React.memo(
  ({
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows,
    height,
    width,
    shouldPagination,
    page,
    keyRT,
  }) => {
    const rowsToRender = shouldPagination ? page : rows;
    return (
      <>
        <Styles>
          <div
            {...getTableProps({
              style: {
                height: height,
                //width: "100%",
              },
            })}
            className="table sticky"
          >
            <div className={keyRT === "setUpDrops" ? "header z-1" : "header"}>
              {headerGroups.map((headerGroup) => (
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr inline" /*style = {{
                  width: "100%",
                  display: "flex",
                }}*/
                >
                  {headerGroup.headers.map((column) => (
                    <div
                      className="th-block"
                      //  {...column.getHeaderProps({ className: column.headerClassName })}
                      {...column.getHeaderProps(
                        column.getSortByToggleProps &&
                          column.getSortByToggleProps()
                      )}
                      {...column.getHeaderProps().style}
                    >
                      {column.canGroupBy ? (
                        // If the column can be grouped, let's add a toggle
                        <span {...column.getGroupByToggleProps()}>
                          {column.isGrouped ? "" : ""}
                        </span>
                      ) : null}
                      {column.render("Header")}
                      {/* Render the columns filter UI */}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i
                              class="fa fa-chevron-down ml-1"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              class="fa fa-chevron-up ml-1"
                              aria-hidden="true"
                            ></i>
                          )
                        ) : (
                          ""
                        )}
                      </span>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div {...getTableBodyProps()}>
              {rowsToRender.map((row, i) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className="tr">
                    {row.cells.map((cell) => {
                      return (
                        <div
                          className={`td ${
                            cell.isGrouped ? "rt-grouped-td" : ""
                          }`}
                          {...cell.getCellProps()}
                        >
                          {cell.isGrouped ? (
                            // If it's a grouped cell, add an expander and row count
                            <>
                              <span {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? "▼" : "►"}
                              </span>{" "}
                              {cell.render("Cell")} ({row.subRows.length})
                            </>
                          ) : cell.isAggregated ? (
                            // If the cell is aggregated, use the Aggregated
                            // renderer for cell
                            cell.render("Aggregated")
                          ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                            // Otherwise, just render the regular cell
                            cell.render("Cell")
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </Styles>
      </>
    );
  }
);

export default BlockLayoutMarkup;
