export const STRATEGY_CONFIG_FILTER_DEPARTMENT = "Department";
export const STRATEGY_CONFIG_FILTER_SUBDEPARTMENT = "Sub-Department";
export const STRATEGY_CONFIG_FILTER_SUBCATEGORY = "Sub-Category";
export const STRATEGY_CONFIG_FILTER_DCS = "DCS";
export const STRATEGY_CONFIG_FILTER_STYLE = "Style Name";
export const STRATEGY_CONFIG_FILTER_COLOR = "Color";
export const STRATEGY_CONFIG_FILTER_SEASON = "Season";
export const STRATEGY_CONFIG_FILTER_GENDER ="Gender";
// export const STRATEGY_CONFIG_FILTER_SEASON = "Season"

export const STRATEGY_CONFIG_TABLE_COL_STYLE_COLOR_ID = "Style Color ID";
export const STRATEGY_CONFIG_TABLE_COL_STYLE_COLOR_DESC = "Style Color Desc";
export const STRATEGY_CONFIG_TABLE_COL_CATEGORY = "Department";
export const STRATEGY_CONFIG_TABLE_COL_DEPARTMENT = "Sub-Department";
export const STRATEGY_CONFIG_TABLE_COL_CLASS = "Class";
export const STRATEGY_CONFIG_TABLE_COL_SUBCLASS = "Sub-Class";


export const STRATEGY_CONFIG_TABLE_COL_CREATED_BY = "Created By";
export const STRATEGY_CONFIG_TABLE_COL_RELEASED_BY = "Updated By";
export const STRATEGY_CONFIG_TABLE_COL_CREATED_DATE = "Creation Date";
export const STRATEGY_CONFIG_TABLE_COL_RELEASED_DATE = "Updated Date";

export const STRATEGY_CONFIG_TABLE_COL_DEMAND_TYPE = "Demand Type";
export const STRATEGY_CONFIG_TABLE_COL_APS = "APS";
export const STRATEGY_CONFIG_TABLE_COL_WOS = "WOS";
export const STRATEGY_CONFIG_TABLE_COL_STORE_INV = "Store Inv Push %";
export const STRATEGY_CONFIG_TABLE_COL_TARGET = "Target Destination";
export const STRATEGY_CONFIG_TABLE_COL_EXECUTION_DATE = "Execution Date";
export const STRATEGY_CONFIG_TABLE_COL_FREQUENCY = "Frequency";

export const STRATEGY_CONFIG_TABLE_COL_PRE_SEASON_DATE = "Preseason Date";
export const STRATEGY_CONFIG_TABLE_COL_IN_SEASON_DATE = "InSeason Date";
export const STRATEGY_CONFIG_TABLE_COL_CLEARANCE_DATE = "Clearance Date";
export const STRATEGY_CONFIG_TABLE_COL_HEADER_PRE_SEASON = "PreSeason Strategy";
export const STRATEGY_CONFIG_TABLE_COL_HEADER_IN_SEASON = "InSeason Strategy";
export const STRATEGY_CONFIG_TABLE_COL_HEADER_CLEARANCE = "Clearance Strategy";

export const STRATEGY_CONFIG_EDIT_HEADER_PRE_SEASON = "PreSeason Strategy";
export const STRATEGY_CONFIG_EDIT_HEADER_IN_SEASON = "InSeason Strategy";
export const STRATEGY_CONFIG_EDIT_HEADER_CLEARANCE = "Clearance Strategy";

export const NETWORK_ERROR_MSG = "Something went wrong!";
export const NO_RECORDS_MSG = "No Records Found";
export const NO_RECORDS_HELPER_MSG = "Try Adjusting the Filters";

export const RESET_BUTTON = "Reset";
export const FILTER_BUTTON = "Filter";
export const SET_STRATEGY_BUTTON = "Set Strategy Configuration";
export const DELETE_STRATEGY_BUTTON = "Delete Strategy Configuration"
export const SET_STRATEGY_PAGE_HEADER = "Strategy Configuration / Set Strategy";
export const SET_STRATEGY_HEADER = "Set Strategy";
export const SAVE_STRATEGY_BUTTON = "Save Strategy Changes";
export const BACK_BUTTON = "Back";

export const PRE_SEASON_DEMAND_TYPE_OPTIONS = [
    { label: "Fixed", value: "Fixed" },
    { label: "APS", value: "APS" },
];
export const PRE_SEASON_FREQUENCY_TYPE_OPTIONS = [
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
];

export const IN_SEASON_DEMAND_TYPE_OPTIONS = [
    { label: "Fixed", value: "Fixed" },
    { label: "APS", value: "APS" },
    { label: "IA Forecast", value: "IA" },
];
export const IN_SEASON_FREQUENCY_TYPE_OPTIONS = [
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
];

export const CLEARANCE_SEASON_DEMAND_TYPE_OPTIONS = [
    { label: "Fixed", value: "Fixed" },
    { label: "APS", value: "APS" },
    { label: "IA Forecast", value: "IA" },
];
export const CLEARANCE_SEASON_FREQUENCY_TYPE_OPTIONS = [
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
];