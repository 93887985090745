export const FETCH_TABLE_DATA = "@@DAILYSUMMARY/FETCH_TABLE_DATA";
export const FETCH_TABLE_DATA_SUCCESS = "@@DAILYSUMMARY/FETCH_TABLE_DATA_SUCCESS";
export const FETCH_TABLE_DATA_ERROR = "@@DAILYSUMMARY/FETCH_TABLE_DATA_ERROR";

export const FETCH_SKU_TABLE_DATA = "@@DAILYSUMMARY/FETCH_SKU_TABLE_DATA";
export const FETCH_SKU_TABLE_DATA_SUCCESS = "@@DAILYSUMMARY/FETCH_SKU_TABLE_DATA_SUCCESS";
export const FETCH_SKU_TABLE_DATA_ERROR = "@@DAILYSUMMARY/FETCH_SKU_TABLE_DATA_ERROR";

export const FETCH_FILTER_DATA = "@@DAILYSUMMARY/FETCH_FILTER_DATA";
export const FETCH_FILTER_SUCCESS = "@@DAILYSUMMARY/FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "@@DAILYSUMMARY/FETCH_FILTER_ERROR";

export const RESET_FILTERS_DATA = "@@DAILYSUMMARY/RESET_FILTERS_DATA";
export const RESET_ALL = "@@DAILYSUMMARY/RESET_ALL";

export const GENERATE_EXCEL = "@@DAILYSUMMARY/GENERATE_EXCEL";
export const GENERATE_EXCEL_SUCCESS = "@@DAILYSUMMARY/GENERATE_EXCEL_SUCCESS";
export const GENERATE_EXCEL_ERROR = "@@DAILYSUMMARY/GENERATE_EXCEL_ERROR";

export const resetAll = () => ({
  type: RESET_ALL,
});

export const resetFiltersOptions = (payload) => ({
  type: RESET_FILTERS_DATA,
  payload,
})

export const fetchFilterData = (payload,filterKey,filterType) => ({
  type: FETCH_FILTER_DATA,
  payload,
  filterKey,
  filterType
});

export const fetchFilterDataSuccesss = (payload) => ({
  type: FETCH_FILTER_SUCCESS,
  payload,
});

export const fetchFilterDataError = (payload) => ({
  type: FETCH_FILTER_ERROR,
  payload,
});

export const fetchDailySummaryData = (payload) => ({
  type: FETCH_TABLE_DATA,
  payload,
});

export const fetchDailySummaryDataSuccess = (payload) => ({
  type: FETCH_TABLE_DATA_SUCCESS,
  payload,
});

export const fetchDailySummaryDataError = (payload) => ({
  type: FETCH_TABLE_DATA_ERROR,
  payload,
});

export const fetchSKUData = (payload) => ({
  type: FETCH_SKU_TABLE_DATA,
  payload,
});

export const fetchSKUDataSuccess = (payload) => ({
  type: FETCH_SKU_TABLE_DATA_SUCCESS,
  payload,
});

export const fetchSKUDataError = (payload) => ({
  type: FETCH_SKU_TABLE_DATA_ERROR,
  payload,
});

// /*
// Function to generate excel
// */

export const generateExcel = (payload) => ({
  type: GENERATE_EXCEL,
  payload
})

export const fetchExcelDataSuccesss = (payload) => ({
  type: GENERATE_EXCEL_SUCCESS,
  payload,
});

export const fetchExcelDataError = (payload) => ({
  type: GENERATE_EXCEL_ERROR,
  payload,
})

