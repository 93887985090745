import React from "react";
import NewStoreApproveView from './views/NewStoreApproveView';
import Notification from "../../components/Notification/Notifications";

/**
 * Ada Visual container maintaining api requests
 * on mounting with allocation section and on chnages of dates selection
 */
const NewStoreApproveContainer = (props) => {
    const {type, store} = props?.location?.state
    return (
        <>
            {/* <Notification /> */}
            <NewStoreApproveView type={type} store={store} />
        </>
    )

}


export default NewStoreApproveContainer;