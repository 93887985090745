export const FETCH_USER_DATA = "@@userManagement/FETCH_USER_DATA";
export const FETCH_USER_DATA_SUCCESS = "@@userManagement/FETCH_USER_DATA_SUCCESS";
export const FETCH_USER_DATA_ERROR = "@@userManagement/FETCH_USER_DATA_ERROR";

export const GET_FORM_DATA = "@@userManagement/GET_FORM_DATA";
export const GET_FORM_DATA_SUCCESS = "@@userManagement/GET_FORM_DATA_SUCCESS";
export const GET_FORM_DATA_ERROR = "@@userManagement/GET_FORM_DATA_ERROR";

export const CREATE_USER = "@@userManagement/CREATE_USER";
export const CREATE_USER_SUCCESS = "@@userManagement/CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "@@userManagement/CREATE_USER_ERROR";

export const EDIT_USER = "@@userManagement/EDIT_USER";
export const EDIT_USER_SUCCESS = "@@userManagement/EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "@@userManagement/EDIT_USER_ERROR";

export const DELETE_USER = "@@userManagement/DELETE_USER";
export const DELETE_USER_SUCCESS = "@@userManagement/DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "@@userManagement/DELETE_USER_ERROR";
export const RESET_ALL = "@@CONSTRAINTS/RESET_ALL";

export const resetAll = () => ({
  type: RESET_ALL,
});

export const fetchUserData = (payload) => ({
  type: FETCH_USER_DATA,
  payload,
});

export const fetchUserDataSuccesss = (payload) => ({
  type: FETCH_USER_DATA_SUCCESS,
  payload,
});

export const fetchUserDataError = (payload) => ({
  type: FETCH_USER_DATA_ERROR,
  payload,
});

export const getFormData = (payload) => ({
    type: GET_FORM_DATA,
    payload
});

export const getFormDataSuccesss = (payload) => ({
    type: GET_FORM_DATA_SUCCESS,
    payload,
  });
  
export const getFormDataError = (payload) => ({
    type: GET_FORM_DATA_ERROR,
    payload,
  });

export const createUser = (payload) => ({
    type: CREATE_USER,
    payload,
});

export const createUserSuccesss = (payload) => ({
    type: CREATE_USER_SUCCESS,
    payload,
  });
  
export const createUserError = (payload) => ({
    type: CREATE_USER_ERROR,
    payload,
  });

export const editUser  = (payload) => ({
    type: EDIT_USER,
    payload,
});

export const editUserSuccesss = (payload) => ({
    type: EDIT_USER_SUCCESS,
    payload,
  });
  
export const editUserError = (payload) => ({
    type: EDIT_USER_ERROR,
    payload,
  });

export const deleteUser = (payload) => ({
    type: DELETE_USER,
    payload
})

export const deleteUserSuccesss = (payload) => ({
    type: DELETE_USER_SUCCESS,
    payload,
  });
  
export const deleteUserError = (payload) => ({
    type: DELETE_USER_ERROR,
    payload,
  });

