import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-awesome-modal";
import * as adaVisualActions from './actions/AdaVisualActions';
import { DISCARD_CONTINUE_WARNING_TEXT, CONTINUE_BUTTON, DISCARD_BUTTON } from './AdaVisualConstants';
import "./AdaVisual.css";

const ModifyWarningDialogView = (props) => {
    const { setShowEditWarningDialog, handleDiscardEdit } = props;

    return (
        <div className="forecast-multiplier-opacity">
            <Modal
                visible={props.showAlert}
                width="45%"
                height="fit-content"
                effect="fadeInDown"
                onClickAway={() => setShowEditWarningDialog(false)}
            >
                <div className="modal-container">
                    <div class="warning-dialog-header">
                        <h5 className="page-heading text-center m-0" />
                        <button type="button" class="close" data-dismiss="modal" style={{ marginTop: "-12px" }}>
                            <i
                                className="fa fa-times text-right m-2 "
                                aria-hidden="true"
                                onClick={() => setShowEditWarningDialog(false)}
                                style={{ cursor: "pointer" }}
                            />
                        </button>
                    </div>

                    <div className="warning-dialog-middle" style={{ backgroundColor: "initial", overflow: "unset" }}>
                        <p className="warning-msg mt-2">
                            {DISCARD_CONTINUE_WARNING_TEXT}
                        </p>
                        <br />
                    </div>
                    <div className="warning-dialog-footer">
                        <button className="btn btn-primary" data-dismiss="modal"
                            onClick={handleDiscardEdit}
                            style={{ marginRight: "10px" }}
                        >
                            {DISCARD_BUTTON}
                        </button>
                        <button className="btn btn-primary" data-dismiss="modal"
                            onClick={() => setShowEditWarningDialog(false)}
                        >
                            {CONTINUE_BUTTON}
                        </button>
                    </div>
                </div>
            </Modal>
        </div >
    )
};

const mapStateToProps = ({ adaVisual }) => ({
    currentFiscalYearWeek: adaVisual.currentFiscalYearWeek,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyWarningDialogView);