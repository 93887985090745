import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import StrategyConfigFilters from './StrategyConfigFilters';
import StrategyConfigurationTableContainer from './StrategyConfigurationTable/StrategyConfigurationTableContainer';

const StrategyConfigView = (props) => {

    const { handleFilterRequest, handleResetStratergy } = props;
    const history = useHistory();
    const [filter, setFilter] = useState(null)

    useEffect(() => {
        return () => {
            handleResetStratergy();
        }
    }, []);

    const filterData = (filters) => {
        if (filters) {
            var selectedFilters = filters
        }
        setFilter(selectedFilters)
    }



    return (
        <>
            <StrategyConfigFilters handleFilterRequest={props.handleFilterRequest} filterData={filterData} />
            <StrategyConfigurationTableContainer filterClicked={props.filterClicked} history={history} filter={filter} handleFilterRequest={props.handleFilterRequest}/>
        </>
    );
}


export default StrategyConfigView;