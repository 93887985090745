import { STORE_GROUP_ACTION, 
  STORE_GROUP_ACTION_SUCCESS, 
  STORE_GROUP_ACTION_ERROR,
  STORE_DETAILS_ACTION,
  STORE_DETAILS_ACTION_SUCCESS,
  STORE_DETAILS_ACTION_ERROR,
  CREATE_STOREGROUP_FILTERS_ACTION_SUCCESS,
  CREATE_STOREGROUP_STOREDATA_ACTION,
  CREATE_STOREGROUP_STOREDATA_ACTION_SUCCESS,
  CREATE_STOREGROUP_STOREDATA_ACTION_ERROR,
  CREATE_STOREGROUP_ACTION,
  CREATE_STOREGROUP_ACTION_SUCCESS,
  CREATE_STOREGROUP_ACTION_ERROR,
  CREATE_STOREGROUP_FILTERS_ACTION_ERROR,
  CREATE_STOREGROUP_FILTERS_ACTION,
  FIRST_OR_SECOND_STOREGROUP,
  GET_STORE_SECONDTABLEDATA_SUCCESS,
  EDIT_STOREGROUP_ACTION,
  EDIT_STOREGROUP_ACTION_SUCCESS,
  EDIT_STOREGROUP_ACTION_ERROR,
  RESET_STORE_GROUP,
  DELETE_STOREGROUP_ERROR,
  DELETE_STOREGROUP_SUCCESS,
  DELETE_STOREGROUP,
  RESET_ALL,
  STORE_GROUP_ACTION_FOR_EDIT_ERROR,
  STORE_GROUP_ACTION_FOR_EDIT_SUCCESS,
  STORE_GROUP_ACTION_FOR_EDIT} from "./StoreGroupAction"
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export const storeGroupState = {
department: null,
storeGroupData: [],
storesStoreData: [],
storeGroupError: null,
storesStoreError: null,
storeGroupLoading: false,
storesStoreLoading: false,
storeGroupFiltersData: {},
createStoreDataLoading: false,
createStoreData: [],
editStoreData: [],
storeDataInFirstOrSecondTable: "",
createStoreSuccess: false,
editStoreSuccess: false,
createStoreError: false,
editStoreError: false,
createStoreGroupError: null,
createdStoreGroupCode: null,
deleteError: false,
deleteSuccess: null, 
};

export default {
storeGroup: handleActions(
{
[RESET_ALL]: (state) => {
 return immutable(state, {
 department: { $set: null },
 storeGroupData: { $set:[]},
 storesStoreData: { $set:[]},
 storeGroupError: { $set:null},
 storesStoreError: { $set:null},
 storeGroupLoading: { $set:false},
 storesStoreLoading: { $set:false},
 storeGroupFiltersData: { $set:{}},
 createStoreDataLoading: { $set:false},
 createStoreData: { $set:[]},
 editStoreData: { $set:[]},
 storeDataInFirstOrSecondTable: { $set:""},
 createStoreSuccess: { $set:false},
 editStoreSuccess: { $set:false},
 createStoreError: { $set:false},
 editStoreError: { $set:false},
 createStoreGroupError: { $set:null},
 createdStoreGroupCode: { $set:null},
 deleteError: { $set:false},
 deleteSuccess: { $set:null}, 
 });
},
[STORE_GROUP_ACTION]: (state) => {
  return immutable(state, {
   storeGroupLoading: { $set: true },
   storeGroupData: { $set: [] },
   createStoreSuccess: { $set: false },
   createStoreError: { $set: false},
   editStoreSuccess: { $set: false},
   editStoreError: { $set: false},
   createStoreData: { $set: [] },
   editStoreData: { $set: [] },
   storesStoreData: { $set: [] },
  });
},
[STORE_GROUP_ACTION_SUCCESS]: (state, { payload }) => {
 return immutable(state, {
   storeGroupData: { $set: (payload && payload.data) || [] },
   storeGroupLoading: { $set: false },
   storeGroupError: { $set: null },
 });
},
[STORE_GROUP_ACTION_ERROR]: (state, { payload }) => {
 return immutable(state, {
   storeGroupError: { $set: (payload && payload.message) || null },
   storeGroupLoading: { $set: false },
   storeGroupData: { $set: [] },
 });
},
[STORE_GROUP_ACTION_FOR_EDIT]: (state) => {
 return immutable(state, {
   department: { $set: null },
   createStoreDataLoading: { $set: true },
 });
},
[STORE_GROUP_ACTION_FOR_EDIT_SUCCESS]: (state, { payload }) => {
return immutable(state, {
 department: { $set: (payload && payload.data) || [] },
});
},
[STORE_GROUP_ACTION_FOR_EDIT_ERROR]: (state, { payload }) => {
return immutable(state, {
 department: { $set: null },
});
},
[STORE_DETAILS_ACTION]: (state) => {
 return immutable(state, {
   storesStoreLoading: { $set: true },
   storesStoreData: { $set: [] },
 });
},
[STORE_DETAILS_ACTION_SUCCESS]: (state, { payload }) => {
 return immutable(state, {
   storesStoreData: { $set: (payload && payload.data) || [] },
   storesStoreLoading: { $set: false},
   storesStoreError: { $set: null}
 });
},
[STORE_DETAILS_ACTION_ERROR]: (state, { payload }) => {
 return immutable(state, {
   storesStoreError: { $set: (payload && payload.message) || null },
   storesStoreLoading: { $set: false },
   storesStoreData: { $set: [] },
 });
},
[DELETE_STOREGROUP]: (state, {payload }) => {
 return immutable(state, {
   deleteError: { $set: false },
   deleteSuccess: { $set: null}
 });
},
[DELETE_STOREGROUP_ERROR]: (state, {payload }) => {
 return immutable(state, {
   deleteError: { $set: true },
   deleteSuccess: { $set: false }
 });
},
[DELETE_STOREGROUP_SUCCESS]: (state, {payload }) => {
return immutable(state, {
 deleteError: { $set : false },
 deleteSuccess: { $set: true }
 });
},
[FIRST_OR_SECOND_STOREGROUP]: (state, { payload }) => {
 return immutable(state, {
   storeDataInFirstOrSecondTable: { $set: payload || ''},
 });
},
[GET_STORE_SECONDTABLEDATA_SUCCESS]:  (state, { payload }) => {
 return immutable(state, {
   editStoreData: { $set: (payload && payload.data) || [] },
   createStoreDataLoading: { $set: false },
 });
},
[CREATE_STOREGROUP_FILTERS_ACTION]: (state, { payload }) => {
 return immutable(state, {
  //  department: { $set: null },
   createStoreGroupError: { $set: null },
   createStoreError: { $set: false},
 });
},
[CREATE_STOREGROUP_FILTERS_ACTION_SUCCESS]: (state, { payload }) => {
 return immutable(state, {
   storeGroupFiltersData:{ $merge: (payload.data?.[0] ? payload.data[0] : {})},
 });
},
[CREATE_STOREGROUP_FILTERS_ACTION_ERROR]: (state, { payload }) => {
 return immutable(state, {
   storeGroupFiltersData: { $set:  {} },
   createStoreGroupError: { $set: (payload && payload.message) || null },
 });
},
[CREATE_STOREGROUP_STOREDATA_ACTION]: (state, { payload }) => {
 return immutable(state, {
   createStoreDataLoading: { $set: true },
   createStoreData: { $set: [] },
   createStoreGroupError: { $set: null },
 });
},
[CREATE_STOREGROUP_STOREDATA_ACTION_SUCCESS]: (state, { payload }) => {
 return immutable(state, {
   createStoreDataLoading: { $set: false },
   createStoreData: { $set: (payload && payload) || [] }
 });
},
[CREATE_STOREGROUP_STOREDATA_ACTION_ERROR]: (state, { payload }) => {
 return immutable(state, {
   createStoreDataLoading: { $set: false },
   createStoreData: { $set : [] },
   createStoreGroupError: { $set: (payload && payload.message) || null },
 });
},
[CREATE_STOREGROUP_ACTION]: (state, { payload }) => {
 return immutable(state, {
  //  storesStoreData: { $set: [] },
   createStoreDataLoading: { $set: true },
   createStoreSuccess: { $set: false },
   createStoreError: { $set: false},
   createdStoreGroupCode: { $set: null}
 });
},
[CREATE_STOREGROUP_ACTION_SUCCESS]: (state, { payload }) => {
 return immutable(state, {
   createStoreDataLoading: { $set: false },
   createStoreSuccess: { $set: true },
   createStoreError: { $set: false},
   createdStoreGroupCode: { $set: payload.store_group_code}
 });
},
[CREATE_STOREGROUP_ACTION_ERROR]: (state, { payload }) => {
 return immutable(state, {
   createStoreDataLoading: { $set: false },
   createStoreSuccess: { $set: false },
   createStoreError: { $set: (payload && payload.error) || null },
 });
},
[EDIT_STOREGROUP_ACTION]: (state, { payload }) => {
 return immutable(state, {
  //  storesStoreData: { $set: [] },
   createStoreDataLoading: { $set: true },
   editStoreSuccess: { $set: false },
   editStoreError: { $set: false},
 });
},
[EDIT_STOREGROUP_ACTION_SUCCESS]: (state, { payload }) => {
 return immutable(state, {
   createStoreDataLoading: { $set: false },
   editStoreSuccess: { $set: true },
   editStoreError: { $set: false},
 });
},
[EDIT_STOREGROUP_ACTION_ERROR]: (state, { payload }) => {
 return immutable(state, {
   createStoreDataLoading: { $set: false },
   editStoreSuccess: { $set: false },
   editStoreError: { $set: (payload && payload.name) || null },
 });
},
[RESET_STORE_GROUP]: (state, { }) => {
 return immutable(state, {
   createStoreData: { $set: [] },
   editStoreData: { $set: [] },
   createStoreError: { $set: false}
 });
},
},

storeGroupState
),
};


