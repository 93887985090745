import React, { useState, useEffect } from "react";

import { AsyncPaginate } from "react-select-async-paginate";
import { useCallback } from "react";


const AsyncSingle = ({onDropDownChange,optionsArray,dropdownValue}) => {

  const [value, setValue] = useState([]);
  const [options ,setOptions] = useState([])

  useEffect(() => {
    setOptions(optionsArray)
  }, [optionsArray])

  useEffect(() => {
      setValue(dropdownValue)
  }, [dropdownValue])

  const onChange = (newValue,event) => {
    // setValue(newValue);
    onDropDownChange(newValue,event)
  };

  const loadOptions = useCallback(async (search, prevOptions) => {
      let filteredOptions;
      if (!search) {
        filteredOptions = options;
      } else {
        const searchLower = search.toLowerCase();
  
        filteredOptions = options.filter(({ label }) =>
          label.toLowerCase().includes(searchLower)
        );
      }

      const hasMore = filteredOptions?.length > prevOptions.length + 10;
      const slicedOptions = filteredOptions?.slice(
        prevOptions.length,
        prevOptions.length + 10
      );
  
      return {
        options: slicedOptions,
        hasMore
      };
  },[options])

  return (
    <div>
      <AsyncPaginate
        value={value}
        loadOptions={loadOptions}
        onChange={onChange}
        cacheUniqs={[options]}
      />
    </div>
  );
};

export default AsyncSingle;

