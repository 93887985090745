import styled from "styled-components";
const Styles = styled.div`
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .rt-btn-prev {
      margin-right: 18px;
    }

    .rt-btn {
      margin-right: 5px;
      margin-left: 5px;
    }

    input {
      height: 27px;
      padding: 10px;
      font-weight: 600;
      width: 64px !important;
      background: transparent;
      border: 1px solid gray;
    }
  }

  input[type="checkbox"] {
    width: 16px; /*Desired width*/
    height: 16px; /*Desired height*/
    cursor: pointer;
  }

  .table {
    border: 1px solid #ddd;
    font-size: 13px;
    user-select: none;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 500px;
    .tr {
      :last-child {
        .td {
          border-bottom: 0;
          border-bottom: 1px solid #ddd;
        }
      }
    }

    .th,
    .th-block {
      font-weight: bold;
      color: white !important;
      background-color: var(--color-primary) !important;
    }

    .td {
      color: #5f5f5f !important;
      font-weight: 400;
      padding: 5px;
      ${"" /* justify-content:flex-start !important; */}
    }

    .ExapandCollapseBtn {
      align-self: flex-start;
      padding-right: 8px;
      padding-left: 6px;
    }

    .th,
    .td,
    .th-block {
      display: flex !important;
      justify-content: center;
      align-items: center;
      line-height: 1.8;
      text-align: center;
      padding: 7px 7px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;
      :last-child {
        border-right: 0;
      }
    }
    .th-block{
      display: block !important;
    }

    .rt-grouped-td {
      justify-content: flex-start;
      text-align: left;
    }

    &.sticky {
      overflow: auto;
      .header,
      .footer {
        position: sticky;
        width: fit-content;
      }

      .header {
        top: 0;
        ${"" /* box-shadow: 0px 3px 3px #ccc; */}
        border-bottom: 2px solid #ddd;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .z-9 {
        z-index: 9;
      }

      .loaderRT {
        height: 90%;
        display: flex;
        color: #44677b;
        justify-content: center;
        align-items: center;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
        text-align: left !important;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`;

export default Styles;
