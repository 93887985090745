export const calculateMaximumEaches = (
    editData,
    tableData,
    dcAvailableMap,
    instance,
    dcKey,
    size,
    index
  ) => {
    const parentRowId = Number(instance.row.id.split(".").slice(0, -1).join(".")); // string
    const row = instance.row.original;
  
    const store_code = tableData?.[parentRowId].store_code;
  
    return (
      (editData?.[row.article]?.[store_code]?.[dcKey]?.[size] >= 0
        ? editData?.[row.article]?.[store_code]?.[dcKey]?.[size]
        : row.size_value?.[dcKey]?.[index]) +
      (dcAvailableMap?.[row.article]?.[dcKey]?.[size] >= 0
        ? dcAvailableMap[row.article]?.[dcKey]?.[size]
        : row.eaches_available?.[dcKey]?.[size])
    );
  };