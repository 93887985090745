export const RESET_INVENTORY_RESERVATION = "RESET_INVENTORY_RESERVATION";
export const GET_INVENTORY_RESERVATION_FILTER_REQUEST = "GET_INVENTORY_RESERVATION_FILTER_REQUEST";
export const GET_INVENTORY_RESERVATION_FILTER_FAILED = "GET_INVENTORY_RESERVATION_FILTER_FAILED";
export const GET_INVENTORY_RESERVATION_FILTER_SUCCESS = "GET_INVENTORY_RESERVATION_FILTER_SUCCESS";
export const GET_INVENTORY_RESERVATION_TABLE_REQUEST = "GET_INVENTORY_RESERVATION_TABLE_REQUEST";
export const GET_INVENTORY_RESERVATION_TABLE_FAILED = "GET_INVENTORY_RESERVATION_TABLE_FAILED";
export const GET_INVENTORY_RESERVATION_TABLE_SUCCESS = "GET_INVENTORY_RESERVATION_TABLE_SUCCESS";
export const GET_INVENTORY_RESERVATION_DATA_REQUEST = "GET_INVENTORY_RESERVATION_DATA_REQUEST";
export const GET_INVENTORY_RESERVATION_DATA_FAILED = "GET_INVENTORY_RESERVATION_DATA_FAILED";
export const GET_INVENTORY_RESERVATION_DATA_SUCCESS = "GET_INVENTORY_RESERVATION_DATA_SUCCESS";
export const SET_ROWS_SELECTED = "SET_ROWS_SELECTED";
export const SET_EDITED_INVENTORY_RESERVATION = "SET_EDITED_INVENTORY_RESERVATION";
export const SAVE_INVENTORY_RESERVATION_DATA_REQUEST = "SAVE_INVENTORY_RESERVATION_DATA_REQUEST";
export const SAVE_INVENTORY_RESERVATION_DATA_FAILED = "SAVE_INVENTORY_RESERVATION_DATA_FAILED";
export const SAVE_INVENTORY_RESERVATION_DATA_SUCCESS = "SAVE_INVENTORY_RESERVATION_DATA_SUCCESS";
export const RESET_SAVE_INVENTORY_RESERVATION = "RESET_SAVE_INVENTORY_RESERVATION";
