import React from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import { Chart } from "primereact/chart";
import { isEmpty } from "lodash";

const StackedBarChartHorizontal = (props) => {
  let options = {
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
          label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                  label += ': ';
              }
              label += Math.round(tooltipItem.xLabel) + ' %';
              return label;
          }
      }
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            fontColor: "#495057",
            min: 0,
            max: 100  
          },
          gridLines: {
            color: "#ebedef",
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            fontColor: "#495057",
          },
          gridLines: {
            color: "#ebedef",
          },
        },
      ],
    },
    legend: {
      labels: {
        fontColor: "#495057",
      },
    },
  };
  return (!isEmpty(props?.data?.datasets)) ? <> <Chart type="horizontalBar" data={props.data} options={options} />  <div className="charts__axes"> <span><b>Distribution %</b></span> </div> </>: <div class="overlay"><div>No data available</div></div>;
};

export default StackedBarChartHorizontal;
