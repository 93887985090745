import React, { useState, useEffect, createRef } from "react";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import "../../ProductStoreStatus.css"
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ReactTable from "../../../../components/Table/ReactTable"
import { getStoreGroupMappingData } from "../Actions/CreateConfigAction"
import PageLoader from '../../../../components/Loader/PageLoader';
import ColumnFilter from "../../../UserManagement/ColumnFilter"
import CreateEditStoreGroup from "../../../StoreGroup/CreateEditStoreGroup/CreateEditStoreGroup"
import AddStoreGroup from "../Popups/AddStoreGroupModal"
import EditIconCell from "../../../../components/Table/Cellrenderer/EditIconCell";
import Notification from "../../../../components/Notification/Notifications";
import * as Notify from "../../../../components/Notification/Notifications"
import StoreGroupMappingTable from "./StoreGroupMappingTable"
import { useHistory } from "react-router-dom";
import { ADD_STORE_GROUP } from "../../../../constants/routeConstants";
import { updateArticleName } from "../../../../utils/filterLevelMapping";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const StoreGroupMappingModal = (props) => {
    const [RTInstance, setRTinstance] = useState({})
    const [showEditStoreGroup, setShowEditStoreGroup] = useState(false)
    const [showAddStoreGroup, setShowAddStoreGroup] = useState(false)
    const [tableData, setTableData] = useState([])
    const [allStoreGroups, setAllStoreGroups] = useState([])
    const [initialSelectedRows, setInitialSelectedRows] = useState({})
    const [request, setRequest] = useState({})
    const [isEdited, setIsEdited] = useState(false)

    const history = useHistory()

    const tableColumns = React.useMemo(() => [
        {
            id: 'expander', // Make sure it has an ID
            Header: 'Store Group',
            accessor: 'storeGroupName',
            Cell: ({ value, row }) =>
                // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                // to build the toggle for expanding a row
                row.canExpand ? (
                    <div className="row"
                        {...row.getToggleRowExpandedProps()}
                    >
                        <span
                            className="expand__text"
                        >
                            {value}
                        </span>
                        {
                            row.isExpanded ? (
                                <div
                                    className="cursor-pointer"
                                    style={{ color: "#50778e" }}
                                >
                                    <i className="fa fa-angle-down fa-lg ml-2 expand-collapse" title="Collpase" ></i>
                                </div>) :
                                <div
                                    className="cursor-pointer"
                                    style={{ color: "#50778e" }}
                                >
                                    <i className="fa fa-angle-right fa-lg ml-2 expand-collapse" title="Expand" ></i>
                                </div>
                        }
                    </div>
                ) : <span style={{ backgroundColor: "rgb(224,224,224)", minHeight: "100%", minWidth: "100%" }}> </span>,
            width: 200,
            Filter: ColumnFilter,
            disableFilters:true
        },
        {
            Header: '#Stores',
            accessor: "storeCount",
            Cell: ({ value, row }) =>
                row.canExpand ? (
                    <span
                        {...row.getToggleRowExpandedProps()}
                    >
                        {value}
                    </span>
                ) : <span style={{ backgroundColor: "rgb(224,224,224)", minHeight: "100%", minWidth: "100%" }}> </span>,
            width: 100,
            Filter: ColumnFilter,
            disableFilters:true
        },
        {
            Header: 'Store Number',
            accessor: "storeId",
            Filter: ColumnFilter,
            disableFilters:true
        },
        {
            Header: 'Store Name',
            accessor: "storeName",
            Filter: ColumnFilter,
            disableFilters:true
        },
        {
            Header: 'Store Grade',
            accessor: "storeGrade",
            Filter: ColumnFilter,
            filter: 'exactText',
        },
        {
            Header: 'Region Description',
            accessor: "desc",
            width: 250,
            Filter: ColumnFilter
        },
        {
            Header: "Action",
            accessor: "action",
            sticky: "right",
            Cell: (instance) => (
                <div>
                    <EditIconCell
                        handleEdit={handleEdit}
                        instance={instance}
                    ></EditIconCell>
                </div>
            ),
            disableFilters: true,
            resizable: true,
            width: 100,
        },
    ])

    const handleEdit = (p_instance) => {
        showStoreGroupModal(2)
    };

    const showStoreGroupModal = (val) => {
        let callFunc = val == 1 ? setShowAddStoreGroup : setShowEditStoreGroup
        callFunc(true)
        // history.push(`${ADD_STORE_GROUP}`, {index: 0})
    }

    const deleteStoreGroups = () => {
        // else{
        Notify.error("Select atleast 1 stores to create store group!!")
        // }
    }

    // useEffect(() => {
    //     props.getStoreGroupMappingData({})
    // }, [])
    useEffect(() => {
        if (props.data?.store_groups) {
            let data = []
            let remaining = []
            let selected = props.data?.selected_groups?.length ? props.data?.selected_groups : [] 
            props.data.store_groups.map(store => {
                if (selected?.includes(store.store_group_code)) {
                    data.push(store)
                }
                else {
                    remaining.push(store)
                }
            });
            let initialSelectedRows = {}
            for (let i = 0; i < data.length; i++) {
                initialSelectedRows[i] = true
            }
            setInitialSelectedRows(initialSelectedRows)
            remaining = data.concat(remaining)
            setTableData(data)
            setAllStoreGroups(remaining)
        }
    }, [props.data])

    useEffect(() => {
        let req = {}
        if (props.selectedArticle?.article) {
            req.article = props.selectedArticle?.article
        }
        if (props.configuration_id) {
            req.configuration_id = props.configuration_id
        }
        setRequest(req)
        props.getStoreGroupMappingData({article: req.article, configuration_id: req.configuration_id, ...props.filters})


    }, [props.selectedArticle])

    const closeModal = (status, editStatus) => {
        setShowAddStoreGroup(false)
        setIsEdited(editStatus)
        if (editStatus) {
            closeParentModal(editStatus)
            // props.getStoreGroupMappingData({article: props.selectedArticle.article,...props.filters})
        }
    }

    const closeParentModal = (editStatus) => {
        props.closeModal(true, editStatus == true ? true : false)
    }
    

    return (
        <div className="modal__div">
            <Modal
                visible={props.showModal}
                width="70%"
                // height="95%"
                effect="fadeInDown"
                onClickAway={closeParentModal}
            >
                {/* <Notification /> */}
                <div className="modal-container">
                    <div className="modal-header">
                        <h5 className="page-heading text-center m-0">
                            Stores / Store Groups Mapped
                        </h5>
                        <button type="button" className="close" data-dismiss="modal">
                            <span style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-times text-right m-2 "
                                    aria-hidden="true"
                                    onClick={closeParentModal}
                                />
                            </span>
                        </button>
                    </div>
                    <PageLoader loader={props.dataLoading} gridLoader={true}>
                        <div className="modal-middle-container modal-body">
                            <div className="mx-2 pr-4 pl-4 row justify-content-around mb-4">
                                <div className="detail__div mr-4">
                                    <span className="fnt-bold mr-2"> {updateArticleName(false)} ID : </span>
                                    <span>{props.selectedArticle.article}</span>
                                </div>

                                <div className="detail__div ml-4 mr-4">
                                    <span className="fnt-bold mr-2">Style Color : </span>
                                    <span>{props.selectedArticle.color_desc}</span>
                                </div>

                                <div className="detail__div ml-4 mr-4">
                                    <span className="fnt-bold mr-2">Style Description : </span>
                                    <span>{props.selectedArticle.style_name}</span>
                                </div>
                            </div>
                            <div className="mx-2 p-2">
                                <StoreGroupMappingTable data={tableData ? tableData : []}
                                    setRTinstance={setRTinstance} hideCheckBox = {true} />
                            </div>
                        </div>
                    </PageLoader>

                    <div className="text-center" style={{ padding: "1rem" }}>
                        <div className="center">
                            <button
                                className="btn btn-primary store-grp-modal__btn"
                                onClick={closeParentModal}
                            >
                                Close
                            </button>

                            <button
                                className="btn btn-primary fn__btn"
                                onClick={() => { showStoreGroupModal(1) }}
                            >
                                Edit Mapping

                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            {
                showEditStoreGroup &&
                <CreateEditStoreGroup
                    showAlert={true}
                    closeAlert={() => { setShowEditStoreGroup(false) }}
                    // existingStoreCode={existingStoreCode}
                    isCreateStoreGroup={false}
                    proStatusDepartment={props.department}
                />
            }
            {
                showAddStoreGroup &&
                <AddStoreGroup
                    request={request}
                    filters={props.filters}
                    data={allStoreGroups}
                    initialSelectedRows={initialSelectedRows}
                    showModal={true}
                    closeModal={closeModal}
                />
            }
        </div>
    )
}

const mapStateToProps = ({ productStatus }) => {
    return {
        data: productStatus.storeGroupData,
        dataLoading: productStatus.storeGroupDataLoading,
        dataError: productStatus.storeGroupDataError
    }
}

const mapDispatchToProps = (dispatch) => ({
    getStoreGroupMappingData: (payload) => dispatch(getStoreGroupMappingData(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StoreGroupMappingModal)