import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { FETCH_FILTER_DATA, FETCH_FILTER_ERROR, FETCH_FILTER_SUCCESS, FETCH_STORE_STATUS_DATA, FETCH_STORE_STATUS_ERROR, FETCH_STORE_STATUS_SUCCESS, GET_STORE_CONFIG_DC_DATA, GET_STORE_CONFIG_DC_DATA_ERROR, GET_STORE_CONFIG_DC_DATA_SUCCESS, RESET_ALL, UPDATE_STORE_STATUS_DATA, UPDATE_STORE_STATUS_ERROR, UPDATE_STORE_STATUS_SUCCESS } from "./ProductStoreAction";

export const productStoreState = {
    filterError: null,
    filterData: {},
    storeStatusData: [],
    storeStatusError: null,
    storeStausLoading: false,
    updateSucess: false,
    updateError: false,
    dcList: []
}

export default {
    productStoreStatus: handleActions(
        {
            [RESET_ALL]: (state, { payload }) => {
                return immutable(state, {
                    filterData: { $set:  {}},
                    storeStatusData: { $set:  []},
                    storeStausLoading: { $set:  false},
                    storeStatusError: { $set:  null},
                    filterError: { $set: null},
                    updateSucess: { $set:  false},
                    updateError: { $set:  false},
                  });
            },
            [FETCH_FILTER_DATA]: (state, { payload }) => {
                return immutable(state, {
                  filterError: { $set: null },
                  filterData: { $set: {} },
                });
            },
            [FETCH_FILTER_SUCCESS]: (state, { payload }) => {
                return immutable(state, {
                  filterData: { $set: (payload?.data?.data?.[0] ? payload.data.data[0] : {}) },
                  filterError: { $set: null },
                });
            },
            [FETCH_FILTER_ERROR]: (state, { payload }) => {
                return immutable(state, {
                  filterData: { $set:  {} },
                  filterError: { $set: (payload && payload.message) || null },
                });
            },
            [FETCH_STORE_STATUS_DATA]: (state, { payload }) => {
                return immutable(state, {
                  storeStatusError: { $set: null },
                  storeStatusData: { $set: [] },
                  storeStausLoading: { $set: true },
                });
            },
            [FETCH_STORE_STATUS_SUCCESS]: (state, { payload }) => {
                return immutable(state, {
                  storeStatusData: { $set: (payload && payload.data) || [] },
                  storeStatusError: { $set: null },
                  storeStausLoading: { $set: false },
                });
            },
            [FETCH_STORE_STATUS_ERROR]: (state, { payload }) => {
                return immutable(state, {
                  storeStatusData: { $set:  [] },
                  storeStatusError: { $set: (payload && payload.message) || null },
                  storeStausLoading: { $set: false },

                });
            },
            [UPDATE_STORE_STATUS_DATA]: (state, { payload }) => {
                return immutable(state, {
                    updateSucess: { $set:  false},
                    updateError: { $set:  false},
                    storeStausLoading: { $set: true },
                });
            },
            [UPDATE_STORE_STATUS_SUCCESS]: (state, { payload }) => {
                return immutable(state, {
                    updateSucess: { $set:  true},
                    storeStausLoading: { $set: false },
                });
            },
            [UPDATE_STORE_STATUS_ERROR]: (state, { payload }) => {
                return immutable(state, {
                  updateError: { $set:  true},
                  storeStausLoading: { $set: false },
                });
            },
            [GET_STORE_CONFIG_DC_DATA]: (state, { payload }) => {
                return immutable(state, {
                  dcList: { $set:  []},
                });
            },
            [GET_STORE_CONFIG_DC_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                  dcList: { $set:  payload.data},
                });
            },
            [GET_STORE_CONFIG_DC_DATA_ERROR]: (state, { payload }) => {
                return immutable(state, {
                  dcList: { $set:  []},
                });
            },
        },
        productStoreState
    )
}