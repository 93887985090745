import * as actionTypes from "./ActionTypes"


export const getStoreGroupMappingData = (payload) => ({
    type: actionTypes.GET_STORE_GROUP_DATA,
    payload
})

export const getProductProfiles = (payload) => ({
    type: actionTypes.GET_PRODUCT_PROFILES,
    payload
})

export const getStoreSizeContribution = (payload) => ({
    type: actionTypes.GET_STORE_SIZE_CONTRIBUTIONS,
    payload
})

export const getDcData = (payload) => ({
    type: actionTypes.GET_DC,
    payload
})

export const createConfig = (payload) => ({
    type: actionTypes.CREATE_CONFIG,
    payload
})

export const saveSelectedStoreGroups = (payload) => ({
    type: actionTypes.SAVE_STORE_GROUP_DATA,
    payload
})

export const resetCreateConfigData = () => ({
    type: actionTypes.RESET_CREATE_CONFIG_DATA
})

export const resetTableData = () => ({
    type: actionTypes.RESET_TABLE_DATA
})

export const resetAll = () => ({
    type: actionTypes.RESET_ALL
})

export const getMappingStyles = (payload) => ({
    type: actionTypes.GET_MAPPING_STYLES,
    payload
})

export const updateAutoAllocationRequired = (payload) => ({
    type: actionTypes.UPDATE_AUTO_ALLOCATION,
    payload
})