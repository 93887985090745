import React, { useState, useCallback } from 'react';
import moment from "moment";
import Select from "../../../../components/Filters/SingleSelect";
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from "primereact/calendar";
import { Checkbox } from 'primereact/checkbox';
import { debounce } from 'lodash';
import {
    STRATEGY_CONFIG_TABLE_COL_DEMAND_TYPE, STRATEGY_CONFIG_TABLE_COL_APS, STRATEGY_CONFIG_TABLE_COL_WOS,
    STRATEGY_CONFIG_TABLE_COL_STORE_INV, STRATEGY_CONFIG_TABLE_COL_TARGET, STRATEGY_CONFIG_TABLE_COL_EXECUTION_DATE,
    STRATEGY_CONFIG_TABLE_COL_FREQUENCY, IN_SEASON_DEMAND_TYPE_OPTIONS, IN_SEASON_FREQUENCY_TYPE_OPTIONS, STRATEGY_CONFIG_EDIT_HEADER_IN_SEASON,
} from '../../StrategyConfigConstants';
import "../../StrategyConfiguration.css";

/**
 * Functional view component for In Season Section
 * @param {*} props 
 * @returns 
 */
const EditInSeason = (props) => {
    const { strategyConfigData, strategyConfigActionsProp, editedConfigReq } = props;
    const { in_season_aps, in_season_wos, in_season_store_inv_push, in_season_execution_date, in_season_demand_type } = editedConfigReq//strategyConfigData;

    const [demandType, setDemandTypeSelected] = useState({
        value: in_season_demand_type,
        label: in_season_demand_type
    });
    const [frequencyType, setFrequencyTypeSelected] = useState(null);

    const onDemandTypeChange = (val) => {
        setDemandTypeSelected(val);
        if (val?.value === 'IA') {
            strategyConfigActionsProp.setEditedStrategyData({
                key: 'in_season_aps',
                value: null,
            });
            strategyConfigActionsProp.setEditedStrategyData({
                key: 'in_season_store_inv_push',
                value: null,
            });
        }
        else if (val?.value === 'APS') {
            strategyConfigActionsProp.setEditedStrategyData({
                key: 'in_season_store_inv_push',
                value: null,
            });
        }
        strategyConfigActionsProp.setEditedStrategyData({
            key: "in_season_demand_type",
            value: val.value,
        });
    };

    const onFrequencyTypeChange = (val) => {
        setFrequencyTypeSelected(val);
        strategyConfigActionsProp.setEditedStrategyData({
            key: "in_season_frequency",
            value: val.value,
        });
    };

    const onInputNumberChange = useCallback(debounce((data, fieldName, type) => {
        console.log('ss',type)
        if (type?.value === 'Fixed') {
            // strategyConfigActionsProp.setEditedStrategyData({
            //     key: 'in_season_aps',
            //     value: null,
            // });
            // strategyConfigActionsProp.setEditedStrategyData({
            //     key: 'in_season_wos',
            //     value: null,
            // });
        }
        else if (type?.value === 'IA') {
            strategyConfigActionsProp.setEditedStrategyData({
                key: 'in_season_aps',
                value: null,
            });
            strategyConfigActionsProp.setEditedStrategyData({
                key: 'in_season_store_inv_push',
                value: null,
            });
        }
        else if (type?.value === 'APS') {
            strategyConfigActionsProp.setEditedStrategyData({
                key: 'in_season_store_inv_push',
                value: null,
            });
        }
        strategyConfigActionsProp.setEditedStrategyData({
            key: fieldName,
            value: data,
        });
    }, 700), []);

    const handleDateChange = (e) => {
        if (e) {
            const changedDate = moment(e.value).format("MM-DD-YYYY");
            strategyConfigActionsProp.setEditedStrategyData({
                key: "in_season_execution_date",
                value: changedDate,
            });
        } else {
            //No code here
        }
    };



    return (
        <>
            <div>
                <h3 className="fnt-bold">{STRATEGY_CONFIG_EDIT_HEADER_IN_SEASON}</h3>
                <div className="p-field p-grid">
                    <label className="non-editable-labels p-col-fixed">
                        {STRATEGY_CONFIG_TABLE_COL_DEMAND_TYPE}
                    </label>
                    <div className="p-col" >
                        <Select
                            id="IN_DEMAND_TYPE_SELECTOR"
                            name="storeNumber"
                            value={demandType}
                            onChange={onDemandTypeChange}
                            placeHolder="Select Type"
                            options={IN_SEASON_DEMAND_TYPE_OPTIONS}
                            className="p-col"
                        />
                    </div>

                    {/* <h5>{STRATEGY_CONFIG_TABLE_COL_TARGET}</h5>
                    <div className="p-formgroup-inline p-col">
                        <div className="p-field-checkbox ">
                            <Checkbox inputId="city3" value="Chicago"
                            // onChange={onCityChange2} checked={cities2.indexOf('Chicago') !== -1} 
                            />
                            <label htmlFor="city3">DC</label>
                        </div>
                        <div className="p-field-checkbox">
                            <Checkbox inputId="city4" value="Los Angeles"
                            // onChange={onCityChange2} checked={cities2.indexOf('Los Angeles') !== -1}
                            />
                            <label htmlFor="city4">Store</label>
                        </div>
                    </div> */}
                </div>

                <div className="p-field p-grid">
                    <label className="non-editable-labels p-col-fixed">
                        {STRATEGY_CONFIG_TABLE_COL_APS}
                    </label>
                    <div className="p-col">
                        <InputNumber inputId="IN_APS" min={0} value={demandType?.value === "IA" ? null : in_season_aps === "" ? null : in_season_aps} disabled={demandType?.value === "IA" || demandType?.value === null}
                            onChange={(e) => onInputNumberChange(e.value, "in_season_aps", demandType)}
                            mode="decimal" useGrouping={false} className="editable-text-field" showButtons />
                    </div>

                    <label className="non-editable-labels p-col-fixed">
                        {STRATEGY_CONFIG_TABLE_COL_EXECUTION_DATE}
                    </label>
                    <div className="p-col">
                        <Calendar
                            id="EXECUTION DATE"
                            className="p-mb-2 execution-date-cal"
                            dateFormat="mm-dd-yy"
                            value={in_season_execution_date === null ? "" : new Date(in_season_execution_date)}
                            onChange={(e) => handleDateChange(e)}
                            readOnlyInput
                            showIcon
                        />
                    </div>
                </div>

                <div className="p-field p-grid">
                    <label className="non-editable-labels p-col-fixed">
                        {STRATEGY_CONFIG_TABLE_COL_WOS}
                    </label>
                    <div className="p-col">
                        <InputNumber inputId="IN_WOS" min={0} value={in_season_wos === "" ? null : in_season_wos} disabled={demandType?.value === null}
                            onChange={(e) => onInputNumberChange(e.value, "in_season_wos", demandType)}
                            mode="decimal" useGrouping={false} className="editable-text-field" showButtons />
                    </div>

                    {/* <label className="non-editable-labels p-col-fixed">
                        {STRATEGY_CONFIG_TABLE_COL_FREQUENCY}
                    </label>
                    <div className="p-col" >
                        <Select
                            id="IN_FREQUENCY_SELECTOR"
                            name="frequencyType"
                            value={frequencyType}
                            onChange={onFrequencyTypeChange}
                            placeHolder="Select Frequency"
                            options={IN_SEASON_FREQUENCY_TYPE_OPTIONS}
                            className="p-col"
                        // isDisabled={editModeDetails?.isEditMode}
                        />
                    </div> */}
                </div>

                <div className="p-field p-grid">
                    <label className="non-editable-labels p-col-fixed">
                        {STRATEGY_CONFIG_TABLE_COL_STORE_INV}
                    </label>
                    <div className="p-col">
                        <InputNumber inputId="IN_STOREINV" min={0} max={100} value={demandType?.value === "APS" || demandType?.value === "IA" ? null : in_season_store_inv_push === "" ? null : in_season_store_inv_push} disabled={demandType?.value !== "Fixed"}
                            onChange={(e) => onInputNumberChange(e.value, "in_season_store_inv_push", demandType)}
                            mode="decimal" useGrouping={false} className="editable-text-field" suffix="%" />
                    </div>
                </div>

            </div>
        </>
    );
}

export default EditInSeason;