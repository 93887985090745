import React from "react";

const LinkCell = ({ value, onClick }) => {
  return (
    <div
      style={{ textDecoration: "none", cursor: "pointer" }}
      onClick={onClick}
      className="clickable"
    >
      {value}
    </div>
  );
};

export default LinkCell;
