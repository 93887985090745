import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

import { cloneDeep } from "lodash";  
import { DELETE_PRODUCT_PROFILE, DELETE_PRODUCT_PROFILE_ERROR, DELETE_PRODUCT_PROFILE_SUCCESS, FETCH_CREATEFILTER_DATA, FETCH_CREATEFILTER_DATA_ERROR, FETCH_CREATEFILTER_DATA_SUCCESS, FETCH_FILTER_SUCCESS,
  FETCH_PRODUCT_LIST_CONTRIBUTION_DATA,
  FETCH_PRODUCT_LIST_CONTRIBUTION_ERROR,
  FETCH_PRODUCT_LIST_CONTRIBUTION_SUCCESS,
  FETCH_PROFILED_PRODUCT_DATA,
  FETCH_PROFILED_PRODUCT_ERROR,
  FETCH_PROFILED_PRODUCT_SUCCESS,
  FETCH_SAVED_DATA,
  FETCH_SAVED_ERROR,
  FETCH_SAVED_SUCCESS,
  FETCH_STORES_DATA,
  FETCH_STORES_DATA_ERROR,
  FETCH_STORES_DATA_SUCCESS,
  FETCH_USER_CREATED_DATA,
  FETCH_USER_CREATED_DATA_ERROR,
  FETCH_USER_CREATED_DATA_SUCCESS,
         RESET_FILTERS_DATA,
         RESET_PRODUCTPROFILE_DATA,
         SAVE_PRODUCT_PROFILE_DATA,
         SAVE_PRODUCT_PROFILE_ERROR,
         SAVE_PRODUCT_PROFILE_SUCCESS, 
       } from "./ProductProfileAction";

export const constraintsState = {
  storeColumn:[],
  profiledProductColumn:[],
  userCreatedData: [],
  storeGradeData: [],
  storeData: [],
  colourStyleData: [],
  userCreatedError: false,
  storeGradeError: false,
  storeError: false,
  popUpError: false,
  userCreatedloading: false,
  storeGradeloading: false,
  storeloading: false,
  level1Options: [],
  level2Options: [],
  level3Options: [],
  level4Options: [],
  level5Options: [],
  level6Options: [],
  level7Options: [],
  filterlabels : {},
  styleOptions: [],
  colourOptions: [],
  createFilterData: [],
  createFilterError: false,
  createFilterloading: false,
  profiledProductData: [],
  originalProfiledProductData: [],
  profiledProductLoading: false,
  profiledProductError: false,
  productListContributionData: [],
  productListContributionLoading: false,
  productListContributionError: false,
  periodOptions: [{label:'last 30 days', value:'30'},{label:'last 90 days', value:'90'},{label:'last 180 days', value:'180'},{label:'last 365 days', value:'365'}],
  popUpData: [],
  popUpLoader: false,
  isUpdated: false,
  saveLoading: false,
  saveError: false,
  saveSuccess: null,
  savedDataLoading: false,
  savedDataError: false,
  savedData: [] ,
  deleteError: false,
  deleteSuccess: null, 
  error: false,
  countryOptions: [],
  ppNextIndex: 0,
  ppTotalCount: 0
};

export default {
productProfile: handleActions(
{
  [FETCH_FILTER_SUCCESS]: (state, { payload }) => {
    console.log('sss12',payload)
    switch (payload.key) {
      case "l1":
        return immutable(state, {
          level1Options: {
            $set:
              payload && payload.data.level1Options
                ? payload.data.level1Options
                : state.level1Options,
          },
          level2Options: [],
          level3Options: [],
          countryOptions: {
            $set:
              payload && payload.data.countryOptions
                ? payload.data.countryOptions
                : state.countryOptions,
          },

          // subdepartments: [],
          filterlabels : {
            $set: 
              payload && payload.data.filterMapping
                ? payload.data.filterMapping
                : state.filterMapping
          }
        });
      case "l2":
          return immutable(state, {
            level2Options: {
              $set:
                payload && payload.data.level2Options
                  ? payload.data.level2Options
                  : state.level2Options,
            },
            level3Options: {
              $set:
                payload && payload.data.level3Options
                  ? payload.data.level3Options
                  : state.level3Options,
            },
            level4Options: {
              $set:
                payload && payload.data.level4Options
                  ? payload.data.level4Options
                  : state.level4Options,
            },
            level5Options: {
              $set:
                payload && payload.data.level5Options
                  ? payload.data.level5Options
                  : state.level5Options,
            },
            level6Options: {
              $set:
                payload && payload.data.level6Options
                  ? payload.data.level6Options
                  : state.level6Options,
            },
            level7Options: {
              $set:
                payload && payload.data.level7Options
                  ? payload.data.level7Options
                  : state.level7Options,
            },
            styleOptions: {
              $set:
                payload && payload.data.styleOptions
                  ? payload.data.styleOptions
                  : state.styleOptions,
            },
            colourOptions: {
              $set:
                payload && payload.data.colourOptions
                  ? payload.data.colourOptions
                  : state.colourOptions,
            },

            // subdepartments: [],
            filterlabels : {
              $set: 
                payload && payload.data.filterMapping
                  ? payload.data.filterMapping
                  : state.filterMapping
            }
          });
      case "l10":
        return immutable(state, {
          level2Options: {
            $set:
              payload && payload.data.level2Options
                ? payload.data.level2Options
                : state.level2Options,
          },
          filterlabels : {
            $set: 
              payload && payload.data.filterMapping
                ? payload.data.filterMapping
                : state.filterMapping
          }
        });
      case "l11":
      return immutable(state, {
        level3Options: {
          $set:
            payload && payload.data.level3Options
              ? payload.data.level3Options
              : state.level3Options,
        },
        filterlabels : {
          $set: 
            payload && payload.data.filterMapping
              ? payload.data.filterMapping
              : state.filterMapping
        }
      });
      case "l3":
            return immutable(state, {
              level3Options: {
                $set:
                  payload && payload.data.level3Options
                    ? payload.data.level3Options
                    : state.level3Options,
              },
              level4Options: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },

              // subdepartments: [],
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l4":
          return immutable(state, {
            level4Options: {
              $set:
                payload && payload.data.level4Options
                  ? payload.data.level4Options
                  : state.level4Options,
            },
            level5Options: {
              $set:
                payload && payload.data.level5Options
                  ? payload.data.level5Options
                  : state.level5Options,
            },
            level6Options: {
              $set:
                payload && payload.data.level6Options
                  ? payload.data.level6Options
                  : state.level6Options,
            },
            level7Options: {
              $set:
                payload && payload.data.level7Options
                  ? payload.data.level7Options
                  : state.level7Options,
            },
            styleOptions: {
              $set:
                payload && payload.data.styleOptions
                  ? payload.data.styleOptions
                  : state.styleOptions,
            },
            colourOptions: {
              $set:
                payload && payload.data.colourOptions
                  ? payload.data.colourOptions
                  : state.colourOptions,
            },

            
            // subdepartments: [],
            filterlabels : {
              $set: 
                payload && payload.data.filterMapping
                  ? payload.data.filterMapping
                  : state.filterMapping
            }
          });
          case "l6":
          return immutable(state, {
            level5Options: {
              $set:
                payload && payload.data.level5Options
                  ? payload.data.level5Options
                  : state.level5Options,
            },
            level6Options: {
              $set:
                payload && payload.data.level6Options
                  ? payload.data.level6Options
                  : state.level6Options,
            },
            level7Options: {
              $set:
                payload && payload.data.level7Options
                  ? payload.data.level7Options
                  : state.level7Options,
            },
            styleOptions: {
              $set:
                payload && payload.data.styleOptions
                  ? payload.data.styleOptions
                  : state.styleOptions,
            },
            colourOptions: {
              $set:
                payload && payload.data.colourOptions
                  ? payload.data.colourOptions
                  : state.colourOptions,
            },

            
            // subdepartments: [],
            filterlabels : {
              $set: 
                payload && payload.data.filterMapping
                  ? payload.data.filterMapping
                  : state.filterMapping
            }
          });
          case "l7":
            return immutable(state, {
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },
  
              
              // subdepartments: [],
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l8":
            return immutable(state, {
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },

              
              // subdepartments: [],
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
          });
          case "l5":
            return immutable(state, {
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },
  
              
              // subdepartments: [],
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          default:
          return immutable(state, {
            filterlabels : {
              $set: 
                payload && payload.data.filterMapping
                  ? payload.data.filterMapping
                  : state.filterMapping
            }
          });
    };


    // return immutable(state, {
    //   level1Options: {
    //     $set:
    //       payload && payload.data.level1Options
    //         ? payload.data.level1Options
    //         : state.level1Options,
    //   },
    //   level2Options: {
    //     $set:
    //       payload && payload.data.level2Options
    //         ? payload.data.level2Options
    //         : state.level2Options,
    //   },
    //   level3Options: {
    //     $set:
    //       payload && payload.data.level3Options
    //         ? payload.data.level3Options
    //         : state.level3Options,
    //   },
    //   level4Options: {
    //     $set:
    //       payload && payload.data.level4Options
    //         ? payload.data.level4Options
    //         : state.level4Options,
    //   },
    //   styleOptions: {
    //     $set:
    //       payload && payload.data.styleOptions
    //         ? payload.data.styleOptions
    //         : state.styleOptions,
    //   },
    //   colourOptions: {
    //     $set:
    //       payload && payload.data.colourOptions
    //         ? payload.data.colourOptions
    //         : state.colourOptions,
    //   },
    //   filterlabels : {
    //     $set: 
    //       payload && payload.data.filterMapping
    //         ? payload.data.filterMapping
    //         : state.filterMapping
    //   }
      
    //   // error: { $set: null },
    // });
  },
 [RESET_FILTERS_DATA]: (state, {payload }) => {
   return immutable(state, {
    storeData: { $set: [] },
    colourStyleData: { $set: [] },
    userCreatedData: { $set: [] },
    level2Options: { $set: [] },
    level3Options : { $set: [] },
    level4Options: { $set: [] },
    level5Options: { $set: [] },
    styleOptions : { $set: [] },
    colourOptions : { $set: [] },
    saveSuccess: {$set : null},
    saveError: {$set : false},
    profiledProductData: { $set : [] },
    createFilterData: { $set : [] },
    productListContributionData: { $set : [] },
    profiledProductColumn: { $set : [] },
    savedData: { $set: [] },
    deleteSuccess: { $set: null},
    deleteError: { $set: false},
    ppTotalCount: { $set: 0 },
    ppNextIndex: { $set: 0 }
   });
 },
 [RESET_PRODUCTPROFILE_DATA]: (state, {payload }) => {
  return immutable(state, {
   deleteSuccess: { $set: null},
   deleteError: { $set: false}
  });
},
  [FETCH_USER_CREATED_DATA]: (state, {payload }) => {
    return immutable(state, {
    userCreatedloading: { $set : true },
    userCreatedError: { $set: false },
    storeData: { $set: [] },
    colourStyleData: { $set: [] },
    ppNextIndex: { $set: 0 }
    });
  },
  [FETCH_USER_CREATED_DATA_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
    userCreatedData: { $set: (payload && payload.data) || [] },
    userCreatedloading: { $set : false },
    userCreatedError: { $set: false },
    ppTotalCount: { $set: (payload && payload.totalCount) || 0 },
    ppNextIndex: { $set: (payload && payload.nextIndex) || 0 }
    });
  },
  [FETCH_USER_CREATED_DATA_ERROR]: (state, {payload }) => {
    return immutable(state, {
    userCreatedData: { $set: [] },
    userCreatedloading: { $set : false },
    userCreatedError: { $set: true }
    });
  },
  [FETCH_STORES_DATA]: (state, {payload }) => {
    return immutable(state, {
    storeloading: { $set: true},
    storeError: { $set: false }
    });
  },
  [FETCH_STORES_DATA_ERROR]: (state, {payload }) => {
    return immutable(state, {
    storeData: { $set: [] },
    colourStyleData: { $set: [] },
    storeloading: { $set : false },
    storeError: { $set: true }
    });
  },
  [FETCH_STORES_DATA_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
    storeData: { $set: (payload && payload.data && payload.data.storeData) || [] },
    colourStyleData: { $set: (payload && payload.data && payload.data.articleData) || [] },
    storeColumn: { $set:  (payload && payload.data && payload.data.column) || [] },
    storeloading: { $set : false },
    storeError: { $set: false }
    });
  },
  [FETCH_CREATEFILTER_DATA]: (state, {payload }) => {
    return immutable(state, {
      createFilterError: { $set: false },
      createFilterloading: { $set: true}
    });
  },
  [FETCH_CREATEFILTER_DATA_ERROR]: (state, {payload }) => {
    return immutable(state, {
      createFilterData: { $set: [] },
      createFilterloading: { $set : false },
      createFilterError: { $set: true }
    });
  },
  [FETCH_CREATEFILTER_DATA_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
    createFilterData: { $set: (payload && payload.data) || [] },
    createFilterloading: { $set : false },
    createFilterError: { $set : false }
    });
  },
  [FETCH_PRODUCT_LIST_CONTRIBUTION_DATA]: (state, {payload }) => {
    return immutable(state, {
      productListContributionError: { $set: false },
      productListContributionLoading: { $set: true}
    });
  },
  [FETCH_PRODUCT_LIST_CONTRIBUTION_ERROR]: (state, {payload }) => {
    return immutable(state, {
      productListContributionData: { $set: [] },
      productListContributionLoading: { $set : false },
      productListContributionError: { $set: payload?.error }
    });
  },
  [FETCH_PRODUCT_LIST_CONTRIBUTION_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
    productListContributionData: { $set: (payload && payload.data) || [] },
    productListContributionLoading: { $set : false },
    productListContributionError: { $set : false }
    });
  },
  [FETCH_PROFILED_PRODUCT_DATA]: (state, {payload }) => {
    return immutable(state, {
      profiledProductError: { $set: false },
      profiledProductLoading: { $set: true}
    });
  },
  [FETCH_PROFILED_PRODUCT_ERROR]: (state, {payload }) => {
    return immutable(state, {
      profiledProductData: { $set: [] },
      profiledProductLoading: { $set : false },
      profiledProductError: { $set: true }
    });
  },
  [FETCH_PROFILED_PRODUCT_SUCCESS]: (state, {payload }) => {
  return immutable(state, {
    profiledProductData: { $set: (payload && payload.data && payload.data.profiledData) || [] },
    profiledProductColumn: { $set: (payload && payload.data && payload.data.column) || [] },
    originalProfiledProductData: { $set: (payload && payload.data && payload.data.originalData) || [] },
    profiledProductLoading: { $set : false },
    profiledProductError: { $set : false }
    });
  },
  [SAVE_PRODUCT_PROFILE_DATA]: (state, {payload }) => {
    return immutable(state, {
      saveError: { $set: false },
      saveLoading: { $set: true}
    });
  },
  [SAVE_PRODUCT_PROFILE_ERROR]: (state, {payload }) => {
    return immutable(state, {
      saveLoading: { $set : false },
      saveError: { $set: payload && payload.error || true },
      saveSuccess: { $set: false }
    });
  },
  [SAVE_PRODUCT_PROFILE_SUCCESS]: (state, {payload }) => {
  return immutable(state, {
    saveLoading: { $set : false },
    saveError: { $set : false },
    saveSuccess: { $set: true }
    });
  },
  [FETCH_SAVED_DATA]: (state, {payload }) => {
    return immutable(state, {
      savedDataError: { $set: false },
      savedDataLoading: { $set: true}
    });
  },
  [FETCH_SAVED_ERROR]: (state, {payload }) => {
    return immutable(state, {
      savedDataLoading: { $set : false },
      savedDataError: { $set: true },
      savedData: { $set: []  }
    });
  },
  [FETCH_SAVED_SUCCESS]: (state, {payload }) => {
  return immutable(state, {
    savedDataLoading: { $set : false },
    savedDataError: { $set : false },
    savedData: { $set: (payload && payload.data) || [] } 
    });
  },
  [DELETE_PRODUCT_PROFILE]: (state, {payload }) => {
    return immutable(state, {
      deleteError: { $set: false },
      deleteSuccess: { $set: null}
    });
  },
  [DELETE_PRODUCT_PROFILE_ERROR]: (state, {payload }) => {
    return immutable(state, {
      deleteError: { $set: true },
      deleteSuccess: { $set: false }
    });
  },
  [DELETE_PRODUCT_PROFILE_SUCCESS]: (state, {payload }) => {
  return immutable(state, {
    deleteError: { $set : false },
    deleteSuccess: { $set: true }
    });
  },
  // [FETCH_CONSTRAINTS_POPUP]: (state, {payload}) => {
  //   return immutable(state, {
  //     popUpLoader: { $set: true },
  //     popUpError: { $set : false }
  //   })
  // },
  // [FETCH_CONSTRAINTS_POPUP_SUCCESS]: (state, {payload }) => {
  //   return immutable(state, {
  //   popUpLoader: { $set: false},
  //   popUpData: { $set: (payload && payload.data ) || [] },
  //   });
  // },
  // [FETCH_CONSTRAINTS_POPUP_ERROR]: (state, {payload }) => {
  //   return immutable(state, {
  //   popUpLoader: { $set: false},
  //   popUpData: { $set: [] },
  //   popUpError: { $set: true}
  //   });
  // },
  // [UPDATE_TABLEDATA]: (state, {payload }) => {
  //   return immutable(state, {
  //     isUpdated: { $set: false},
  //     loading: { $set: true}
  //   })
  // },
  // [UPDATE_TABLEDATA_SUCCESS]: (state, {payload }) => {
  //   return immutable(state, {
  //     loading: { $set: false},
  //     isUpdated: { $set: true},
  //   })
  // }
},

constraintsState
),
};


