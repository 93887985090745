export const GET_DRAFTS = "@@drafts/GET_DRAFTS";
export const GET_DRAFTS_SUCCESS = "@@drafts/GET_DRAFTS_SUCCESS";
export const GET_DRAFTS_ERROR = "@@drafts/GET_DRAFTS_ERROR";

export const RESET_ALL = "@@drafts/RESET_ALL"

export const getDrafts = (payload) => ({
    type: GET_DRAFTS,
    payload,
});

export const getDraftsSuccess = (payload) => ({
    type: GET_DRAFTS_SUCCESS,
    payload,
});

export const getDraftsrror = (payload) => ({
    type: GET_DRAFTS_ERROR,
    payload,
});

export const resetAll = () => ({
    type: RESET_ALL,
});