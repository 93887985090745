// This is a custom filter UI for selecting
// a unique option from a list
import React from 'react'
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  },...props) {
    const options = React.useMemo(() => {
      const options = []
      preFilteredRows.forEach(row => {
        options.push(...row.values[id])
      })
      return [...new Map(options.map(item => [item['value'], item])).values()]
    }, [id, preFilteredRows])
  
    return (
    <div className='filterMultiSelectCell'>
      <ReactMultiSelectCheckboxes
          options = {options}
          onChange={(e) => {
            setFilter(e || undefined)
          }}
          menuPortalTarget={document.body}
          value={filterValue}
      />
    </div>
    )
  }
  
  export default SelectColumnFilter