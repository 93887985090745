// Blueprint for `features` prop for ReactTable

export const ReactTableFeatures = {
  CHECKBOX_FOR_FIRSTCOLUMN: true,
  CHECKBOX_FOR_LASTCOLUMN: true,
  HIDE_CHECKALL: true,
};

export const IDLE_TIME = 1000 * 60 * 5

export const INTERVAL_TO_REFRESH_TOKEN = 1000 * 60 * 5