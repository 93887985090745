import React, { useState, useRef } from 'react'
import IdleTimer from 'react-idle-timer'
import { logoutUser } from '../../utils/commonUtilities'
import { IDLE_TIME } from '../Table/CustomHooks/Constants'

function IdleTimerContainer (props) {
  const idleTimerRef = useRef(null)

  const onIdle = () => {
    sessionStorage.setItem('isActive',false)
  }
  

  const onActive = () => {
    sessionStorage.setItem('isActive',true)
  }

  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={IDLE_TIME}
        onIdle={onIdle}
        onActive={onActive}
      />
    </div>
  )
}

export default IdleTimerContainer