import React, { useEffect, useState, useRef } from "react"
import PageLoader from "../../../../components/Loader/PageLoader";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);


const NewStoresTable = (props) => {
    const { dataLoading, deleteNewStoreLoading, data, columns } = props

    return (
        <section className="section mt-4 m-0">
            <PageLoader loader={dataLoading || deleteNewStoreLoading} gridLoader={true}>
                {/* {(dataError) ? (<div className="error">{dataError}</div>) : */}
                {/* (data ? ( */}
                <ReactTableWithPlugins
                    shouldPagination
                    data={(data ? data : [])}
                    columns={columns}
                    renderMarkup='TableMarkup'
                    keyRT="sortAndSearch"
                    tableId="newstore_reserved"
                />
                {/* ) : null)} */}
            </PageLoader>
        </section>
    )
}

export default NewStoresTable;