import * as AdaActionsTypes from './actionTypes';

/**
 * Function to dispatch ada allocation data loading request
 * @param {adaRequest} payload 
 * @returns 
 */
export const getAdaAllocationData = (payload) => ({
  type: AdaActionsTypes.GET_ADA_ALLOCATION_DATA_REQUEST,
  payload,
});

/**
 * Function to dispatch ada stanndalone data loading request
 * @param {starDate, endDate} payload 
 * @returns 
 */
export const getAdaStandAloneData = (payload) => ({
  type: AdaActionsTypes.GET_ADA_STANDALONE_DATA_REQUEST,
  payload,
});

/**
 * Fucntion to dispatch ada filters loading request
 * 
 */
export const getAdaFiltersData = (payload, filterType) => ({
  type: AdaActionsTypes.GET_ADA_FILTER_DATA_REQUEST,
  payload,
  filterType,
});

/**
 * Function to dispatch declaration of ada allocation service request body
 * @param {*} requestBodyObject
 * @returns 
 */
export const setAdaAllocationRequest = (payload) => ({
  type: AdaActionsTypes.SET_ADA_ALLOCATION_REQBODY,
  payload,
});

/**
 * Function to dispatch declaration of start and end date
 * @param {current year Week} payload 
 * @returns 
 */
export const setAdaStartEndDate = (payload) => ({
  type: AdaActionsTypes.SET_STARTEND_FISCAL_YEARWEEK,
  payload,
});

/**
 * Function to dispatch action of to reset start and end date 
 * @returns 
 */
export const resetAdaStartEndDate = () => ({
  type: AdaActionsTypes.RESET_SELECTED_DATES,
});

/**
 * Function to dispatch declaration of current fiscal-yearweek
 * @param {current year Week} payload 
 * @returns 
 */
export const setAdaCurrentDate = (payload) => ({
  type: AdaActionsTypes.SET_CURR_FISCAL_YEARWEEK,
  payload,
});

/**
 * Function to dispatch declaration of ada display time constraint
 * @param {week, month, year} payload 
 * @returns 
 */
export const setAdaDataTimeConstraint = (payload) => ({
  type: AdaActionsTypes.SET_ADA_DATA_TIME_CONSTRAINT,
  payload,
});

/**
 * Fucntion to dispatch fetching of drivers data request
 * @param {*} payload 
 * @returns 
 */
export const getAdaDriversData = (payload) => ({
  type: AdaActionsTypes.GET_ADA_DRIVERS_DATA_REQUEST,
  payload,
});

/**
 * Fucntion to dispatch resetting adaVisual state
 * @returns 
 */
export const resetAdaVisual = () => ({
  type: AdaActionsTypes.RESET_ADA_VISUAL,
});

/**
 * Fucntion to dispatch resetting adaVisual state
 * @returns 
 */
export const updateForecastData = (payload) => ({
  type: AdaActionsTypes.UPDATE_FORECAST_DATA,
  payload,
});

/**
 * Function to dispatch declaration of ada standalone service request body
 * @param {*} requestBodyObject
 * @returns 
 */
export const setAdaStandAloneRequest = (payload) => ({
  type: AdaActionsTypes.SET_STANDALONE_REQBODY,
  payload,
});

/**
 * Action function to reset Ada Filters
 * @returns 
 */
export const resetAdaFilterData = () => ({
  type: AdaActionsTypes.RESET_ADA_FILTER_DATA,
});

/**
 * Action function to dispatch Hierarchy data loading request
 * @param {hierarchyRequestbody} payload 
 * @returns 
 */
export const getAdaHierarchyData = (payload) => ({
  type: AdaActionsTypes.GET_ADA_HIERARCHY_DATA_REQUEST,
  payload,
});

export const getAdaHierarchyAllocationData = (payload) => ({
  type: AdaActionsTypes.GET_ADA_HIERARCHY_ALLOCATION_DATA_REQUEST,
  payload,
});
/**
 * Action function to dispatch Forecast Multiplier data update
 * @param {forecast multiplier modal value} payload 
 * @returns 
 */
export const updateForecastMul = (payload) => ({
  type: AdaActionsTypes.UPDATE_FORECAST_MULTIPLIER,
  payload,
});

/**
 * Action function dispatch Review request
 * @param {review Ada Request Body} payload 
 * @returns 
 */
export const reviewAdaVisual = (payload) => ({
  type: AdaActionsTypes.REVIEW_ADA_DATA_REQUEST,
  payload,
});

/**
 * Action function dispatch Save request
 * @param {review Ada Request Body} payload 
 * @returns 
 */
export const saveAdaVisual = (payload) => ({
  type: AdaActionsTypes.SAVE_ADA_DATA_REQUEST,
  payload,
});

export const saveChangesCaVisual = (payload) => ({
  type: AdaActionsTypes.SAVE_CHANGES_CA_VISUAL_REQUEST,
  payload,
});
/**
 * Action function to restore Non modified Ada Data State
 * @returns 
 */
export const restoreAdaData = () => ({
  type: AdaActionsTypes.RESTORE_ADA_DATA,
});

/**
 * Action function to restore Non modified Hierarachy Data State
 * @returns 
 */
export const restoreHierarchyData = () => ({
  type: AdaActionsTypes.RESTORE_HIERARCHY_DATA,
});

export const clearHierarchyData = () => ({
  type: AdaActionsTypes.CLEAR_HIERARCHY_DATA,
});


/**
 * Action function to update hierarchy data
 * @param {hierarchy change params} payload 
 * @returns 
 */
export const changeHierarchy = (payload) => ({
  type: AdaActionsTypes.CHANGE_HIERARCHY,
  payload,
});

export const downloadCsv = (payload) => ({
  type: AdaActionsTypes.DOWNLOAD_CSV,
  payload
})

export const downloadExcelLower = (payload) => ({
  type: AdaActionsTypes.DOWNLOAD_EXCEL_LOWER,
  payload
})

export const getAdaStoreHierarchyData = (payload) => ({
  type: AdaActionsTypes.GET_ADA_STORE_HIERARCHY_DATA_REQUEST,
  payload,
});

export const generateExcel = (payload) => ({
  type: AdaActionsTypes.GENERATE_EXCEL,
  payload
});

export const fetchExcelDataSuccess = (payload) => ({
  type: AdaActionsTypes.GENERATE_EXCEL_SUCCESS,
  payload,
});

export const fetchExcelDataError = (payload) => ({
  type: AdaActionsTypes.GENERATE_EXCEL_ERROR,
  payload,
});

export const resetDownloadExcel = () => ({
  type: AdaActionsTypes.RESET_DOWNLOAD_EXCEL
});