export const FETCH_FILTER_DATA = "@@productStatus/FETCH_FILTER_DATA";
export const FETCH_FILTER_SUCCESS = "@@productStatus/FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "@@productStatus/FETCH_FILTER_ERROR";

export const GET_PRODUCT_STATUS_DATA = "@@productStatus/GET_PRODUCT_STATUS_DATA"
export const GET_PRODUCT_STATUS_DATA_SUCCESS = "@@productStatus/GET_PRODUCT_STATUS_DATA_SUCCESS"
export const GET_PRODUCT_STATUS_DATA_ERROR = "@@productStatus/GET_PRODUCT_STATUS_DATA_ERROR"

export const GET_ARTICLE_CONFIG_DATA = "@@productStatus/GET_ARTICLE_CONFIG_DATA"
export const GET_ARTICLE_CONFIG_DATA_SUCCESS = "@@productStatus/GET_ARTICLE_CONFIG_DATA_SUCCESS"
export const GET_ARTICLE_CONFIG_DATA_ERROR = "@@productStatus/GET_ARTICLE_CONFIG_DATA_ERROR"

export const GET_STORE_GROUP_DATA = "@@productStatus/GET_STORE_GROUP_DATA"
export const GET_STORE_GROUP_DATA_SUCCESS = "@@productStatus/GET_STORE_GROUP_DATA_SUCCESS"
export const GET_STORE_GROUP_DATA_ERROR = "@@productStatus/GET_STORE_GROUP_DATA_ERROR"

export const GET_PRODUCT_PROFILES = "@@productStatus/GET_PRODUCT_PROFILES"
export const GET_PRODUCT_PROFILES_SUCCESS  = "@@productStatus/GET_PRODUCT_PROFILES_SUCCESS "
export const GET_PRODUCT_PROFILES_ERROR = "@@productStatus/GET_PRODUCT_PROFILES_ERROR"

export const GET_STORE_SIZE_CONTRIBUTIONS = "@@productStatus/GET_STORE_SIZE_CONTRIBUTIONS"
export const GET_STORE_SIZE_CONTRIBUTIONS_SUCCESS  = "@@productStatus/GET_STORE_SIZE_CONTRIBUTIONS_SUCCESS "
export const GET_STORE_SIZE_CONTRIBUTIONS_ERROR = "@@productStatus/GET_STORE_SIZE_CONTRIBUTIONS_ERROR"

export const GET_DC = "@@productStatus/GET_DC"
export const GET_DC_SUCCESS  = "@@productStatus/GET_DC_SUCCESS "
export const GET_DC_ERROR = "@@productStatus/GET_DC_ERROR"

export const CREATE_CONFIG = "@@productStatus/CREATE_CONFIG"
export const CREATE_CONFIG_SUCCESS = "@@productStatus/CREATE_CONFIG_SUCCESS"
export const CREATE_CONFIG_ERROR = "@@productStatus/CREATE_CONFIG_ERROR"

export const DELETE_CONFIG = "@@productStatus/DELETE_CONFIG"
export const DELETE_CONFIG_SUCCESS = "@@productStatus/DELETE_CONFIG_SUCCESS"
export const DELETE_CONFIG_ERROR = "@@productStatus/DELETE_CONFIG_ERROR"

export const GET_MISSING_CONFIG = "@@productStatus/GET_MISSING_CONFIG"
export const GET_MISSING_CONFIG_SUCCESS = "@@productStatus/GET_MISSING_CONFIG_SUCCESS"
export const GET_MISSING_CONFIG_ERROR = "@@productStatus/GET_MISSING_CONFIG_ERROR"

export const SAVE_FILTER = "@@productStatus/SAVE_FILTER"

export const SAVE_STORE_GROUP_DATA = "@@productStatus/SAVE_STORE_GROUP_DATA"

export const NO_ARTICLE_ERROR = "@@productStatus/NO_ARTICLE_ERROR"

export const RESET_FILTERS_DATA = "@@productStatus/RESET_FILTERS_DATA";
export const RESET_ALL = "@@productStatus/RESET_ALL";
export const RESET_CREATE_CONFIG_DATA = "@@productStatus/RESET_CREATE_CONFIG_DATA";
export const RESET_TABLE_DATA = "@@productStatus/RESET_TABLE_DATA";

export const GET_MAPPING_STYLES = "@@productStatus/GET_MAPPING_STYLES"
export const GET_MAPPING_STYLES_SUCCESS = "@@productStatus/GET_MAPPING_STYLES_SUCCESS"
export const GET_MAPPING_STYLES_ERROR = "@@productStatus/GET_MAPPING_STYLES_ERROR"

export const GET_CHECK_ALL_DATA_SUCCESS = "@@productStatus/GET_CHECK_ALL_DATA_SUCCESS"

export const DOWNLOAD_EXCEL_DATA = "@@productStatus/DOWNLOAD_EXCEL_DATA"
export const GET_DOWNLOAD_EXCEL_DATA_SUCCESS = "@@productStatus/GET_DOWNLOAD_EXCEL_DATA_SUCCESS"
export const GET_DOWNLOAD_EXCEL_DATA_ERROR = "@@productStatus/GET_DOWNLOAD_EXCEL_DATA_ERROR"
export const RESET_DOWNLOAD_INDEX = "@@productStatus/RESET_DOWNLOAD_INDEX"
export const UPDATE_AUTO_ALLOCATION = "@@productStatus/UPDATE_AUTO_ALLOCATION"
export const UPDATE_AUTO_ALLOCATION_SUCCESS = "@@productStatus/UPDATE_AUTO_ALLOCATION_SUCCESS"
export const UPDATE_AUTO_ALLOCATION_ERROR = "@@productStatus/UPDATE_AUTO_ALLOCATION_ERROR"
