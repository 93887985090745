import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PageLoader from "../../../../components/Loader/PageLoader";
import SetStrategyView from './SetStrategyView';
import * as strategyConfigActions from '../../actions/StrategyConfigActions';
import * as Notify from "../../../../components/Notification/Notifications"
import Notification from '../../../../components/Notification/Notifications';
import { STRATEGY_CONFIGURAION_PATH, PRODUCT_STORE_STATUS } from "../../../../constants/routeConstants";
import { NETWORK_ERROR_MSG, NO_RECORDS_MSG, NO_RECORDS_HELPER_MSG } from '../../StrategyConfigConstants';
import "../../StrategyConfiguration.css";

/**
 * Container class with handler methods for Strategy Configuration Set
 */
class SetStrategyContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleSaveStrategies = this.handleSaveStrategies.bind(this);
    }

    handleSaveStrategies() {
        const { strategyConfigActionsProp, editedConfigReq } = this.props;
        if (editedConfigReq.pre_season_demand_type === "APS" && (editedConfigReq.pre_season_aps === null || editedConfigReq.pre_season_wos === null)) {
            var check = editedConfigReq.pre_season_aps === null ? "aps" : "wos"
            Notify.error(`Pre season ${check} is mandatory`)
        }
        else if (editedConfigReq.pre_season_demand_type === "Fixed" && editedConfigReq.pre_season_store_inv_push === null) {
            Notify.error("Pre season store inv push is mandatory")
        }
        else if (editedConfigReq.in_season_demand_type === "APS" && (editedConfigReq.in_season_aps === null || editedConfigReq.in_season_wos === null)) {
            var check = editedConfigReq.in_season_aps === null ? "aps" : "wos"
            Notify.error(`In season ${check} is mandatory`)
        }
        else if (editedConfigReq.in_season_demand_type === "Fixed" && editedConfigReq.in_season_store_inv_push === null) {
            Notify.error("In season store inv push is mandatory")
        }
        else if (editedConfigReq.clearance_season_demand_type === "APS" && (editedConfigReq.clearance_season_aps === null || editedConfigReq.clearance_season_wos === null)) {
            var check = editedConfigReq.clearance_season_aps === null ? "aps" : "wos"
            Notify.error(`Clearance ${check} is mandatory`)
        }
        else if (editedConfigReq.clearance_season_demand_type === "Fixed" && editedConfigReq.clearance_season_store_inv_push === null) {
            Notify.error("Clearance store inv push is mandatory")
        }
        else if (editedConfigReq.in_season_demand_type === "IA" && editedConfigReq.in_season_wos === null){
                Notify.error("In season wos is mandatory")
        }
        else if(editedConfigReq.clearance_season_demand_type === "IA" && editedConfigReq.clearance_season_wos===null){
            Notify.error("Clearance wos is mandatory")
        }
        else {
            strategyConfigActionsProp.saveStrategyConfigData(editedConfigReq);
            strategyConfigActionsProp.resetSaveStrategyScreen();
            this.props.history.push({ pathname: `${PRODUCT_STORE_STATUS}`, state: { data: 'strategyConfig' } });
        }

    }

    render() {
        const { strategyConfigData, isStrategyConfigDataLoading, isStrategyConfigDataFailed, isStrategyConfigDataSuccess, strategyConfigActionsProp, editedConfigReq } = this.props;

        return (
            <>
                {/* <Notification /> */}
                <PageLoader loader={isStrategyConfigDataLoading} loaderMessage='Loading...'>
                    {isStrategyConfigDataSuccess
                        ? strategyConfigData && strategyConfigData.length > 0
                            ?
                            (
                                <>
                                    <SetStrategyView
                                        strategyConfigData={strategyConfigData[0]}
                                        handleSaveStrategies={this.handleSaveStrategies}
                                        strategyConfigActionsProp={strategyConfigActionsProp}
                                        editedConfigReq={editedConfigReq}
                                    />
                                </>
                            )
                            :
                            (
                                <div className="section_container shadow-sm">
                                    <div className="empty">
                                        {NO_RECORDS_MSG}
                                        <p>{NO_RECORDS_HELPER_MSG}</p>
                                    </div>
                                </div>
                            )
                        : (isStrategyConfigDataFailed ? <div className="error">{NETWORK_ERROR_MSG}</div> : null)}
                </PageLoader>
            </>
        );
    }
}

const mapStateToProps = ({ strategyConfiguration }) => ({
    strategyConfigData: strategyConfiguration.strategyConfigData,
    isStrategyConfigDataLoading: strategyConfiguration.isStrategyConfigDataLoading,
    isStrategyConfigDataFailed: strategyConfiguration.isStrategyConfigDataFailed,
    isStrategyConfigDataSuccess: strategyConfiguration.isStrategyConfigDataSuccess,
    editedConfigReq: strategyConfiguration.editedConfigReq,
    saveDataSuccess: strategyConfiguration.isStrategyConfigSaveSuccess
});

const mapDispatchToProps = (dispatch) => ({
    strategyConfigActionsProp: bindActionCreators(strategyConfigActions, dispatch),

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SetStrategyContainer));