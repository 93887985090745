import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import MultiSelect from "../../../components/Filters/MultiSelect";
import Select from "../../../components/Filters/SingleSelect";
import { handleSelectAll } from "../../../utils/SelectAllHandler/SelectAllHander";
import { LOST_SALES_QUARTER_FILTER_LABEL, LOST_SALES_WEEK_FILTER_LABEL } from "./LostSalesConstants";
import "./LostSalesReport.css";

/**
 * Week Level Filetring Component
 * @param {*} props
 * @returns
 */
const WeekFilter = (props) => { 
  const { handleOnChange, showWeekFilter, defaultValue } = props

    const { quarters } = props;
    const [week, setWeek] = useState([]);
    const [quarterOptions, setQuarterOptions] = useState([]);
    const [quarter, setQuarter] = useState({});
    const [isWeekChanged, setIsWeekChange] = useState(false);

  const onWeekChange = (val, event, that) => {
    let valuesArray = handleSelectAll(val, event, that)
    setWeek(valuesArray[1]);
    setIsWeekChange(true);
}

  const onQuarterChange = (quar) => {
    if(quarter?.value !== quar.value) {
      setQuarter(quar);
      setWeek(quar?.weeks);
      getData(quar?.year, Number(quar?.value?.split("-")[0]), quar?.weeks?.map(item => item.value));
    }
  };

  const onBlurHandler = () => {
    if(isWeekChanged && week?.length) {
      setTimeout(() => {
        getData(quarter?.year, Number(quarter?.value?.split("-")[0]), week.map(item => item.value));
      }, 100)
    }
  }

  const getData = (year, quarter, weeks) => {
    handleOnChange(year, quarter, weeks.filter(w => w !== "*"));
    setIsWeekChange(false);
  }

  useEffect(() => {
    if(quarters?.length) {
      let q_options = [];
      quarters.forEach(q => {
        q_options.push({
          value: `${q.quarter}-${q.year}`,
          label: `${q.quarter} (${q.year})`,
          weeks: q.weeks.map(week => {return {value: week, label: `${week} (${q.year})`}}),
          year: q.year
        })
      })
      setQuarterOptions(q_options);
      setQuarter(q_options[0]);
      setWeek(q_options[0]?.weeks)
    }
  }, [quarters])

  // useEffect(() => {
  //   if(quarterOptions?.length) {
  //     setQuarter(quarterOptions[0]);
  //     !defaultValue && setWeek(quarterOptions[0]?.weeks)
  //   }
  // }, [defaultValue])

  return (
    <div id="WEEK_FILTER_CONzTAINER" style={{ display: "inline-flex" }}>
        <div>
          <label className="fnt-md fnt-bold fnt-bold" style={{ marginTop: "2%" }}>
            {LOST_SALES_QUARTER_FILTER_LABEL}
          </label>
          <Select
            id="WEEK_SELECTOR"
            name="week"
            value={quarter}
            onChange={onQuarterChange}
            placeHolder="Select Quarter"
            options={quarterOptions?.length ? quarterOptions : []}
            className="week_selector"
          />
        </div>
        {showWeekFilter &&
        <div className="week_filter fill col-md-3 p-0 ml-4">
          <label className="fnt-md fnt-bold fnt-bold" style={{ marginTop: "2%" }}>
            {LOST_SALES_WEEK_FILTER_LABEL}
            </label>
          <MultiSelect
            name="week"
            dropdownValue={week}
            onBlur={onBlurHandler}
            onDropDownChange={onWeekChange}
            placeHolder={"Select Week"}
            optionsArray={quarter?.weeks?.length ? quarter.weeks : []}
          />
        </div>
        }
     </div>
  );
};

export default WeekFilter;
