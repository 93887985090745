import * as actionTypes from "../POAllocationAction/actionTypes"
import { poAllocationInitialState } from "./InitialState"
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export default {
    poAllocation: handleActions(
        {
            [actionTypes.GET_PO_DATA]: (state, payload) => {
                return immutable(state, {
                    poAllocationData: { $set: [] },
                    poAllocationDataLoading: { $set: true },
                    poAllocationDataError: { $set: null },
                });
            },
            [actionTypes.GET_PO_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    poAllocationData: { $set: (payload && payload.data) || [] },
                    poAllocationDataLoading: { $set: false },
                    poAllocationDataError: { $set: null }
                });
            },
            [actionTypes.GET_PO_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    poAllocationData: { $set: [] },
                    poAllocationDataLoading: { $set: false },
                    poAllocationDataError: { $set: (payload && payload.error) || null }
                });
            }
        },
        poAllocationInitialState
    )
}
            