export const STORE_GROUP_ACTION = "@@storeGroup/STORE_GROUP_ACTION";
export const STORE_GROUP_ACTION_SUCCESS = "@@storeGroup/STORE_GROUP_ACTION_SUCCESS";
export const STORE_GROUP_ACTION_ERROR = "@@storeGroup/STORE_GROUP_ACTION_ERROR";
export const STORE_GROUP_ACTION_FOR_EDIT = "@@storeGroup/STORE_GROUP_ACTION_FOR_EDIT";
export const STORE_GROUP_ACTION_FOR_EDIT_SUCCESS = "@@storeGroup/STORE_GROUP_ACTION_FOR_EDIT_SUCCESS";
export const STORE_GROUP_ACTION_FOR_EDIT_ERROR = "@@storeGroup/STORE_GROUP_ACTION_FOR_EDIT_ERROR";
export const STORE_DETAILS_ACTION = "@@storeGroup/STORE_DETAILS_ACTION";
export const STORE_DETAILS_ACTION_SUCCESS = "@@storeGroup/STORE_DETAILS_ACTION_SUCCESS";
export const STORE_DETAILS_ACTION_ERROR = "@@storeGroup/STORE_DETAILS_ACTION_ERROR";
export const FIRST_OR_SECOND_STOREGROUP = "@@storeGroup/FIRST_OR_SECOND_STOREGROUP";
export const CREATE_STOREGROUP_FILTERS_ACTION = "@@storeGroup/CREATE_STOREGROUP_FILTERS_ACTION";
export const CREATE_STOREGROUP_FILTERS_ACTION_SUCCESS = "@@storeGroup/CREATE_STOREGROUP_FILTERS_ACTION_SUCCESS";
export const CREATE_STOREGROUP_FILTERS_ACTION_ERROR = "@@storeGroup/CREATE_STOREGROUP_FILTERS_ACTION_ERROR";
export const CREATE_STOREGROUP_STOREDATA_ACTION = "@@storeGroup/CREATE_STOREGROUP_STOREDATA_ACTION";
export const CREATE_STOREGROUP_STOREDATA_ACTION_SUCCESS = "@@storeGroup/CREATE_STOREGROUP_STOREDATA_ACTION_SUCCESS";
export const CREATE_STOREGROUP_STOREDATA_ACTION_ERROR = "@@storeGroup/CREATE_STOREGROUP_STOREDATA_ACTION_ERROR";
export const CREATE_STOREGROUP_ACTION = "@@storeGroup/CREATE_STOREGROUP_ACTION";
export const CREATE_STOREGROUP_ACTION_SUCCESS = "@@storeGroup/CREATE_STOREGROUP_ACTION_SUCCESS";
export const CREATE_STOREGROUP_ACTION_ERROR = "@@storeGroup/CREATE_STOREGROUP_ACTION_ERROR";
export const EDIT_STOREGROUP_ACTION = "@@storeGroup/EDIT_STOREGROUP_ACTION";
export const EDIT_STOREGROUP_ACTION_SUCCESS = "@@storeGroup/EDIT_STOREGROUP_ACTION_SUCCESS";
export const EDIT_STOREGROUP_ACTION_ERROR = "@@storeGroup/EDIT_STOREGROUP_ACTION_ERROR";
export const DELETE_STOREGROUP= "@@storeGroup/DELETE_STOREGROUP";
export const DELETE_STOREGROUP_SUCCESS = "@@storeGroup/DELETE_STOREGROUP_SUCCESS";
export const DELETE_STOREGROUP_ERROR = "@@storeGroup/DELETE_STOREGROUP_ERROR";
export const GET_STORE_SECONDTABLEDATA_SUCCESS = "@@storeGroup/GET_STORE_SECONDTABLEDATA_SUCCESS";
export const RESET_STORE_GROUP = "@@storeGroup/RESET_STORE_GROUP";
export const RESET_ALL = "@@storeGroup/RESET_ALL";

export const resetAll = () => ({
  type: RESET_ALL,
});

export const storeGroupAction = payload => ({
  type: STORE_GROUP_ACTION,
  payload
});

export const storeGroupActionSuccess = payload => ({
  type: STORE_GROUP_ACTION_SUCCESS,
  payload
});

export const storeGroupActionError = payload => ({
  type: STORE_GROUP_ACTION_ERROR,
  payload
});

export const storeGroupActionForEdit = payload => ({
  type: STORE_GROUP_ACTION_FOR_EDIT,
  payload
});

export const storeGroupActionForEditSuccess = payload => ({
  type: STORE_GROUP_ACTION_FOR_EDIT_SUCCESS,
  payload
});

export const storeGroupActionForEditError = payload => ({
  type: STORE_GROUP_ACTION_FOR_EDIT_ERROR,
  payload
});

export const storeDetailsAction = payload => ({
  type: STORE_DETAILS_ACTION,
  payload
});

export const storeDetailsActionSuccess = payload => ({
  type: STORE_DETAILS_ACTION_SUCCESS,
  payload
});

export const storeDetailsActionError = payload => ({
  type: STORE_DETAILS_ACTION_ERROR,
  payload
});

export const setstoreDataInFirstOrSecondTable = payload => ({
  type: FIRST_OR_SECOND_STOREGROUP,
  payload
});

export const getStoreSecondDataSuccess = payload => ({
  type: GET_STORE_SECONDTABLEDATA_SUCCESS,
  payload
})

export const createStoreGroupFiltersAction = (payload,isEdit,levelOptions) => ({
  type: CREATE_STOREGROUP_FILTERS_ACTION,
  payload,
  isEdit,
  levelOptions
});

export const createStoreGroupFiltersActionSuccess = payload => ({
  type: CREATE_STOREGROUP_FILTERS_ACTION_SUCCESS,
  payload
});

export const createStoreGroupFiltersActionError = payload => ({
  type: CREATE_STOREGROUP_FILTERS_ACTION_ERROR,
  payload
});

export const createStoreGroupStoreDataAction = (payload , editingOrCreatingStoreGroup ) => ({
  type: CREATE_STOREGROUP_STOREDATA_ACTION,
  payload,
  editingOrCreatingStoreGroup:editingOrCreatingStoreGroup
});

export const createStoreGroupStoreDataActionSuccess = payload => ({
  type: CREATE_STOREGROUP_STOREDATA_ACTION_SUCCESS,
  payload
});

export const createStoreGroupStoreDataActionError = payload => ({
  type: CREATE_STOREGROUP_STOREDATA_ACTION_ERROR,
  payload
});

export const createStoreGroupAction = payload => ({
  type: CREATE_STOREGROUP_ACTION,
  payload
});

export const createStoreGroupActionSuccess = payload => ({
  type: CREATE_STOREGROUP_ACTION_SUCCESS,
  payload
});

export const createStoreGroupActionError = payload => ({
  type: CREATE_STOREGROUP_ACTION_ERROR,
  payload
});

export const editStoreGroupAction = payload => ({
  type: EDIT_STOREGROUP_ACTION,
  payload
});

export const editStoreGroupActionSuccess = payload => ({
  type: EDIT_STOREGROUP_ACTION_SUCCESS,
  payload
});

export const editStoreGroupActionError = payload => ({
  type: EDIT_STOREGROUP_ACTION_ERROR,
  payload
});

export const deleteStoreGroup = payload => ({
  type: DELETE_STOREGROUP,
  payload
});

export const deleteStoreGroupSuccess = payload => ({
  type: DELETE_STOREGROUP_SUCCESS,
  payload
});

export const deleteStoreGroupError = payload => ({
  type: DELETE_STOREGROUP_ERROR,
  payload
});

export const resetStoreGroup = () => ({
  type: RESET_STORE_GROUP,
})