import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect, components } from "react-select";

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
          <svg width="24" height="24"><path d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z" fill="currentColor" fill-rule="evenodd"></path></svg>
      </components.DropdownIndicator>
    )
  );
};

const Select = (props) => {

   if (props.allowSelectAll) {
    if (
      props.options &&
      props.options.length > 0 &&
      props.value &&
      props.value.length &&
      props.value.length === props.options.length
    ) {
      return (
        <ReactSelect
          {...props}
          components={{ DropdownIndicator }}
          isDisabled={props.isDisabled}
          placeholder={props.placeholder}
          value={[props.allOption]}
          closeMenuOnSelect={false}
          label={props.label}
          className="react-select-container filter-selects"
          classNamePrefix="react-select"
          onChange={(selected) => {
            if (!selected) {
              return props.onChange([]);
            }
            return selected.length
              ? props.onChange(selected.slice(1))
              : props.onChange(selected);
          }}
        />
      );
    }

    return (
      <ReactSelect
        {...props}
        components={{ DropdownIndicator }}
        options={
          props.options && props.options.length
            ? [props.allOption, ...props.options]
            : props.options
        }
        placeholder={props.placeHolder}
        closeMenuOnSelect={false}
        className="react-select-container filter-selects"
        classNamePrefix="react-select"
        onChange={(selected) => {
          if (!selected) {
            return props.onChange([]);
          }
          if (
            selected.length > 0 &&
            selected[selected.length - 1].value === props.allOption.value
          ) {
            return props.onChange(props.options);
          }
          return props.onChange(selected);
        }}
      />
    );
  }

  return (
    <ReactSelect
      {...props}
      components={{ DropdownIndicator }}
      isDisabled={props.isDisabled}
      placeholder={props.placeholder}
      className="react-select-container filter-selects"
      classNamePrefix="react-select"
    />
  );
};

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

Select.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
  options: [],
};

export default Select;
