import { handleActions } from "redux-actions";
import nsApproveReducer from './NewStoreApprove/NewStoreApproveReducer';

const nsApproveReleaseReducers = {
    newStoreSetUp: handleActions(
        {

        },
        {}
    ),
    ...nsApproveReducer,
};

export default nsApproveReleaseReducers;