import React, { useEffect, useState, useRef } from "react"
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ColumnFilter from "../../UserManagement/ColumnFilter";
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import { PO_ALLOCATION_TABLE_HEADERS } from "./POAllocationConstants"
import { cloneDeep } from "lodash";
import StylesPopup from "./StylesPopup"
import { dateFormat } from "../../../utils/commonUtilities";
import { numberUSFormatting } from "../../../utils/formatters/valueFormatters";
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping";


const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const POAllocationTable = (props) => {
    const { data, allocatePO, toRelease, isPopup, setTableInstance, buttonLabel, type, status } = props

    const [showStylesPopup, setShowStyles] = useState(false)
    const [selectedData, setStylesData] = useState({})
    const [initialHiddenColumns, setHiddenColumns] = useState([])

    const getColumnBehaviour = (canExpand, value) => {
        return canExpand ? value : <span style={{ backgroundColor: "rgb(224,224,224)", minHeight: "100%", minWidth: "100%" }}> </span>
    }

    useEffect(() => {
        if (toRelease) {
            setHiddenColumns(["action","article", "description", "count", "latest_receipt_date","creation_date"])
        }
        if (isPopup) {
            setHiddenColumns(["allocation_name", "dc", "action","asn_id","po_id", "dc_code", "inventory_date", "latest_receipt_date", 
            "inventory_source", "count", "status", "vendor_id", "vendor_name", "creation_date", "style_color_count", "created_at",
            "l2_name", "l3_name", "l4_name", "l5_name", "style_color_cnt"])
        }
        else {
            setHiddenColumns(["article", "inv_avai", "l4_name", "style_code", "style_name", "color_code", "color_desc", "store_groups","assortment_indicator","factory_type", "intro_date", "max_allocation_date"])
        }
        // if (type === "POASN") {
        //     setHiddenColumns(["asn_id","quantity_variance","article", "description",])
        // }
        // if (type === "ASN") {
        //     setHiddenColumns(["action","quantity_variance"])
        // }
        // if (type === "RECEIPT") {
        //     setHiddenColumns(["action","asn_id"])
        // }
        // if (type === "PO") {
        //     setHiddenColumns(["action","asn_id","quantity_variance"])
        // }
        // if (type === "ERROR") {
        //     setHiddenColumns(["action","asn_id"])
        // }
        if (type === "POASN" && status === "Processed") {
            setHiddenColumns(["asn_id","quantity_variance", "latest_receipt_date", "article", "description",])
        }
        if (type === "ASN" && status === "Pending") {
            setHiddenColumns(["action","quantity_variance", "latest_receipt_date","creation_date"])
        }
        if (type === "POASN" && status === "Mismatch") {
            setHiddenColumns(["action","asn_id","creation_date"])
        }
        if (type === "PO" && status === "Pending") {
            setHiddenColumns(["action","asn_id","quantity_variance", "latest_receipt_date","creation_date"])
        }
        if (type === "POASN" && status === "Error") {
            setHiddenColumns(["action","asn_id","creation_date"])
        }
    }, [toRelease, isPopup, type, status])

    const columns = [
        {
            Header: " ",
            sticky: "left",
            columns: [{
                Header: PO_ALLOCATION_TABLE_HEADERS.ASN_ID,
                sticky: "left",
                accessor: 'asn_id',
                Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
                disableFilters: true,
            },
            {
                Header: PO_ALLOCATION_TABLE_HEADERS.PO_ID,
                sticky: "left",
                accessor: 'po_id',
                Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
                disableFilters: true,
            }]
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.ALLOCATION_NAME,
            accessor: 'allocation_name',
            // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
            // disableFilters: true,
            Filter: ColumnFilter,
            width: 350
        },
        // {
        //     Header: PO_ALLOCATION_TABLE_HEADERS.DC,
        //     accessor: 'dc',
        //     // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
        //     disableFilters: true,
        //     width: 200
        // },
        // {
        //     Header: PO_ALLOCATION_TABLE_HEADERS.INVENTORY,
        //     accessor: 'inventory_source',
        //     Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
        //     disableFilters: true,
        // },
        {
            // id: isPopup ? '': 'expander', // Make sure it has an ID
            Header: PO_ALLOCATION_TABLE_HEADERS.STYLE_COUNT,
            accessor: 'style_color_count',
            id: "style_color_cnt",
            // Cell: ({ value, row }) =>
            //     // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            //     // to build the toggle for expanding a row
            //     !toRelease ? (
            //         row.canExpand ? (
            //             <div className="row"
            //                 {...row.getToggleRowExpandedProps()}
            //             >
            //                 <span className="expand__text" style={{ width: "60px" }}>
            //                     {value}
            //                 </span>
            //                 {
            //                     row.isExpanded ? (
            //                         <div
            //                             className="cursor-pointer"
            //                             style={{ color: "#50778e" }}
            //                         >
            //                             <i className="fa fa-angle-down fa-lg ml-2 expand-collapse" title="Collpase" ></i>
            //                         </div>) :
            //                         <div
            //                             className="cursor-pointer"
            //                             style={{ color: "#50778e" }}
            //                         >
            //                             <i className="fa fa-angle-right fa-lg ml-2 expand-collapse" title="Expand" ></i>
            //                         </div>
            //                 }
            //             </div>
            //         ) : <span style={{ backgroundColor: "rgb(224,224,224)", minHeight: "100%", minWidth: "100%" }}> </span>)
            //         : <div>
            //             <div>{value}</div>
            //         </div>,
            // width: 200,
            disableFilters: true,

        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.STYLE,
            accessor: 'article',
            Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
            filter: 'bulkFilterCommaSeperated',
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.STYLE_ID,
            accessor: 'style_code',
            Filter: ColumnFilter,
            // disableFilters: true,
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.COLOR_ID,
            accessor: 'color_code',
            Filter: ColumnFilter,
            // disableFilters: true,
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.STYLE_DESC,
            accessor: 'style_name',
            Filter: ColumnFilter,
            // disableFilters: true,
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.COLOR_DESC,
            accessor: 'color_desc',
            Filter: ColumnFilter,
            // disableFilters: true,
        },
        // {
        //     Header: PO_ALLOCATION_TABLE_HEADERS.DESC,
        //     accessor: 'description',
        //     disableFilters: true,
        // },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: 'l2_name',
            disableFilters: true,
        },
        {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: 'l3_name',
            disableFilters: true,
        },
        {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: 'l4_name',
            disableFilters: true,
        },
        // Hide the column(Silhouette) as per the task GEN-1960
        // {
        //     Header: levelFiltersLabelFormatter("level5"),
        //     accessor: 'l5_name',
        //     disableFilters: true,
        // },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.CREATION_DATE,
            accessor: 'created_at',
            id: "creation_date",
            Cell: ({ value, row }) => dateFormat(value),
            disableFilters: true,
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.EXPECTED_DATE,
            accessor: "inventory_date",
            Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
            disableFilters: true,
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.STORE_GROUP,
            accessor: "store_groups",
            // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
            Filter: ColumnFilter
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.ASSORTMENT_INDICATOR,
            accessor: "assortment_indicator",
            // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
            Filter: ColumnFilter,
            // disableFilters: true,
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.FACTORY_TYPE,
            accessor: "factory_type",
            // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
            Filter: ColumnFilter,
            disableFilters: true,
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.LW_UNITS,
            accessor: "lw_qty",
            // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.WTD_SALES_UNIT,
            accessor: "tw_qty",
            Cell: (instance) => (
                <div>
                    {instance?.row?.original.tw_qty !== null ? numberUSFormatting(instance?.row?.original.tw_qty): "-"}   
                </div>
            ),
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.BULK_REMAINING,
            accessor: "bulk_remaining",
            // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            // disableFilters: true,
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.ALLOCATED_QTY,
            accessor: "allocated_quantity",
            // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            // disableFilters: true,
        },
        // {
        //     Header: "Inv Available",
        //     accessor: "inv_avai",
        //     disableFilters: true
        // },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.RECEIPT_DATE,
            accessor: "latest_receipt_date",
            Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value ? dateFormat(value) : " "),
            disableFilters: true,
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.TOTAL_QTY,
            accessor: "total_quantity",
            Cell: (inst) => numberUSFormatting(inst?.value),
            disableFilters: true,
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.RECEIPT_QTY,
            accessor: "receipt_quantity",
            Cell: (inst) => numberUSFormatting(inst?.value),
            disableFilters: true,

        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.QTY_VARIANCE,
            accessor: "quantity_variance",
            Cell: (inst) => numberUSFormatting(inst?.value),
            disableFilters: true,

        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.ALLOCATION_STATUS,
            accessor: "status",
            Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
            disableFilters: true,
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.VENDOR_ID,
            accessor: "vendor_id",
            disableFilters: true,
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.VENDOR_NAME,
            accessor: 'vendor_name',
            disableFilters: true,
            width: 200
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.INTRODUCTION_DATE,
            accessor: "intro_date",
            // Cell: ({ value, row }) => dateFormat(value),
            Filter: ColumnFilter,
            // disableFilters: true,
            width: 200,
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.LAST_ALLOCATION_DATE,
            accessor: "max_allocation_date",
            // Cell: ({ value, row }) => dateFormat(value),
            Filter: ColumnFilter,
            // disableFilters: true,
            width: 200,
        },
        {
            Header: " ",
            sticky: "right",
            columns: [{
                Header: "Action",
                accessor: "action",
                Cell: ({row}) => (
                    <div>
                        <button
                            className="btn btn-outline-secondary btn-sm px-2 rounded-md text-body"
                            onClick={() => showPOStyles(row)}
                        >
                            Review
                            <i
                                className="fa fa-angle-right ml-2"
                                title="Configure"
                                aria-hidden="true"
                            ></i>
                        </button>
                    </div>
                ),
                width: 150,
                disableFilters: true
            }]
        },
    ]

    const showPOStyles = (row) => {
        setStylesData({data: row.originalSubRows, po: row.original.po_id, ...row?.original})
        setShowStyles(true)
    }

    const createDynamicColumnsForErrorFlow = () => {
        if(status === "Error") {
            let columnIndices = [9, 10, 11]
            const subColNames = [PO_ALLOCATION_TABLE_HEADERS.EACHES, PO_ALLOCATION_TABLE_HEADERS.PACKS, PO_ALLOCATION_TABLE_HEADERS.TOTAL]
            let acc = {
                9: ["total_eaches_nos", "total_pack_nos", "total_quantity"],
                10: ["eaches_nos", "pack_nos", "receipt_quantity"],
                11: ["eaches_variance", "pack_variance", "quantity_variance"],

            }
            columnIndices.forEach(id => {
                columns[id].columns = []
                acc[id].forEach((subAcc, index) => {
                    columns[id].columns.push({
                        Header: subColNames[index],
                        accessor: subAcc,
                        disableFilters: true,
                    })
                })

            })  
        }
    }

    return (
        <>
            {createDynamicColumnsForErrorFlow()}
            <ReactTableWithPlugins
                pageSize={!isPopup && 20}
                shouldPagination
                hideColumnsFilter={isPopup}
                data={(data ? data : [])}
                columns={columns}
                renderMarkup='TableMarkup'
                containSubRow={true}
                keyRT={toRelease ? "toBeReleased" : (isPopup ? "StyleInvTable" : "autoApprove")}
                features={isPopup ? ["CHECKBOX_FOR_FIRSTCOLUMN"] : []}
                initialHiddenColumns={initialHiddenColumns}
                headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: 4 }}
                getInstanceOnMount={(instance) => {
                    setTableInstance(instance)
                }}
                isHideCount = {true}
                tableId={"auto_approve"}
                hideDropdown={!isPopup && "Yes"}
            />
            {/* {
                !toRelease && !isPopup &&
                <div className="text-center">
                    <button
                        className="btn btn-primary fnt-md px-2"
                        onClick={allocatePO}
                    >
                        {buttonLabel}
                    </button>
                </div>
            } */}
            {
                showStylesPopup &&
                <StylesPopup poData={selectedData} closeModal={() => {setShowStyles(false)}}
                    showModal={showStylesPopup} />
            }
        </>
    )
}

export default POAllocationTable