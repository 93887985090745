import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import './ProductStoreStatus.css'
import ProductStatus from "./ProductStatus/ProductStatus"
import StoreStatus from './StoreStatus/StoreStatus'
import { hideTabForClientID, hideToProd, isCoach } from '../../utils/commonUtilities'
import NewStoreSetUpContainer from '../NewStoreSetUp/NewStoreSetUpContainer';
import StrategyConfigurationContainer from '../StrategyConfiguration/StrategyConfigurationContainer';

const ProductStoreStatus = (props) => {
    const [activeTab, setActiveTab] = useState("ps");
    const handleTabSelect = (key) => {
        setActiveTab(key);
    }

    useEffect(() => {
        if (props?.location?.state?.storeDetails || props?.location?.state?.isNewStore) {
            setActiveTab("newstore")
        }
    }, [props?.location?.state?.storeDetails, props?.location?.state?.isNewStore])

    useEffect(()=>{
        if(props?.location?.state?.data){
            setActiveTab("strategyConfig")
        }
    },[props?.location?.state?.data])

 
    

    return (
        <div className="product__store__status__container">
            <div className="container__header">
                <h1 className="fnt-lg fnt-bold">Configuration</h1>
            </div>
            <div className="container__body">
                <div className="container__tabs">
                    <Tabs defaultActiveKey="ps" activeKey={activeTab} onSelect={handleTabSelect}>
                         <Tab eventKey="ps" title="Product Configuration">
                            {
                                activeTab === "ps" &&
                                <ProductStatus />
                            }
                        </Tab> 
                        {
                            !isCoach() &&
                            <Tab eventKey="strategyConfig" title="Strategy Configuration">
                                {
                                    activeTab === "strategyConfig" &&
                                    <StrategyConfigurationContainer storeDetails={props?.location?.state?.storeDetails} />
                                }
                            </Tab>
                        }
                        {
                            // hideToProd() &&
                            <Tab eventKey="ss" title="Store Configuration">
                                {
                                    activeTab === "ss" &&
                                    <StoreStatus />
                                }
                            </Tab>
                        }
                        {/* <Tab eventKey="cc" title="Constraints">
                            <ConstraintsConfig/>
                        </Tab> */}
                        {
                            !hideTabForClientID("newstore") &&
                            <Tab eventKey="newstore" title="New Store Setup">
                                {
                                    activeTab === "newstore" &&
                                    <NewStoreSetUpContainer storeDetails={props?.location?.state?.storeDetails} />
                                }
                            </Tab>
                        }
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default ProductStoreStatus
