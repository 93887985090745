import { takeLatest, put, call, all } from 'redux-saga/effects';
import { FETCH_LOGIN_API, fetchLoginApiSuccess, fetchLoginApiError, FETCH_JWT_AFTER_SSO_API, fetchJwtAfterSSOLoginApiSuccess, fetchJwtAfterSSOLoginApiError } from './LoginActions';

// apis
import {
  JWTAfterSSOApi,
  loginApiData
} from "../../routes/api";

// Worker Saga

function* loginApiDataFetch(action) {
  //

  try {
    const res = yield call(loginApiData, action.payload);
    if (res.data.status) {
      yield put(fetchLoginApiSuccess(res.data));
    }
    else {
      yield put(fetchLoginApiError('Error!'));
    }
  } catch (err) {
    yield put(fetchLoginApiError('Error!'));
  }
}

function* JWTAfterSSOApiDataFetch(action) {
  try {
    const res = yield call(JWTAfterSSOApi, action.payload);
    if (res.data.status) {
      yield put(fetchJwtAfterSSOLoginApiSuccess(res.data.data));
    }
    else {
      yield put(fetchJwtAfterSSOLoginApiError('Error!'));
    }
  } catch (err) {
    yield put(fetchJwtAfterSSOLoginApiError('Error!'));
  }
}

function* watchLoginApi() {
  yield takeLatest(FETCH_LOGIN_API, loginApiDataFetch);
  yield takeLatest(FETCH_JWT_AFTER_SSO_API, JWTAfterSSOApiDataFetch);
}

export function* loginSaga() {
  yield all([watchLoginApi()]);
}
