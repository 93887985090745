import axios from "axios";
import { omit } from "lodash";
 import * as Notify from "../components/Notification/Notifications"

import { BASE_URL, REFRESH_API } from "../constants/apiConstants";
import { logoutRedirect } from "../utils/commonUtilities";

class Api {
  static headers(headersparams) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorizationkey: localStorage.getItem("access_token"),
      ...headersparams,
    };
  }

  static get(route, key = null) {
    return this.request(route, null, "GET", key);
  }

  static put(route, params, key = null) {
    return this.request(route, params, "PUT", key);
  }

  static post(route, params, key) {
    return this.request(route, params, "POST", key);
  }

  static delete(route, params, key = null) {
    return this.request(route, params, "DELETE", key);
  }

  static upload(route, form, params, key) {
    return this.request(route, params, "UPLOAD", key, form);
  }

  static download(route, params, key) {
    return this.request(route, params, "DOWNLOAD", key);
  }

  static request(route, params, verb, key, form) {
    let url;
    let options;
    if (route.match("https://")) {
      url = `${route}`;
      options = { method: verb, params };
    } else {
      url = `${BASE_URL}${route}`;
      options = {
        method: verb === "UPLOAD" || verb === "DOWNLOAD" ? "POST" : verb,
        data: params,
      };
      var keys = {
        ...key,
      };
      options.headers = Api.headers(keys);
    }

    if (verb === "UPLOAD" && form) {
      return axios
        .post(url, form, {
          headers: omit(options.headers, ["Content-Type"]),
        })
        .catch((err) => {
          console.log(err)
          // if (
          //   err?.response?.status === 401 &&
          //   err?.response?.data.code === "token_not_valid"
          // ) {
          //   axios
          //     .post(REFRESH_API, {
          //       refresh: localStorage.getItem("refresh_token"),
          //     })
          //     .then((res) => {
          //       if (res?.data?.access) {
          //         localStorage.setItem("access_token", res.data.access);
          //         return axios.post(url, form, {
          //           headers: omit(options.headers, ["Content-Type"]),
          //         });
          //       } else {
          //         throw new Error("Refresh token not valid");
          //       }
          //     });
          // } else {
          //   throw new Error(
          //     "API failed with status:" +
          //       err?.response?.status +
          //       " ,code:" +
          //       err?.response?.data?.code
          //   );
          // }
        });
    } else {
      if (verb === "DOWNLOAD") {
        options = { ...options, ...{ responseType: "blob" } };
      }
      return axios(url, options).catch((err) => {
        // if (
        //   err.response.status === 401 &&
        //   err.response.data.code === "token_not_valid"
        // ) {
        //   //refresh token API call here
        //   axios
        //     .post(REFRESH_API, {
        //       refresh: localStorage.getItem("refresh_token"),
        //     })
        //     .then((res) => {
        //       if (res?.data?.access) {
        //         localStorage.setItem("access_token", res.data.access);
        //         return axios(url, options);
        //       } else {
        //         throw new Error("Refresh token not valid");
        //       }
        //     });
        // }
        // else
         if (err?.response?.status === 403) {    
          Notify.error("User access restricted. Redirecting to login", 2500);
            setTimeout(() => {
              logoutRedirect();
                //redirect to login page
            }, 2500);
          } 
          if (err?.response?.status === 524) {
            Notify.error("Timeout Error!");
            // return Promise.reject(522)
          }    
        
      });
    }
  }
}

export default Api;
