import React from "react";
import Chart from "react-apexcharts";

const LineChartStack = (props) => {
  const { data, height, width, text, stacked, xAxisLabel, yAxisLabel } = props;

  let options = {
    chart: {
      type: "area",
      stacked: stacked,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: data.colorOptions,
    // fill: {
    //     type: "solid",
    // },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      title: {
        text: xAxisLabel,
        style: {
          fontWeight: 600,
        },
      },
      categories: data.labels,
    },
    yaxis: {
      title: {
        text: yAxisLabel,
        style: {
          fontWeight: 600,
        },
      },
    },
    // Logic for years in the tooltip
    tooltip: {
      x: {
        formatter: (value, { dataPointIndex }) =>
          ` ${data.labels[dataPointIndex]} (${
            data.yearData.find(
              (item) => item.week == data.labels[dataPointIndex]
            )?.year
          })`,
      },
    },
  };

  return (
    <>
      <Chart
        options={options}
        series={data.series}
        type="area"
        height={height}
        width={width}
      />
      <div className="charts__axes">
        {" "}
        <span>
          <b>{text}</b>
        </span>{" "}
      </div>
    </>
  );
};

export default LineChartStack;
