export const GET_ALLOCATION_PLANS = "@@viewallocation/GET_ALLOCATION_PLANS";
export const GET_ALLOCATION_PLANS_SUCCESS = "@@viewallocation/GET_ALLOCATION_PLANS_SUCCESS";
export const GET_ALLOCATION_PLANS_ERROR = "@@viewallocation/GET_ALLOCATION_PLANS_ERROR";

export const RESET_ALL = "@@viewallocation/RESET_ALL";
export const RESET_STYLE_INDEX = "@@viewallocation/RESET_STYLE_INDEX";

export const getAllocationPlans = (payload) => ({
    type: GET_ALLOCATION_PLANS,
    payload,
});

export const getAllocationPlansSuccess = (payload) => ({
    type: GET_ALLOCATION_PLANS_SUCCESS,
    payload,
});

export const getAllocationPlansError = (payload) => ({
    type: GET_ALLOCATION_PLANS_ERROR,
    payload,
});

export const resetAll = () => ({
    type: RESET_ALL,
});

export const resetStyleIndex = () => ({
    type: RESET_STYLE_INDEX,
});