import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { filter, isEmpty } from 'lodash';
import * as Notify from "../../../../components/Notification/Notifications";
import Notification from "../../../../components/Notification/Notifications";
import PageLoader from "../../../../components/Loader/PageLoader";
import StrategyConfigurationTableView from './StrategyConfigurationTableView';
import * as strategyConfigActions from '../../actions/StrategyConfigActions';
import { SET_STRATEGY_CONFIGURATION } from "../../../../constants/routeConstants";
import { NETWORK_ERROR_MSG, NO_RECORDS_MSG, NO_RECORDS_HELPER_MSG } from '../../StrategyConfigConstants';
import "../../StrategyConfiguration.css";
import { rowCount } from "./StrategyConfigurationTableView"

/**
 * Container class with handler methods for Strategy Configuration Table
 */
class StrategyConfigurationTableContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleSetStrategyConfiguration = this.handleSetStrategyConfiguration.bind(this);
        this.handleRowsSelection = this.handleRowsSelection.bind(this);
    }

    handleSetStrategyConfiguration(strategyCongfigTabInst) {
        const { strategyConfigActionsProp, history, filter } = this.props;

        let request = {
            article: [...strategyCongfigTabInst.selectedFlatRows.map(row => row.original.article)],
        };

        var data = {
            ...filter,
            set_all: true
        }

        if (request.article.length === 0) {
            Notify.error("Select atleast one coloumn")
        }
        else if (request.article.length === rowCount) {
            strategyConfigActionsProp.getStrategyConfigData(data);
            history.push(`${SET_STRATEGY_CONFIGURATION}`);
        }
        else {
            strategyConfigActionsProp.getStrategyConfigData(request);
            history.push(`${SET_STRATEGY_CONFIGURATION}`);
        }
    }

    handleRowsSelection(rows) {
        const { strategyConfigActionsProp } = this.props;
        if (!isEmpty(rows)) {
            let checkedRows = {};
            rows.forEach((item) => {
                checkedRows[item.id] = true;
            });
            strategyConfigActionsProp.setRowsSelected(checkedRows);
        }
    }

    render() {
        const { strategyTableData, isStrategyConfigTableLoading, isStrategyConfigTableFailed, isStrategyConfigDeleteLoading,
            isStrategyConfigTableSuccess, tableRowsSelected, strategyConfigActionsProp, totalCount, filter, nextIndex } = this.props;
        return (
            <>

                <PageLoader loader={isStrategyConfigTableLoading || isStrategyConfigDeleteLoading} loaderMessage='Loading...'>
                    {/* {isStrategyConfigTableSuccess
                        ? strategyTableData && strategyTableData.length > 0
                            ?
                            ( */}
                    <>
                        <div className="section_container shadow-sm">
                            <StrategyConfigurationTableView strategyTableData={strategyTableData}
                                handleSetStrategyConfiguration={this.handleSetStrategyConfiguration}
                                handleRowsSelection={this.handleRowsSelection}
                                handleFilterRequest={this.props.handleFilterRequest}
                                filterClicked={this.props.filterClicked}
                                tableRowsSelected={tableRowsSelected}
                                strategyConfigActionsProp={strategyConfigActionsProp}
                                totalCount={totalCount}
                                filter={filter}
                                nextIndex={nextIndex}
                            />
                        </div>
                    </>
                    {/* )
                            :
                            (
                                <div className="empty">{NO_RECORDS_MSG}
                                    <p>{NO_RECORDS_HELPER_MSG}</p>
                                </div>
                            )
                        : (isStrategyConfigTableFailed ? <div className="error">{NETWORK_ERROR_MSG}</div> : null)} */}
                </PageLoader>
            </>
        );
    }
}

const mapStateToProps = ({ strategyConfiguration }) => ({
    strategyTableData: strategyConfiguration.strategyTableData,
    isStrategyConfigTableLoading: strategyConfiguration.isStrategyConfigTableLoading,
    isStrategyConfigTableFailed: strategyConfiguration.isStrategyConfigTableFailed,
    isStrategyConfigTableSuccess: strategyConfiguration.isStrategyConfigTableSuccess,
    totalCount: strategyConfiguration.totalCount,
    nextIndex: strategyConfiguration.nextIndex,
    tableRowsSelected: strategyConfiguration.tableRowsSelected,
    isStrategyConfigDeleteLoading: strategyConfiguration.isStrategyConfigDeleteLoading,
});

const mapDispatchToProps = (dispatch) => ({
    strategyConfigActionsProp: bindActionCreators(strategyConfigActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StrategyConfigurationTableContainer);