import DailySummaryReducer from "./DailySummaryReducers";
import StoreViewReducer from "./StoreView/Reducers/StoreViewReducer";

const rootReducer = {
    ...DailySummaryReducer,
    ...StoreViewReducer
}

const dailySummaryReducers = rootReducer

export default dailySummaryReducers