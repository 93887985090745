import { takeLatest, put, all } from "redux-saga/effects";
import { demoActionSuccess, DEMO_ACTION } from "./actions";

function* demoWorkerSaga(action) {
  yield put(demoActionSuccess());
}

function* demoWatcherSaga() {
  yield takeLatest(DEMO_ACTION, demoWorkerSaga);
}

export function* demoSaga() {
  yield all([demoWatcherSaga()]);
}
