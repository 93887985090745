import React from 'react'
import MultiSelect from '../../components/Filters/MultiSelect'
import Select from '../../components/Filters/SingleSelect'
import UserManagementSearchFilter from './UserManagementSearchFilter'

function UserManagementFilter(props) {
    return (
        props.data.map(val =>
        (
            <div className={val.isRequired ? 'required col-md-2 my-sm-1 ' : 'col-md-2 my-sm-1 '} key={val.id}>

                {
                    val.label?
                    (<label className="fnt-md fnt-bold">{val.label}</label>):
                    ('')
                }
                {!val.isMultiSelect ?
                    (
                        <Select
                            {...val}
                        />) : (
                        <MultiSelect
                            {...val}
                        />
                    )
                }

            </div>
        )
        )
    )
}

export default UserManagementFilter
