import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SalesView from './SalesView';
import EditRadioSection from './EditRadioSection';
import { SAVE_BUTTON, REVIEW_BUTTON, DRIVERS_TABLE_HEADER } from './AdaVisualConstants';
import ConfirmationAlert from '../../components/ConfirmationAlert/ConfirmationAlert';
import * as Notify from "../../components/Notification/Notifications";
import { REVIEW_FAILED_MSG, REVIEW_SUCCESS_MSG, SAVE_SUCCESS_MSG, SAVE_FAILED_MSG, SAVE_WARNING_MSG } from './AdaVisualConstants';
import * as adaVisualActions from './actions/AdaVisualActions';
import "primereact/resources/primereact.css";
import "./AdaVisual.css";

/**
 * Ada Visual View component
 * @returns Sales Graph View, Forecasts Driver, Adjustment, Hierarchy tabular view.
 */
const AdaVisualView = (props) => {
    const { handleReviewRequest, handleSaveRequest , isReviewReq, isReviewSuccess, isReviewFailed, isSaveReq, isSaveSuccess, isSaveFailed, isAdaDataLoading, isHierarchyModified, isHierarchyTable} = props;
    const [isShowAlert, setIsShowAlert] = useState(false);

    const handleAlertOk = () => {
        handleSaveRequest()
        setIsShowAlert(false)
      }
    
      const handleAlertCancel = () => {
        setIsShowAlert(false)
      }
    
      const handleAlertClose = () => {
        setIsShowAlert(false)
      }

      useEffect(() => {
        if (!isReviewReq && !isReviewFailed && isReviewSuccess && !isSaveReq && !isSaveFailed && !isAdaDataLoading) {
            Notify.success(REVIEW_SUCCESS_MSG);
            Notify.error(SAVE_WARNING_MSG);
        } else if (!isReviewReq && isReviewFailed && !isReviewSuccess && !isSaveReq && !isSaveFailed && !isAdaDataLoading) {
            Notify.error(REVIEW_FAILED_MSG);
        } else if (!isSaveReq && isSaveSuccess && !isSaveFailed & !isAdaDataLoading) {
            Notify.success(SAVE_SUCCESS_MSG);
        } else if (!isSaveReq && !isSaveSuccess && isSaveFailed && !isAdaDataLoading && !isHierarchyModified) {
            Notify.error(SAVE_FAILED_MSG);
        } else if (!isSaveReq && !isSaveSuccess && isSaveFailed && !isAdaDataLoading && isHierarchyTable) {
            Notify.error(SAVE_FAILED_MSG);
        }
        else {

        }
      }, [isReviewReq, isReviewSuccess, isReviewFailed, isSaveReq, isSaveSuccess, isSaveFailed, isAdaDataLoading, isHierarchyModified, isHierarchyTable])

    return (
        <React.Fragment>
            
            <div className="drivers_container shadow-sm">
                <SalesView />
            </div>
            {isShowAlert && <ConfirmationAlert
                modalStyle={{ width: '45rem' }}
                message="The adjustments you are saving will be used for allocation recommendations !"
                handleOk={handleAlertOk}
                handleCancel={handleAlertCancel}
                handleClose={handleAlertClose} />}
            <div>
                <EditRadioSection />
                <span style={{ display: "flex", justifyContent: "center" }}>
                    <button
                        className="btn btn-primary"
                        style={{ padding: "2px" }}
                        onClick={handleReviewRequest}
                    >
                        {REVIEW_BUTTON}
                    </button>
                    <button
                        className="btn btn-primary"
                        style={{ marginLeft: "4px", padding: "2px" }}
                        onClick={() => setIsShowAlert(true)}
                    >
                        {SAVE_BUTTON}
                    </button>
                </span>
            </div>
            
                
        </React.Fragment >
    )
}
const mapStateToProps = ({ adaVisual }) => ({
    isReviewReq: adaVisual.isReviewReq,
    isReviewFailed: adaVisual.isReviewFailed,
    isReviewSuccess: adaVisual.isReviewSuccess,
    isForecastModified: adaVisual.isForecastModified,
    isHierarchyModified: adaVisual.isHierarchyModified,
    isSaveReq: adaVisual.isSaveReq,
    isSaveSuccess: adaVisual.isSaveSuccess,
    isSaveFailed: adaVisual.isSaveFailed,
    isHierarchyTable: adaVisual.isHierarchyTable,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdaVisualView);