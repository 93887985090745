import * as strategyConfigActions from './actionTypes';

/**
 * Function to dispatch strategy configuration filters loading request
 * 
 */
export const getStrategyConfigFiltersData = (payload, filterType) => ({
    type: strategyConfigActions.GET_STRATEGY_CONFIG_FILTER_REQUEST,
    payload,
    filterType,
});

/**
 * Function to dispatch strategy configuration table data loading request
 * 
 */
export const getStrategyConfigTableDetails = (payload) => ({
    type: strategyConfigActions.GET_STRATEGY_CONFIG_TABLE_REQUEST,
    payload,
});


/**
 * Function to dispatch strategy configuration data loading request
 */
export const getStrategyConfigData = (payload) => ({
    type: strategyConfigActions.GET_STRATEGY_CONFIG_DATA_REQUEST,
    payload,
});

/**
 * Function to dispatch selected rows
 * @param {*} payload 
 * @returns 
 */
export const setRowsSelected = (payload) => ({
    type: strategyConfigActions.SET_ROWS_SELECTED,
    payload,
});

/**
 * Function to dispatch updated strategy configuration data request
 * 
 */
export const setEditedStrategyData = (payload) => ({
    type: strategyConfigActions.SET_EDITED_STRATEGY,
    payload,
});

/**
 * Function to dispatch strategy configuration data saving request
 * 
 */
export const saveStrategyConfigData = (payload) => ({
    type: strategyConfigActions.SAVE_STRATEGY_CONFIG_DATA_REQUEST,
    payload,
});

/**
 * Function to dispatch delete strategy configuration
 * 
 */
 export const deleteStrategyConfigData = (payload) => ({
    type: strategyConfigActions.DELETE_STRATEGY_CONFIG,
    payload,
});

/**
 * Action function reset strategy configuration data
 * @returns 
 */
export const resetStrategyConfigScreen = () => ({
    type: strategyConfigActions.RESET_STRATEGY_CONFIG,
});

export const filterClicked = () => ({
    type: strategyConfigActions.FILTER_CLICKED,
});

export const resetRowsSelected = () => ({
    type: strategyConfigActions.RESET_ROWS_SELECTED,
});

/**
 * Action function reset save strategy data
 * @returns 
 */
export const resetSaveStrategyScreen = () => ({
    type: strategyConfigActions.RESET_SAVE_STRATEGIES,
});
