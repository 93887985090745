import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PageLoader from "../../../../components/Loader/PageLoader";
import DCSourcingView from "./DCSourcingView";
import * as storeConfigDetailsActions from '../../actions/StoreDetailsConfigActions/StoreConfigActions';
import * as StoreDetailsConsts from './StoreDetailsDcConfigConstants';
import "./Storedetailsconfig.css";

/**
 * DC Sourcring Container with lifecycle and handler methods
 */
class DCSourcingContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { storeConfigDetailsActionsProp, dcSourceData, isDcSourceDataLoading, isDcSourceDataFailed,
            isDcSourceDataSuccess, dcSourceRowsSelected, selectedSisterStoreCode, setDcSrcRowsSelection } = this.props;

        let content = isDcSourceDataSuccess ?
            (dcSourceData && dcSourceData.length > 0)
                ?
                <DCSourcingView dcSourceData={dcSourceData} dcSourceRowsSelected={dcSourceRowsSelected}
                    selectedSisterStoreCode={selectedSisterStoreCode} storeConfigDetailsActionsProp={storeConfigDetailsActionsProp}
                    setDcSrcRowsSelection={setDcSrcRowsSelection} />
                : (isDcSourceDataFailed ?
                    <div className="error">{StoreDetailsConsts.DC_SOURCING_NETWORK_ERROR_MSG}</div>
                    :
                    (<div className="empty">{StoreDetailsConsts.DC_SOURCING_NO_RECORDS_MSG}
                        <p>{StoreDetailsConsts.DC_SOURCING_NO_RECORDS_HELPER_MSG}</p>
                    </div>)
                )
            : null;

        return (
            <PageLoader loader={isDcSourceDataLoading} loaderMessage='Loading...'>
                {content ?
                    <div className="section_container shadow-sm">
                        {content}
                    </div>
                    : null
                }
            </PageLoader>
        );

    }
}

const mapStateToProps = ({ newStoreDetailConfig }) => ({
    dcSourceData: newStoreDetailConfig.dcSourceData,
    isDcSourceDataLoading: newStoreDetailConfig.isDcSourceDataLoading,
    isDcSourceDataFailed: newStoreDetailConfig.isDcSourceDataFailed,
    isDcSourceDataSuccess: newStoreDetailConfig.isDcSourceDataSuccess,
    dcSourceRowsSelected: newStoreDetailConfig.dcSourceRowsSelected,
    selectedSisterStoreCode: newStoreDetailConfig.selectedSisterStoreCode,
});

const mapDispatchToProps = (dispatch) => ({
    storeConfigDetailsActionsProp: bindActionCreators(storeConfigDetailsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DCSourcingContainer);