import { cloneDeep, isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import PageLoader from "../../../components/Loader/PageLoader"
import SetAllTable from "../../../components/SetAllTable/SetAllTable"
import InputCell from "../../../components/Table/Cellrenderer/InputCell";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import { ERROR_MSG } from "../../../constants/globalConstant";
import { updateArticleName } from "../../../utils/filterLevelMapping";
import { getPacksForBulkEdit, updatePacksEachesForMultipleStores } from "../FinalizeAction";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const BulkEditPacksAndEaches = (props) => {

    const [showModal, setShowModal] = useState(false)
    const [column, setColumn] = useState([])
    const [data, setData] = useState([])
    const [allocated, setAllocated] = useState({})
    const [available, setAvailable] = useState({})
    const [dcCode, setDcode] = useState(null)
    const [DCStoreMapping, setDCMapping] = useState({})

    const {packConfig, selectedFlatRows, buttonLabel, sizes, packsAvailable, eachesAvailable, article, allocationCode, allocationCodeAfterUpdate} = props
    

    useEffect(() => {
        if(buttonLabel === "Packs" && selectedFlatRows?.length) {
            let selectedStores = selectedFlatRows?.map(val => val.values.store_code)
            props.getPacksForBulkEdit({"allocation_code":allocationCodeAfterUpdate,"article":article,"store":selectedStores})
        }
        setDcode(selectedFlatRows[0]?.original?.dc_code)
    },[buttonLabel,selectedFlatRows])

    useEffect(() => {
        if(!isEmpty(selectedFlatRows)) {
            let column = [], l_data= {}, packsAvailablePerStore = 0, eachesAvailablePerStore = {}, no_of_stores = selectedFlatRows.length, eachesAvailablePerStorePerDC = {}
            
            if(buttonLabel === "Packs") {
                if(props?.packDataForBulkUpdate?.data?.length && props?.packDataForBulkUpdate?.pack_sizes?.length) {
                    const { data, pack_sizes } = props?.packDataForBulkUpdate
                    
                                    // packsAvailablePerStore = Number(packsAvailable) || 0;
                                    // console.log(props.packDataForBulkUpdate,'packDataForBulkUpdate')
                                    
                                    // selectedFlatRows.forEach(val => {
                                    //     packsAvailablePerStore+= (Number(val.values?.['packs_allocated']) || 0)  
                                    // })
                                    
                                    // packsAvailablePerStore = Math.floor(packsAvailablePerStore/no_of_stores)
                    
                                    let sizes_column = pack_sizes?.map(val => {
                                        return {
                                            Header:'Pack Configuration',
                                            columns: [{
                                                Header: val,
                                                accessor: val
                                            }]
                                        }
                                    })
                    
                                    column.push({
                                        Header: 'Packs per store',
                                        accessor: 'packs_allocated',
                                        Cell: (instance) => (
                                        <InputCell {...instance} style={{ textAlign: "center" }} 
                                                min={0} type="number" step={1} max={instance?.row?.original?.packs_available}
                                                changeHandler={(rowIdx, columnId, values) => {
                                                    let l_available_units = Number(instance?.row?.original?.packs_available) - Number(values)
                                                    let l_available_in_req = Number(instance?.row?.original?.total_available) - (Number(values) * Number(no_of_stores))
                                                    instance.updateMyData(rowIdx, 'pack_available_per_store', l_available_units);
                                                    setAllocated((old) => {
                                                        return {
                                                            ...old,
                                                            [instance.row.original['pack_id']]: values,
                                                        }
                                                    })
                    
                                                    setAvailable((old) => {
                                                        return {
                                                            ...old,
                                                            [instance.row.original['pack_id']]: l_available_in_req,
                                                        //    'available': l_available_units,
                                                        }
                                                    })
                                                }}
                                        /> 
                                        ),
                                    },
                                    {
                                        Header: 'Packs avaialble per store',
                                        accessor: 'pack_available_per_store'
                                    })
                    
                                    column.push({
                                        Header: 'Pack Id',
                                        accessor: 'pack_id',
                                    },
                                    ...sizes_column
                                    )
                    
                                    // let packConfigCopy = {...packConfig} 
                                    // packConfigCopy['packs_available'] = packsAvailablePerStore
                                    // packConfigCopy['packs_allocated'] = null
                                    // packConfigCopy['pack_id'] = selectedFlatRows[0]?.values?.['packs']
                                    // data = [{...packConfigCopy}]
                                    let prep_data = data?.map(val => {
                                        return {
                                            "packs_available": Math.floor((val.pack_available|| 0)/no_of_stores),
                                            "total_available": val.pack_available,
                                            "pack_available_per_store": val.pack_available_per_store,
                                            "pack_id": val.pack_id,
                                            "packs_allocated": null,
                                            ...val?.pack_config
                                        }
                                    })
                                    l_data = prep_data
                }

            }
            else {
                
                // let l_dcCode = selectedFlatRows[0]?.original?.dc_code 
                eachesAvailablePerStorePerDC = cloneDeep(eachesAvailable) || {};
                eachesAvailablePerStore = eachesAvailablePerStorePerDC?.[dcCode] || eachesAvailablePerStorePerDC;

                // let l_sizes = Object.keys(eachesAvailable)
                let dcNames = Object.keys(eachesAvailable)

                let DCWiseStoreMapping = {}
                let eachesAvailablePerDCSize = {}

                //Mapped stores DC wise
                dcNames.forEach(dc => {
                    eachesAvailablePerDCSize[dc] = {}
                })
                selectedFlatRows.forEach(row => {
                    let rowData = row.original
                    Object.keys(rowData?.dc_available_size_final)?.forEach(dc => {
                        DCWiseStoreMapping[dc] = DCWiseStoreMapping[dc] ? [...DCWiseStoreMapping[dc], rowData?.store_code] : [rowData?.store_code]
                        sizes.forEach((s, index) => {
                            if(Object.keys(eachesAvailablePerDCSize[dc]).includes(s)) {
                                eachesAvailablePerDCSize[dc][s] += (Number(rowData?.size_value?.[dc]?.[index]) || 0)
                            }
                            else {
                                eachesAvailablePerDCSize[dc][s] = (Number(eachesAvailable[dc]?.[s]) || 0) + (Number(rowData?.size_value?.[dc]?.[index]) || 0)
                            }
                        })

                    })
                })

                setDCMapping(DCWiseStoreMapping)

                //Eaches available per store by DC size level
                // selectedFlatRows.forEach(row => {
                //     let rowData = row.original
                //     sizes.forEach((s, index) => {
                //         dcNames.forEach(dc => {
                //             if(Object.keys(eachesAvailablePerDCSize[dc]).includes(s)) {
                //                 eachesAvailablePerDCSize[dc][s] += (Number(rowData?.size_value?.[dc]?.[index]) || 0)
                //             }
                //             else {
                //                 eachesAvailablePerDCSize[dc][s] = (Number(eachesAvailable[dc]?.[s]) || 0) + (Number(rowData?.size_value?.[dc]?.[index]) || 0)
                //             }
                //         })
                //     })
                // })
                Object.keys(eachesAvailablePerDCSize).forEach(dc => {
                    Object.keys(eachesAvailablePerDCSize[dc]).forEach(s => {
                        eachesAvailablePerDCSize[dc][s] /= DCWiseStoreMapping[dc]?.length
                    })
                })


                // selectedFlatRows.forEach((val) => {
                //     l_sizes.forEach((s) => {
                //         eachesAvailablePerStore[s] = (Number(eachesAvailablePerStore[s]) || 0) + (Number(val.values[s]) || 0)
                //     })
                // });

                // Object.keys(eachesAvailablePerStore).forEach(val => {
                //     eachesAvailablePerStore[val] = Math.floor(Number(eachesAvailablePerStore[val])/no_of_stores)
                // })
                // let eachesAvailablePerDCPerSize = {}
                // Object.keys(eachesAvailable).forEach(dc => {
                //     eachesAvailablePerDCPerSize[dc] = {}
                //     sizes.forEach(s => {
                //         eachesAvailablePerDCPerSize[dc][s] = Math.floor(Number(eachesAvailablePerStore[s])/no_of_stores)
                //     })
                // })
                // let eachesAvailablePerStorePerSize = cloneDeep(eachesAvailablePerDCPerSize)

                column = sizes?.map(val => {
                    return {
                        Header: val,
                        accessor: val,
                        Cell: (instance) => (
                            instance.row?.original?.['id'] === 'edit' ? (
                            <InputCell {...instance} style={{ textAlign: "center" }} 
                                min={0} type="number" step={1} 
                                // max={eachesAvailable?.[instance?.row?.original?.dc]?.[val]}
                                max={Math.floor(eachesAvailablePerDCSize?.[instance?.row?.original?.dc]?.[val])}
                                changeHandler={(rowIdx, columnId, values) => {
                                    // let l_available_units = eachesAvailablePerStore[val] - Number(values)
                                    // let l_available_units = eachesAvailable?.[instance?.row?.original?.dc]?.[val] - Number(values)
                                    let l_available_units = Math.floor(eachesAvailablePerDCSize?.[instance?.row?.original?.dc]?.[val] - Number(values))
                                    instance.updateMyData(rowIdx+1, instance?.row?.original?.dc+val, l_available_units);

                                    setAllocated((old) => {
                                        return {
                                            ...old,
                                            [instance?.row?.original?.dc] : {...old[instance?.row?.original?.dc], [columnId] : values},
                                            // [val]: values,
                                        }
                                    })

                                    setAvailable((old) => {
                                        return {
                                            // ...old,
                                            // [val]: l_available_units,
                                            ...old,
                                            [instance?.row?.original?.dc]: {...old[instance?.row?.original?.dc], [columnId] : l_available_units}, 
                                            // [dcCode] : {[columnId] : l_available_units}, 
                                        }
                                    })
                                }}
                            /> 
                        ): (
                            <div>
                                {/* {instance?.row?.original?.[val]} */}
                                {instance?.row?.original?.[instance?.row?.original?.dc+val] >= 0 ? instance?.row?.original?.[instance?.row?.original?.dc+val]  : Math.floor(eachesAvailablePerDCSize[instance?.row?.original?.dc]?.[val])}
                            </div>
                        )),
                    }
                })

                column.unshift({
                    Header: ' ',
                    accessor: 'label'
                })
                column.unshift({
                    Header: 'DC',
                    accessor: 'dc',
                    Cell: (instance) => (instance?.row?.original?.id == "edit" ? <td className="row-span2__column">{instance.value}</td> : <td className="row-span2__column border-bottom__td"></td>),
                    width: 200,
                    rowSpan: 2,
                })   
                l_data = []
                for(let dc in DCWiseStoreMapping) { 
                    l_data.push({id:'edit', label:'Eaches per store', dc},{dc, id:'view', label:'Eaches available per store', ...eachesAvailablePerDCSize})
                }
                // l_data = [{id:'edit', label:'Eaches per store'},{id:'view', label:'Eaches available per store', ...eachesAvailablePerStore}]
            }
            setColumn(column)
            setData(l_data)
        }

    },[packConfig,selectedFlatRows,buttonLabel,sizes,packsAvailable,eachesAvailable,props.packDataForBulkUpdate,dcCode])

    useEffect(() => {
        if(props.buttonClicked) {
            setShowModal(true);
        }
    }, [props.buttonClicked])

    const handleSetAllOk = () => {
        let packId = selectedFlatRows[0]?.values?.['packs']
        let selectedStores = selectedFlatRows?.map(val => val.values.store_code)
        let req = {}
        if(buttonLabel === "Packs") {
           let stores = []
           selectedStores.forEach(val => {
               stores.push({
                   "store": val,
                   "updated_packs": {
                        // [packId] : allocated.packs_allocated
                        ...allocated
                    }
               })
           })
           req = {
                "allocation_code": allocationCodeAfterUpdate,
                "available_packs" : {
                    // [packId] : available.available
                    ...available
                },
                "article" : article,
                stores
           }
        }
        else {
            let stores = []
            selectedStores.forEach(val => {
                stores.push({
                    "store": val,
                    "updated_eaches": {
                        ...allocated
                     }
                })
            })
            req = {
                 "allocation_code": allocationCodeAfterUpdate,
                 "available_eaches" : {
                      ...available
                 },
                 "article" : article,
                 stores
            }

            selectedFlatRows.forEach((store, stIndex) => {
                for(let dc in eachesAvailable) {
                    if(!req.stores[stIndex]["updated_eaches"].hasOwnProperty(dc)){
                        req.stores[stIndex]["updated_eaches"][dc] = {}
                        sizes.forEach((s, index) => {
                            req.stores[stIndex]["updated_eaches"][dc][s] = store.original?.size_value?.[dc]?.[index] || 0
                        })
                    }
                }
            })
            Object.keys(eachesAvailable)?.forEach(dc => {
                req.stores?.forEach(storeObj => {
                    if(!DCStoreMapping[dc]?.includes(storeObj.store)) {
                        delete storeObj.updated_eaches[dc]
                    }
                })
            })
        }

        let { originalAllocationCode } = props
        let l_original_allocation_code = allocationCodeAfterUpdate === originalAllocationCode ? "" : originalAllocationCode


        req["original_allocation_code"] = l_original_allocation_code
        if(props?.articles?.length) {
            req["articles"] = props?.articles
        }

        for(let dc in eachesAvailable) {
            if(!req["available_eaches"].hasOwnProperty(dc)){
                req["available_eaches"][dc] = eachesAvailable[dc]
            }
        }
  
        props.updatePacksEachesForMultipleStores(req)
        props.resetButtonClick();
    }
    
    const handleSetAllCancel = () => {
        props.resetButtonClick();
        setShowModal(false);
    }

    const handleClose = () => {
        props.resetButtonClick();
        showModal && setShowModal(false);
    }

      
    return (
        showModal &&
        <div>
            <SetAllTable 
                handleOk={handleSetAllOk} 
                handleCancel={handleSetAllCancel} 
                handleClose={() => handleClose()} 
                heading= {`Bulk Edit ${buttonLabel}`}
                ignoreCloseOnSave={true}
            >
                <div className="mx-2 pr-4 pl-4 row justify-content-center mb-4">
                    <div className="detail__div mr-4">
                        <span className="fnt-bold mr-2">Number of Stores :</span>
                        <span>{selectedFlatRows.length}</span>
                    </div>

                    <div className="detail__div mr-4">
                        <span className="fnt-bold mr-2">{updateArticleName(false)} : </span>
                        <span>{article}</span>
                    </div>
                </div>
                <div className="mx-2 p-2 row justify-content-center">
                    <PageLoader loader={props.packsForBulkUpdateLoading} gridLoader={true}>
                        {props.packForBulkUpdateError ? (
                        <div className="error">{ERROR_MSG}</div>
                        ) : (
                        data.length && <ReactTableWithPlugins
                            hideColumnsFilter
                            style={{ maxWidth: "100%", margin: "auto", display: "flex", justifyContent: "center" }}
                            data={data}
                            columns={column}
                            renderMarkup="TableMarkup"
                        />
                        )}
                    </PageLoader>
                </div>
            </SetAllTable>
        </div>
    )
}

const mapStateToProps = ({ finalize }) => {
    return {
        packsForBulkUpdateLoading: finalize.packsForBulkUpdateLoading,
        packDataForBulkUpdate: finalize.packDataForBulkUpdate,
        packForBulkUpdateError: finalize.packForBulkUpdateError,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updatePacksEachesForMultipleStores: (payload) => dispatch(updatePacksEachesForMultipleStores(payload)),
    getPacksForBulkEdit: (payload) => dispatch(getPacksForBulkEdit(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BulkEditPacksAndEaches)
