const TableAction = {
  DELETE_ROW: "DELETE_ROW",
  SET_SELECTED_COMMON_FILTER: "SET_SELECTED_COMMON_FILTER",
};

export default function StateReducer(newState, action, prevState, ins) {
  const { type, rowId } = action;
  switch (type) {
    case TableAction.DELETE_ROW:
      const { data } = newState;
      let filterData = data.filter((row) => {
        return data[rowId] !== row[rowId];
      });

      return {
        ...newState,
        data: filterData,
      };

    case TableAction.SET_SELECTED_COMMON_FILTER:
      return {
        ...newState,
        setSelectedFilter: action.payload,
      };

    default:
      return { ...newState };
  }
}
