import React from 'react'
import './Card.css'

const Card = ({title, value, style}) => {

    return (
        <div className="card__container" style={style}>
            <h4>{title}</h4>
            <span>{value}</span>
        </div>
    )
}

export default React.memo(Card);
