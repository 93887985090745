import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { FETCH_FILTER_SUCCESS, FETCH_TABLE_DATA, FETCH_TABLE_DATA_ERROR, FETCH_TABLE_DATA_SUCCESS, GENERATE_EXCEL, GENERATE_EXCEL_ERROR, GENERATE_EXCEL_SUCCESS, RESET_ALL, RESET_FILTERS_DATA, RESET_DOWNLOAD_EXCEL } from "./DeepDiveAction";

export const deepDiveState = {
  tableData: [],
  success: false,
  error: false,
  loading: false,
  excelLoading: false,
  departmentOptions: [],
  genderOptions: [],
  subCatOptions: [],
  dcsOptions: [],
  level5Options: [],
  level6Options: [],
  level7Options: [],
  styleOptions: [],
  colorOptions: [],
  storeIdOptions: [],
  articleIdOptions: [],
  seasonCodeOptions: [],
  seasonCodeOpenOptions: [],
  filterlabels: {},
  errorMessage: '',
  cardData: [],
  totalCount: 0,
  nextIndex: 0,
  excelData: [],
  excelError: false,
  excelSuccess: true,
  totalCountExcel: 0,
  nextIndexExcel: 0,
};

export default {
  deepDive: handleActions(
    {
      [FETCH_FILTER_SUCCESS]: (state, { payload }) => {
        console.log('ss',payload.key)
        switch (payload.key) {
          case "l1":
            return immutable(state, {
              departmentOptions: {
                $set:
                  payload && payload.data.departmentOptions
                    ? payload.data.departmentOptions
                    : state.departmentOptions,
              },
              genderOptions: [],
              subCatOptions: [],
              dcsOptions: [],
              level5Options: [],
              level6Options: [],
              level7Options: [],
              colorOptions: [],
              articleIdOptions: [],
              styleOptions: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l2":
            return immutable(state, {
              genderOptions: {
                $set:
                  payload && payload.data.genderOptions
                    ? payload.data.genderOptions
                    : state.genderOptions,
              },
              subCatOptions: {
                $set:
                  payload && payload.data.subCatOptions
                    ? payload.data.subCatOptions
                    : state.subCatOptions,
              },
              dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l3":
            return immutable(state, {
              subCatOptions: {
                $set:
                  payload && payload.data.subCatOptions
                    ? payload.data.subCatOptions
                    : state.subCatOptions,
              },
              dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l4":
            return immutable(state, {
              dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l10":
            return immutable(state, {
              genderOptions: {
                $set:
                  payload && payload.data.genderOptions
                    ? payload.data.genderOptions
                    : state.genderOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l11":
              return immutable(state, {
                subCatOptions: {
                  $set:
                    payload && payload.data.subCatOptions
                      ? payload.data.subCatOptions
                      : state.subCatOptions,
                },
                dcsOptions: {
                  $set:
                    payload && payload.data.dcsOptions
                      ? payload.data.dcsOptions
                      : state.dcsOptions,
                },
                filterlabels: {
                  $set:
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping
                }
              });
          case "l8":
            return immutable(state, {
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l9":
            return immutable(state, {
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l12":
            return immutable(state, {
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l13":
              return immutable(state, {
                colorOptions: {
                  $set:
                    payload && payload.data.colorOptions
                      ? payload.data.colorOptions
                      : state.colorOptions,
                },
                articleIdOptions: {
                  $set:
                    payload && payload.data.articleIdOptions
                      ? payload.data.articleIdOptions
                      : state.articleIdOptions,
                },
                styleOptions: {
                  $set:
                    payload && payload.data.styleOptions
                      ? payload.data.styleOptions
                      : state.styleOptions,
                },
                storeIdOptions: {
                  $set:
                    payload && payload.data.storeIdOptions
                      ? payload.data.storeIdOptions
                      : state.storeIdOptions,
                },
                seasonCodeOptions: {
                  $set:
                    payload && payload.data.seasonCodeOptions
                      ? payload.data.seasonCodeOptions
                      : state.seasonCodeOptions,
                },
                seasonCodeOpenOptions: {
                  $set:
                    payload && payload.data.seasonCodeOpenOptions
                      ? payload.data.seasonCodeOpenOptions
                      : state.seasonCodeOpenOptions,
                },
                filterlabels: {
                  $set:
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping
                }
              });
           
          case "l14":
            return immutable(state, {
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
             
          case "l5":
            return immutable(state, {
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l6":
            return immutable(state, {
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l7":
            return immutable(state, {
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          default:
            return immutable(state, {
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
        };
      },
      [RESET_FILTERS_DATA]: (state, { payload }) => {
        return immutable(state, {
          tableData: { $set: [] },
          genderOptions: { $set: [] },
          subCatOptions: { $set: [] },
          dcsOptions: { $set: [] },
          level5Options:{$set:[]},
          level6Options:{$set:[]},
          level7Options:{$set:[]},
          styleOptions: { $set: [] },
          colorOptions: { $set: [] },
          storeIdOptions: { $set: [] },
          articleIdOptions: { $set: [] },
          seasonCodeOptions: { $set: [] },
          seasonCodeOpenOptions: { $set: [] },
          error: { $set: false },
          success: { $set: false },
          loading: { $set: false },
          excelLoading: { $set: false},
          errorMessage: { $set: '' },
          cardData: { $set: [] },
          totalCount: { $set: 0 },
          nextIndex: { $set: 0 },
          excelData: { $set: [] },
          excelError: { $set: false },
          excelSuccess: { $set: false },
          totalCountExcel: { $set: 0 },
          nextIndexExcel: { $set: 0 },
        });
      },
      [FETCH_TABLE_DATA]: (state, { payload }) => {
        return immutable(state, {
          success: { $set: false },
          error: { $set: false },
          loading: { $set: true },
          errorMessage: { $set: '' },
          totalCount: { $set: 0 },
          nextIndex: { $set: 0 },
        });
      },
      [FETCH_TABLE_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          tableData: { $set: [] },
          loading: { $set: false },
          error: { $set: true },
          success: { $set: false },
          errorMessage: { $set: payload.error },
          cardData: { $set: [] },
        });
      },
      [FETCH_TABLE_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          tableData: { $set: (payload && payload.data) || [] },
          loading: { $set: false },
          error: { $set: false },
          success: { $set: true },
          errorMessage: { $set: '' },
          cardData: { $set: (payload?.refresh_aggregate_data ? payload?.cardData : state.cardData) },
          totalCount: { $set: (payload && payload.totalCount) || 0 },
          nextIndex: { $set: (payload && payload.nextIndex) || 0 }
        });
      },
      [GENERATE_EXCEL]: (state, { payload }) => {
        return immutable(state, {
          excelSuccess: { $set: false },
          excelError: { $set: false },
          excelLoading: { $set: true },
        });
      },
      [GENERATE_EXCEL_ERROR]: (state, { payload }) => {
        return immutable(state, {
          excelData: { $set: [] },
          excelLoading: { $set: false },
          excelError: { $set: true },
          excelSuccess: { $set: false },
          totalCountExcel: { $set: 0 },
          nextIndexExcel: { $set: 0 },
        });
      },
      [GENERATE_EXCEL_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          excelData: { $set: (payload && payload.data) || [] },
          excelLoading: { $set: false },
          excelError: { $set: false },
          excelSuccess: { $set: true },
          totalCountExcel: { $set: (payload && payload.totalCountExcel) || 0 },
          nextIndexExcel: { $set: (payload && payload.nextIndexExcel) || 0 }
        });
      },
      [RESET_DOWNLOAD_EXCEL]: (state, { payload }) => {
        return immutable(state, {
          excelData: { $set: [] },
          nextIndexExcel: { $set: 0 },
          totalCountExcel: { $set: 0 },
          excelLoading: { $set: false },
          excelError: { $set: false }
        });
      },
    },
    deepDiveState
  ),
};


