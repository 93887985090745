export const FETCH_USER_CREATED_DATA = "@@PRODUCTPROFILE/FETCH_USER_CREATED_DATA";
export const FETCH_USER_CREATED_DATA_SUCCESS = "@@PRODUCTPROFILE/FETCH_USER_CREATED_DATA_SUCCESS";
export const FETCH_USER_CREATED_DATA_ERROR = "@@PRODUCTPROFILE/FETCH_USER_CREATED_DATA_ERROR";

export const FETCH_STORES_DATA = "@@PRODUCTPROFILE/FETCH_STORES_DATA";
export const FETCH_STORES_DATA_SUCCESS = "@@PRODUCTPROFILE/FETCH_STORES_DATA_SUCCESS";
export const FETCH_STORES_DATA_ERROR = "@@PRODUCTPROFILE/FETCH_STORES_DATA_ERROR";

export const FETCH_FILTER_DATA = "@@PRODUCTPROFILE/FETCH_FILTER_DATA";
export const FETCH_FILTER_SUCCESS = "@@PRODUCTPROFILE/FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "@@PRODUCTPROFILE/FETCH_FILTER_ERROR";


export const FETCH_CREATEFILTER_DATA = "@@PRODUCTPROFILE/FETCH_CREATEFILTER_DATA";
export const FETCH_CREATEFILTER_DATA_SUCCESS = "@@PRODUCTPROFILE/FETCH_CREATEFILTER_DATA_SUCCESS";
export const FETCH_CREATEFILTER_DATA_ERROR = "@@PRODUCTPROFILE/FETCH_CREATEFILTER_DATA_ERROR";

export const FETCH_PRODUCT_LIST_CONTRIBUTION_DATA = "@@PRODUCTPROFILE/FETCH_PRODUCT_LIST_CONTRIBUTION_DATA";
export const FETCH_PRODUCT_LIST_CONTRIBUTION_SUCCESS = "@@PRODUCTPROFILE/FETCH_PRODUCT_LIST_CONTRIBUTION_SUCCESS";
export const FETCH_PRODUCT_LIST_CONTRIBUTION_ERROR = "@@PRODUCTPROFILE/FETCH_PRODUCT_LIST_CONTRIBUTION_ERROR";

export const FETCH_PROFILED_PRODUCT_DATA = "@@PRODUCTPROFILE/FETCH_PROFILED_PRODUCT_DATA";
export const FETCH_PROFILED_PRODUCT_SUCCESS = "@@PRODUCTPROFILE/FETCH_PROFILED_PRODUCT_SUCCESS";
export const FETCH_PROFILED_PRODUCT_ERROR = "@@PRODUCTPROFILE/FETCH_PROFILED_PRODUCT_ERROR";

export const SAVE_PRODUCT_PROFILE_DATA = "@@PRODUCTPROFILE/SAVE_PRODUCT_PROFILE_DATA";
export const SAVE_PRODUCT_PROFILE_SUCCESS = "@@PRODUCTPROFILE/SAVE_PRODUCT_PROFILE_SUCCESS";
export const SAVE_PRODUCT_PROFILE_ERROR = "@@PRODUCTPROFILE/SAVE_PRODUCT_PROFILE_ERROR";

export const FETCH_SAVED_DATA = "@@PRODUCTPROFILE/FETCH_SAVED_DATA";
export const FETCH_SAVED_SUCCESS = "@@PRODUCTPROFILE/FETCH_SAVED_SUCCESS";
export const FETCH_SAVED_ERROR = "@@PRODUCTPROFILE/FETCH_SAVED_ERROR";

export const DELETE_PRODUCT_PROFILE = "@@PRODUCTPROFILE/DELETE_PRODUCT_PROFILE";
export const DELETE_PRODUCT_PROFILE_SUCCESS = "@@PRODUCTPROFILE/DELETE_PRODUCT_PROFILE_SUCCESS";
export const DELETE_PRODUCT_PROFILE_ERROR = "@@PRODUCTPROFILE/DELETE_PRODUCT_PROFILE_ERROR";


export const RESET_FILTERS_DATA = "@@PRODUCTPROFILE/RESET_FILTERS_DATA";
export const RESET_PRODUCTPROFILE_DATA = "@@PRODUCTPROFILE/RESET_PRODUCTPROFILE_DATA";

export const resetFiltersOptions = (payload) => ({
  type: RESET_FILTERS_DATA,
  payload,
})

export const resetProductProfileData = (payload) => ({
  type: RESET_PRODUCTPROFILE_DATA,
  payload,
})


export const fetchUserCreatedData = (payload) => ({
  type: FETCH_USER_CREATED_DATA,
  payload,
});

export const fetchUserCreatedDataSuccess = (payload) => ({
  type: FETCH_USER_CREATED_DATA_SUCCESS,
  payload,
});

export const fetchUserCreatedDataError = (payload) => ({
  type: FETCH_USER_CREATED_DATA_ERROR,
  payload,
});

export const fetchStoresData = (payload) => ({
  type: FETCH_STORES_DATA,
  payload,
});

export const fetchStoresDataSuccess = (payload) => ({
  type: FETCH_STORES_DATA_SUCCESS,
  payload,
});

export const fetchStoresDataError = (payload) => ({
  type: FETCH_STORES_DATA_ERROR,
  payload,
});


export const fetchFilterData = (payload,filterKey,filterType) => ({
  type: FETCH_FILTER_DATA,
  payload,
  filterKey,
  filterType,
});

export const fetchFilterDataSuccesss = (payload) => ({
  type: FETCH_FILTER_SUCCESS,
  payload,
});

export const fetchFilterDataError = (payload) => ({
  type: FETCH_FILTER_ERROR,
  payload,
});

export const fetchCreateFilterData = (payload) => ({
  type: FETCH_CREATEFILTER_DATA,
  payload
});

export const fetchCreateFilterSuccesss = (payload) => ({
  type: FETCH_CREATEFILTER_DATA_SUCCESS,
  payload,
});

export const fetchCreateFilterError = (payload) => ({
  type: FETCH_CREATEFILTER_DATA_ERROR,
  payload,
});

export const fetchProductListContribution = (payload) => ({
  type: FETCH_PRODUCT_LIST_CONTRIBUTION_DATA,
  payload
})

export const fetchProductListContributionSuccesss = (payload) => ({
  type: FETCH_PRODUCT_LIST_CONTRIBUTION_SUCCESS,
  payload,
});

export const fetchProductListContributionError = (payload) => ({
  type: FETCH_PRODUCT_LIST_CONTRIBUTION_ERROR,
  payload,
});

export const fetchProfiledProduct = (payload) => ({
  type: FETCH_PROFILED_PRODUCT_DATA,
  payload
})

export const fetchProfiledProductSuccess = (payload) => ({
  type: FETCH_PROFILED_PRODUCT_SUCCESS,
  payload
})

export const fetchProfiledProductError = (payload) => ({
  type: FETCH_PROFILED_PRODUCT_ERROR,
  payload
})

export const saveProductProfile = (payload) => ({
  type: SAVE_PRODUCT_PROFILE_DATA,
  payload
})

export const saveProductProfileSuccess = (payload) => ({
  type: SAVE_PRODUCT_PROFILE_SUCCESS,
  payload
})

export const saveProductProfileError = (payload) => ({
  type: SAVE_PRODUCT_PROFILE_ERROR,
  payload
})

export const fetchSavedData = (payload) => ({
  type: FETCH_SAVED_DATA,
  payload
})

export const fetchSavedDataSuccess = (payload) => ({
  type: FETCH_SAVED_SUCCESS,
  payload
})

export const fetchSavedDataError = (payload) => ({
  type: FETCH_SAVED_ERROR,
  payload
})

export const deleteProductProfile = (payload) => ({
  type: DELETE_PRODUCT_PROFILE,
  payload
})

export const deleteProductProfileSuccess = (payload) => ({
  type: DELETE_PRODUCT_PROFILE_SUCCESS,
  payload
})

export const deleteProductProfileError = (payload) => ({
  type: DELETE_PRODUCT_PROFILE_ERROR,
  payload
})
