import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LostSalesFilters from './LostSalesFilters';
import LostSalesGraphView from './LostSalesGraphView';
import LostSalesReportDetailsTable from './LostSalesReportDetailsTable';
import PageLoader from "../../../components/Loader/PageLoader";
import { LOST_SALES_NETWORK_ERROR_MSG } from './LostSalesConstants';
import * as lostSalesActions from './actions/LostSalesReportActions';
import './LostSalesReport.css';

/**
 * Lost Sales Report Container View 
 */
const LostSalesReportView = (props) => {
    const { handleFilterRequest, isLostSalesAllDataLoading, isLostSalesAllDataFailed,
        isLostSalesAllDataSuccess, activeTab, handleResetLostSales } = props;

    useEffect(() => {
        if (activeTab !== "lost") {
            handleResetLostSales();
        }
    }, [activeTab]);

    useEffect(() => {
        return () => {
            handleResetLostSales();
        }
    }, []);

    return (
        <>
            <div className="filter row reporting-filters" style={{ paddingTop: "0" }} >
                <LostSalesFilters handleFilterRequest={handleFilterRequest} activeTab={activeTab}
                    handleResetLostSales={handleResetLostSales} />
            </div>
            <PageLoader loader={isLostSalesAllDataLoading} loaderMessage='Loading...'>
                {isLostSalesAllDataSuccess
                    ?
                    <div id="LOST_SALES_REPORT_VIEW_CONTAINER" className="lost_sales_report_container shadow-sm">
                        <LostSalesGraphView />
                        <LostSalesReportDetailsTable />
                    </div>
                    : (isLostSalesAllDataFailed ? <div className="error">{LOST_SALES_NETWORK_ERROR_MSG}</div> : null)}
            </PageLoader>
        </>
    )
}

const mapStateToProps = ({ lostSalesReport }) => ({
    isLostSalesAllDataLoading: lostSalesReport.isLostSalesAllDataLoading,
    isLostSalesAllDataFailed: lostSalesReport.isLostSalesAllDataFailed,
    isLostSalesAllDataSuccess: lostSalesReport.isLostSalesAllDataSuccess,
});

const mapDispatchToProps = (dispatch) => ({
    lostSalesActionsProp: bindActionCreators(lostSalesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LostSalesReportView);

