import React, { useState, useEffect } from "react";
import { CheckboxSelect } from "@atlaskit/select";

import { withAsyncPaginate } from "react-select-async-paginate";
import { useCallback } from "react";

const CustomAsyncPaginate = withAsyncPaginate(CheckboxSelect);

const AsyncMulti = ({onDropDownChange,optionsArray,dropdownValue}) => {

  const [value, setValue] = useState([]);
  const [options ,setOptions] = useState([])

  useEffect(() => {
    setOptions(optionsArray)
  }, [optionsArray])

  useEffect(() => {
      setValue(dropdownValue)
  }, [dropdownValue])

  const onChange = (newValue,event) => {
    // setValue(newValue);
    onDropDownChange(newValue,event)
  };

  let onFocus = () => {
    if(dropdownValue?.length && optionsArray?.length){
      let options
      options = [...new Set([...dropdownValue,...optionsArray])]
      const uniqueObjects = [...new Map(options.map(item => [item.value, item])).values()]
      setOptions(uniqueObjects)
    }
    else{
      let options
      options = [...optionsArray]
      setOptions(options)
    }  
  }

  const loadOptions = useCallback(async (search, prevOptions) => {
      let filteredOptions;
      if (!search) {
        filteredOptions = options;
      } else {
        const searchLower = search.toLowerCase();
  
        filteredOptions = options.filter(({ label }) =>
          label.toLowerCase().includes(searchLower)
        );
      }

      const hasMore = filteredOptions?.length > prevOptions.length + 10;
      const slicedOptions = filteredOptions?.slice(
        prevOptions.length,
        prevOptions.length + 10
      );
  
      return {
        options: slicedOptions,
        hasMore
      };
  },[options])

  return (
    <div className="async-multi-div">
      <CustomAsyncPaginate
        SelectComponent={CheckboxSelect}
        value={value}
        loadOptions={loadOptions}
        onChange={onChange}
        cacheUniqs={[options]}
        onFocus={onFocus}
      />
    </div>
  );
};

export default AsyncMulti;

