import React, { useState, useEffect, useRef } from "react";
import moment from "moment"
import "./CellRenderer.scss";

const DateInput = React.memo(({ ...instance }) => {
    let ref = useRef()
    let {
        row,
        column,
        updateMyData,
        value,
        dateFormat,
        min,
        changeHandler,
        isDisabled,
    } = instance;

    const [date, setDate] = useState(null);
    const today = moment(new Date()).format("YYYY-MM-DD")

    useEffect(() => {
        let date = moment(value).format("YYYY-MM-DD")
        setDate(() => date);
        if (ref.current) {
            ref.current.setAttribute(
                "data-date",
                moment(date).format(dateFormat)
            )
        }

    }, [value]);

    const onDateChange = (e) => {
        e.target.setAttribute("data-date",
            moment(e.target.value, "YYYY-MM-DD")
                .format(dateFormat))
        setDate(e.target.value)
        updateMyData(
            row.index,
            column.id,
            e.target.value,
            row.id,
            e.target.idTemp,
            row
        );
        if(changeHandler){
            changeHandler(row.index,
            column.id,
            e.target.value,
            row.id,
            e.target.idTemp,
            row);
          }
    }

    return (
        <div>
            {/* {
                ref.current?.setAttribute

            } */}
            <input type="date" ref={ref} className="date-input" disabled={isDisabled} min={min || today} data-date=""
                value={date || ""} onChange={onDateChange} required

            />
        </div>
    );
});

export default DateInput;
