import React from "react";
import Styles from "./TableMarkupStyles";
import { SelectFilter, GlobalFilter } from "../FilterMarkups/TableFilters";

const FooterMarkup = (props) => {
  let {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows,
    height,
    footerGroups,

    preGlobalFilteredRows,
    globalFilter,
    defaultSelectFilter,
    setGlobalFilter,
    state,
    label,
    filterOptions,
    keyRT,
    dispatch,
    customSelectedFilter,
    customFilterOptions
  } = props;

  let FilterComp = null;
  // HANDLE FILTER COMPONENT ACCORDING TO KEYRT
  if (keyRT === "BudgetLevelThreeComponent-removetshis") {
    FilterComp = (
      <SelectFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={customSelectedFilter || null}
          setGlobalFilter={setGlobalFilter}
          label={label}
          filterOptions={customFilterOptions || []}
          dispatch={dispatch}
          // setCommonFilterandOptions={setCommonFilterandOptions}
        />
    );
  }

  return (
    <>
    {FilterComp}
    <Styles>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((group) => (
            <tr className="tr" {...group.getHeaderGroupProps()}>
              {group.headers.map((column) => (
                <th className="th" {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td className="td" {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {rows.length ? (
          <tfoot>
            {/* if rowSpan is zero, aoid rendering TR */}
            {footerGroups.map((group, i) => {
              if (group.headers[i].rowSpan == 0) {
                return null;
              }
              return (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => {
                    return (
                      <td
                        className="td"
                        {...column.getFooterProps({
                          rowSpan: column.rowSpan,
                        })}
                      >
                        {column.render("Footer")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tfoot>
        ) : null}
      </table>
    </Styles>
  </>
  );
};
export default FooterMarkup;
