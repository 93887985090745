import React from "react";
import { withRouter } from "react-router-dom";

import "./NavbarComponent.scss";

import { reset as resetPostHog } from "../../utils/PostHog/postHog";
import { logoutRedirect } from "../../utils/commonUtilities";

const Navbar = (props) => {
  const logoutHandler = () => {
    logoutRedirect();
    localStorage.clear()
    resetPostHog()
    // localStorage.removeItem("access_token");
    // localStorage.removeItem("group_id");
    // localStorage.removeItem("userName");
    // localStorage.removeItem("user_id");
    // localStorage.removeItem("email");
    // localStorage.removeItem("user_preference");
    // localStorage.removeItem("column_preference")

  };

  const userData = localStorage.getItem("userName");
  const groupName = localStorage.getItem("group_name")
  return (
    <nav className="navbar">
      <div className="fnt-bold navbar-logo">Allocate$mart</div>
      <div className="navbar-menu-right">
        <ul className="navbar-nav">
          <li className="nav-item">
            <div className="nav-item-btn">P</div>
          </li>
          <li className="nav-item">
            <div className="nav-item-username fnt-bold fnt-md">
              {" "}
              Hi {userData}{"!"}
            </div>
          </li>
          <li title="Logout" onClick={logoutHandler} className="nav-item">
            <i className="fa fa-sign-out icon-header" aria-hidden="true"></i>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default withRouter(Navbar);
