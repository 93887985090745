import React, { useEffect, useRef, useState } from "react";
import { ReactTableFeatures } from "./Constants";

// Validates features and remove typo
function validateFeatures(features = [], keyRT) {
  features.forEach((feature) => {
    let find = ReactTableFeatures[feature];
    if (!find) {
      throw new Error(
        `Feature should be valid : ${feature}, from keyRT: ${keyRT}`
      );
    }
  });
}

// Hook will be passed to table to get new instance, where instance is required in controller file
const useInstance = (instance) => {
  const {
    keyRT,
    settabledata,
    getInstanceOnMount,
    features,
    data,
    state: { selectedRowIds, setSelectedFilter },
  } = instance;
  // Call ValidateFeatures for find if there are any typos in a `features` prop
  validateFeatures(features, keyRT);
  const getSelectedRows = selectedRowIds && Object.keys(selectedRowIds);

  useEffect(() => {
    if (getInstanceOnMount) getInstanceOnMount(instance);
  }, [
    getInstanceOnMount,
    keyRT,
    instance,
    instance.data,
    selectedRowIds,
    setSelectedFilter,
  ]);

  const deleteRow = function deleteRow(acc, id) {
    let filteredData = data.filter((row, i) => {
      return row[acc] !== id;
    });

    settabledata(filteredData);

    return filteredData;
  };

  Object.assign(instance, {
    getSelectedRows,
    deleteRow,
  });
};

export const useGetInstance = (hooks) => {
  hooks.useInstance.push(useInstance);
};

// Checkbox Markup
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    const customOnChange = (e) => {
      rest.onChange(e)
      rest?.checkAllCallBack && rest.checkAllCallBack(rest)
      rest?.checkCallBack && rest.checkCallBack(rest)
    }

    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          onChange={ (e) => customOnChange(e)}
          style={{ width: "1rem", marginTop: "3px" }}
        />
      </>
    );
  }
);

const IndeterminateRadio = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        {/*<input type="checkbox" ref={resolvedRef} {...rest} />*/}
        <input 
          type="radio" 
          ref={resolvedRef} 
          {...rest} 
          style={{ width: "1rem", marginTop: "3px" }}
        />
      </>
    );
  }
);

// Based on `features prop` that is passed, It checks if checkox is needed for first column or firstColumn with header checkbox
export const useCheckBoxColumn = (hooks) => {
  hooks.visibleColumns.push((columns) => [
    {
      id: "checkboxForFirstColumn",
      sticky: "left",
      Header: (instance) => {
        const { features,checkAllCallBack } = instance;
        return features && features.includes("CHECKBOX_FOR_FIRSTCOLUMN") && !features.includes("HIDE_CHECKALL") ? (
          <div>
            <IndeterminateCheckbox
              {...instance.getToggleAllRowsSelectedProps()}
              checkAllCallBack = {checkAllCallBack}
              instance = {instance?.data}
            />
          </div>
        ) : (
          ""
        );
      },
      Cell: (instance) => {
        const { checkCallBack } = instance;
        return (
          (instance?.row?.canExpand || !Object.keys(instance?.row).includes("canExpand")) && !instance?.row?.original?.showCheckbox ?
          <div>
            <IndeterminateCheckbox
              {...instance.row.getToggleRowSelectedProps()}
              checkCallBack = {checkCallBack}
              instance = {instance?.row?.original}
            />
          </div>
          : (instance?.row?.original?.checkColor ? <span className="checkbox_color_code"></span> : "")
        );
      },
      width: 60,
      minWidth: 50,
    },
    ...columns,
  ]);
};

export const useRadioColumn = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      {
        id: "radioForFirstColumn",
        sticky: "left",
        Header: () => {
          return (<div></div>)
        },
        Cell: ({ row,toggleAllRowsSelected,toggleRowSelected }) => {
          return (
            <div>
              <IndeterminateRadio
                {...row.getToggleRowSelectedProps()}
                onClick={() => {
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id, true);
                }}
              />
            </div>
          )
        },
        width: 60,
        minWidth: 50,
      },
      ...columns,
    ]);
  };

export const useCheckBoxLastColumn = (hooks) => {
  hooks.visibleColumns.push((columns) => [
    ...columns,
    {
      id: "selection",
      Header: (instance) => {
        const { features } = instance;
        return features && features.includes("CHECKBOX_FOR_LASTCOLUMN") ? (
          <div>
            <IndeterminateCheckbox
              {...instance.getToggleAllRowsSelectedProps()}
            />
          </div>
        ) : (
          "Action"
        );
      },
      Cell: (instance) => {
        return (
          <div>
            <IndeterminateCheckbox
              {...instance.row.getToggleRowSelectedProps()}
            />
          </div>
        );
      },
      width: 100,
      minWidth: 50,
    },
  ]);
};

// Hook for row spanning, use markup file for rendering - 'TableMarkup.js' as it has td based html layout
export const useRowSpan = (hooks) => {
  hooks.useInstance.push(function (instance) {
    const { allColumns } = instance;

    let rowSpanHeaders = [];

    allColumns.forEach((column, i) => {
      const { id, enableRowSpan } = column;

      if (enableRowSpan !== undefined) {
        rowSpanHeaders = [
          ...rowSpanHeaders,
          { id, topCellValue: null, topCellIndex: 0 },
        ];
      }
    });

    Object.assign(instance, { rowSpanHeaders });
  });
};

export function useDeepCompareState(cb, [{ state }]) {
  console.log("gotstate", state);
  const ref = useRef(null);

  const Compare = (newstate) => {
    for (const key in state) {
      if (newstate.hasOwnProperty(key)) {
        const element = newstate[key];
        console.log("ele", element, newstate[key]);
        if (ref[key] !== element) {
          ref.current = newstate;
          return ref.current;
        }
      }
    }
  };
  console.log("proprqs", ref.current);

  React.useEffect(cb, [cb, Compare(state)]);

  return ref.current;
}
