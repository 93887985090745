import React from "react";
import ReactDOM from "react-dom";
import "./theme.css";
import App from "./containers/App";

import { store, persistor } from "./configs/store/index";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

import { Provider as AlertProvider } from "react-alert";
import { AlertTemplate, options } from "./AlertSettings";

function AppWrap() {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </AlertProvider>
  );
}

const render = () => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppWrap />,
    document.getElementById("root")
  );
};

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./containers/App", () => {
    render();
  });
}

// Render once
render();
