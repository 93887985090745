import ProductStatusReducer from "./ProductStatusReducer";
import ConfigurationReducer from "./ConfigurationReducer";

const rootReducer = {
    ...ConfigurationReducer,
    ...ProductStatusReducer
}

const productConfigReducers = rootReducer

export default productConfigReducers