import { updateArticleName } from "../../utils/filterLevelMapping";

export const DRIVERS_FORECAST_COL_DRIVERS = "Drivers";
export const DRIVERS_FORECAST_COL_IMPORTANCE = "Importance";
export const GRAPH_WEEK = "Week";
export const GRAPH_MONTH = "Month";
export const GRAPH_QUARTER = "Quarter";
export const SALES_DISPLAY_HEADER = "Sales Unit Forecasts";
export const DRIVERS_TABLE_HEADER = "Drivers of Forecasts";
export const FORECAST_ADJUSTMENT_HEADER = "Forecast Adjustment";
export const EDIT_HIERARCHY_HEADER = "View/Edit lower hierarchy forecasts";
export const FORECAST_MULTIPLIER = "Forecast Multiplier";
export const SELECT_DATE = "Select Date";
export const APPLY = "Apply";
export const GRAPH_ACTUAL_LABEL = "Actual";
export const GRAPH_IA_LABEL = "IA Forecast";
export const GRAPH_ADJUSTED_LABEL = "Adjusted Forecasts";
export const GRAPH_LAST_YEAR_LABEL = "Last Year Sales";
export const FORECAST_ROW_HEADERS = {
    ia_forecast: "IA Forecast",
    actual_sales:"Actual",
    forecast_multiplier: "Forecast Multiplier",
    adjusted_forecast: "Adjusted Forecasts",
};
export const DRIVERS_ROW_HEADERS = {
    price: "Price",
    promo: "Promo",
    baseline: "Baseline",
    store_count: "Store Count",
};
export const ADA_VISUAL_KEYS = ["ia_forecast","actual_sales", "forecast_multiplier", "adjusted_forecast"];
export const SELECT_REQUIRED_FIELD_NOTIFICATION = "Please select all mandatory options!!";
export const NETWORK_ERROR_MSG = "Something went wrong!";
export const EMPTY_DRIVERS_MSG = "No Articles Record Found";
export const EMPTY_FORECAST_MSG = "No Articles Record Found";
export const EMPTY_HIERARCHY_MSG = "No Articles Record Found";
export const EMPTY_STORE_MSG = "No Store Record Found";
export const EMPTY_DATA_HELPER_MSG = "Try Adjusting the Filters!!!";
export const NO_REVIEW_MSG = "No Changes to Review!!";
export const REVIEW_SUCCESS_MSG = "Review Successful!!";
export const SAVE_WARNING_MSG = "After Review Make Sure to Save Before Switching Tables Otherwise Old Table Data will be Lost !!!"
export const REVIEW_FAILED_MSG = "Review Failed!!";
export const SAVE_SUCCESS_MSG = "Save Successful!!";
export const SAVE_FAILED_MSG = "Save Failed!!";
export const NO_SAVE_MSG = "Please Review changes before Saving!!";
export const ADA_DATA_WEEKLY = "week";
export const ADA_DATA_QUARTERLY = "quarter";
export const ADA_DATA_MONTHLY = "month";
export const ADA_FILTER_DEPARTMENT = "Department";
export const ADA_FILTER_GENDER = "Gender";
export const ADA_FILTER_SUBCATEGORY = "Sub-Category";
export const ADA_FILTER_DCS = "DCS";
export const ADA_FILTER_ARTICLE_ID = `${updateArticleName(false)} ID`
export const REVIEW_BUTTON = "Review";
export const SAVE_BUTTON = "Save";
export const DRIVERS_FORECAST_HEADER = "Drivers";
export const IMPORTANCE_FORECAST_HEADER = "Importance";
export const HIERARCHY_ID_HEADER = `${updateArticleName(false)} ID`
export const HIERARCHY_ID_DESC = `${updateArticleName(false)} Description`
export const DISCARD_CONTINUE_WARNING_TEXT = " Do you want to continue Editing or discard the changes ?";
export const CONTINUE_BUTTON = "Continue";
export const DISCARD_BUTTON = "Discard";
export const ADA_LINE_CHART_PARAMS = {
    labels: [],
    datasets: [
        {
            label: GRAPH_ACTUAL_LABEL,
            data: [],
            fill: true,
            borderWidth: 5,
            borderColor: '#FFA726',
            backgroundColor: '#ffa72533',
        },
        {
            label: GRAPH_IA_LABEL,
            data: [],
            fill: true,
            borderWidth: 3,
            borderColor: '#42A5F5',
            backgroundColor: '#42a5f50f',
            // "#42a5f521",
        },
        {
            label: GRAPH_ADJUSTED_LABEL,
            data: [],
            fill: false,
            borderWidth: 4,
            borderDash: [5, 5],
            borderColor: '#8c6de8',
        },
        {
            label: GRAPH_LAST_YEAR_LABEL,
            data: [],
            fill: false,
            borderWidth: 5,
            borderColor: '#8CDDD0',
        }],
};

export const ADA_BAR_CHART_PARAMS = {
    labels: [],
    datasets: [
        {
            label: GRAPH_ACTUAL_LABEL,
            data: [],
            backgroundColor: '#FFA726'
        },
        {
            label: GRAPH_IA_LABEL,
            data: [],
            fill: true,
            backgroundColor: '#42A5F5'
        },
        {
            label: GRAPH_ADJUSTED_LABEL,
            data: [],
            backgroundColor: '#8c6de8'
        },
        {
            label: GRAPH_LAST_YEAR_LABEL,
            data: [],
            backgroundColor: '#8CDDD0',
        }
    ]
};
export const ITEMS_PER_PAGE = 20;
export const ITEM_PER_PAGE_STORES = 20;
export const CHUNK_SIZE = 10;