export const AAA = "#42A5F5";
export const AA = "#FFA726";
export const A = "#C6C6C6";
export const B = "#7DD4FA";
export const C = "#7E9FFA";
export const D = "#44677B";

export const STOCK_OUT = "#42A5F5";
export const SHORT_FALL = "#FFA726";
export const NORMAL = "#C6C6C6";
export const EXCESS = "#7DD4FA";

export const colors = {
    "AAA": "#42A5F5",
    "AA": "#FFA726",
    "A": "#C6C6C6",
    "B": "#7DD4FA",
    "C": "#7E9FFA",
    "D": "#44677B",
    "Stock Out": "#42A5F5",
    "Shortfall": "#FFA726",
    "Normal": "#C6C6C6",
    "Excess": "#7DD4FA",
    "Warehouse": "#66BB6A"
}