import { getLabel } from "../../../utils/commonUtilities";
import { updateArticleName } from "../../../utils/filterLevelMapping";

export const DEEP_DIVE_TABLE_HEADERS = {
    ARTICLE_ID: `${updateArticleName(false)} ID`,
    STYLE_ID: "Style ID",
    COLOR_ID: "Color ID",
    STYLE_DESCRIPTION: "Style Description",
    STYLE_COLOR: "Style Color",
    COLOR: "Color",
    CHANNEL:" Channel",
    DEPARTMENT: "Department",
    CLASS: "Class",
    STYLEGROUP: "Style Group",
    SILHOUETTE: "Silhouette",
    SUBCOLLECT: "Sub Collect",
    STORE_ID: "Store Number",
    STORE_NAME: "Store Name",
    SEASON_CODE_ACTIVE: "Season Code Active",
    SEASON_CODE_OPEN: getLabel("season_code_open"),
    STORE_GRADE: "Store Grade",
    PLANNED_ALLOCATION: "Planned Allocation",
    ACTUAL_ALLOCATION: "Actual Allocation",
    UNIT_SALES: "Unit Sales",
    QTY_MATCH_PER: "Qty Match %",
    SIZE_MATCH_PER: "Size Match %",
    ALLOCATION_MATCH: "Qty Match %",
    SIZE_MATCH_PER_SALES: "Size Match %",
    SKU_DEPTH: "SKU Depth",
    AVG_GRADE_REVENUE: "Avg Grade Revenue",
    STORE_REVENUE: "Store Revenue",
    AVG_GRADE_MARGIN: "Avg. Grade Margin",
    STORE_MARGIN: "Store Margin", 
    QTY_MATCH_PER_EXCEL: "Qty Match % (Plan vs Actual Allocation)",
    SIZE_MATCH_PER_EXCEL: "Size Match % (Plan vs Actual Allocation)",
    ALLOCATION_MATCH_EXCEL: "Qty Match % (Actual Allocation vs Sales)",
    SIZE_MATCH_PER_SALES_EXCEL: "Size Match % (Actual Allocation vs Sales)",
    SKU_DEPTH_EXCEL: "SKU Depth (Actual Allocation vs Sales)",
    AVG_GRADE_REVENUE_EXCEL: "Avg Grade Revenue (Sales Performance)",
    STORE_REVENUE_EXCEL: "Store Revenue (Sales Performance)",
    AVG_GRADE_MARGIN_EXCEL: "Avg. Grade Margin (Sales Performance)",
    STORE_MARGIN_EXCEL: "Store Margin (Sales Performance)", 
};

export const CARDS = {
    CARD1: "Total Planned Allocation",
    CARD2: "Total Actual Allocation",
    CARD3: "Qty Match % (Plan vs Actual)",
    CARD4: "Qty Match % (Actual vs Sales)",
    CARD5: "Size Match % (Plan vs Actual)",
    CARD6: "Size Match % (Actual vs Sales)"
}