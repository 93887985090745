import { isEmpty } from "lodash";
import { currentClientName } from "../../utils/commonUtilities";
import { DC_NAMES, STORE_STOCK_TABLE_HEADERS } from "./ReportingDCConstants";

export const formatReportingExcelData = (data) => {
  let dcColumnNames = {};
  let excelData = []
  if (!isEmpty(data)) {
    DC_NAMES[currentClientName].forEach((dcName) => {
      dcColumnNames[dcName?.name] = dcName?.acc;
    });
  }
  data.forEach((item) => {
    let l_dcColumnNames = {};

    for (let i in dcColumnNames) {
      l_dcColumnNames[i] = item[dcColumnNames[i]];
    }

    excelData.push({
      [STORE_STOCK_TABLE_HEADERS.STORE_NUMBER]: item.store_code,
      [STORE_STOCK_TABLE_HEADERS.STORE_NAME]: item.store_name,
      [STORE_STOCK_TABLE_HEADERS.LEVEL1]: item.l1_name,
      [STORE_STOCK_TABLE_HEADERS.LEVEL2]: item.l2_name,
      [STORE_STOCK_TABLE_HEADERS.LEVEL3]: item.l3_name,
      [STORE_STOCK_TABLE_HEADERS.LEVEL4]: item.l4_name,
      [STORE_STOCK_TABLE_HEADERS.LEVEL5]: item.l5_name,
      [STORE_STOCK_TABLE_HEADERS.LEVEL6]: item.l6_name,
      [STORE_STOCK_TABLE_HEADERS.STORE_GRADE]: item.store_grade,
      ...l_dcColumnNames,
      // [STORE_STOCK_TABLE_HEADERS.REGION]: item.region,
      [STORE_STOCK_TABLE_HEADERS.CLIMATE]: item.climate,
      [STORE_STOCK_TABLE_HEADERS.STYLE_COLOR_ID]: item.article,
      [STORE_STOCK_TABLE_HEADERS.DESCRIPTION]: item.style_name,
      [STORE_STOCK_TABLE_HEADERS.STYLE_COLOR]: item.color_desc,
      [STORE_STOCK_TABLE_HEADERS.OH]: item.oh,
      [STORE_STOCK_TABLE_HEADERS.IT]: item.it,
      [STORE_STOCK_TABLE_HEADERS.OO]: item.oo,
      [STORE_STOCK_TABLE_HEADERS.TOT_INV]: item.total_inventory,
      [STORE_STOCK_TABLE_HEADERS.LW_UNITS]: item.lw_qty,
      [STORE_STOCK_TABLE_HEADERS.WOS]: item.wos,
      [STORE_STOCK_TABLE_HEADERS.OH_SI]: item.si_oh,
      [STORE_STOCK_TABLE_HEADERS.IT_SI]: item.si_it,
      [STORE_STOCK_TABLE_HEADERS.DC_SI]: item.si_dc,
      [STORE_STOCK_TABLE_HEADERS.DC_TOTAL]: item.si_all,
      [STORE_STOCK_TABLE_HEADERS.BULK_REMAINING]: item.bulk_remaining,
      [STORE_STOCK_TABLE_HEADERS.STYLE_COLOR_STATUS]: item.article_status,
      [STORE_STOCK_TABLE_HEADERS.STORE_STATUS]: item.store_status,
      [STORE_STOCK_TABLE_HEADERS.CLERANCE]: item.clearance,
    });
  });
  return excelData
};
