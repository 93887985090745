import { takeLatest, takeEvery, put, call, all, delay } from "redux-saga/effects";
import * as actionTypes from "./Actions/ActionTypes"
import {
    getExcessTableData, getExcessGraphData, getExcessFilters
} from "../../../routes/api";
import { isEmpty, reverse } from "lodash";
import { updateArticleName } from "../../../utils/filterLevelMapping";
import { addDollar, numberUSFormatting } from "../../../utils/formatters/valueFormatters";
import { flattenArray } from "../../../utils/commonUtilities";
import { ERROR_MSG } from "../../../constants/globalConstant";

const g_level_mappind_excess_data = {
    "level1": "l1_name",
    "level2": "l2_name",
    "level3": "l3_name",
    "level4": "l4_name",
    "level5": "l5_name",
    "level6":"l6_name",
    "level7":"l7_name",
    "article":"article",
    "style":"style",
    "store":"store",
    "color":"color",
    "selected_fw":"selected_fw",
    "selected_fy":"selected_fy",
    "row_count":"row_count",
    "row_index":"row_index",
    "sortColumn": "sortColumn",
    "searchColumns": "searchColumns",
    "year": "year",
    "quarter": "quarter",
    "week": "week",
    "width": "width",
    "screen_type": "screen_type"
  }

function* fetchFilterDataWorker(action) {
    const level_ln_map = {
        "level1": "l1_name",
        "level2": "l2_name",
        "level3": "l3_name",
        "level4": "l4_name",
        "level5": "l5_name",
        "level6":"l6_name",
        "level7":"l7_name",
        "article":"article",
        "style":"style",
        "store":"store",
        "color":"color",
        "width": "width"
      }
    try {
        const { payload, filterKey } = action;
        let req = {};
        if (!isEmpty(payload)) {
            for (const key in payload) {
                if (payload[key] && payload[key].length > 0)
                    // req[key] = payload[key]?.map((ele) => ele.value)
                    payload[key] && (req[level_ln_map[key]] = flattenArray(payload[key])?.map((ele) => ele.value))
            }
        }
        const res = yield call(getExcessFilters, req);
        if (res.data.status) {
            const data = {};
            data["filterMapping"] = res.data?.filterMapping

            for (const key in res.data.data[0]) {
                let k = "";
                if (key === "l1_name") {
                    k = "departmentOptions";
                } else if (key === "l2_name") {
                    k = "genderOptions";
                } else if (key === "l3_name") {
                    k = "rbuOptions";
                } else if (key === "l4_name") {
                    k = "dcsOptions";
                } else if (key === "l5_name") {
                    k = "level5Options";
                } else if (key === "l6_name") {
                    k = "level6Options";
                } else if (key === "l7_name") {
                    k = "level7Options";
                }else if (key === "store") {
                    k = "storeOptions";
                } else if (key === "article") {
                    k = "articleOptions"
                } else if (key === "color") {
                    k = "colorOptions"
                } else if (key === "style") {
                    k = "styleOptions"
                } else if (key === "width") {
                    k = "widthOptions"
                }

                // data["departmentOptions"] = [{ label: "Accessories", value: "Accessories" }, { label: "Apparel", value: "Apparel" }, { label: "Footwear", value: "Footwear" }]

                data[k] = res.data.data[0][key]?.filter(val => val)?.map((element) => ({
                    value: element,
                    label: element,
                }));
            }

            yield put({ type: actionTypes.FETCH_FILTER_SUCCESS, data: data, key: filterKey });
        }
    } catch (error) {
        yield put({ type: actionTypes.FETCH_FILTER_ERROR, error: ERROR_MSG });
    }
}

function* getExcessInvDataWorker(action) {
    try {
        const { payload } = action;
        let req = {}
        for (let key in payload) {
            if (payload[key]) {
                payload[key] && (req[g_level_mappind_excess_data[key]] = payload[key])
            }
        }
        req.row_index = payload.row_index
        if(req["screen_type"] === "Quarter") {
            delete req["week"]
        }

        const res = yield call(getExcessTableData, req);
        if (res.data.status) {
            const { inv_cost_sum, inv_sum } = res.data.global_data
            const count = res.data.total_count

            yield put({ type: actionTypes.GET_EXCESS_DATA_SUCCESS, tableData: res.data.data, totalCount: count, sum: { inv_cost_sum, inv_sum }, nextIndex: payload.row_index + 100 });
        }
        else {
            yield put({ type: actionTypes.GET_EXCESS_DATA_ERROR, error: ERROR_MSG });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_EXCESS_DATA_ERROR, error: ERROR_MSG });
    }
}

function* generateExcelWorker(action) {
    const PARALLEL_CALLS_COUNT = 10;

    try {
        const { payload } = action;
        const total = payload.total;
        delete payload.total
        let req = {}
        
        for (let key in payload) {
            if (payload[key]) {
                payload[key] && (req[g_level_mappind_excess_data[key]] = payload[key])
            }
        }
        req["row_index"] = payload.row_index
        req["row_count"] = payload.row_count
        if(req["screen_type"] === "Quarter") {
            delete req["week"]
        }
        let parallelCalls = []
        let rowIndex = payload?.row_index;
        let count = 1;
        let run = true;
        while(run) {
            if(count >= PARALLEL_CALLS_COUNT) {
                run = false;
            }
            if(rowIndex < total) {
                parallelCalls.push(call(getExcessTableData, {...req, row_index: rowIndex }))
            }
            rowIndex = payload.row_index + (count * payload.row_count)
            count++;
        }
        const res = yield all(parallelCalls)
        // const res = yield call(getExcessTableData, req);
        if (res[0]?.data?.status) {        
            let reportingData = []
            res?.forEach(item => {
                reportingData = [...reportingData, ...item?.data?.data]
            })             
            const count = res[0].data.total_count
            yield put({ type: actionTypes.GENERATE_EXCEL_SUCCESS, excelData: reportingData, totalCountExcel: count, totalCount: count, nextIndex: payload.row_index + (payload.row_count * PARALLEL_CALLS_COUNT) } );
        }
        else {
            yield put({ type: actionTypes.GENERATE_EXCEL_ERROR, error: ERROR_MSG });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GENERATE_EXCEL_ERROR, error: ERROR_MSG });
    }
}

function* getExcessGraphDataWorker(action) {
    try {
        const { payload } = action;
        let req = {}
        for (let key in payload) {
            if (payload[key]) {
                req[g_level_mappind_excess_data[key]] = payload[key]
            }
        }
        const res = yield call(getExcessGraphData, req);
        if (res.data.status) {

            let graphData = {
                series: [{ name: "Excess Inventory", data: [] },
                { name: "Inventory", data: [] },
                { name: "Units Sold", data: [] }],

                labels: [],
                yearData: [],
            }
            let quartersObj = {};
            res.data.data.reverse().map(item => {
                graphData.series[0].data.push(item.excess_inv_sum)
                graphData.series[1].data.push(item.inv_sum)
                graphData.series[2].data.push(item.unit_sold_sum)

                if (!graphData.labels.includes(item.week)) {
                    graphData.labels.push(item.week)
                }
                if (!graphData.yearData.includes(item.week)) {
                    graphData.yearData.push({ week: item.week, year: item.year });
                }
                if(!quartersObj?.[`${item.quarter}-${item.year}`]) {
                    quartersObj[`${item.quarter}-${item.year}`] = {
                        weeks: [item.week],
                        quarter: item.quarter,
                        year: item.year
                    };
                }
                else if(!quartersObj[`${item.quarter}-${item.year}`].weeks.includes(item.week)){
                    quartersObj[`${item.quarter}-${item.year}`].weeks.push(item.week);
                }
            })
            if (graphData.labels.length === 0) {
                yield put({ type: actionTypes.GET_EXCESS_GRAPH_DATA_ERROR, error: "No Data for Selected Filters !!" });    
                return;
            }
            yield put({ type: actionTypes.GET_EXCESS_GRAPH_DATA_SUCCESS, graphData, quarters: Object.values(quartersObj).reverse(), yearData: graphData.yearData });
        }
        else {
            yield put({ type: actionTypes.GET_EXCESS_GRAPH_DATA_ERROR, error: ERROR_MSG });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_EXCESS_GRAPH_DATA_ERROR, error: ERROR_MSG });
    }
}

// function* fetchFilterDataWatcher() {
//     yield takeLatest(actionTypes.FETCH_FILTER_DATA, fetchFilterDataWorker)
// }

function* getExcessInvDataWatcher() {
    yield takeLatest(actionTypes.GET_EXCESS_DATA, getExcessInvDataWorker)
}

function* getExcessGraphDataWatcher() {
    yield takeLatest(actionTypes.GET_EXCESS_GRAPH_DATA, getExcessGraphDataWorker)
}

function* fetchFilterDataWatcher() {
    yield takeEvery(actionTypes.FETCH_FILTER_DATA, fetchFilterDataWorker)
}

function* generateExcelWatcher() {
    yield takeLatest(actionTypes.GENERATE_EXCEL, generateExcelWorker)
}


export function* excessSaga() {
    yield all([
        // fetchFilterDataWatcher(),
        getExcessInvDataWatcher(),
        getExcessGraphDataWatcher(),
        fetchFilterDataWatcher(),
        generateExcelWatcher()
    ])
}