import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import PageLoader from '../../components/Loader/PageLoader';
import './ProductProfile.css'
import { PROFILE_PAGE } from '../../constants/routeConstants'
import IARecommendedProfile from './ProductProfileTable/IARecommendedProfile';
import UserCreatedProfile from './ProductProfileTable/UserCreatedProfile';
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
import { resetFiltersOptions } from './ProductProfileAction';
import { connect } from 'react-redux';

function ProductProfile(props) {
    let history = useHistory();
    const [activeTab, setActiveTab] = useState("ppu")

    const handleSelect = (p_tab) => {
        setActiveTab(p_tab)
    }

  useEffect(() => {
    return () => {
        props.resetFiltersOptions([]);
    }
  }, [])


    return (
        <div className="product__profile_container">
        <div className="container__header__with__button">
            <h1 className="fnt-lg fnt-bold">Product Profile</h1>
            <div className="container__button">
                <button className="btn-primary-rounded" onClick={() => history.push(`${PROFILE_PAGE}/create`)}>
                    <i className="fa fa-plus" title="Create Product Profile" aria-hidden="true"></i>
                </button>
                <p className="fnt-sm">Create Product Profile</p>
            </div>
        </div>
        <div className="container__body">
                <div className="container__tabs">
                    <Tabs defaultActiveKey="ppu" onSelect={handleSelect}>
                        <Tab eventKey="ppu" title="User Created">
                            {
                                activeTab === "ppu" &&
                               <UserCreatedProfile activeTab={activeTab}/>
                            }
                        </Tab>
                        <Tab eventKey="ppi" title="IA Recommended">
                            {
                                activeTab === "ppi" &&
                               <IARecommendedProfile activeTab={activeTab}/>
                            }
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  });

export default connect(null, mapDispatchToProps)(ProductProfile)
