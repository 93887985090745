import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import StrategyConfigView from './views/ StrategyConfigView';
import * as strategyConfigActions from './actions/StrategyConfigActions';

/**
 * Container class with handler methods for Strategy Configuration Table
 */
class StrategyConfigurationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleResetStrategyConfig = this.handleResetStrategyConfig.bind(this);
        this.handleFilterRequest = this.handleFilterRequest.bind(this);
    }

    handleResetStrategyConfig() {
        const { strategyConfigActionsProp } = this.props;
        strategyConfigActionsProp.resetStrategyConfigScreen();
        strategyConfigActionsProp.resetSaveStrategyScreen();
    }

    handleFilterRequest(filters) {
        const { strategyConfigActionsProp } = this.props;
        if (filters) {
           var selectedFilters = filters
        } 
        strategyConfigActionsProp.filterClicked();
        strategyConfigActionsProp.getStrategyConfigTableDetails({...selectedFilters,row_index:0, row_count:100});
    }

    render() {

        return (
            <>
                 <StrategyConfigView handleFilterRequest={this.handleFilterRequest} handleResetStratergy={this.handleResetStrategyConfig}/>
            </>
        )
    }
}

const mapStateToProps = ({ strategyConfiguration }) => ({

});

const mapDispatchToProps = (dispatch) => ({
    strategyConfigActionsProp: bindActionCreators(strategyConfigActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StrategyConfigurationContainer);