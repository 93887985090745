import { updateArticleName } from "../../../../utils/filterLevelMapping"

export const REVIEW_HEADER = "Review Allocation"
export const REVIEW_TABLE_HEADERS = {
    STYLE: "Style",
    ARTICLE:  `${updateArticleName(false)} ID`,
    STYLE_COLOR: "Style Color",
    DESC: "Description",
    GLOBAL_COLOR: "Global Color",
    DEPARTMENT: "Department",
    GENDER: "Gender",
    SUB: "Sub Category",
    DCS: "DCS",
    DROP: "Inv Available",
    STORE_ALLOCATION: "Store Allocation",
    DEMAND: "Demand Estimated",
    RESERVED: "Reserved %"
}