import { all } from "redux-saga/effects";
import { dailySummarySaga } from "./DailySummarySaga"
import { storeViewSaga } from "./StoreView/StoreViewSaga"

/**
 * Root saga
 */
export function* dailySummarySagas() {
    yield all([
        dailySummarySaga(),
        storeViewSaga()
    ]);
}