import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import "../../components/Table/Cellrenderer/CellRenderer.scss";

export const today = moment(new Date()).format("YYYY-MM-DD");
const DateInput = React.memo((props) => {
  let ref = useRef();

  const [date, setDate] = useState(null);

  useEffect(() => {
    if (props.defaultValue) setDate(props.defaultValue);
    else setDate("Select Date");
  }, [props.defaultValue]);

  const onDateChange = (e) => {
    if (e.target.value) {
      e.target.setAttribute(
        "data-date",
        moment(e.target.value, "YYYY-MM-DD").format(props.dateFormat)
      );
      setDate(e.target.value);
      props.onChange(e.target.value);
    } else {
      setDate("");
      e.target.setAttribute("data-date", "MM-DD-YYYY");
      props.onChange(e.target.value);
    }
  };

  const onFocusHandler = (e) => {
    if (!date || date == "Select Date") {
      e.target.setAttribute("data-date", "MM-DD-YYYY");
    } else {
      e.target.setAttribute(
        "data-date",
        moment(date).format(props.dateFormat)
      );
    }
    e.target.type = "date";
  };

  return (
    <div>
      <input
        type="text"
        onFocus={(e) => onFocusHandler(e)}
        // OnBlur is used to propagate the proper dates when user click outside the date filter.
        onBlur={(e) => {
          e.target.type = "text"
          // ref will update the input value to current date.
          ref.current.value = date
        }}
        id="date"
        ref={ref}
        className="date-input"
        {...props.minOrmax}
        data-date=""
        value={date || ""}
        onChange={onDateChange}
      />
    </div>
  );
});

export default DateInput;
