export const HEADER = "New Stores"
export const ARTICLE_TABLE_HEADER = {
    STYLE_ID: "Style Color ID",
    DESC: "Style Desc",
    STYLE_COLOR: "Style Color",
    DEPARMENT: "Department",
    SUB_DEPT: "Sub Department",
    CLASS: "Class",
    SUB_CLASS: "Sub Class",
    RESERVED_UNITS: "Reserved Units",
    PO: "PO ID",
    ASN: "ASN ID",
    INVENTORY: "Inventory",
    PACK: "PACK ID",
    ALLOCATED_UNITS: "Allocated Units",
    PACKS_COUNT: "# Packs",
    EACHES_COUNT: "# Eaches",
    TOTAL: "Total",
    ORIGINAL_RESERVED: "Original Reserved"
}